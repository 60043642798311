import { Bounce, toast } from "react-toastify";
import Swal from "sweetalert2";

export function checkErrors (response , errorMessage) {
    console.log(response)
    errorMessage && console.log(errorMessage)
    if(response) {
        if(response.status === 401 || response.status_code === 401) {
            // if(window.location.pathname !== "/login-page") {
                // Swal.fire({
                //     title: "Un Authinticated!",
                //     text: "You Should Login First!",
                //     icon: 'error',
                //     confirmButtonText: 'OK'
                // });
            if (window.location.pathname !== "/login-page") {
                window.localStorage.clear();
                window.location.pathname = "/login-page";
            }else {
                Swal.fire({
                    title: "Un Authinticated!",
                    text: "Please Try Again!",
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        }else {
            if(response.data.errors && Object.keys(response.data.errors).length >= 1) {
                const errors = response.data.errors;
                console.log(errors)
                for (const key in errors) {
                    if (errors.hasOwnProperty(key)) {
                        const errorMessages = errors[key];
                        errorMessages.forEach(errorMessage => {
                            toast.error(
                                errorMessage , {
                                position: "top-right",
                                autoClose: 5000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "light",
                                transition: Bounce,
                            });
                        });
                    }
                }
            }else {
                Swal.fire({
                    title: "Error!",
                    text: response.data.message,
                    icon: 'error',
                    confirmButtonText: 'OK'
                });
            }
        }
    }else {
        Swal.fire({
            title: "Error!",
            text: errorMessage,
            icon: 'error',
            confirmButtonText: 'OK'
        });
    }
}