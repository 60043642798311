import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import ContextApi from "../../../store/ContextApi";
import { Spinner, Table } from "react-bootstrap";
import { getInvoicesResultsFunc } from "../../../store/custom-actions/invoices-actions/GetInvoicesResultsSlicer";
import faildImg from "../../../media/images/planet.png"
import PageTitle from "../../layouts/PageTitle";
import { Link } from "react-router-dom";
import { getMoreDetailsInvoicesFunc } from "../../../store/custom-actions/invoices-actions/GetMoreDetailsInvoicesSlicer";
function InvoicesResultsMoreDetailsPage() {
    const contextApi = useContext(ContextApi);
    const earningsInvoicesMoreDetailsApi = `${contextApi.dashboard_URL_API}/report/invoices`
    const [details , setDetails] = useState([]);
    const dispatch = useDispatch();
    const {data:invoicesDeatils , loading} = useSelector(state => state.getMoreDetailsInvoicesSlicer);
    const [yearFilter , setYearFilter] = useState("");
    const [monthFilter , setMonthFilter] = useState("");
    useEffect(() => {
        const getResults = () => {
            dispatch(getMoreDetailsInvoicesFunc(`${earningsInvoicesMoreDetailsApi}?${yearFilter !== "" ? `year=${yearFilter}` : ""}&${(monthFilter !== "" || monthFilter !== 0) ? `month=${monthFilter}` : ""}`))
        }
        getResults();
    },[monthFilter , yearFilter])

    useEffect(() => {
        invoicesDeatils && setDetails(invoicesDeatils)
    },[invoicesDeatils])
    const monthsArray = [1 , 2 , 3 , 4 , 5 , 6 , 7 , 8 , 9 , 10 , 11 , 12]
    return (
        <div className="relative">
            <PageTitle motherMenu={"Home"} activeMenu={"Invoices Details"} />
            <div className="filter-div relative row flex justify-end mb-3">
                <div className="relative col-sm-12 col-md-3">
                    <select 
                        className="form-control"
                        onChange={(e) => setMonthFilter(e.target.value)}
                        defaultValue={0}
                    >
                        <option disabled value={0}>Filter with month</option>
                        <option value={""}>All</option>
                        {
                            monthsArray
                            &&
                            monthsArray.map((month , index) => {
                                return <option key={index} value={month}>{month}</option>
                            })
                        }
                    </select>
                </div>
                <div className="input-group search-area w-auto">
                    <span className="input-group-text">
                        <Link to={"#"}>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                            d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                            fill="var(--primary)"
                            ></path>
                        </svg>
                        </Link>
                    </span>
                    <input
                        type="text"
                        className="form-control"
                        placeholder="Search with year.."
                        onChange={(e) => {
                            setYearFilter(e.target.value);
                        }}
                    />
                </div>
            </div>
            {
                loading
                ?
                (
                    <>
                        <div className="row d-flex justify-content-center gap-2">
                            <Spinner animation="grow" className="bg-primary" />
                            <Spinner animation="grow" className="bg-primary" />
                            <Spinner animation="grow" className="bg-primary" />
                            <Spinner animation="grow" className="bg-primary" />
                        </div>
                    </>
                )
                :
                (
                    invoicesDeatils
                    ?
                    (
                        <>
                            <Table responsive className="bg-white rounded-md">
                                <thead>
                                    <tr>
                                    <th>
                                        <strong className="text-primary">Student</strong>
                                    </th>
                                    <th>
                                        <strong className="text-primary">Course</strong>
                                    </th>
                                    <th>
                                        <strong className="text-primary">Category</strong>
                                    </th>
                                    <th>
                                        <strong className="text-primary">Execution</strong>
                                    </th>
                                    <th>
                                        <strong className="text-primary">Price</strong>
                                    </th>
                                    <th>
                                        <strong className="text-primary">Discount</strong>
                                    </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    details
                                    &&
                                    details.length > 0 
                                    ?
                                    details.map((detail,index)=>{
                                    return (
                                    <tr key={index}>
                                        <td>
                                            <i className="fa-solid fa-graduation-cap mr-1"></i>
                                            {detail.student_name}
                                        </td>
                                        <td>{detail.course_name}</td>
                                        <td>{detail.category_name}</td>
                                        <td>{detail.execution_name}</td>
                                        <td>&pound;{detail.execution_price}</td>
                                        <td>&pound;{detail.execution_descount}</td>
                                        {/* <td className="relative flex items-center">
                                            <i class="fa-solid fa-money-bill-wave mr-1 text-green-400"></i>
                                            $ {result.grand_total}
                                        </td> */}
                                    </tr>
                                    )
                                    })
                                    :
                                    <tr>
                                        <td colSpan={4}>No Details!</td>
                                    </tr>
                            }
                                </tbody>
                            </Table>
                        </>
                    )
                    :
                    <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                        <img
                        src={faildImg}
                        alt="faild img"
                        className="w-[150px] mb-3"
                        />
                        <p>
                        Faild load Details!
                        <span
                            className="text-[#4d5fe3] cursor-pointer"
                            onClick={() =>
                            dispatch(getMoreDetailsInvoicesFunc(earningsInvoicesMoreDetailsApi))
                            }
                        >
                            Try again
                        </span>
                        </p>
                    </div>
                )
            }
        </div>
    )
}

export default InvoicesResultsMoreDetailsPage