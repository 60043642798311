import { useContext , useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ContextApi from "../../../store/ContextApi";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { fetchAllCategoriesFunc } from "../../../store/custom-actions/categories-actions/GetAllCategoriesSlicer";
import { fetchAllCoursesFunc } from "../../../store/custom-actions/courses-actions/GetAllCoursesSlicer";
import { getInvoicesFunc } from "../../../store/custom-actions/invoices-actions/GetInvoicesSlicer";
import defaultStImg from "../../../media/images/graduated.png";
import { Spinner } from "react-bootstrap";
import { postMessageToStudentsFunc } from "../../../store/custom-actions/notfications-slicer/SendNotficationSlicer";
import Swal from "sweetalert2";
import "./send-notfication-age.css";
import sendIcon from "../../../media/images/send.png"
import { useTranslation } from "react-i18next";
import { fetchUsersFunc } from "../../../store/custom-actions/users-actions/GetUsersSlicer";
import checkUserRole from "../../global-functions/checkUserRole";
function SendNotficationPage() {
    const user = JSON.parse(window.localStorage.getItem("user"))
    const userRoleName = user && user.roleName && user.roleName.toLowerCase();
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const categories_api = `${contextApi.dashboard_URL_API}/categories`;
    const courses_API_URL = `${contextApi.dashboard_URL_API}/courses`;
    const invoicesApi = `${contextApi.dashboard_URL_API}/subscriptions?payment_status=confirmed`;
    const notfications_API_URL = `${contextApi.dashboard_URL_API}/notifications/send-student`;
    const dispatch = useDispatch();
    const {register , handleSubmit , reset , formState : {errors}} = useForm();
    const [categoriesList , setCategoriesList] = useState([]);
    const [coursesList , setCoursesList] = useState([]);
    const [courseId , setCourseId] = useState(0);
    const [messageSubject , setMessageSubject] = useState();
    const [instructorFilter , setInstructorFilter] = useState();
    const [studentsList , setStudentsList] = useState([]);
    const [stArr , setStArr] = useState([])
    const { loading:getCategoriesLoading , categoriesData } = useSelector(
        (state) => state.getAllCategoriesSlicer
    );
    const { loading:getCoursesLoading , courses } = useSelector(
        (state) => state.getAllCoursesSlicer
    );
    const { loading:getInvoicesLoading, data:invoicesData } = useSelector(
        (state) => state.getInvoicesSlicer
    );
    const { loading:sendMessageLoading } = useSelector(
        (state) => state.postMessageToStudentSlicer
    );
    const {loading , data:users} = useSelector(
        state => state.fetchUsersSlicer
    )
    const users_API = `${contextApi.dashboard_URL_API}/users`
    const [usersList , setUsersList] = useState([]);
    useEffect(() => {
        const getData = () => {
        dispatch(fetchUsersFunc(users_API))
        }
        userRoleName === "manager" && getData()
    },[])
    useEffect(()=>{
        const notMangerUsers = users && users.filter(user => user.roles[0].name.toLowerCase() === "teacher");
        users && notMangerUsers && setUsersList(notMangerUsers);
        notMangerUsers && setInstructorFilter(notMangerUsers[0].id)
    },[users]);
    useEffect(() => {
        dispatch(fetchAllCoursesFunc(`${courses_API_URL}?teacher_id=${instructorFilter ? instructorFilter : user?.id}`))
    },[instructorFilter])
    useEffect(() => {
        courses && setCoursesList(courses);
        setCourseId(0);
        setStudentsList([])
    },[courses])
    useEffect(() => {
        courseId && dispatch(getInvoicesFunc(`${invoicesApi}&course_id=${courseId}`));
    },[courseId])
    useEffect(() => {
        const students = invoicesData && invoicesData.flatMap(subscripe => subscripe.student);
        setStudentsList(students)
    },[invoicesData])
    const modules = {
        toolbar: {
        container: [
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['link', 'image', 'video'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'align': [] }],
        ['clean']
        ],
    }
    };
    const handleSubmitMessage = async (data) => {
        if(stArr.length < 1) {
            Swal.fire({
                title: t("Oops!"),
                text: t("You Should Choose At Least One Student To Send Message!"),
                icon: "warning"
            });
        }else {
            dispatch(postMessageToStudentsFunc({messagesApi : notfications_API_URL , messageData : {...data , students : stArr , subject : messageSubject}}))
            .then(result => {
                if(result.payload.success === true) {
                    setTimeout(() => window.history.back() , 2000);
                    reset();
                    setMessageSubject("");
                    setStArr([]);
                }
            })
        }
    }
    const handleCheckAll = () => {
        const allStudents = document.querySelectorAll(".st-checkox");
        if (allStudents) {
            allStudents.forEach((st) => {
                st.checked = true;  // This sets each checkbox to checked
                st.dataset.id && setStArr((prev) => [...prev , st.dataset.id])
            });
        }
    }
    const handleUnCheckAll = () => {
        const allStudents = document.querySelectorAll(".st-checkox");
        if (allStudents) {
            allStudents.forEach((st) => {
                st.checked = false;  // This sets each checkbox to checked
            });
        }
    }
    return (
        <>
        
            <div className="relative flex row justify-between items-center mb-3">
                <div className="relative flex items-center">
                    <img src={sendIcon} alt="send icon" className="w-[20px] mr-1" />
                    <h4 className="m-0">{t("New Message")}</h4>
                </div>
                <div className="row  flex items-center justify-end">
                    <button
                    type="submit"
                    form="send-message-form"
                    class={`flex items-center bg-blue-500 text-white gap-1 px-4 py-2 cursor-pointer font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 hover:gap-2 hover:translate-x-3 ${sendMessageLoading  ? "pointer-events-none opacity-80" : ""}`}
                    >
                        {
                            sendMessageLoading
                            ?
                            <>{("Please Wait")}...</>
                            :
                            <>
                            {t("Submit")}
                            <svg
                                class="w-5 h-5"
                                stroke="currentColor"
                                stroke-width="1.5"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                                stroke-linejoin="round"
                                stroke-linecap="round"
                                ></path>
                            </svg>
                            </>
                        }
                    </button>
                    <button type="reset" className="" form="new-lesson-form"
                        onClick={() => {
                            setMessageSubject("")
                            reset();
                        }}
                    >{t("Reset Values")}</button>
                </div>
            </div>

            <div className="new-lesson-form-div relative w-full ">
                <form 
                    className="send-message-formm"
                    id="send-message-form"
                    onSubmit={handleSubmit((data) => {
                        handleSubmitMessage(data)
                    })}
                >
                    
                        <>
                            <div className={'row'}>
                                <div className="form-group mb-3 col-sm-12">
                                    <label className="flex items-center">
                                        <i className="fa-brands fa-cuttlefish mr-1"></i>
                                        {t("Title")}
                                    </label>
                                    {errors.title?.message && (
                                    <p className="text-red-600">
                                        {errors.title?.message}
                                    </p>
                                    )}
                                    <input
                                    {...register("title", {
                                        required: "Title is required!"
                                    })}
                                    type="text"
                                    className="form-control"
                                    />
                                </div>
                            </div>
                                {
                                    checkUserRole
                                    (
                                        ["manager"],
                                        <div className={`row`}>
                                            {
                                            loading
                                            ?
                                            <div className="form-group flex items-center">
                                                <p>{t("loading")}...</p>
                                            </div>
                                            :
                                            (
                                                users && usersList && usersList.length <= 0
                                                ?
                                                <div className="form-group flex items-center">
                                                    <p>{t("No data avilable")}</p>
                                                </div>
                                                :
                                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                                <label>{t("Instructor")}</label>
                                                {errors.teacher_id?.message && (
                                                    <p className="text-red-600">
                                                    {errors.teacher_id?.message}
                                                    </p>
                                                )}
                                                <select
                                                    className={`form-control ${sendMessageLoading ? "pointer-events-none opacity-80" : ""}`}
                                                    onChange={(e) => setInstructorFilter(e.target.value)}
                                                    defaultValue={0}
                                                >
                                                    <option value={0} disabled>{t("Choose Instructor")}...</option>
                                                    {
                                                    usersList &&
                                                    usersList.map((item , index) => {
                                                        return (
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                        )
                                                    })
                                                    }
                                                </select>
                                                </div>
                                            )
                                            }

                                        </div>
                                    )
                                }
                            <div className="students-line mb-3 relative my-3 flex justify-center items-center after:absolute after:left-[50%] after:translate-x-[-50%] after:top-[50%] after:px-2 after:bg-[#eaebef] after:content-['Message Subject'] after:translate-y-[-50%] bg-slate-300 w-full h-[1px]"></div>
                            <h4 className="my-2">{t("Select Students")} <span className='text-sm font-normal'>({t("At Least 1 Student")})</span></h4>
                            <div className='row my-4'>
                                {
                                getInvoicesLoading
                                ?
                                (
                                <div className="row d-flex justify-content-center gap-2">
                                    <Spinner animation="grow" className="bg-primary" />
                                    <Spinner animation="grow" className="bg-primary" />
                                    <Spinner animation="grow" className="bg-primary" />
                                    <Spinner animation="grow" className="bg-primary" />
                                </div>
                                )
                                :
                                <>
                                    {
                                        invoicesData &&
                                        studentsList.length >= 1
                                        &&
                                        <div className='cursor-pointer justify-start m-1 col-sm-10 col-md-5 col-lg-2 flex items-center'>
                                        <input 
                                        onChange={(e)=>{
                                            if(e.target.checked){
                                                handleCheckAll();
                                            }else {
                                                handleUnCheckAll();
                                                setStArr([])
                                            }
                                        }}
                                        type='checkbox' 
                                        className={`st-checkox cursor-pointer m-0 ${sendMessageLoading ? "pointer-events-none opacity-80" : ""}`} 
                                        id={"all"}/>
                                        <label className={`cursor-pointer flex items-center ml-1 mt-1 ${sendMessageLoading ? "pointer-events-none opacity-80" : ""}`} htmlFor={"all"}>
                                            <p className='m-0'>{t("Select All")}</p>
                                        </label>
                                        </div>
                                    }
                                    {
                                        courseId !== 0 &&
                                        invoicesData &&
                                        studentsList.length >= 1
                                        ?
                                        studentsList.map((student , index) => {
                                            return (
                                            <>
                                                <div key={index} className='cursor-pointer justify-start m-1 col-sm-10 col-md-5 col-lg-2 flex items-center'>
                                                <input 
                                                onChange={(e)=>{
                                                    if(e.target.checked){
                                                    stArr.push(student.id);
                                                    }else {
                                                    let filtred = stArr.filter(st => {
                                                        return st !== student.id
                                                    });
                                                    setStArr([...filtred]);
                                                    }
                                                }}
                                                data-id = {student.id}
                                                type='checkbox' 
                                                className={`st-checkox cursor-pointer m-0 ${sendMessageLoading ? "pointer-events-none opacity-80" : ""}`} 
                                                id={index}/>
                                                <label className={`cursor-pointer flex items-center ml-1 mt-1 ${sendMessageLoading ? "pointer-events-none opacity-80" : ""}`} htmlFor={index}>
                                                    <img src={student.image_url !== null ? student.image_url : defaultStImg} className='relative rounded-[50%] w-[25px] mx-2' alt="st img" />
                                                    <p className='m-0'>{student.name}</p>
                                                </label>
                                                </div>
                                            </>
                                            )
                                        })
                                        :
                                        <p>{t("There are no students!")}</p>

                                    }
                                </>
                                }
                            </div>
                            <div className="message-subject-line mb-3 relative my-3 flex justify-center items-center after:absolute after:left-[50%] after:translate-x-[-50%] after:top-[50%] after:px-2 after:bg-[#eaebef] after:content-['Message Subject'] after:translate-y-[-50%] bg-slate-300 w-full h-[1px]"></div>
                            <div className="row">
                                <ReactQuill 
                                modules = {modules}
                                className="p-0 border-none outline-none form-control bg-white mt-3" 
                                value={messageSubject} theme="snow" 
                                onChange={setMessageSubject} />
                            </div>
                        </>
                </form>
            </div>
            {/* <OverLayLoading loading={addNewLessonLoading} loadingText={"Loading..."}/> */}
        </>
        
    )
}

export default SendNotficationPage;