import { useContext, useEffect, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ContextApi from "../../../../store/ContextApi";
import emptyImg from "../../../../media/images/planet.png"
import { getSingleLessonSchuadleFunc } from "../../../../store/custom-actions/lessons-actions/lessons-schuadles-actions/GetSingleLessonSchuadleSlicer";
import videoIcon from "../../../../media/images/video-camera.png";
import quizIcon from "../../../../media/images/quiz.png";
import fileIcon from "../../../../media/images/google-docs.png";
import pdfIcon from "../../../../media/images/file.png";
import rich_textIcon from "../../../../media/images/files.png";
import { useTranslation } from "react-i18next";
export default function ShowLessonsInTheSameSchuadle(props) {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const {lesson_schadule_id , lesson_title} = props;
    const contextApi = useContext(ContextApi);
    const single_lesson_schuadle_API_URL = `${contextApi.dashboard_URL_API}/lesson-schadules/${lesson_schadule_id}`
    const {loading , data} = useSelector(state => state.getSingleLessonSchuadleSlicer)
    const [lessonsList , setLessonsList] = useState([])
    useEffect(() => {
        dispatch(getSingleLessonSchuadleFunc(single_lesson_schuadle_API_URL))
    },[])
    useEffect(() => {
        data && data.lessons && setLessonsList(data.lessons)
    },[data])
    // const deleteQuestionFunction = (questionId) => {
    //     swal({
    //         title: "Confirm Question Deletion",
    //         text: `Once deleted, will not be Question in this quize!`,
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //         }).then((willDelete) => {
    //         if (willDelete) {
    //             console.log(`${delete_question_API_URL}/${questionId}`)
    //             dispatch(deleteQuestionFunc(`${delete_question_API_URL}/${questionId}`))
    //             .then(result => {
    //                 if(result.payload.success === true) {
    //                     props.onHide();
    //                 }
    //             });
    //         } else {
    //             swal("Question is safe!");
    //         }
    //         });
    // }


    // let questionType;

    // const checkQuestionType = (question) => {
    //     switch(question.type) {
    //     case 1:
    //         questionType = "True & False";
    //         break;
    //     case 2:
    //         questionType = "Choose Question";
    //         break;
    //     case 3:
    //         questionType = "Essay Question";
    //         break;
    //     default:
    //         questionType = "no type"
    //     }
    // }
    // const showOptionsFunc = (question) => {
    //   if(question.options !== null) {
    //     setShowOptions(true)
    //     setQuestionOptions(question.options)
    //     setCorrectOption(question.correct_option)
    //   }else {
    //     Swal.fire("Sorry, No options for this question!");
    //   }
    // }
    const checkLessonType = (lessonType) => {
        if(lessonType) {
            switch(lessonType) {
                case "video" :
                return <img src={videoIcon} alt="icon" className="relative mr-1 w-[20px]" />
                case "quiz" :
                return <img src={quizIcon} alt="icon" className="relative mr-1 w-[20px]" />
                case "file" :
                return <img src={fileIcon} alt="icon" className="relative mr-1 w-[20px]" />
                case "pdf" :
                return <img src={pdfIcon} alt="icon" className="relative mr-1 w-[20px]" />
                case "rich_text" :
                return <img src={rich_textIcon} alt="icon" className="relative mr-1 w-[20px]" />
                default :
                return <img src={videoIcon} alt="icon" className="relative mr-1 w-[20px]" />

            }
        }
    }


  return (
    <>
      <Modal
        size="lg"
        show={props.show}
        onHide={props.onHide}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {t("Lessons in the same schadule")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                loading
                ?
                <h4 className="relative flex items-center">
                    <span class="relative flex h-3 w-3 mr-2">
                        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                        <span class="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                    </span>
                    {t("loading")}...
                </h4>
                :
                (
                lessonsList && lessonsList.length > 0 ?
                <Table responsive>
                    <thead>
                      <tr>
                        <th className="width80">
                          <strong>#{t("ID")}</strong>
                        </th>
                        <th>
                          <strong>{t("Name")}</strong>
                        </th>
                        {/* <th>
                          <strong>GRADE</strong>
                        </th> */}
                        <th>
                          <strong>{t("Type")}</strong>
                        </th>
                        {/* <th>
                          <strong>OPTIONS</strong>
                        </th>
                        <th>
                        <strong>ACTIONS</strong>
                        </th> */}
                      </tr>
                    </thead>
                    <tbody>
                        {lessonsList && lessonsList.map((lesson,index)=>{
                            return (
                            <tr key={index}>
                                <td>
                                    <strong>#{lesson.lesson_id}</strong>
                                </td>
                                <td>{lesson.name}</td>
                                <td>
                                    <h4 className="relative flex items-center">
                                        {checkLessonType(lesson.type)}
                                        {lesson.type}
                                    </h4>
                                </td>
                                {/* <td>
                                    {
                                        questionType !== "essay question"
                                        ?
                                        <button className="show-options btn btn-primary"
                                        onClick = {()=>{
                                        showOptionsFunc(question)
                                        }}
                                    
                                    >
                                        Show Options
                                    </button>
                                    :
                                    <p>No options</p>
                                    }
                                </td>
                                <td>
                                    <DeletingBtn
                                        key={index}
                                        question={question}
                                        delete_question_function={deleteQuestionFunction}
                                    />
                                </td> */}
                            </tr>
                            )
                        })}
                    </tbody>
                </Table>
                :
                <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                    <img
                    src={emptyImg}
                    alt="faild img"
                    className="w-[150px] mb-3 opacity-80"
                    />
                    <p>
                        {t("No Lessons In The Same Schuadle!")}
                    </p>
                </div>
                )
            }
        </Modal.Body>
      </Modal>
    </>
  );
}