import "./courses.css";
import { useParams , Link, useNavigate} from "react-router-dom";
import { useState , useEffect } from "react";
import { Tab, Nav , Spinner , Dropdown } from "react-bootstrap";
import "react-modal-video/css/modal-video.min.css";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { useContext } from "react";
import ContextApi from "../../../store/ContextApi";
import { fetchSingleCourseFunc } from "../../../store/custom-actions/courses-actions/GetSingleCourseSlicer";
import faildImg from "../../../media/images/fail-img.svg";
import Swal from "sweetalert2";
import { deleteCourseFunc } from "../../../store/custom-actions/courses-actions/DeleteCourseSlicer";
import UpdateCourseImageForm from "../../global-compnents/courses-forms/UpdateCourseImage";
import UpdateCourseForm from "../../global-compnents/courses-forms/UpdateCourseForm";
import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
import { getChaptersFunc } from "../../../store/custom-actions/chapters/GetChaptersSlicer";
import 'react-modal-video/css/modal-video.min.css';
import pic2 from './../../../images/courses/pic2.jpg';
import course1 from './../../../images/courses/course1.jpg';
import DecisionsDropMenu from "./CourseDescion";
import { getReviewsFunc } from "../../../store/custom-actions/reviews/GetReviewsSlicer";
import { deleteChapterFunc } from "../../../store/custom-actions/chapters/DeleteChapterSlicer";
import { useTranslation } from "react-i18next";
import ReviewRow from "./ReviewRow";

const CourseDetail1 =()=> {
    const {t} = useTranslation();
    const dispatch = useDispatch()
    const params = useParams();
    const courseId = params.courseId;
    // const [courseData , setCourseData] = useState({});
    // const [courseLessons , setCourseLessons] = useState();
    const contextApi = useContext(ContextApi)
    const courses_API_URL = `${contextApi.dashboard_URL_API}/courses`;
    const course_API_URL = `${contextApi.dashboard_URL_API}/courses/${courseId}`;
    // const [quizzesList , setQuizzesList] = useState([]);
    const [chapterSearchValue , setChapterSearchValue] = useState("");
    // const [courseStudents , setCourseStudents] = useState([]);
    const [courseExecutions , setCourseExecutions] = useState([]);
    const [courseName , setCourseName] = useState("");
    const [courseImage , setCourseImage] = useState("https://img.freepik.com/free-vector/students-watching-webinar-computer-studying-online_74855-15522.jpg?w=826&t=st=1702899442~exp=1702900042~hmac=f3c89975f0344fd66d9bfba0158a777d5e50d509a0b753d71c809ad690ab30f3");
    const [courseCategory , setCourseCategory] = useState("");
    const [description , setDescription] = useState("");
    // const [courseType , setCourseType] = useState();
    const [showUpdateImageForm , setShowUpdateImageForm] = useState();
    const [showUpdateDataForm , setShowUpdateDataForm] = useState();
    const navigate = useNavigate();
    // const {lessons} = useSelector(
    //   (state) => state.getCourseLessonsSlicer
    //   );
      // const {quizzesData} = useSelector(
      //   state => state.getQuizzesSlicer
      // )
    const { deleteCourseLoading } = useSelector(
        (state) => state.deleteCourseSlicer
    );
    const { loading , data } = useSelector(
      (state) => state.getSingleCourseSlicer
    );
    const { loading: getChaptersLoading, data:cahptersData } = useSelector(
      (state) => state.getChaptersSlicer
    );
    const { loading: getReviewsLoading, data:reviewsData } = useSelector(
      (state) => state.getReviewsSlicer
    );
    const {data:languageData} = useSelector(
      state => state.getLanguageSettingsSlicer
    )
    const [chaptersList, setChaptersList] = useState([]);
    const chaptersApi = `${contextApi.dashboard_URL_API}/courses/${courseId}/chapters`;
    const reviewsApi = `${contextApi.dashboard_URL_API}/courses/${courseId}/reviews`;
    const [reviewsList , setReviewsList] = useState([]);
    useEffect(() => {
      const getChapters = async () => {
        dispatch(getChaptersFunc(`${chaptersApi}`));
      };
      const getReviews = async () => {
        dispatch(getReviewsFunc(`${reviewsApi}`));
      };
      getChapters();
      getReviews();
    }, []);
    useEffect(() => {
      cahptersData && setChaptersList(cahptersData);
    }, [cahptersData]);
    useEffect(() => {
      reviewsData && setReviewsList(reviewsData);
    }, [reviewsData]);

    const refetchDate = async () => {
        try {
          dispatch(fetchSingleCourseFunc(`${courses_API_URL}/${courseId}`));
        }catch(error) {
          Swal.fire({
            title: "Oops!",
            text: error,
            icon: "error"
          });
        }
    } 

    useEffect(()=>{
      const getAllData = async () => {
          try {
            dispatch(fetchSingleCourseFunc(course_API_URL));
          }catch(error) {
            Swal.fire({
              title: "Oops!",
              text: error,
              icon: "error"
            });
          }
      } 
      getAllData()
    },[]);
    // useEffect(() => {
    //   lessons && setCourseLessons(lessons);
    // },[lessons])
    // useEffect(() => {
    //   quizzesData && setQuizzesList(quizzesData);
    // },[quizzesData])


    // for lessons search
    // useEffect(() => {
    //   let searchTimeout;
    //   const searchApi = `${chaptersApi}?${chapterSearchValue ? `search=${chapterSearchValue}` : ""}`
    //   const chapterSearch = () => {
    //     if(chapterSearchValue !== "") {
    //       clearTimeout(searchTimeout)
    //       searchTimeout = setTimeout(() => dispatch(getChaptersFunc(searchApi)),2000)
    //     }else {
    //       dispatch(getChaptersFunc(chaptersApi));
    //     }
    //   }
    //   chapterSearch()
    //   return () => clearTimeout(searchTimeout);
    // },[chapterSearchValue]);
    const [instructor , setInstructor] = useState({})
    // const [courseChapters , setCourseChapters] = useState([]);
    useEffect(() => {
      if (data) {
          // setCourseData(data);
          data.name && setCourseName(data.name);
          data.image_url && setCourseImage(data.image_url);
          data.category && data.category.name && setCourseCategory(data.category.name)
          data.description && setDescription(data.description);
          // data.type && setCourseType(data.type);
          data.user && setInstructor(data.user);
          // data.lessons && setCourseLessons(data.lessons);
          // data.chapters && setCourseChapters(data.chapters);
          data.executions && setCourseExecutions(data.executions);
      }else {
        console.log("no course data")
      }
    }, [data]);
    const deleteCourseFunction = async () => {
      swal({
        title: t("Confirm Course Deletion"),
        text: t(`Once deleted, course will not be avilable in platform!`),
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          dispatch(deleteCourseFunc(`${courses_API_URL}/${courseId}`))
          .then((result) => {
            if(result.payload.success === true){
              window.history.back();
            }
          })
        }
      });
    };

    const {loading:deleteChapterLoading} = useSelector(
      state => state.deleteChapterSlicer
    )
    const chapterApi = `${contextApi.dashboard_URL_API}/chapters`;
    const deleteChapter = async (id) => {
      swal({
        title: t("Confirm Deletion"),
        text: t(`Once deleted, Chapter will not be avilable!`),
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          dispatch(deleteChapterFunc(`${chapterApi}/${id}`))
          .then((result) => {
            if(result.payload.success === true){
              // window.history.back();
              dispatch(fetchSingleCourseFunc(course_API_URL));
            }
          })
        }
      });
    };
	return(
		<>
			<ol className="breadcrumb">
				<li className="breadcrumb-item active">
					<Link className="d-flex align-self-center" to={"/courses"}>
						<svg width="25" height="25" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M8.99981 12C8.99905 11.8684 9.02428 11.7379 9.07404 11.6161C9.12381 11.4942 9.19713 11.3834 9.28981 11.29L13.2898 7.28999C13.4781 7.10168 13.7335 6.9959 13.9998 6.9959C14.2661 6.9959 14.5215 7.10168 14.7098 7.28999C14.8981 7.47829 15.0039 7.73369 15.0039 7.99999C15.0039 8.26629 14.8981 8.52168 14.7098 8.70999L11.4098 12L14.6998 15.29C14.8636 15.4813 14.9492 15.7274 14.9395 15.979C14.9298 16.2307 14.8255 16.4695 14.6474 16.6475C14.4693 16.8256 14.2305 16.93 13.9789 16.9397C13.7272 16.9494 13.4811 16.8638 13.2898 16.7L9.28981 12.7C9.10507 12.5137 9.00092 12.2623 8.99981 12Z" fill="#374557"/>
						</svg>
						{t("Back")}	
					</Link>
				</li>
			</ol>
			{
        (loading || getChaptersLoading || getReviewsLoading)
        ?
        <>
          <div className="row d-flex justify-content-center gap-2">
            <Spinner animation="grow" className="bg-primary" />
            <Spinner animation="grow" className="bg-primary" />
            <Spinner animation="grow" className="bg-primary" />
            <Spinner animation="grow" className="bg-primary" />
          </div>
        </>
        :
        (
          data
          ?
          <div className="row">
            <div className="col-sm-12 col-xl-7">
              <div className="card  course-dedails-bx">
                <div className="card-header border-0 pb-0">
                  <h2>{courseName}</h2>
                  <DecisionsDropMenu
                    deleteFunction = {() => deleteCourseFunction()}
                    updateData = {() => setShowUpdateDataForm(true)}
                    updateImage = {() => setShowUpdateImageForm(true)}
                    courseId={courseId}
                    courseType={data.type}
                  />
                </div>
                <div className="card-body pt-0">
                  <div className="description">
                    <ul className="d-flex align-items-center raiting flex-wrap">
                      <li className={`${languageData?.defaultLang?.slug === "ar" ? "border-r-0" : "border-r-0"}`}>{courseCategory}</li>
                      <li className={`${languageData?.defaultLang?.slug === "ar" ? " border-r-0 border-l" : "border-r border-l-0 px-1"}`}>{t("Review")} ({reviewsList.length})</li>
                      {/* <li className={`${languageData?.defaultLang?.slug === "ar" ? " border-r-0 border-l" : "border-r border-l-0 px-1"}`}>{data.type}</li> */}
                    </ul>
                    <div className="user-pic mb-3">
                      <img src={instructor.image_url ? instructor.image_url : pic2} alt="img" className="m-0"/>
                      <span className="mx-2">{instructor.name}</span>
                    </div>
                  </div>
                  <div className="course-details-tab style-2">
                    <Tab.Container defaultActiveKey='About'>
                      <nav>
                        <Nav as='div' className="nav nav-tabs justify-content-start tab-auto" id="nav-tab" role="tablist">
                          <Nav.Link as='button' className="nav-link" id="nav-about-tab" eventKey='About' >{t("About")}</Nav.Link>
                          <Nav.Link as='button' className="nav-link" id="nav-reviews-tab"  eventKey='Chapters'>{t("Chapters")}</Nav.Link>
                          <Nav.Link as='button' className="nav-link" id="nav-reviews-tab"  eventKey='Reviews'>{t("Reviews")}</Nav.Link>
                        </Nav>
                      </nav>
                      <Tab.Content >
                        <Tab.Pane eventKey='About' className="about-course-content max-h-[400px] overflow-y-auto">
                           <div className="about-content">	
                            <h2>{t("About This Course")}</h2>
                            <div className="" dangerouslySetInnerHTML={{ __html: description }}/>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey='Reviews' className="reviews-parent-div max-h-[400px] overflow-y-auto">											
                          {
                          reviewsList && reviewsList.length >= 1
                          ?
                          reviewsList.map((item, index)=>(
                            <ReviewRow key={index} item={item} refetchDate={() => dispatch(getReviewsFunc(`${reviewsApi}`))} active={item.publish}/>
                          ))
                          :
                          <h4 className="my-2">{t("No data available")}</h4>
                          }	
                        </Tab.Pane>
                        <Tab.Pane eventKey='Chapters' className="chapters-course-content max-h-[400px]" style={{overflow : "inherit"}}>		
                          <div className="">	
                            {
                              chaptersList && chaptersList.length >=1 
                              ?
                              chaptersList.map((item, index)=>(
                              <div className="user-pic2" key={index}>
                                <div className="d-flex align-items-center">
                                  <img src={"https://img.freepik.com/free-psd/3d-team-work-concept-background_23-2151527886.jpg?t=st=1720331916~exp=1720335516~hmac=f0e7d2f701df436da6830780f90031ce6f6593ae66f7e4c31c4176e7b1859135&w=740"} alt="" />
                                  <div className="ms-3">
                                    <div className="relative flex justify-between items-center">
                                      <Link to={`/chapter-details/${item.id}`}>
                                        <h4>{item.name}</h4>
                                      </Link>
                                      <div className="relative flex items-center">

                                      </div>
                                      <Dropdown>
                                        <Dropdown.Toggle
                                          as="a"
                                          className="btn-link i-false btn sharp tp-btn-light btn-dark"
                                        >
                                          <svg
                                            width="24"
                                            height="25"
                                            viewBox="0 0 24 25"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M12.0012 9.86C11.6544 9.86 11.3109 9.92832 10.9905 10.061C10.67 10.1938 10.3789 10.3883 10.1336 10.6336C9.88835 10.8788 9.6938 11.17 9.56107 11.4905C9.42834 11.8109 9.36002 12.1544 9.36002 12.5012C9.36002 12.848 9.42834 13.1915 9.56107 13.5119C9.6938 13.8324 9.88835 14.1236 10.1336 14.3688C10.3789 14.6141 10.67 14.8086 10.9905 14.9413C11.3109 15.0741 11.6544 15.1424 12.0012 15.1424C12.7017 15.1422 13.3734 14.8638 13.8687 14.3684C14.3639 13.873 14.642 13.2011 14.6418 12.5006C14.6417 11.8001 14.3632 11.1284 13.8678 10.6332C13.3724 10.138 12.7005 9.85984 12 9.86H12.0012ZM3.60122 9.86C3.25437 9.86 2.91092 9.92832 2.59048 10.061C2.27003 10.1938 1.97887 10.3883 1.73361 10.6336C1.48835 10.8788 1.2938 11.17 1.16107 11.4905C1.02834 11.8109 0.960022 12.1544 0.960022 12.5012C0.960022 12.848 1.02834 13.1915 1.16107 13.5119C1.2938 13.8324 1.48835 14.1236 1.73361 14.3688C1.97887 14.6141 2.27003 14.8086 2.59048 14.9413C2.91092 15.0741 3.25437 15.1424 3.60122 15.1424C4.30171 15.1422 4.97345 14.8638 5.46866 14.3684C5.96387 13.873 6.24198 13.2011 6.24182 12.5006C6.24166 11.8001 5.96324 11.1284 5.46781 10.6332C4.97237 10.138 4.30051 9.85984 3.60002 9.86H3.60122ZM20.4012 9.86C20.0544 9.86 19.7109 9.92832 19.3905 10.061C19.07 10.1938 18.7789 10.3883 18.5336 10.6336C18.2884 10.8788 18.0938 11.17 17.9611 11.4905C17.8283 11.8109 17.76 12.1544 17.76 12.5012C17.76 12.848 17.8283 13.1915 17.9611 13.5119C18.0938 13.8324 18.2884 14.1236 18.5336 14.3688C18.7789 14.6141 19.07 14.8086 19.3905 14.9413C19.7109 15.0741 20.0544 15.1424 20.4012 15.1424C21.1017 15.1422 21.7734 14.8638 22.2687 14.3684C22.7639 13.873 23.042 13.2011 23.0418 12.5006C23.0417 11.8001 22.7632 11.1284 22.2678 10.6332C21.7724 10.138 21.1005 9.85984 20.4 9.86H20.4012Z"
                                              fill="#A098AE"
                                            />
                                          </svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu
                                          className="dropdown-menu dropdown-menu-end"
                                          align="right"
                                        >
                                          <Dropdown.Item
                                            onClick={() => {
                                              navigate(`/update-chapter/${item.id}`)
                                            }}
                                          >
                                            {/* <i className="fa-solid fa-ban mr-1"></i> */}
                                            <i className="fa-solid fa-gear mr-1"></i>
                                            {t("Edit")}
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => {
                                              navigate(`/show-lesson-schuadles/${item.id}`)
                                            }}
                                          >
                                            {/* <i className="fa-solid fa-ban mr-1"></i> */}
                                            <i className="fa-solid fa-calendar-days mr-1"></i>
                                            {t("Lessons Schuadles")}
                                          </Dropdown.Item>
                                          <Dropdown.Item
                                            onClick={() => {
                                              deleteChapter(item.id)
                                            }}
                                          >
                                            {/* <i className="fa-solid fa-ban mr-1"></i> */}
                                            <i className="fa-solid fa-ban mr-1"></i>
                                            {t("Delete")}
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    </div>
                                    <div className="" dangerouslySetInnerHTML={{ __html: item.description }}/>
                                  </div>
                                </div>
                              </div>
                            ))
                            :
                            <h4 className="my-2">{t("No data available")}</h4>
                            }	
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>	
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-xl-5">
              <div className="card">
                <div className="card-body">
                  <div className="video-img">
                    <div className="view-demo">
                      <img src={courseImage ? courseImage : course1} alt="course img" />
                    </div>	
                  </div>
                  <div className="course-prise d-flex justify-content-between align-items-center flex-wrap">
                  </div>
                  <div className="course-learn">
                    <h4>{t("Content")}:</h4>
                    <ul>
                        <li>
                          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-2">
                            <g clipPath="url(#clip0_1082_8)">
                            <path d="M8 0.5C6.41775 0.5 4.87104 0.969192 3.55544 1.84824C2.23985 2.72729 1.21447 3.97672 0.608967 5.43853C0.00346629 6.90034 -0.15496 8.50887 0.153721 10.0607C0.462403 11.6126 1.22433 13.038 2.34315 14.1569C3.46197 15.2757 4.88743 16.0376 6.43928 16.3463C7.99113 16.655 9.59966 16.4965 11.0615 15.891C12.5233 15.2855 13.7727 14.2602 14.6518 12.9446C15.5308 11.629 16 10.0822 16 8.5C16 6.37827 15.1571 4.34344 13.6569 2.84315C12.1566 1.34285 10.1217 0.5 8 0.5V0.5ZM12.5467 6.57L7.52667 12.2633C7.44566 12.3546 7.34742 12.429 7.23759 12.4822C7.12776 12.5354 7.00851 12.5663 6.88667 12.5733H6.82667C6.59929 12.5747 6.3795 12.4916 6.21 12.34L3.52334 9.95667C3.33857 9.79267 3.22652 9.562 3.21182 9.31539C3.19713 9.06878 3.28101 8.82643 3.445 8.64167C3.609 8.4569 3.83967 8.34484 4.08628 8.33015C4.33289 8.31546 4.57524 8.39934 4.76 8.56333L6.76 10.3267L11.1633 5.32667C11.3269 5.14146 11.5573 5.0288 11.8039 5.01349C12.0505 4.99817 12.2931 5.08145 12.4783 5.245C12.6635 5.40855 12.7762 5.63898 12.7915 5.88559C12.8068 6.1322 12.7236 6.37479 12.56 6.56L12.5467 6.57Z" fill="#4CBC9A"/>
                            </g>
                            <defs>
                            <clipPath id="clip00_1082_8">
                            <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                            </clipPath>
                            </defs>
                          </svg>
                          ( {chaptersList.length} ) {t("Chapters")}
                        </li>
                        <li>
                          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-2">
                            <g clipPath="url(#clip0_1082_8)">
                            <path d="M8 0.5C6.41775 0.5 4.87104 0.969192 3.55544 1.84824C2.23985 2.72729 1.21447 3.97672 0.608967 5.43853C0.00346629 6.90034 -0.15496 8.50887 0.153721 10.0607C0.462403 11.6126 1.22433 13.038 2.34315 14.1569C3.46197 15.2757 4.88743 16.0376 6.43928 16.3463C7.99113 16.655 9.59966 16.4965 11.0615 15.891C12.5233 15.2855 13.7727 14.2602 14.6518 12.9446C15.5308 11.629 16 10.0822 16 8.5C16 6.37827 15.1571 4.34344 13.6569 2.84315C12.1566 1.34285 10.1217 0.5 8 0.5V0.5ZM12.5467 6.57L7.52667 12.2633C7.44566 12.3546 7.34742 12.429 7.23759 12.4822C7.12776 12.5354 7.00851 12.5663 6.88667 12.5733H6.82667C6.59929 12.5747 6.3795 12.4916 6.21 12.34L3.52334 9.95667C3.33857 9.79267 3.22652 9.562 3.21182 9.31539C3.19713 9.06878 3.28101 8.82643 3.445 8.64167C3.609 8.4569 3.83967 8.34484 4.08628 8.33015C4.33289 8.31546 4.57524 8.39934 4.76 8.56333L6.76 10.3267L11.1633 5.32667C11.3269 5.14146 11.5573 5.0288 11.8039 5.01349C12.0505 4.99817 12.2931 5.08145 12.4783 5.245C12.6635 5.40855 12.7762 5.63898 12.7915 5.88559C12.8068 6.1322 12.7236 6.37479 12.56 6.56L12.5467 6.57Z" fill="#4CBC9A"/>
                            </g>
                            <defs>
                            <clipPath id="clip00_1082_8">
                            <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                            </clipPath>
                            </defs>
                          </svg>
                          ( {data.lessons && data.lessons.length} ) {t("Lessons")}
                        </li>
                        <li>
                          <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" className="mx-2">
                            <g clipPath="url(#clip0_1082_8)">
                            <path d="M8 0.5C6.41775 0.5 4.87104 0.969192 3.55544 1.84824C2.23985 2.72729 1.21447 3.97672 0.608967 5.43853C0.00346629 6.90034 -0.15496 8.50887 0.153721 10.0607C0.462403 11.6126 1.22433 13.038 2.34315 14.1569C3.46197 15.2757 4.88743 16.0376 6.43928 16.3463C7.99113 16.655 9.59966 16.4965 11.0615 15.891C12.5233 15.2855 13.7727 14.2602 14.6518 12.9446C15.5308 11.629 16 10.0822 16 8.5C16 6.37827 15.1571 4.34344 13.6569 2.84315C12.1566 1.34285 10.1217 0.5 8 0.5V0.5ZM12.5467 6.57L7.52667 12.2633C7.44566 12.3546 7.34742 12.429 7.23759 12.4822C7.12776 12.5354 7.00851 12.5663 6.88667 12.5733H6.82667C6.59929 12.5747 6.3795 12.4916 6.21 12.34L3.52334 9.95667C3.33857 9.79267 3.22652 9.562 3.21182 9.31539C3.19713 9.06878 3.28101 8.82643 3.445 8.64167C3.609 8.4569 3.83967 8.34484 4.08628 8.33015C4.33289 8.31546 4.57524 8.39934 4.76 8.56333L6.76 10.3267L11.1633 5.32667C11.3269 5.14146 11.5573 5.0288 11.8039 5.01349C12.0505 4.99817 12.2931 5.08145 12.4783 5.245C12.6635 5.40855 12.7762 5.63898 12.7915 5.88559C12.8068 6.1322 12.7236 6.37479 12.56 6.56L12.5467 6.57Z" fill="#4CBC9A"/>
                            </g>
                            <defs>
                            <clipPath id="clip00_1082_8">
                            <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
                            </clipPath>
                            </defs>
                          </svg>
                          ( {courseExecutions.length} ) {t("Executions")}
                        </li>
                    </ul>
                  </div>
                </div>
                <div className="card-footer d-flex border-0 pt-0">
                  <Link to={`/excutions-page/${courseId}`} className="btn btn-outline-light btn-md w-50 me-2">{t("Executions")}</Link>
                  <Link to={`/new-chapter/${courseId}`} className="btn btn-primary btn-md w-50 ms-2">{t("New Chapter")}</Link>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
            <img
              src={faildImg}
              alt="faild img"
              className="w-[150px] mb-3"
            />
            <p>
              {t("No data available")}
              <span
                className="text-[#4d5fe3] cursor-pointer"
                onClick={() =>
                  refetchDate()
                }
              >
                {t("Try again")}
              </span>
            </p>
          </div>
        )
      }
			{/* <ModalVideo channel='youtube' autoplay isOpen={isOpen} videoId="e6MhFghdQps" onClose={() => setOpen(false)} /> */}
      <OverLayLoading loading={deleteCourseLoading} loadingText={"Deletin Course.."} />
      <UpdateCourseForm
        show = {showUpdateDataForm}
        onHide = {() => setShowUpdateDataForm(false)}
        oldcoursename  = {courseName}
        oldcoursedescription = {description}
        course_id = {courseId}
        refetch_data_func = {() => refetchDate()}
      />
      <UpdateCourseImageForm
        show = {showUpdateImageForm}
        onHide = {()=>setShowUpdateImageForm(false)}
        oldname  = {courseName}
        course_id = {courseId}
        refetch_data_func = {() => refetchDate()}
      />
      <OverLayLoading loading={deleteChapterLoading} loadingText={"Loading.."}/>
		</>
	)
}
export default CourseDetail1;