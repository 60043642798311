import { lazy, Suspense, useEffect } from "react";
import Index from "./jsx";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { useState } from "react";
import ContextApi from "./store/ContextApi";
import { getBasicSettingsFunc } from "./store/custom-actions/basic-settings/GetBasicSettingsSlicer";
import { getLanguageSettingsFunc } from "./store/custom-actions/language/GetLanguageSettingsSlicer";
import i18n from "i18next";
import { getColorsFunc } from "./store/custom-actions/colors/GetColorsSlicer";
function App(props) {
  const dispatch = useDispatch();
  const [isLogedin, setIslogedIn] = useState(false);
  const { loading ,  status } = useSelector((state) => state.loginStatus);
  const global_data = {
    dashboard_URL_API : `${process.env.REACT_APP_VERSEL_API}/api/dashboard`,
    userPanelApi : process.env.REACT_APP_VERSEL_API,
    main_headers : (
      axios.defaults.headers.common["Content-Type"] = "application/json",
      axios.defaults.headers.common["Accept"] = "application/json",
      axios.defaults.headers.common["ngrok-skip-browser-warning"] = "69420"
    )
  }
  const {loading:getBasicSettingsLoading , data:settingsData} = useSelector(
    state => state.getBasicSettingsSlicer
  )
  const {loading:getColorsLoading , data:colorsData} = useSelector(
    state => state.getColorsSlicer
  )
  const getColorsSettings = () => {
    dispatch(getColorsFunc(`${global_data.userPanelApi}/api/settings`))
  }
  useEffect(() => {
    getColorsSettings()
  },[])
  useEffect(() => {
    !colorsData && document.documentElement.style.setProperty("--primary" , window.localStorage.getItem("primaryColor"))
    !colorsData && document.documentElement.style.setProperty("--secondary" , window.localStorage.getItem("secondaryColor"))
    if(colorsData) {
      document.documentElement.style.setProperty("--primary" , colorsData?.back_primary_color)
      document.documentElement.style.setProperty("--secondary" , colorsData?.back_secondary_color)
    }
  },[colorsData])
  const changeFavicon = (iconURL) => {
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
    }
    link.href = iconURL;
  };
  const changeTitle = (title) => {
    if(title) {
      document.title = title;
    }
  };
  const basicSettingsApi = `${global_data.dashboard_URL_API}/settings/general-settings`
  const getSettingsFunc = () => {
    dispatch(getBasicSettingsFunc(basicSettingsApi))
  }
  useEffect(() => {
    getSettingsFunc();
  },[])
  useEffect(() => {
    const userToken = window.localStorage.getItem("token");
    if(userToken) {
      axios.defaults.headers.common = {'Authorization' : `Bearer ${userToken}`};
      axios.defaults.headers.common["Content-Type"] = "application/json";
      axios.defaults.headers.common["Accept"] = "application/json";
    }
  },[])
  useEffect(()=>{
    if(status) {
      if(status.success && status.user){
        setIslogedIn(true)
        getSettingsFunc();
      }else {
        setIslogedIn(false)
      }
    }
  },[status])

  useEffect(() => {
    changeFavicon(settingsData?.site_logo);
    changeTitle(settingsData?.site_title);
    return () => {
        changeFavicon(settingsData?.site_logo);
        changeTitle(settingsData?.site_title)
    };
  }, [settingsData]);
  const languageAPi = `${global_data?.userPanelApi}/api/language-settings`
  useEffect(() => {
    const getSettings = () => {
        if(window.localStorage.getItem("langSlug")) {
          dispatch(getLanguageSettingsFunc(`${languageAPi}?lang=${window.localStorage.getItem("langSlug")}`))
        }else {
          dispatch(getLanguageSettingsFunc(languageAPi))
        }
    }
    getSettings()
},[])
const {loading:switchLangLoading , data:languageData} = useSelector(
  state => state.getLanguageSettingsSlicer
)
useEffect(() => {
  const fetchTranslations = async () => {
    if (languageData) {
      const { defaultLang, all_word } = languageData;
      if (defaultLang && all_word) {
        const { direction, slug } = defaultLang;
        document.documentElement.dir = direction; // Set the direction dynamically
        window.localStorage.setItem("langSlug" , defaultLang.slug);
        console.log(defaultLang.slug)
        i18n.addResources(slug, 'translation', all_word);
        i18n.changeLanguage(slug);
      } else {
        console.error('Invalid language data structure', languageData);
      }
    }
  };
  fetchTranslations();
}, [languageData]);

  return (
    <>
      <ContextApi.Provider value={global_data}>
        <Index/>
      </ContextApi.Provider>
    </>
  )
  
}

// export default withRouter(connect(mapStateToProps)(App));
export default App
