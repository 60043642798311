import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const fetchSingleUserFunc = createAsyncThunk(
    "get single user instructors",
    async (api) => {
        try {
            const response = await axios.get(api );
            // if(response.data.status_code === 401 || response.data.status_code 
            checkErrorsFunctions(response , response.data.errors);
            return response.data.data.user;
        }
        catch (error) {
            // swal({
            //     title: "Oops!",
            //     text: error,
            //     icon: "warning",
            //     button: "Retry",
            // });
            checkErrors(error.response , error.message);
        }
    }
)


const getSingleUserSlicer = createSlice({
    name: "get single user slicer",
    initialState : {
        loading : null,
        userData : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(fetchSingleUserFunc.pending , (state) => {
            state.loading = true
            state.userData = null
            state.error = false
        })
        builder.addCase(fetchSingleUserFunc.rejected , (state) => {
            state.loading = false
            state.userData = null
            state.error = true
        })
        builder.addCase(fetchSingleUserFunc.fulfilled , (state , action) => {
            state.loading = false
            state.userData = action.payload
            state.error = false
        })
    }
})

export default getSingleUserSlicer.reducer