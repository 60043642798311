import { Spinner } from "react-bootstrap";
import { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import ContextApi from "../../../store/ContextApi";
import { deleteSubscriptionFunc } from "../../../store/custom-actions/subscription-actions/DeleteSubscriptionSlicer";
import { getSubscriptionsFunc } from "../../../store/custom-actions/subscription-actions/GetSubscriptionsSlicer";
import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
import SubscripeDescion from "./SubscripeDescion";
import PageTitle from "../../layouts/PageTitle";
import { updateSubscripePaymentsStatusFunc } from "../../../store/custom-actions/subscription-actions/UpdateSubscripePaymentsStatus";
import NewSubscripeForm from "../../custom-forms/subscriptions/NewSubscripeForm";
import { useTranslation } from "react-i18next";
import UpdateSubscripeStatus from "../courses-page/course-subscriptions/UpdateSubscripeStatus";
import ShowImageModal from "../../global-compnents/show-image-modal/ShowImageModal";
import { fetchUsersFunc } from "../../../store/custom-actions/users-actions/GetUsersSlicer";


export default function SubscriptionsPage() {
    const {t} = useTranslation();
    const {loading:getSubscriptionsLoading , data:subscriptionsData} = useSelector(state => state.getSubscriptionSlicer)
    const {loading:deleteSubscripLoading} = useSelector(state => state.deleteSubscriptionSlicer)
    const {loading:updateSubscripeLoading} = useSelector(state => state.updateSubscripePaymentsStatus)
    const {loading:getBasicSettingsLoading , data:settingsData} = useSelector(
      state => state.getBasicSettingsSlicer
    )
    const dispatch = useDispatch();
    const contextApi = useContext(ContextApi);
    const subscriptionsApi = `${contextApi.dashboard_URL_API}/subscriptions`;
    const [subscriptionsList , setSubscriptionList] = useState([]);
    const [showSubscriptionForm , setShowSubscriptionForm] = useState();
    const [filtredSubscriptions, setFiltredSubscriptions] = useState([]);
    const user = JSON.parse(window.localStorage.getItem("user"));
    const userRoleName = user && user.roleName;
    const users_API = `${contextApi.dashboard_URL_API}/users`;
    const {loading:getUsersLoadidng , data:users} = useSelector(state => state.fetchUsersSlicer)
    const [instructorsList , setInstructorsList] = useState([])
    useEffect(() => {
      const getData = () => {
        // dispatch(getSubscriptionsFunc(subscriptionsApi));
        dispatch(fetchUsersFunc(users_API))
    }
    getData()
  },[])
    const deleteSubscripeFunc = async (id) => {
      const deleteFunc = () => {
        dispatch(deleteSubscriptionFunc(`${subscriptionsApi}/${id}`))
        .then((result) => {
          if(result.payload.success === true) {
            dispatch(getSubscriptionsFunc(subscriptionsApi));
          }
        })
      }
      swal({
        title: "Confirm Deletion",
        text: `Once deleted, will not be in platform!`,
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          deleteFunc()
        }
      });
    }
    const sort = 10;
  useEffect(() => {
    console.log(subscriptionsData)
    if (subscriptionsData && subscriptionsData.subscriptions) {
        setSubscriptionList(subscriptionsData?.subscriptions);
        setFiltredSubscriptions(subscriptionsData?.subscriptions?.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
        ));
    }
}, [subscriptionsData]);
  let paggination = Array(Math.ceil(subscriptionsList.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const activePag = useRef(0);
  const jobData = useRef(
    subscriptionsList.slice(
      activePag.current * sort,
      (activePag.current + 1) * sort
    )
  );
  const onClick = (i) => {
    activePag.current = i;
    jobData.current = subscriptionsList.slice(
      activePag.current * sort,
      (activePag.current + 1) * sort
    );
    setFiltredSubscriptions(subscriptionsList.slice(
      activePag.current * sort,
      (activePag.current + 1) * sort
    ))
  };
      useEffect(()=>{
        const notMangerUsers = users && users.filter(user => user.roles[0].name.toLowerCase() === "teacher");
        users && notMangerUsers && setInstructorsList(notMangerUsers)
      },[users]);
  const [searchValue , setSearchValue] = useState("");
  const [instructorFilter , setInstructorFilter] = useState("");
  const [paymnetStatusFilter , setPaymnetStatusFilter] = useState("");
//   useEffect(() => {
//     if (searchValue !== "") {
//         const searchResult = subscriptionsData &&
//             subscriptionsData.length >= 1 && subscriptionsData.filter((sub) =>
//                 sub.student.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
//             );
//         setFiltredSubscriptions(searchResult);
//     } else {
//         setFiltredSubscriptions(subscriptionsData.slice(
//             activePag.current * sort,
//             (activePag.current + 1) * sort
//         ));
//     }
// }, [searchValue, subscriptionsData]);

useEffect(() => {
  // if(searchValue !== "" || instructorFilter !== "" || paymnetStatusFilter !== "") {
    dispatch(getSubscriptionsFunc(`${subscriptionsApi}?${searchValue!=="" ? `search=${searchValue}` : ""}&${instructorFilter !== "" ? `teacher_id=${instructorFilter}` : ""}&${paymnetStatusFilter !== "" ? `payment_status=${paymnetStatusFilter}` : ""}`));
  // }
},[searchValue , instructorFilter , paymnetStatusFilter])


const updateSubscripe = (value , id) => {
  const updateFunc = () => {
    dispatch(updateSubscripePaymentsStatusFunc({api : `${subscriptionsApi}/${id}` , data : {payment_status : value , "_method" : "PUT"}})).then((result) => {
      if(result.payload.success === true) {
        dispatch(getSubscriptionsFunc(subscriptionsApi));
      }
    })
  }
    swal({
    title: t("Confirm Update"),
    text: t(`Please Confirm Update Subscription!`),
    icon: "warning",
    buttons: true,
    dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {
      updateFunc()
    }
  });
}
const [showUpdateStatusPament , setShowUpdateStatusPament] = useState(false);
const [subOldStatus , setSubOldStatus] = useState();
const [paymentId , setPaymentId] = useState();

const handleShowUpdateStatus = (oldStatus , id) => {
  setShowUpdateStatusPament(true);
  setSubOldStatus(oldStatus);
  setPaymentId(id);
}
const [showReceipt , setShowReceipt] = useState(false);
const [receipt , setReceipt] = useState();
const handleShowReceipt = (image) => {
  setShowReceipt(true);
  setReceipt(image);
}
return (
    <>
    <PageTitle motherMenu={"Dashboard"} activeMenu={"Subscriptions"} />
    {
      // subscriptionsList && subscriptionsList.length >= 1
      // &&
      <div className="col-12">
        <div className="card">
          <div className="card-header flex justify-between items-center">
            {
              getSubscriptionsLoading
              ?
              <p>{t("loading")}..</p>
              :
              <h4 className="card-title">{t("Total")}: {subscriptionsData?.total_subscriptions} {settingsData?.site_global_currency}</h4>
            }
            <div className="relative add-and-search flex items-center">
              <div className="input-group search-area w-auto mr-2">
                  <span className="input-group-text"
                    // onClick={() => search()}
                  >
                      <Link to={"#"}>
                      <svg
                          width="24"
                          height="24"
                          viewBox="0 0 32 32"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                      >
                          <path
                          d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                          fill="var(--primary)"
                          ></path>
                      </svg>
                      </Link>
                  </span>
                  <input
                      type="text"
                      className="form-control"
                      placeholder={t("Search here...")}
                      onChange={(e) => {
                      setSearchValue(e.target.value);
                      }}
                  />
              </div>
              {
                (userRoleName.toLowerCase() === "manager")
                ?
                <div className="relative flex items-center">
                  {
                      getUsersLoadidng ? (
                          <div className="form-group flex items-center">
                              <p>{t("loading")}...</p>
                          </div>
                      ) : users &&
                          instructorsList &&
                          instructorsList.length <= 0 ? (
                          <div className="form-group flex items-center">
                              <p>{t("No data available")}</p>
                          </div>
                      ) : (
                        <select
                          className="form-control mx-1"
                          onChange={(e) => {
                            setInstructorFilter(e.target.value)
                          }}
                          >
                            <option value={""}>All</option>
                            {
                              instructorsList.map((item , index) => (
                                <option value={item.id} key={index}>{item.name}</option>
                              ))
                            }
                          </select>
                      )
                  }
                  <select
                  className="form-control mx-1"
                  onChange={(e) => {
                    setPaymnetStatusFilter(e.target.value)
                  }}
                  >
                    <option value={""}>All</option>
                    <option>Confirmed</option>
                    <option>Un Confirmed</option>
                    <option>Rejected</option>
                  </select>
                </div>
                :
                ""
              }
              {/* {
                checkPermissions(
                  "create subscriptions",
                  <Link 
                  to={`#`} 
                  className="btn btn-primary btn-sm"
                  onClick={() => setShowSubscriptionForm(true)}
                  >
                    Create
                    <i className="fa-solid fa-plus ml-1"></i>
                  </Link>
                )
              } */}
            </div>
          </div>
          <div className="card-body">
            <div className="w-100 table-responsive stuff-table">
              <div id="example_wrapper" className="dataTables_wrapper">
                <table id="example" className="display w-100 dataTable responsive">
                  <thead>
                    <tr role="row">
                      {/* <th>#ID</th> */}
                      <th>{t("Student")}</th>
                      <th>{t("Course")}</th>
                      {/* <th>{t("Execution")}</th> */}
                      <th>{t("Price")}</th>
                      <th>{t("Payment")}</th>
                      <th>{t("Payment Status")}</th>
                      <th>{t("Start")}</th>
                      <th>{t("End")}</th>
                        <th>{t("Actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                        getSubscriptionsLoading
                        ?
                        (
                            <>
                                <div className="row d-flex justify-content-center gap-2">
                                <Spinner animation="grow" className="bg-primary" />
                                <Spinner animation="grow" className="bg-primary" />
                                <Spinner animation="grow" className="bg-primary" />
                                <Spinner animation="grow" className="bg-primary" />
                                </div>
                            </>
                        )
                        :
                        (
                          subscriptionsList && subscriptionsList.length >= 1
                          ?
                          filtredSubscriptions.map((item , index) => {
                            const {student , course_execution , type_payment , payment_status , total_price , receipt_image_url} = item;
                            const {title:exName , course , start_date_student , end_date_student} = course_execution;
                            const {name:courseName , id:courseId} = course;
                            return (
                                <tr key={index}>
                                    <td className="flex items-center">
                                        <Link className="relative flex items-center" to={`student-profile/${student.id}`}>
                                            <img 
                                            src={student.student && student.student.image_url !== null ? student.student.image_url : "studentImg"} 
                                            alt="student img"
                                            className="relative w-[40px] mr-1"
                                            />
                                            {student.name}
                                        </Link>
                                    </td>
                                    <td>
                                      <Link to={`/course-details/${courseId}`}>
                                        {courseName}
                                      </Link>
                                    </td>
                                    {/* <td>{exName}</td> */}
                                    <td>{total_price} {settingsData?.site_global_currency}</td>
                                    <td>{type_payment && type_payment.replace(/_/g , " ")}</td>
                                    <td>
                                        <span className={`rounded-md text-white p-2 ${payment_status === "unconfirmed" && "bg-orange-500"} ${payment_status === "rejected" && "bg-red-500"} ${payment_status === "confirmed" && "bg-green-500"} text-sm`}>
                                            {payment_status}
                                        </span>
                                    </td>
                                    <td>{start_date_student}</td>
                                    <td>{end_date_student}</td>
                                    <td>
                                        <SubscripeDescion
                                        deleteFunction={() => deleteSubscripeFunc(item.id)}
                                        paymentStatus = {payment_status}
                                        paymentType = {type_payment}
                                        updateFunction = {updateSubscripe}
                                        id={item.id}
                                        showUpdateStatus = {() => {
                                          handleShowUpdateStatus(payment_status , item.id)
                                        }}
                                        showReceiptFunc = {() => {
                                          handleShowReceipt(receipt_image_url)
                                        }}
                                        />
                                    </td>
                                </tr>
                              )
                          }) 
                          :
                          <tr>
                            <td colSpan={4}>
                              {t("No data available")}
                            </td>
                          </tr>
                        )
                    }
                  </tbody>
                </table>
                <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                  <div className="dataTables_info">
                    {t("Showing")} {activePag.current * sort + 1} to{" "}
                    {subscriptionsList.length > (activePag.current + 1) * sort
                      ? (activePag.current + 1) * sort
                      : subscriptionsList.length}{" "}
                    {t("of")} {subscriptionsList.length} {t("entries")}
                  </div>
                  <div
                    className="dataTables_paginate paging_simple_numbers mb-0"
                    id="example5_paginate"
                  >
                    <Link
                      className="paginate_button previous disabled"
                    //   to="/table-datatable-basic"
                      to="#"
                      onClick={() =>
                        activePag.current > 0 && onClick(activePag.current - 1)
                      }
                    >
                      <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                    </Link>
                    <span>
                      {paggination.map((number, i) => (
                        <Link
                          key={i}
                        //   to="/table-datatable-basic"
                          to="#"
                          className={`paginate_button  ${
                            activePag.current === i ? "current" : ""
                          } `}
                          onClick={() => onClick(i)}
                        >
                          {number}
                        </Link>
                      ))}
                    </span>
                    <Link
                      className="paginate_button next"
                    //   to="/table-datatable-basic"
                      to="#"
                      onClick={() =>
                        activePag.current + 1 < paggination.length &&
                        onClick(activePag.current + 1)
                      }
                    >
                      <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
            // :
            // <h3 className="text-center">{t("No data available")}</h3>
    }
    <OverLayLoading loading={deleteSubscripLoading} loadingText={"Loading..."}/>
    <OverLayLoading loading={updateSubscripeLoading} loadingText={"Loading..."}/>
    <NewSubscripeForm
        show = {showSubscriptionForm}
        onHide = {()=>setShowSubscriptionForm(false)}
        refetchFunction = {()=> dispatch(getSubscriptionsFunc(subscriptionsApi))}
        // studentId = {true}
    />
    <UpdateSubscripeStatus
      // courseId=  {courseId}
      show = {showUpdateStatusPament}
      onHide={() => setShowUpdateStatusPament(false)}
      oldStatus = {subOldStatus}
      id = {paymentId}
      reftechData={() => dispatch(getSubscriptionsFunc(subscriptionsApi))}
    />
    <ShowImageModal
      show = {showReceipt}
      onHide = {() => setShowReceipt(false)}
      image = {receipt}
    />
    </>
)
}
