import axios from "axios";

export default function logoutFunction () {
    const logout_api = "https://apis.alawa2el.com/api/dashboard/logout";
    const logout = async() => {
        await axios.post(logout_api , {} )
        window.localStorage.clear();
        window.location.reload();
    }
    logout();
}