import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PageTitle from '../../../../layouts/PageTitle';
import { TreeSelect } from 'antd';
import { getChaptersFunc } from '../../../../../store/custom-actions/chapters/GetChaptersSlicer';
import ContextApi from '../../../../../store/ContextApi';
import { fetchCourseLessonsFunc } from '../../../../../store/custom-actions/lessons-actions/GetCourseLessons';
import { getQuestionsFunc } from '../../../../../store/custom-actions/Question-actions/GetQuestionsSlicer';
import { copyQuestionsFunc } from '../../../../../store/custom-actions/lessons-actions/CopyQuestionsSlicer';
import { Modal, ModalBody , CloseButton } from 'react-bootstrap';

const convertAllChildrenKey = (data) => {
  if (!Array.isArray(data)) return [];
  return data.map((item) => {
    const { name, id, all_children, ...rest } = item;
    return {
      ...rest,
      title: name,
      value: id,
      children: convertAllChildrenKey(all_children),
    };
  });
};

const TreeSelectExam = ({treeData , setNewIdFunc}) => {
const [value, setValue] = useState();
const onChange = (newValue) => {
  setNewIdFunc(newValue)
  setValue(newValue);
};
const modifiedTreeData = convertAllChildrenKey(treeData);

return (
  <TreeSelect
  style={{
      width: '100%',
      height: '40px',
  }}
  value={value}
  dropdownStyle={{
      maxHeight: 400,
      overflow: 'auto',
  }}
  treeData={modifiedTreeData}
  placeholder="Please select"
  treeDefaultExpandAll
  onChange={onChange}
  treeDataSimpleMode={{
      id: 'value',
      pId: 'parentId',
      rootPId: null,
  }}
  />
);
};

export default function CopyQuestionsModale({show , onHide , courseId , lessonIdWhichCopyForIt}) {
    // const {courseId} = useParams();
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const [chaptersList, setChaptersList] = useState([]);
    const chaptersApi = `${contextApi.dashboard_URL_API}/courses/${courseId}/chapters`;
    const [chapterId , setChapterId] = useState(null);
    const [lessonId , setLessonId] = useState(null);
    const questions_API_URL = `${contextApi.dashboard_URL_API}/lessons/${lessonId}/questions`;
    const lessonQuestions_API_URL = `${contextApi.dashboard_URL_API}/lessons/${lessonIdWhichCopyForIt}/questions`;
    const submitQuestions_API_URL = `${contextApi.dashboard_URL_API}/lessons/${lessonIdWhichCopyForIt}/questions/copy`;
    const [lessonsList , setLessonsList] = useState([]);
    const [questionsList , setQuestionsList] = useState([]);
    const [questionsWhichSubmit , setQuestionsWhichSubmit] = useState([]);
    const [questionsAlreadySelected , setQuestionsAlreadySelected] = useState([]);
    const { loading: getChaptersLoading, data:cahptersData } = useSelector(
      (state) => state.getChaptersSlicer
    );
    const { loadingCourseLessons: getLessonsLoading , data:lessonsData} = useSelector(
      (state) => state.getCourseLessonsSlicer
    );
    const {getQuestionsLoading , data:questionsData} = useSelector(
      state => state.getQuestionsSlicer
    );
    const {loading:copyQuestionsLoading} = useSelector(
      state => state.copyQuestionsSlicer
    );
    useEffect(() => {
      const getChapters = async () => {
        dispatch(getChaptersFunc(`${chaptersApi}`));
      };
      show && getChapters();
    }, [show]);
    useEffect(() => {
      cahptersData && setChaptersList(cahptersData);
    }, [cahptersData]);
    const dispatch = useDispatch();
    useEffect(() => {
      if(chapterId) {
          const lessonsApi = `${contextApi.dashboard_URL_API}/chapters/${chapterId}/lessons`;
          const getLessons = () => {
              dispatch(fetchCourseLessonsFunc(lessonsApi))
              .then((result) => {
                  const filtredLessons = result?.payload?.lessons?.filter(ls => (ls.type === "quiz" && ls.id !== lessonIdWhichCopyForIt));
                  setLessonsList(filtredLessons);
                  setLessonId(filtredLessons[0]?.id);
              })
          }
          getLessons()
      }
    },[chapterId])
    const handleSetChapterId = (id) => {
        setChapterId(id)
    }

  useEffect(() => {
      const getData = () => {
        dispatch(getQuestionsFunc(lessonQuestions_API_URL))
        .then(result => {
          setQuestionsAlreadySelected(result?.payload)
          dispatch(getQuestionsFunc(questions_API_URL))
          .then(result => {
            setQuestionsList(result?.payload)
          })
        })
      }
      lessonId && getData()
  },[lessonId])
  const switchQuestionType = (type) => {
    if(type) {
        switch(type) {
            case 1:
                return t("True & False")
            case 2:
                return t("Choose")
            case 3:
                return t("Essay")
            default:
                return ""
        }
    }
  }

  const handleSelect = (e , id) => {
    if(e.target.checked) {
      setQuestionsWhichSubmit(prev => [...prev , id])
    }else {
      const filter = questionsWhichSubmit.filter(item => item !== id)
      setQuestionsWhichSubmit(filter)
    }
  }
  const handleSubmitQuestions = () => {
    if(questionsWhichSubmit) {
      dispatch(copyQuestionsFunc({api : submitQuestions_API_URL , data : {question_ids : questionsWhichSubmit}}))
      .then(result => {
        if(result?.payload?.success) {
          onHide();
          setChapterId(null);
          setLessonId(null);
          setQuestionsList([]);
          setLessonsList(null)
        }
      })
    }
  }
  useEffect(() => console.log(questionsAlreadySelected) , [questionsAlreadySelected])
  return (
    <>
    <Modal
    show = {show}
    onHide={onHide}
    size='lg'
    >
      <Modal.Header closeButton>
      </Modal.Header>
      <Modal.Body>
        <div className='row'>
          <div className='col-12'>
            {/* <div className='card p-3'> */}
              <div className="form-group mb-3 col-sm-12">
                {
                    getChaptersLoading ? (
                        <div className="form-group flex items-center">
                            <p>{t("loading")}...</p>
                        </div>
                    ) : cahptersData &&
                        chaptersList &&
                        chaptersList.length <= 0 ? (
                        <div className="form-group flex items-center">
                            <p>{t("No data available")}</p>
                        </div>
                    ) : (
                        <div
                            className={`form-group mb-3 col-sm-12`}
                        >
                        <label>{t("Choose Chapter")}</label>
                          <TreeSelectExam treeData={chaptersList} 
                          setNewIdFunc = {handleSetChapterId}
                          />
                        </div>
                    )
                }
              </div>
              <div className="form-group mb-3 col-sm-12">
                {
                    getLessonsLoading ? (
                        <div className="form-group flex items-center">
                            <p>{t("loading")}...</p>
                        </div>
                    ) :
                        lessonsList &&
                        lessonsList.length <= 0 ? (
                        <div className="form-group flex items-center">
                            <p>{t("No data available")}</p>
                        </div>
                    ) : (
                        <div
                            className={`form-group mb-3 col-sm-12`}
                        >
                        <label>{t("Choose Lesson")}</label>
                          <select className="form-control"
                            onChange={(e) => setLessonId(e.target.value)}
                            >
                              {
                                lessonsList
                                &&
                                lessonsList.length >= 1
                                &&
                                lessonsList.map((item , index) => {
                                  // if(item !== "manager" && item.name !== "teacher") {
                                    return <option key={index} value={item.id}>{item.name}</option>
                                  // }
                                })
                              }
                            </select>
                        </div>
                    )
                }
              </div>
              {
                getQuestionsLoading
                ?
                t("loading")
                :
                (
                  questionsList?.length >= 1
                  &&
                  <div className="w-100 table-responsive">
                    <div id="example_wrapper" className="dataTables_wrapper">
                        <table id="example" className="display w-100 dataTable">
                            <thead>
                                <tr>
                                    <th className="">#{t("ID")}</th>
                                    <th className="">{t("Question")}</th>
                                    <th className="">{t("Type")}</th>
                                    <th className="">{t("Grade")}</th>
                                    <th className="">
                                        {t("Select")}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    questionsData && questionsList?.length >= 1
                                    ?
                                    questionsList.map((question, index) => {
                                      const alreadySelected = questionsAlreadySelected?.length >= 1 && questionsAlreadySelected.find(item => item.title === question.title);
                                      // console.log(alreadySelected)
                                      console.log(question?.id)
                                      console.log(questionsAlreadySelected)
                                        return (
                                        <tr key={index}>
                                            <td className="font-bold">
                                            #{question.id}
                                            </td>
                                            <td>
                                            <div className="d-flex align-items-center">
                                                <i className="fa-solid fa-circle-question text-[20px] text-slate-700"></i>
                                                <h4 className="mb-0 fs-16 font-w500 ml-2">
                                                    <div className="essay-q-content" dangerouslySetInnerHTML={{__html : question?.title?.slice(0,20)}} />
                                                </h4>
                                            </div>
                                            </td>
                                            <td>{switchQuestionType(question?.type)}</td>
                                            <td>{question.grade}</td>
                                            <td className="text-end">
                                              <span className='flex justify-end items-center'>
                                                {
                                                  alreadySelected && <p className='mx-1 my-0'>{t("Selected")}</p>
                                                }
                                                <input
                                                type='checkbox'
                                                className='scale-110 cursor-pointer'
                                                checked = {alreadySelected}
                                                disabled = {alreadySelected}
                                                onChange={(e) => {
                                                  // if(e.target.checked) {
                                                  //   console.log(`selected ${question?.id}`)
                                                  // }else {
                                                  //   console.log(`removed ${question?.id}`)
                                                  // }
                                                  handleSelect(e , question?.id)
                                                }}
                                                />
                                              </span>
                                            </td>
                                        </tr>
                                        )
                                    }
                                        
                                    )
                                    :
                                    (
                                      <tr>
                                          <td colSpan={3}>{t("No data available")}</td>
                                      </tr>
                                    )
                                }
                                {/* {
                                    searchValue === "" && filtredQuestions && filtredQuestions.length < 1 
                                    &&
                                    <tr>
                                        <td colSpan={3}>{t("No data available")}</td>
                                    </tr>
                                } */}
                            </tbody>
                        </table>
                    </div>
                  </div>
                )
              }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="relative w-full flex justify-end items-center my-2">
            <button type="submit" className={`btn btn-primary sw-btn-next ms-1 ${(copyQuestionsLoading || questionsWhichSubmit?.length <= 0) ? "pointer-events-none opacity-50" : ""}`}
            onClick={handleSubmitQuestions}
            >
                {
                copyQuestionsLoading
                ?
                t("loading")
                :
                t("Submit" )
                }
            </button>
        </div>
      </Modal.Footer>
    </Modal>
    </>
  )
}