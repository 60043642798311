import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import {Provider} from 'react-redux';
import { myStore } from "./store/CustomStore";
import reportWebVitals from "./reportWebVitals";
import SimpleReactLightbox from "simple-react-lightbox";
import  ThemeContext  from "./context/ThemeContext";
import "./i18n.js"
ReactDOM.render(
	<React.StrictMode>
		<Provider store = {myStore}>
            {/* <PersistGate loading={null} persistor={pStore}> */}
                <SimpleReactLightbox>
                    <BrowserRouter >
                        <ThemeContext>
                            <App />
                        </ThemeContext>  
                    </BrowserRouter>    
                </SimpleReactLightbox>
            {/* </PersistGate> */}
        </Provider>	
	</React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
