import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const editAssistantFunc = createAsyncThunk(
    "edit assistant function",
    async ({assistantApi , assistantNewData}) => {
        const config = {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
            },
        }
        try {
            const response = await axios.put(assistantApi , assistantNewData , config)
            // if(response.data.status_code === 401 || response.data.status_code === 403) {
            //     window.localStorage.clear();
            //     window.location.reload();
            // }else{
            //     return response.data
            // }
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            console.log(error)
            checkErrors(error.response , error.message);
        }
        
    }
)

const editAssistantSlicer = createSlice({
    name : "edit assistant slicer",
    initialState : {
        editAssistantLoading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(editAssistantFunc.pending , (state) => {
            state.editAssistantLoading = true
            state.data = {}
            state.error = false
        });
        builder.addCase(editAssistantFunc.rejected , (state) => {
            state.editAssistantLoading = false
            state.data = {}
            state.error = true
        });
        builder.addCase(editAssistantFunc.fulfilled , (state , action) => {
            state.editAssistantLoading = false
            state.data = action.payload
            state.error = false
        });
    }
});

export default editAssistantSlicer.reducer;