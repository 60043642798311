import { Modal , Button } from "react-bootstrap";
import { useEffect, useContext } from "react";
import { useSelector , useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ContextApi from "../../../store/ContextApi";
import Swal from "sweetalert2";
import excutionIcon from "../../../media/images/gear.png";
import HandleErrorsListFunc from "../../global-functions/handleErrorsListFunc";
import { updateCourseExecutionFunc } from "../../../store/custom-actions/courses-actions/excutions-actions/UpdateCourseExecutionSlicer";
import { useTranslation } from "react-i18next";
function UpdateExecutionForm(props) {
    const {t} = useTranslation();
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
      setValue
    } = useForm();
    const { old_execution } = props;
    const {loading:updateExecutionLoading} = useSelector(state => state.updateCourseExecutionSlicer);
    const dispatch = useDispatch();
    const contextApi = useContext(ContextApi);
    const extintionsApi = `${contextApi.dashboard_URL_API}/course-executions/${old_execution && old_execution.id}`;
    const handleSubmitExtintions = (data) => {
        if(data.start_date_student > data.end_date_student) {
            Swal.fire({
                title: t("Oops!"),
                text: t("start publish date for students must be less than end date or more than today"),
                icon: "warning"
            });
        }else if (data.start_date_publish > data.end_date_publish){
            Swal.fire({
                title: t("Oops!"),
                text: t("start publish date for anyone must be less than end date or more than today"),
                icon: "warning"
            });
        }else {
            const notEmptyData = Object.fromEntries(
                Object.entries(data).filter(([key, value]) => value !== "")
            );
            dispatch(updateCourseExecutionFunc({extintionsApi : extintionsApi , extintionData : notEmptyData}))
            .then(result => {
                if(result.payload.success === true) {
                    props.onHide();
                    window.location.reload();
                }
            })
        }
    }
    useEffect(() => {
        if(old_execution) {
            setValue("title" , old_execution.title);
            setValue("price" , old_execution.price);
            setValue("is_visible" , old_execution.is_visible);
            setValue("discount" , old_execution.discount);
            setValue("order" , old_execution.order);
            setValue("start_date_student" , old_execution.start_date_student);
            setValue("end_date_student" , old_execution.end_date_student);
            setValue("start_date_publish" , old_execution.start_date_publish);
            setValue("end_date_publish" , old_execution.end_date_publish);
        }
    },[setValue , old_execution])
    useEffect(() => {
        HandleErrorsListFunc(errors)
    },[errors])
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="relative flex items-center">
            <img src={excutionIcon} alt='icon' className="relative w-[20px] mr-1" />
            <h3 className="m-0">{t("Update Data")}</h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="basic-form">
            <form 
            className="course-extintions-form" 
            id="course-extintions-form"
            onSubmit={handleSubmit((data) => {
                handleSubmitExtintions(data)
            })
            }
            >
            <div className="row">
                <div className="form-group mb-3 col-sm-12 col-md-6">
                    <label className="relative flex items-center">
                        {t("Title")}
                    </label>
                    {errors.title?.message && (
                        <p className="text-red-600">{errors.title?.message}</p>
                    )}
                    <input
                        {...register("title", {
                        required: t("title Should not be empty!"),
                        })}
                        type="text"
                        className="form-control"
                />
                </div>
                <div className="form-group mb-3 col-sm-12 col-md-6">
                    <label className="relative flex items-center">
                        {t("Price")}
                    </label>
                    {errors.price?.message && (
                        <p className="text-red-600">{errors.price?.message}</p>
                    )}
                    <input
                        {...register("price", {
                        required: t("price Should not be empty!"),
                        })}
                        type="number"
                        className="form-control"
                />
                </div>
            </div>
            <div className="row">
                <div className="form-group mb-3 col-sm-12">
                    <label className="relative flex items-center">
                        {t("Course Visibilty")}
                    </label>
                    {errors.is_visible?.message && (
                        <p className="text-red-600">{errors.is_visible?.message}</p>
                    )} 
                    <select
                        className="form-control"
                        {...register("is_visible", {
                        required: t("visibilty Should not be empty!"),
                        })}
                    >
                        <option value={1}>{t("Visible")}</option>
                        <option value={0}>{t("Not Visible")}</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="form-group mb-3 col-sm-12 col-md-6">
                    <label className="relative flex items-center">
                        {t("Discount")} ({t("Optional")})
                    </label>
                    {errors.discount?.message && (
                        <p className="text-red-600">{errors.discount?.message}</p>
                    )}
                    <input
                        {...register("discount")}
                        type="number"
                        className="form-control"
                />
                </div>
                <div className="form-group mb-3 col-sm-12 col-md-6">
                    <label className="relative flex items-center">
                        {t("Order")}
                    </label>
                    {errors.order?.message && (
                        <p className="text-red-600">{errors.order?.message}</p>
                    )}
                    <input
                        {...register("order", {
                        required: t("order Should not be empty!"),
                        })}
                        type="number"
                        className="form-control"
                />
                </div>
            </div>
            <div className="row">
                <div className="form-group mb-3 col-sm-12 col-md-6">
                    <label className="relative flex items-center">
                        {t("Subscription start date")}
                    </label>
                    {errors.start_date_student?.message && (
                        <p className="text-red-600">{errors.start_date_student?.message}</p>
                    )}
                    <input
                        {...register("start_date_student", {
                        required: t("date Should not be empty!"),
                        })}
                        type="date"
                        className="form-control"
                />
                </div>
                <div className="form-group mb-3 col-sm-12 col-md-6">
                    <label className="relative flex items-center">
                        {t("Subscription end date")}
                    </label>
                    {errors.end_date_student?.message && (
                        <p className="text-red-600">{errors.end_date_student?.message}</p>
                    )}
                    <input
                        {...register("end_date_student", {
                        required: t("end_date Should not be empty!"),
                        })}
                        type="date"
                        className="form-control"
                />
                </div>
            </div>
            <div className="row">
                <div className="form-group mb-3 col-sm-12 col-md-6">
                    <label className="relative flex items-center">
                        {t("Publication start date")}
                    </label>
                    {errors.start_date_publish?.message && (
                        <p className="text-red-600">{errors.start_date_publish?.message}</p>
                    )}
                    <input
                        {...register("start_date_publish" , {
                            required: t("end date Should not be empty!"),
                        })}
                        type="date"
                        className="form-control"
                />
                </div>
                <div className="form-group mb-3 col-sm-12 col-md-6">
                    <label className="relative flex items-center">
                        {t("Publication end date")}
                    </label>
                    {errors.end_date_publish?.message && (
                        <p className="text-red-600">{errors.end_date_publish?.message}</p>
                    )}
                    <input
                        {...register("end_date_publish" , {
                            required: t("end date Should not be empty!"),
                        })}
                        type="date"
                        className="form-control"
                />
                </div>
            </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>{
            props.onHide();
          }}>{t("Close")}</Button>
        <Button type="submit" form="course-extintions-form" className={`btn btn-primary flex items-center justify-center ${updateExecutionLoading ? "pointer-events-none" : ""}`}>
            {!updateExecutionLoading ? (
            t("Submit")
            ) : (
            <div className="relative flex justify-center items-center">
                {t("loading")}..
                <div className="loading-spin-data w-[15px] h-[15px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-1"></div>
            </div>
            )}
        </Button>
        </Modal.Footer>
      </Modal>
    );
  }

export default UpdateExecutionForm;