import { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { editLessonFunc } from "../../../store/custom-actions/lessons-actions/EditLessonSlicer";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import ContextApi from "../../../store/ContextApi";
import { useTranslation } from "react-i18next";

export default function EditLessonOrder(props) {
    const {t} = useTranslation();
    const {lesson , lesson_id ,  lesson_title , refetch_course_lessons} = props;
    const contextApi = useContext(ContextApi)
    const lessons_API_URL = `${contextApi.dashboard_URL_API}/lessons/${lesson_id}`;
    const {loading} = useSelector(state => state.editLessonSlicer);
    const [lessonOrder , setLessonOrder] = useState();
    const [oldOrder , setOldOrder] = useState();
    const dispatch = useDispatch();
    const {register , handleSubmit , reset} = useForm();

    const handleSubmitOrder = (data) => {
        if(data){
            dispatch(editLessonFunc({
                lessonApi : lessons_API_URL , 
                lessonData : {
                    name : lesson_title , 
                    description : lesson.description , 
                    order:lessonOrder
                    }
                }
                )).then(result => {
                    if(result.payload.success === true) {
                        refetch_course_lessons();
                        reset();
                    }
                })
        }
    }
    useEffect(() => {
        lesson && setOldOrder(lesson.order)
    },[lesson])
    return (
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            {t("Update Lesson Order")}
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form className="order-form"
            id="update-order-form"
            onSubmit={handleSubmit((data) => {
                handleSubmitOrder(data)
            })}
          >
            <div className="form-group mb-3 col-sm-12">
                <label>{t("Lesson Oreder")} ({t("old order is")} <span className="text-">{oldOrder}</span>)</label>
                <input
                {...register("order" , {required : "order is required"})}
                type="number"
                min={1}
                required
                className="form-control"
                onChange={(e)=>setLessonOrder(e.target.value)}
                placeholder="New Order"
                />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
        <button onClick={props.onHide} className="">{t("Close")}</button>
        <Button form="update-order-form" type="submit" className={`primary ${loading ? "opacity-80 pointer-events-none" : ""}`}>
            {loading
                ?
                <>
                <div className="relative flex justify-center items-center">
                  {t("loading")}...
                  <div className="loading-spin-data w-[13px] h-[13px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-2"></div> 
                </div>
                </>
                :
                t("Submit" )
              }
        </Button>
        </Modal.Footer>
    </Modal>
    );
}