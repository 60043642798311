import { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import "./right-side-bar.css";
import emptyImg from "../../../../media/images/planet.png"
import { Spinner } from 'react-bootstrap';
   

function NotficationsList({show , onHide , notfications , notficationsLoading , readAllNotsFunc}) {
    const [animate , setAnimate] = useState();
    useEffect(() => {
        !notficationsLoading ? setAnimate(true) : setAnimate(false)
    },[notficationsLoading])
  return (
    <>
      <Modal
        className='z-[9999999999] notfications-parent'
        show={show}
        onHide={onHide}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className=''>
              <i className="fa-solid fa-bell mr-1"></i>
              Notfications
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                notficationsLoading
                ?
                <>
                <div className="row d-flex justify-content-center gap-2">
                    <Spinner animation="grow" className="bg-primary" />
                    <Spinner animation="grow" className="bg-primary" />
                    <Spinner animation="grow" className="bg-primary" />
                    <Spinner animation="grow" className="bg-primary" />
                </div>
                </>
                :
                (   
                    notfications.length >= 1
                    ?
                    <div className='notfications-content overflow-x-hidden relative w-full max-h-[300px] overflow-y-auto'>
                        {
                            notfications &&
                            notfications.map((note , index) => {
                                const noteDate = new Date(note.created_at);
                                const sendingDay = noteDate.getDate();
                                const sendingMonth = noteDate.getMonth()+1;
                                const sendingYear = noteDate.getFullYear();
                                
                                const readingDate = new Date(note.read_at);
                                const readingDay = readingDate.getDate();
                                const readingMonth = readingDate.getMonth()+1;
                                const readingYear = readingDate.getFullYear();

                                const {data:noteData} = note;
                                const {title , subject , sender} = noteData;
                                return (
                                    <div style={{animationDelay : `.${index}s`}} key={index} className={`notfication-box transition-all duration-500 relative w-full px-2 py-3 mb-1 flex justify-between items-center text-black bg-blue-100 rounded-md ${animate ? "active" : ""}`}>
                                      <div className='relative flex flex-col'>
                                        <h4 className={"m-0 text-primary"}>
                                          <i className="fa-brands fa-cuttlefish mr-1"></i>
                                          {title}
                                        </h4>
                                        <h5 className='m-0 text-[#555758] flex flex-col'>
                                          {subject}
                                          <div className='relative flex flex-col mt-1'>
                                            <div className='note-date relative flex items-center text-sm font-normal'>
                                              <i className="fa-solid fa-user mr-1 opacity-90"></i>
                                              <p className='m-0'>Sender  <strong className='text-primary'>{sender}</strong></p>
                                            </div>
                                            <div className='note-date relative flex items-center text-sm font-normal'>
                                              <i className="fa-solid fa-calendar-days mr-1 opacity-90"></i>
                                              <p className='m-0 text-[10px]'>Sent on {`${sendingYear} / ${sendingMonth} / ${sendingDay}`}</p>
                                            </div>
                                            <div className='read-note-date relative flex items-center text-sm font-normal'>
                                              <i className="fa-solid fa-eye mr-1 opacity-90"></i>
                                              <p className='m-0 text-[10px]'>Read in {`${readingYear} / ${readingMonth} / ${readingDay}`}</p>
                                            </div>
                                          </div>
                                        </h5>
                                      </div>
                                      <i className="fa-solid fa-comments text-primary text-[18px]"></i>
                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    <div className='relative flex flex-col justify-center items-center'>
                        <img src={emptyImg} alt="empty img" className='w-[100px]' />
                        <p className='mt-3'>No Notfications</p>
                    </div>
                )
            }
        </Modal.Body>
        <Modal.Footer>
          <Button className="border-0 text-white" onClick={onHide}>
            Close
          </Button>
          {
            notfications?.length>= 1
            &&
            <Button className="bg-primary border-0 text-white"
              onClick={() => {
                readAllNotsFunc()
              }}
            >
              Read All 
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NotficationsList;