import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const filterStudentsFunc = createAsyncThunk(
    "filter students function",
    async (api) => {
        try {
            const response = await axios.get(api );
            if(response.data.status_code === 401 || response.data.status_code === 403) {
                        window.localStorage.clear();
                        window.location.reload();
            }else{
                return response.data.data.students;
            }
        }
        catch (error) {
            // Swal.fire({
            //     title: "Oops!",
            //     text:error,
            //     icon: "error"
            // });
            checkErrors(error.response , error.message);
        }
        
    }
)

const filterStudentsSlicer = createSlice({
    name : "filter students slicer",
    initialState : {
        filterLoading : null,
        filtredStudents : null,
        error : null
    },
    reducer : {},
    extraReducers : (builder) => {
        builder.addCase(filterStudentsFunc.pending , (state) => {
            state.filterLoading = true
            state.filtredStudents = null
            state.error = null
        })
        builder.addCase(filterStudentsFunc.rejected , (state) => {
            state.filterLoading = null
            state.filtredStudents = null
            state.error = true
        })
        builder.addCase(filterStudentsFunc.fulfilled , (state , action) => {
            state.filterLoading = false
            state.filtredStudents = action.payload
            state.error = null
        })
    }
})

export default filterStudentsSlicer.reducer;