import checkPermissions from "../../global-functions/checkPermissionsFunc";
import { useDispatch, useSelector } from "react-redux"
import { useContext, useState } from "react";
import ContextApi from "../../../store/ContextApi";
import { deleteCategoryFunc } from "../../../store/custom-actions/categories-actions/DeleteCategoriesSlicer";
import swal from "sweetalert";
import { fetchCourseLessonsFunc } from "../../../store/custom-actions/lessons-actions/GetCourseLessons";
import DescionDrop from "./DescionDrop";
import { unApproveLessonFunc } from "../../../store/custom-actions/lessons-actions/UnApproveLessonSlicer";
import { approveLessonFunc } from "../../../store/custom-actions/lessons-actions/ApproveLessonSlicer";
import ApproveItemNofication from "../courses-page/ApproveItemNofication";
// import LinkQuizeToLesson from "../../global-compnents/link-quize-to-lesson/LinkQuizeToLesson";
import PassingLessonRequireForm from "../../global-compnents/lesson-box-forms/PassingLessonRequireForm";
// import UnLinkQuizzesForm from "../../global-compnents/lesson-box-forms/UnLinkQuizzesForm";
import EditLessonOrder from "../../global-compnents/lesson-box-forms/EditLessonOrder";
import EditLessonMatrialForm from "../../global-compnents/lesson-box-forms/EditLessonMatrialForm";
import UploadLessonVideoForm from "../../global-compnents/lesson-box-forms/UploadLessonVideoForm";
import ShareMatrialForm from "../../global-compnents/lesson-box-forms/ShareMatrialForm";
import EditLessonDataForm from "../../global-compnents/lesson-box-forms/EditLessonDataForm";
import ShowLessonVideo from "../../global-compnents/lesson-box-components/ShowLessonVideoModal";
import Swal from "sweetalert2";



export default function RowOfLesson({lesson}) {
    const {deleteCategoriesLoading} = useSelector(state => state.deleteCategorySlicer)
    const {loadingCourseLessons , lessons:lessonsData} = useSelector(state => state.getCourseLessonsSlicer)
    const contextApi = useContext(ContextApi);
    const categories_API_URL = `${contextApi.dashboard_URL_API}/categories`;
    const [lessonsList , setLessonsList] = useState([]);
    const lessonsApi = `${contextApi.dashboard_URL_API}/chapters/${lesson.chapter_id}/lessons`;
    const allLessonsApi = `${contextApi.dashboard_URL_API}/lessons`;
    const dispatch = useDispatch();
    const { approve_lesson_loading } = useSelector(
        (state) => state.approveLessonSlicer
    );
    const { deleteLessonLoading } = useSelector(
        (state) => state.deleteLessonSlicer
    );
    const {un_approve_lesson_loading } = useSelector(
        (state) => state.unApproveLessonSlicer
    );
    const {loading:deleteChapterLoading } = useSelector(
        (state) => state.deleteChapterSlicer
    );
    const [videoUrl, setVideoUrl] = useState(
        "https://www.pexels.com/video/sea-people-water-wave-17823998/"
    );
    const chaptersApi = `${contextApi.dashboard_URL_API}/chapters`;
    const lesson_API_URL = `${contextApi.dashboard_URL_API}/lessons/${lesson.id}/approve`
    const lessons_API_URL = `${contextApi.dashboard_URL_API}/chapters/${lesson.cahpter_id}/lessons`
    const update_lessons_api = `${contextApi.dashboard_URL_API}/lessons/${lesson.id}`;
    const approve_lessons_api = `${contextApi.dashboard_URL_API}/lessons/${lesson.id}/approve`;
    const delete_lessons_API_URL = `${contextApi.dashboard_URL_API}/lessons`;
    const [showApproveNotfication , setShowApproveNotfication ] = useState();
    const [showLinkQuizeForm , setShowLinkQuizeForm ] = useState();
    const upload_lessons_files_api = `${contextApi.dashboard_URL_API}/lessons/${lesson.id}/upload-file`;
    const [showVideo, setShowVideo] = useState();
    const [showEditNameAndDescForm, setShowEditNameAndDescForm] = useState();
    const [showEditVideoForm, setShowEditVideoForm] = useState();
    const [showEditMatrialForm, setShowEditMatrialForm] = useState();
    const [showEditOrderform, setShowEditOrderForm] = useState();
    const [showPassingRequireFrom, setShowPassingRequireFrom] = useState();
    const [lessonIcon, setLessonIcon] = useState();
    const [showShareMatrialForm, setShowShareMatrialForm] = useState();
    const [showUnlinkQuizForm, setShowUnlinkQuizForm] = useState();
    const [showRequirmentsList, setShowRequirmentsList] = useState();
    const approveLesson = async () => {
        if(lesson){
            await dispatch(approveLessonFunc({lessonApi: approve_lessons_api}))
            .then(result => {
                if(result.payload.success === true){
                    dispatch(fetchCourseLessonsFunc(lessons_API_URL));
                }
            })
        }
    }
    const unApproveLesson = async () => {
        if(lesson){
            await dispatch(unApproveLessonFunc({lessonApi: lesson_API_URL}))
            .then(result => {
                if(result.payload.success === true){
                    dispatch(fetchCourseLessonsFunc(lessons_API_URL))
                }
            })
        }
    }
    const showMatrial = async () => {
        window.open(lesson.file_url , "_blank")
    }

    const handleDeleteLesson = async () => {
        const deleteFunc = () => {
          try {
            dispatch(deleteCategoryFunc(`${allLessonsApi}/${lesson.id}`))
            .then((result)=>{
              if(result.payload.success === true){
                dispatch(fetchCourseLessonsFunc(lessonsApi));
              }
            });
          }catch (error) {
            console.log(error);
          }
        }
        swal({
          title: "Confirm Item Deletion",
          text: `Once deleted, this will not avilable in platform!`,
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            deleteFunc()
          }
        })
      };
    return (
        <>
            <tr>
                <td>{lesson.id}</td>
                <td>{lesson.name}</td>
                <td>{lesson.type}</td>
                <td>{lesson.order}</td>
                <td>{lesson.view_count}</td>
                <td>{lesson.publish}</td>
                {
                checkPermissions(
                    ["update lessons" , "delete lessons"],
                    <td>
                        <DescionDrop
                            lessonStatus = {lesson.status}
                            lesson_order = {lesson.order}
                            lessonId = {lesson.id}
                            lesson_type = {lesson.type}
                            quizzes_array = {lesson.quizzes}
                            deleteFunction = {() => handleDeleteLesson(lesson.id)}
                            showEditNameAndDescForm = {()=>setShowEditNameAndDescForm(true)}
                            showEditVideoForm = {()=>setShowEditVideoForm(true)}
                            showEditMatrialForm = {()=>setShowEditMatrialForm(true)}
                            showapprovelessonnotficationFunc = {()=>setShowApproveNotfication(true)}
                            unapprovelessonfunction = {unApproveLesson}
                            showLessonMatrial = {() => showMatrial()}
                            showEditOrderForm = {() => setShowEditOrderForm(true)}
                            showLinkQuizeForm = {() => setShowLinkQuizeForm(true)}
                            showShareFilesForm = {() => setShowShareMatrialForm(true)}
                            showPassLessonRequirmentsForm = {()=>setShowPassingRequireFrom(true)}
                            showUnlinkQuizForm = {()=>setShowUnlinkQuizForm(true)}
                            showRequirmentsList = {()=>setShowRequirmentsList(true)}
                            lesson_id = {lesson.id}
                            handleDeleteChapter = {() => handleDeleteLesson()}
                            showLessonVideo = {() => {
                                if(!lesson.file_url) {
                                    Swal.fire({
                                        title: "Oops!",
                                        text: `No Video For This Lesson`,
                                        icon: "warning"
                                    });
                                }
                                else{
                                    setVideoUrl(lesson.file_url);
                                    setShowVideo(true);
                                }
                            }}
                            />
                    </td>
                )
                }
            </tr>
            <ShowLessonVideo
            show={showVideo}
            onHide={() => setShowVideo(false)}
            lessonTitle={lesson.name}
            lessonVideoUrl={videoUrl}
        />
        <EditLessonDataForm
            show = {showEditNameAndDescForm}
            onHide = {()=>setShowEditNameAndDescForm(false)}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
            refetch_course_lessons = {() => dispatch(fetchCourseLessonsFunc(lessonsApi))}
            // course_id = {courseId}
            lesson = {lesson}
            update_lessons_api = {update_lessons_api}
        />
        <ShareMatrialForm
            show = {showShareMatrialForm}
            onHide = {()=>setShowShareMatrialForm(false)}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
            refetch_course_lessons = {() => dispatch(fetchCourseLessonsFunc(lessonsApi))}
            // course_id = {courseId}
            lesson = {lesson}
            update_lessons_api = {update_lessons_api}
            lesson_type = {lesson.type}
        />
        <UploadLessonVideoForm
            show = {showEditVideoForm}
            onHide = {()=>setShowEditVideoForm(false)}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
            refetch_course_lessons = {() => dispatch(fetchCourseLessonsFunc(lessonsApi))}
            // course_id = {courseId}
            lesson = {lesson}
            upload_video_api = {upload_lessons_files_api}
        />
        <EditLessonMatrialForm
            show = {showEditMatrialForm}
            onHide = {()=>setShowEditMatrialForm(false)}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
            refetch_course_lessons = {() => dispatch(fetchCourseLessonsFunc(lessonsApi))}
            // course_id = {courseId}
            lesson = {lesson}
            upload_files_url = {upload_lessons_files_api}
            lesson_type = {lesson.type}
        />
        <EditLessonOrder
            show = {showEditOrderform}
            onHide = {()=>setShowEditOrderForm(false)}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
            refetch_course_lessons = {() => dispatch(fetchCourseLessonsFunc(lessonsApi))}
            // course_id = {courseId}
            lesson = {lesson}
            upload_files_url = {upload_lessons_files_api}
        />
        {/* <UnLinkQuizzesForm
            show = {showUnlinkQuizForm}
            onHide = {()=>setShowUnlinkQuizForm(false)}
            quizzes_list = {lesson.quizzes}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
        /> */}
        <PassingLessonRequireForm
            show = {showPassingRequireFrom}
            onHide = {()=>setShowPassingRequireFrom(false)}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
            refetch_course_lessons = {() => dispatch(fetchCourseLessonsFunc(lessonsApi))}
            // course_id = {courseId}
            lesson = {lesson}
            // course_lessons = {courseLessons}
            lesson_name = {lesson.name}
        />
        {/* {
        showLinkQuizeForm
        &&
        <LinkQuizeToLesson
            show = {showLinkQuizeForm}
            onHide = {()=>setShowLinkQuizeForm(false)}
            // category_id = {courseCategoryId}
            // course_id = {courseId}
            lesson_id = {lesson.id}
        />
        } */}
        <ApproveItemNofication
            show_approve_notfication = {showApproveNotfication}
            hide_approve_notfication = {()=>setShowApproveNotfication(false)}
            itemTitle = {lesson.name}
            refetchFunction = {() => dispatch(fetchCourseLessonsFunc(lessonsApi))}
            // courseId = {courseId}
            item = {lesson}
            approve_function={approveLesson}
            approve_loading = {approve_lesson_loading}
        />
        </>
    )
}