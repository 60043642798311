import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ShowImageModal from "../show-image-modal/ShowImageModal";
// import ShowImageModal from '../../../global-compnents/show-image-modal/ShowImageModal';

function SessionExamModal(props) {
    const {sessionexam} = props;
    const [img , setImg] = useState();
    const [showImgModal , setShowImageModal] = useState();
    const creationExamDate = new Date(sessionexam.created_at);
    const creationDay = creationExamDate.getDate();
    const creationMonth = creationExamDate.getMonth()+1;
    const creationYear = creationExamDate.getFullYear();
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            Show Session Exam
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='relative flex justify-between'>
            <p className='m-0'>
                <span className='text-primary font-bold'>Creation Date : </span>
                {`${creationDay}/${creationMonth}/${creationYear}`}
            </p>
            <p>
                <span className='text-primary font-bold'>Grade : </span>
                {sessionexam.max_grade}
            </p>
        </div>
        <div className='relative exam-images flex flex-wrap gap-2 w-full'>
            {
                sessionexam
                &&
                sessionexam.images_url
                &&
                sessionexam.images_url.map((image , index)=>{
                    return (
                        <div key={index} className='relative img-div w-[100px] h-[80px] rounded-md overflow-hidden cursor-pointer'
                            onClick={()=>{
                                setImg(image);
                                setShowImageModal(true)
                            }}
                        >
                            <img src={image} alt='exam img' className='absolute hover:rotate-6 hover:scale-125 transition-all duration-500 top-0 left-0 w-full h-full' />
                        </div>
                    )
                })
            }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
      <ShowImageModal
        image={img}
        show = {showImgModal}
        onHide = {() => setShowImageModal(false) }
      />
    </Modal>
  );
}
export default SessionExamModal;