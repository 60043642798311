import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const readAllNotficationsFunc = createAsyncThunk(
    "read all notfications function",
    async (api) => {
        try {
            const response = await axios.post(api , {} );
            console.log(response)
            // checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            return error
        }
        
    }
);

const readAllNotficationsSlicer = createSlice({
    name : "read all notfications slicer",
    initialState : {
        loading : null,
        data : [],
        error : false
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(readAllNotficationsFunc.pending , (state)=>{
            state.loading = true
            state.data = []
            state.error = null
        })
        builder.addCase(readAllNotficationsFunc.rejected , (state)=>{
            state.loading = false
            state.data = []
            state.error = true
        })
        builder.addCase(readAllNotficationsFunc.fulfilled , (state , action)=>{
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
});
export default readAllNotficationsSlicer.reducer;