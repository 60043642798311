import { Bounce, toast } from "react-toastify"

export default function HandleErrorsListFunc(errors)  {
    if(errors) {
        Object.keys(errors).forEach(key => {
            toast.error(
            errors[key].message , {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
            })
        })
    }
}