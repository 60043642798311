import { Modal } from "react-bootstrap";
import { Button } from "react-bootstrap";
import correctOption from "../../../media/images/checklist.png";
import flaseOption from "../../../media/images/cross.png";
import { useTranslation } from "react-i18next";
function QuestionOptionsList(props) {
    const [t] = useTranslation();
    const {question , show_options , options , hide_options , correct_option} = props;

    return (
      <>
        <Modal
          show={show_options}
          onHide={hide_options}
          backdrop="static"
          keyboard={false}
          size={question?.type !== 3 ? "md" : "lg"}
        >
          <Modal.Header closeButton>
            <Modal.Title>{question?.type !== 3 ? t("Question Options") : t("Essay Content")}</Modal.Title>
          </Modal.Header>
          {
            question?.type !== 3
            ?
            <Modal.Body>
              {
                options && options.map((option , index) => {
                  return (
                    <div key={index} className="relative flex items-center rounded-md p-2 bg-primary mb-2">
                      {
                        index === correct_option
                        ?
                        <img src={correctOption} alt="correct option" className="w-[17px] h-[17px] mr-2" />
                        :
                        <img src={flaseOption} alt="correct option" className="w-[17px] h-[17px] mr-2" />
                      }
                      <span className={`${index === correct_option ? "text-green-500" : "text-white"} text-[17px] flex option-content`}>
                      <div dangerouslySetInnerHTML={{__html: option}} className="m-0 p-0"/>
                      </span>
                    </div>
                  )
                })
              }
            </Modal.Body>
            :
            <Modal.Body>
              <div dangerouslySetInnerHTML={{__html : question?.title}} />
            </Modal.Body>

          }
          <Modal.Footer>
            <Button variant="secondary" onClick={hide_options}>
              {t("Close")}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
}

export default QuestionOptionsList;