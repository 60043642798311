import "./courses.css";
import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useDispatch } from "react-redux";
import ContextApi from "../../../store/ContextApi";
import { useContext , useState , useEffect} from "react";
import { fetchAllCoursesFunc } from "../../../store/custom-actions/courses-actions/GetAllCoursesSlicer";
import onlineIcon from "../../../media/images/audiobook.png";
import offlineIcon from "../../../media/images/offline.png";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

function DecisionsDropMenu(props) {
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          as="a"
          className="btn-link i-false btn sharp tp-btn-light btn-dark"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0012 9.86C11.6544 9.86 11.3109 9.92832 10.9905 10.061C10.67 10.1938 10.3789 10.3883 10.1336 10.6336C9.88835 10.8788 9.6938 11.17 9.56107 11.4905C9.42834 11.8109 9.36002 12.1544 9.36002 12.5012C9.36002 12.848 9.42834 13.1915 9.56107 13.5119C9.6938 13.8324 9.88835 14.1236 10.1336 14.3688C10.3789 14.6141 10.67 14.8086 10.9905 14.9413C11.3109 15.0741 11.6544 15.1424 12.0012 15.1424C12.7017 15.1422 13.3734 14.8638 13.8687 14.3684C14.3639 13.873 14.642 13.2011 14.6418 12.5006C14.6417 11.8001 14.3632 11.1284 13.8678 10.6332C13.3724 10.138 12.7005 9.85984 12 9.86H12.0012ZM3.60122 9.86C3.25437 9.86 2.91092 9.92832 2.59048 10.061C2.27003 10.1938 1.97887 10.3883 1.73361 10.6336C1.48835 10.8788 1.2938 11.17 1.16107 11.4905C1.02834 11.8109 0.960022 12.1544 0.960022 12.5012C0.960022 12.848 1.02834 13.1915 1.16107 13.5119C1.2938 13.8324 1.48835 14.1236 1.73361 14.3688C1.97887 14.6141 2.27003 14.8086 2.59048 14.9413C2.91092 15.0741 3.25437 15.1424 3.60122 15.1424C4.30171 15.1422 4.97345 14.8638 5.46866 14.3684C5.96387 13.873 6.24198 13.2011 6.24182 12.5006C6.24166 11.8001 5.96324 11.1284 5.46781 10.6332C4.97237 10.138 4.30051 9.85984 3.60002 9.86H3.60122ZM20.4012 9.86C20.0544 9.86 19.7109 9.92832 19.3905 10.061C19.07 10.1938 18.7789 10.3883 18.5336 10.6336C18.2884 10.8788 18.0938 11.17 17.9611 11.4905C17.8283 11.8109 17.76 12.1544 17.76 12.5012C17.76 12.848 17.8283 13.1915 17.9611 13.5119C18.0938 13.8324 18.2884 14.1236 18.5336 14.3688C18.7789 14.6141 19.07 14.8086 19.3905 14.9413C19.7109 15.0741 20.0544 15.1424 20.4012 15.1424C21.1017 15.1422 21.7734 14.8638 22.2687 14.3684C22.7639 13.873 23.042 13.2011 23.0418 12.5006C23.0417 11.8001 22.7632 11.1284 22.2678 10.6332C21.7724 10.138 21.1005 9.85984 20.4 9.86H20.4012Z"
              fill="#A098AE"
            />
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu dropdown-menu-end"
          align="right"
        >
          <Dropdown.Item
            onClick={() => {
              props.deleteFunction();
            }}
          >
            <i className="fa-solid fa-ban mr-1"></i>
            Delete
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.showEditForm();
            }}
          >
            <i className="fa-solid fa-pen-nib mr-1"></i>
            Update
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              props.showEditImageForm();
            }}
          >
            <i className="fa-regular fa-image mr-1"></i>
            Update Image
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

function CourseCard({
  id,
  course,
  course_img,
  course_status,
  course_name,
  course_description,
  course_price,
  coures_instructor,
  course_rating,
  lessonsCount,
  deleteCourseFunc,
  showEditForm,
  loading,
  courseType
}) {
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const courses_API_URL = `${contextApi.dashboard_URL_API}/courses`;
    const dispatch = useDispatch();
    const [showUpdateImageForm , setShowUpdateImageForm] = useState();
    const [animate , setAnimate] = useState()
    const [showUpdateDataForm , setShowUpdateDataForm] = useState()
    useEffect(() => {
      !loading ? setTimeout(()=>setAnimate(true) , 300) : setAnimate(false)
    },[loading])
    const refetchCourses = async () => {
      try {
        dispatch(fetchAllCoursesFunc(courses_API_URL));
      }
      catch (error) {
        Swal.fire({
          title: t("Oops!"),
          text: error,
          icon: "error"
        });
      }
    }
  return (
    <>
    <div className="col-xl-4 col-md-6 mb-3 course-card-col">
      <div style={{"animationDelay": `.${id}s`}} className={`course-card  transition-all duration-500 bg-white relative w-full shadow-md shadow-slate-300 flex flex-col justify-center p-4 rounded-lg overflow-hidden ${animate ? "animate" : ""}`}>
        <div className="course-image relative w-full h-[200px] overflow-hidden rounded-md">
          <img
            src={`${course_img != null ? course_img : "https://getskills-python-django-learning-admin-dashboard-template.dexignzone.com/static/getskills/images/courses/course1.jpg"}`}
            alt="course img"
            className="absolute top-0 left-0 w-full h-full transition-all duration-500"
          />
            <Link
              to={`/course-details/${id}`}
              className="absolute top-0 left-0 w-full h-full z-[2]"
            >
            </Link>
        </div>
        <div className="course-info flex flex-col my-2">
          <div className="row flex my-1 justify-between">
            <Link
              to={`/course-details/${id}`}
              className="text-lg font-bold text-black"
            >
              {course_name}
            </Link>
              {/* {
                courseType === "online"
                ?
                <div className="relative flex items-center mt-1">
                  <img src={onlineIcon} alt="online img" className="w-[20px] mr-2" />
                  {t("Online")}
                </div>
                :
                <div className="relative flex items-center mt-1 ">
                  <img src={offlineIcon} alt="online img" className="w-[20px] mr-2" />
                  {t("Offline")}
                </div>
              } */}
            {/* <h2 className="text-lg text-[#ff6a59]">
            </h2> */}
          </div>
          <div className="row flex items-center my-1">
            <h3 className="text-sm my-auto"><span className="text-primary">{coures_instructor.name}</span></h3>
            {
              course_rating
              &&
              <div className="flex items-center course-rating">
                <i className="fa-solid fa-star text-orange-500 mr-1"></i>
                <span className="text-orange-500">({course_rating})</span>
              </div>
            }
          </div>
        </div>
        <div className="btns-group relative flex justify-between items-center mb-2">
            <div className="relative flex flex-col rounded-md">
              <div className="box">
                <i className="fa-solid fa-book-open-reader mr-2 text-[20px] opacity-75"></i>
                {t("Lessons")} ({lessonsCount})
              </div>
            </div>
            <Link to={`/course-details/${id}`} className="custom-button text-white submit-rivew-btn rounded-md py-2 px-4 relative flex justify-center items-center shadow-md  transition-all duration-500 bg-primary overflow-hidden">
            {t("Details")}
          </Link>
        </div>
        {/* <div
          className="absolute top-[10px] right-0 z-20">
          <DecisionsDropMenu
            showEditForm = {() => setShowUpdateDataForm(true)}
            deleteFunction={deleteCourseFunc}
            course_status = {course_status}
            showEditImageForm = {()=>setShowUpdateImageForm(true)}
          />
          <UpdateCourseImageForm
            show = {showUpdateImageForm}
            onHide = {()=>setShowUpdateImageForm(false)}
            oldname  = {course_name}
            course_id = {id}
            refetch_data_func = {() => refetchCourses()}
          />
          <UpdateCourseForm
            show = {showUpdateDataForm}
            onHide = {() => setShowUpdateDataForm(false)}
            oldcoursename  = {course_name}
            oldcoursedescription = {course_description}
            course_id = {id}
            refetch_data_func = {() => refetchCourses()}
          />
        </div> */}
      </div>
    </div>
    </>
  );
}

export default CourseCard;