import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux";
import { getContactsSettingsFunc } from "../../../store/custom-actions/contact-settings-actions/GetContactsSettingsSlicer";
import ContextApi from "../../../store/ContextApi";
import Swal from "sweetalert2";
import { Button, Spinner } from "react-bootstrap";
import faildImg from "../../../media/images/planet.png";
import { useForm } from "react-hook-form";
import { addContactSettingsFunc } from "../../../store/custom-actions/contact-settings-actions/AddContactSettingsSlicer";
import PageTitle from "../../layouts/PageTitle";
import { useTranslation } from "react-i18next";

function ContactSettingsPage() {
    const {t} = useTranslation();
    const [contactsSettings , setContactsSettings] = useState({});
    const contextApi = useContext(ContextApi);
    const contactsSettingsApi = `${contextApi.dashboard_URL_API}/settings/contact-settings`;
    const dispatch = useDispatch();
    const [showPass , setShowPass] = useState(false);
    const {loading:getContactsSettingsLoading , data:contactsSettingsData} = useSelector(
        state => state.getContactsSettingsSlicer
    )
    const {loading:addContactSettingsLoading} = useSelector(
        state => state.addContactSettingsSlicer
    )

    const {register , handleSubmit} = useForm();
    const getContactsSettings = () => {
        try {
            dispatch(getContactsSettingsFunc(contactsSettingsApi))
        }
        catch(error) {
            Swal.fire({
                title: t("Oops!"),
                text: error,
                icon: "warning"
            });
        }
    }
    useEffect(() => {
        getContactsSettings()
    },[])
    useEffect(() => {
        contactsSettingsData && setContactsSettings(contactsSettingsData)
    },[contactsSettingsData])

    const handleUpdateContactSettings = (data) => {
        if(data) {
            try {
                dispatch(addContactSettingsFunc({api : contactsSettingsApi , data}))
                .then(result => {
                    if(result.payload.success === true) {
                        dispatch(getContactsSettingsFunc(contactsSettingsApi))
                    }
                })
            }
            catch(error) {
                Swal.fire({
                    title: t("Oops!"),
                    text: error,
                    icon: "warning"
                });
            }
        }
    }
    return (
        <>
            <PageTitle motherMenu="Dashboard" activeMenu="Contact Settings" customClasses={"mb-2"}/>
            {
                getContactsSettingsLoading
                ?
                (
                    <>
                        <div className="row d-flex justify-content-center gap-2">
                            <Spinner animation="grow" className="bg-primary" />
                            <Spinner animation="grow" className="bg-primary" />
                            <Spinner animation="grow" className="bg-primary" />
                            <Spinner animation="grow" className="bg-primary" />
                        </div>
                    </>
                )
                :
                (
                    contactsSettingsData
                    ?
                    (
                        contactsSettings
                        ?
                        <div className="relative flex flex-col">
                            <div className="relative flex flex-col md:flex-row justify-end items-center p-1 mb-2">
                                <Button
                                    className="mr-2"
                                    onClick={() => window.history.back()}
                                >{t("Back")}</Button>
                                <Button form="update-contacts-settings-form" type="submit" 
                                className={`w-fit flex justify-center items-center ${addContactSettingsLoading ? "opacity-80 pointer-events-none" : ""}`}>
                                    {
                                        addContactSettingsLoading
                                        ?
                                        <>{(t("loading"))}..</>
                                        :
                                        t("Submit")
                                    }
                                    <i className={`fa-solid fa-gear ml-1 ${addContactSettingsLoading ? " animate-spin" : ""}`}></i>
                                </Button>
                            </div>
                            <div className="row">
                                <div className="col-xl-12 col-xxl-12">
                                    <div className="card p-4">
                                        <form 
                                            id="update-contacts-settings-form"
                                            className="relative mt-3"
                                            onSubmit={handleSubmit((data) => {
                                                handleUpdateContactSettings(data)
                                            })}
                                        >
                                            <div className="row">
                                                {
                                                    Object.keys(contactsSettings).map((key , index) => {
                                                        return (
                                                        ( 
                                                            key !== "id" 
                                                            &&
                                                            key !== "created_at" 
                                                            &&
                                                            key !== "updated_at" 
                                                            )
                                                            &&
                                                            <div className="form-group col-sm-12 col-md-6 mt-1">
                                                                <label>{key && key.replace(/_/g , " ")}</label>
                                                                <div className="relative">
                                                                    <input 
                                                                        className="form-control"
                                                                        {...register(key , {required : `${key} Must not be empty`})}
                                                                        type={
                                                                            key === "password" ? (showPass ? "text" : "password") : "text"
                                                                        }
                                                                        defaultValue={contactsSettings[key]}
                                                                    />
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className="form-group col-sm-12 mt-1">
                                                    <div className="relative">
                                                        <label>{t("Password")}</label>
                                                        <input 
                                                        className="form-control col-sm-12"
                                                        {...register("site_smtp_mail_password")}
                                                        type={showPass ? "text" : "password"}
                                                        />
                                                        <i className="fa-solid fa-eye absolute right-[10px] top-[60%]  cursor-pointer"
                                                            onClick={() => setShowPass(!showPass)}
                                                        ></i>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                        <h5>{t("No Contacts Settings")}</h5>
                    )
                    :
                    <div className='relative w-full h-[60vh] flex flex-col justify-center items-center'>
                        <img src={faildImg} alt="faild img" className='w-[150px] mb-3'/>
                        <p>{t("Faild load Contacts Settings!")}
                        <span className="text-[#4d5fe3] cursor-pointer"
                        onClick={()=> getContactsSettings()}
                        >
                        {t("Try again")}
                        </span>
                        </p>
                    </div>
                )
            }
        </>
    )
}

export default ContactSettingsPage;