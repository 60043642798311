import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
import Swal from "sweetalert2";
export const unLinkQuizFunc = createAsyncThunk(
    "un link quize function",
    async ({api , data}) => {
        try {
            const response = await axios.delete(api , {data , withCredentials : true});
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
        
    }
);

const unLinkQuizSlicer = createSlice({
    name : "un link quize",
    initialState : {
        loading : null,
        lessons : [],
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(unLinkQuizFunc.pending , (state)=>{
            state.loading = true
            state.lessons = []
            state.error = null
        })
        builder.addCase(unLinkQuizFunc.rejected , (state)=>{
            state.loading = false
            state.lessons = []
            state.error = true
        })
        builder.addCase(unLinkQuizFunc.fulfilled , (state , action)=>{
            state.loading = false
            state.lessons = action.payload
            state.error = null
        })
    }
});
export default unLinkQuizSlicer.reducer;