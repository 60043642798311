import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const updateSubscripePaymentsStatusFunc = createAsyncThunk(
    "subscribe/update",
    async ({api , data}) => {
        try {
            const response = await axios.post(api , data);
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            checkErrors(error.response , error.message);
            return error
        }
        
    }
);

const updateSubscripePaymentsStatus = createSlice({
    name : "subscribe/update",
    initialState : {
        loading: null,
        data : [],
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(updateSubscripePaymentsStatusFunc.pending , (state)=>{
            state.loading = true
            state.data = []
            state.error = null
        })
        builder.addCase(updateSubscripePaymentsStatusFunc.rejected , (state)=>{
            state.loading = false
            state.data = []
            state.error = true
        })
        builder.addCase(updateSubscripePaymentsStatusFunc.fulfilled , (state , action)=>{
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
});
export default updateSubscripePaymentsStatus.reducer;