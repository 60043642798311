import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
import Swal from "sweetalert2";

export const addQuestionVideoFunc = createAsyncThunk(
    "add question video function",
    async ({questionApi , videoData}) => {
        try{
            const response = await axios.post(
                questionApi , 
                videoData , 
                {withCredentials : true , onUploadProgress : (data) => {
                    console.log(data);
                }});
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
    }
)

const addQuestionVideoSlicer = createSlice({
    name : "add question video slicer",
    initialState : {
        loading : null,
        data : null,
        error : null,
    },
    reducers: {},
    extraReducers : (builder) => {
        builder.addCase(addQuestionVideoFunc.pending , (state) => {
            state.loading = true;
            state.data = null;
            state.error = null;
        })
        builder.addCase(addQuestionVideoFunc.rejected , (state) => {
            state.loading = false;
            state.data = null;
            state.error = true;
        })
        builder.addCase(addQuestionVideoFunc.fulfilled , (state , action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = false;
        })
    }
})

export default addQuestionVideoSlicer.reducer;