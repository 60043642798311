import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const addAssistantFunc = createAsyncThunk(
    "add new assistant function",
    async ({api , assistantData}) => {
        const config = {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
            },
        }
        try {
            const response = await axios.post(api , assistantData , config );
            // if(response.data.status_code === 401 || response.data.status_code === 403) {
            //     window.localStorage.clear();
            //     window.location.reload();
            // }else{
            //     return response.data
            // }
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            console.log(error)
            checkErrors(error.response , error.message);
        }
        
    }
)

const addAssistantSlicer = createSlice({
    name : "add new assistant slicer",
    initialState : {
        addAssistnatLoading : null,
        userData : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(addAssistantFunc.pending , (state) => {
            state.addAssistnatLoading = true
            state.userData = false
            state.error = false
        });
        builder.addCase(addAssistantFunc.rejected , (state) => {
            state.addAssistnatLoading = false
            state.userData = null
            state.error = true
        });
        builder.addCase(addAssistantFunc.fulfilled , (state , action) => {
            state.addAssistnatLoading = null
            state.userData = action.payload
            state.error = null
        });
    }
});

export default addAssistantSlicer.reducer;