import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import ContextApi from '../../../../store/ContextApi';
import sessionImg from "../../../../media/images/online-learning.png";
import excutionIcon from "../../../../media/images/gear.png";
import { getCourseExcutionFunc } from '../../../../store/custom-actions/courses-actions/excutions-actions/GetCourseExcutionsSlicer';
import { addCouponeFunc } from '../../../../store/custom-actions/couponse-actions/AddCouponseSlicer';
import { getCouponseFunc } from '../../../../store/custom-actions/couponse-actions/GetCouponseSlicer';
import { fetchAllCoursesFunc } from '../../../../store/custom-actions/courses-actions/GetAllCoursesSlicer';
import HandleErrorsListFunc from '../../../global-functions/handleErrorsListFunc';
import { fetchAllCategoriesFunc } from '../../../../store/custom-actions/categories-actions/GetAllCategoriesSlicer';
import { useTranslation } from 'react-i18next';
import { TreeSelect } from 'antd';
// Function to convert 'children' to 'all_children'
const convertAllChildrenKey = (data) => {
  if (!Array.isArray(data)) return [];
  return data.map((item) => {
    const { name, id, all_children, ...rest } = item;
    return {
      ...rest,
      title: name,
      value: id,
      children: convertAllChildrenKey(all_children),
    };
  });
};

const TreeSelectExam = ({treeData , selectCategory}) => {
  const [value, setValue] = useState();
  const onChange = (newValue) => {
    console.log(newValue);
    selectCategory(newValue)
    setValue(newValue);
  };
  const modifiedTreeData = convertAllChildrenKey(treeData);

  return (
    <TreeSelect
      style={{
        width: '100%',
        height: '40px',
      }}
      value={value}
      dropdownStyle={{
        maxHeight: 400,
        overflow: 'auto',
      }}
      treeData={modifiedTreeData}
      placeholder="Please select"
      treeDefaultExpandAll
      onChange={onChange}
      treeDataSimpleMode={{
        id: 'value',
        pId: 'parentId',
        rootPId: null,
      }}
    />
  );
};
function NewCouponeForm(props) {
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const {register , handleSubmit , formState : {errors} , reset } = useForm();
    const dispatch = useDispatch();
    const couponsApi = `${contextApi.dashboard_URL_API}/coupons`;
    const coursesApi = `${contextApi.dashboard_URL_API}/courses`;
    const { loading:getExecutionsLoading , data:executionsData } = useSelector((state) => state.getCourseExcutionsSlicer);
    const {loading:addCouponeLoading} = useSelector(
        state => state.addCouponeSlicer
    )
    const {loading:getCoursesLoading , courses:coursesData} = useSelector(
        state => state.getAllCoursesSlicer
    )
    const [executionsList , setExecutionsList] = useState([]);
    const [chooseExcution , setChooseExcution] = useState(false)
    const [excutionWhichChoosed , setExcutionWhichChoosed] = useState("Choose Excution..");
    const [excutionId , setExcutionId] = useState();
    const [startDate , setStartDate] = useState();
    const [endDate , setEndDate] = useState();
    const [courseId , setCourseId] = useState();
    const [coursesList , setCoursesList] = useState([])
    const [chooseCourse , setChooseCourse] = useState(false)
    const [courseWhichChoosedImg , setCourseWhichChoosedImg] = useState()
    const [courseWhichChoosed , setCourseWhichChoosed] = useState()
    const [category , setCategory] = useState(t("Choose Category.."))
    const excutions_URL_APi = `${contextApi.dashboard_URL_API}/courses/${courseId}/course-executions`;

    const { loading: getCategoriesLoading, categoriesData } = useSelector(
      (state) => state.getAllCategoriesSlicer
    );
    const categories_api = `${contextApi.dashboard_URL_API}/categories`;
    const [categoriesList, setCategoriesList] = useState([]);
    useEffect(() => {
      const getCategoriesFunc = async () => {
        dispatch(fetchAllCategoriesFunc(`${categories_api}`));
      };
      getCategoriesFunc();
    }, [categories_api]);
    useEffect(() => {
      categoriesData && setCategoriesList(categoriesData)
    },[categoriesData])
    useEffect(() => {
      courseId && dispatch(getCourseExcutionFunc(excutions_URL_APi));
    },[courseId])
    useEffect(() => {
      executionsData && setExecutionsList(executionsData)
    },[executionsData])
    useEffect(() => {
      category !== "Choose Category.."
      &&
      dispatch(fetchAllCoursesFunc(`${coursesApi}?${category !== "Choose Category.." ? `category_id=${category}` : ""}`))
    },[category])
    useEffect(() => {
      const onlineCourses = coursesData && coursesData.length >= 1 && coursesData.filter(course => course.type === "online");
      coursesData && setCoursesList(onlineCourses)
    },[coursesData])
    const handleSubmitData = (data) => {
        if(data) {
            dispatch(addCouponeFunc({api : couponsApi , data : {...data , course_execution_id : excutionId && excutionId}}))
            .then(result => {
                if(result.payload.success === true) {
                    props.onHide();
                    reset();
                    setExcutionWhichChoosed("Choose Excution..");
                    dispatch(getCouponseFunc(couponsApi))
                    setCategory("Choose Category..")
                }
            })
        }
    }
    const handleSetCategory = (id) => {
      setCategory(id)
    }
  useEffect(() => {
    HandleErrorsListFunc(errors)
  },[errors])
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='flex items-center'>
          <img src={sessionImg} alt="session-icon" className='relative w-[20px] mr-2' />
          {t("New Coupon")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='session-form-div'>
            <form
                id="session-form"
                onSubmit={handleSubmit((data) => {
                    handleSubmitData(data);
                })}
            >
                <div className='row'>
                    <div className='form-group col-sm-12 col-md-6'>
                        <label>{t("Coupon")}</label>
                        {errors.code?.message && (
                          <p className="text-red-600">
                            {errors.code?.message}
                          </p>
                        )}
                        <input 
                        {...register("code" , {required : t("Coupon is required!")})}
                        type="text" 
                        className='form-control'
                        placeholder='XXXXX'
                        />
                    </div>
                    <div className='form-group col-sm-12 col-md-6'>
                        <label>{t("Usage Limit")}</label>
                        {errors.count_used?.message && (
                          <p className="text-red-600">
                            {errors.count_used?.message}
                          </p>
                        )}
                        <input 
                        {...register("count_used" , {required : t("Usage Limit is required!")})}
                        type="number" 
                        min={1}
                        className='form-control'
                        />
                    </div>
                </div>
                <div className='row'>
                    <div className='form-group col-sm-12 col-md-6'>
                        <label>{t("Coupon Discount")}</label>
                        {errors.discount?.message && (
                          <p className="text-red-600">
                            {errors.discount?.message}
                          </p>
                        )}
                        <input 
                        {...register("discount" , {required : t("Discount is required!")})}
                        type="number" 
                        className='form-control'
                        />
                    </div>
                    <div className='form-group col-sm-12 col-md-6'>
                        <label>{t("Discount Type")}</label>
                        <select 
                            {...register("type" , {required : t("Discount Type is required!")})}
                            className='form-control'
                        >
                            <option value={"fixed"}>{t("Fixed")}</option>
                            <option value={"percentage"}>{t("Percentage")}</option>
                        </select>
                    </div>
                </div>
                <div className='row'>
                    <div className='form-group col-sm-12 col-md-6'>
                        <label>{t("From")} :</label>
                        {errors.start_date?.message && (
                          <p className="text-red-600">
                            {errors.start_date?.message}
                          </p>
                        )}
                        <input 
                        {...register("start_date" , {required : t("Start Date is required!")})}
                        type="date" 
                        className='form-control'
                        onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                    <div className='form-group col-sm-12 col-md-6'>
                        <label>{t("To")} :</label>
                        {errors.end_date?.message && (
                          <p className="text-red-600">
                            {errors.end_date?.message}
                          </p>
                        )}
                        <input 
                        {...register("end_date" , {required : t("End Date is required!")})}
                        type="date" 
                        className='form-control'
                        onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                </div>
                <div className='row'>
                {
                    getCategoriesLoading ? (
                      <div className="form-group flex items-center">
                        <p>{t("loading")}...</p>
                      </div>
                    ) : categoriesData &&
                      categoriesList &&
                      categoriesList.length <= 0 ? (
                      <div className="form-group flex items-center">
                        <p>{t("No Categories")}</p>
                      </div>
                    ) : (
                      <div
                        className={`form-group mb-3 col-sm-12`}
                      >
                        <label>{t("Course Category")}</label>
                        {/* <select
                          className="form-control col-sm-12 w-full"
                          onChange={(e) => {
                            setCategory(e.target.value)
                          }}
                        >
                          <option value={t("Choose Category..")}>
                            {t("Choose Category")}...
                          </option>
                          {categoriesList &&
                            categoriesList.map((cat, index) => {
                              return (
                                <option key={cat.id} value={cat.id}>
                                  {cat.name}
                                </option>
                              );
                            })}
                        </select> */}
                        <TreeSelectExam treeData={categoriesList} selectCategory = {handleSetCategory}/>

                      </div>
                    )}
                </div>
                {
                  category !== t("Choose Category..")
                  &&
                  <div className='row'>
                  {
                    getCoursesLoading
                    ?
                    <p>{t("loading")}...</p>
                    :
                    (
                      coursesData
                      ?
                      (
                        coursesList && coursesList.length >= 1
                        ?
                        <div className="col-sm-12">
                            <div className="form-group mb-3 col-sm-12">
                                <label>{t("Course")} ({t("Optional")})</label>
                                <span 
                                    className="relative flex justify-between items-center px-2 w-full h-[45px] rounded-md border cursor-pointer bg-white"
                                    onClick={()=>setChooseCourse(!chooseCourse)}
                                >
                                    <div className="relative flex items-center">
                                        {
                                          courseWhichChoosedImg
                                          &&
                                          <img src={courseWhichChoosedImg} alt="teacher chooses" className="relative w-[20px] mr-2" />
                                        }
                                        {courseWhichChoosed}
                                    </div>
                                    <i className={`fa-solid fa-circle-chevron-left transition-all duration-500 text-[20px] text-[#075985] ${chooseCourse ? "rotate-[-90deg] opacity-100" : "rotate-[90deg] opacity-70"} `}></i>
                                </span>
                            </div>
                            <div className={`instructors-list overflow-y-auto transition-all duration-500 w-[98%] cursor-pointer ${chooseCourse ? "h-[150px]" : "h-0"}`}>
                            { coursesList &&
                                coursesList.map((course , index) =>{
                                return (
                                    <div key={index} className="input-div relative flex items-center my-1 p-2 pl-0">
                                    <input
                                    form="new-course-form"
                                    id={course.id} 
                                    type="radio" 
                                    name="course-name" 
                                    value={course.id}
                                    className=""
                                    data-name={course.name}
                                    onChange={(e)=>{
                                        setChooseCourse(false);
                                        setCourseId(e.target.value);
                                        setCourseWhichChoosed(e.target.dataset.name);
                                        setCourseWhichChoosedImg(course.image_url ? course.image_url : excutionIcon);
                                    }}
                                    />
                                    <label htmlFor={course.id} className="cursor-pointer mr-2 flex items-center translate-y-[2px]">
                                        <img src={course.image_url ? course.image_url : excutionIcon} alt="img" className="w-[20px] mx-2 rounded-[50%]"/>
                                        {course.name}
                                    </label>
                                    </div>
                                )
                                })
                            }
                            </div>
                        </div>
                        :
                        <p>{t("No Courses for this Category")}</p>
                      )
                      :
                      <p className="m-0">{t("Feild Load Courses")}!</p>
                    )

                  }
                  </div>
                }
                {
                  courseId && courseId !== "" && category !== "Choose Category.."
                  &&
                  <div className='row'>
                  {
                              getExecutionsLoading
                              ?
                              <p>{t("loading")}...</p>
                              :
                              (
                                  executionsData
                                  ?
                                  (
                                    executionsList && executionsList.length >= 1
                                    ?
                                    <div className="col-sm-12">
                                        <div className="form-group mb-3 col-sm-12">
                                            <label>{t("Course Excutions")}</label>
                                            {errors.course_execution_id?.message && (
                                            <p className="text-red-600">
                                            {errors.course_execution_id?.message}
                                            </p>
                                            )}
                                            <span 
                                                className="relative flex justify-between items-center px-2 w-full h-[45px] rounded-md border cursor-pointer bg-white"
                                                onClick={()=>setChooseExcution(!chooseExcution)}
                                            >
                                                <div className="relative flex items-center">
                                                    {
                                                        <img src={excutionIcon} alt="teacher chooses" className="relative w-[20px] mr-2" />
                                                    }
                                                    {excutionWhichChoosed}
                                                </div>
                                                <i className={`fa-solid fa-circle-chevron-left transition-all duration-500 text-[20px] text-[#075985] ${chooseExcution ? "rotate-[-90deg] opacity-100" : "rotate-[90deg] opacity-70"} `}></i>
                                            </span>
                                        </div>
                                        <div className={`instructors-list overflow-y-auto transition-all duration-500 w-[98%] cursor-pointer ${chooseExcution ? "h-[150px]" : "h-0"}`}>
                                        { executionsList &&
                                            executionsList.map((excution , index) =>{
                                            return (
                                                <div key={index} className="input-div relative flex items-center my-1 p-2 pl-0">
                                                <input
                                                form="new-course-form"
                                                id={index} 
                                                type="radio" 
                                                name="instructor-name" 
                                                value={excution.id}
                                                className=""
                                                data-name={excution.title}
                                                onChange={(e)=>{
                                                  setChooseExcution(false);
                                                  setExcutionId(e.target.value);
                                                  setExcutionWhichChoosed(e.target.dataset.name);
                                                }}
                                                />
                                                <label htmlFor={index} className="cursor-pointer mr-2 flex items-center translate-y-[2px]">
                                                    <img src={excutionIcon} alt="img" className="w-[20px] mx-2 rounded-[50%]"/>
                                                    {excution.title}
                                                </label>
                                                </div>
                                            )
                                            })
                                        }
                                        </div>
                                    </div>
                                    :
                                    <p>{t("No executions for this course")}</p>
                                  )
                                  :
                                  (
                                    coursesData
                                    &&
                                    <p className="m-0">{t("Feild Load Executions")}!</p>
                                  )
                              )

                          }
                  </div>
                }
            </form>
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
          props.onHide();
          setCategory("Choose Category..");
          }}>{t("Close")}</Button>
        <Button type="submit" form="session-form" className={`${addCouponeLoading ? "pointer-events-none opacity-80" : ""}`}>
          {!addCouponeLoading ? (
            t("Submit")
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                {t("loading")}..
                <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white"></div>
              </div>
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default NewCouponeForm