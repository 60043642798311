import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import ContextApi from '../../../../store/ContextApi';
import sessionImg from "../../../../media/images/online-learning.png";
import excutionIcon from "../../../../media/images/gear.png";
import { addActivationCodeFunc } from '../../../../store/custom-actions/code-bulcks-actions/AddCodeSlicer';
import { getCourseExcutionFunc } from '../../../../store/custom-actions/courses-actions/excutions-actions/GetCourseExcutionsSlicer';
import HandleErrorsListFunc from '../../../global-functions/handleErrorsListFunc';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
function NewBulckForm(props) {
    const {t} = useTranslation();
    const {course_id , refetch_data} = props;
    const contextApi = useContext(ContextApi);
    const {register , handleSubmit , formState : {errors} , reset } = useForm();
    const dispatch = useDispatch();
    const bulcksApi = `${contextApi.dashboard_URL_API}/code-bulks`;
    const { loading:getExecutionsLoading , data:executionsData } = useSelector((state) => state.getCourseExcutionsSlicer);
    const {loading:addBulckLoading} = useSelector(
        state => state.addActivationCodeSlicer
    )
    const excutions_URL_APi = `${contextApi.dashboard_URL_API}/courses/${course_id}/course-executions`;
    const [executionsList , setExecutionsList] = useState([]);
    const [chooseExcution , setChooseExcution] = useState(false);
    const [excutionWhichChoosed , setExcutionWhichChoosed] = useState(t("Choose Excution.."));
    const [excutionId , setExcutionId] = useState();

      useEffect(() => {
        dispatch(getCourseExcutionFunc(excutions_URL_APi));
      },[])
      useEffect(() => {
        executionsData && setExecutionsList(executionsData)
      },[executionsData])
      
    const handleSubmitData = (data) => {
        if(data) {
          if(excutionWhichChoosed !== "Choose Excution..") {
            dispatch(addActivationCodeFunc({api : bulcksApi , data : {...data , course_execution_id : excutionId}}))
            .then(result => {
                if(result.payload.success === true) {
                    refetch_data()
                    props.onHide();
                    reset();
                    setExcutionWhichChoosed("Choose Excution..")
                }
            })
          }else {
            toast.error(t("You Should Choose Execution!"))
          }
        }
    }
    
    useEffect(() => {
      HandleErrorsListFunc(errors)
    },[errors])
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='flex items-center'>
          <img src={sessionImg} alt="session-icon" className='relative w-[20px] mr-2' />
          {t("New Bulck")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='session-form-div'>
            <form
                id="session-form"
                onSubmit={handleSubmit((data) => {
                    handleSubmitData(data);
                })}
            >
                <div className='row'>
                    <div className='form-group col-sm-12 col-md-6'>
                        <label>{t("Name")}</label>
                        {errors.name?.message && (
                          <p className="text-red-600">
                            {errors.name?.message}
                          </p>
                        )}
                        <input 
                        {...register("name" , {required : t("Name is required!")})}
                        type="text" 
                        className='form-control'
                        />
                    </div>
                    <div className='form-group col-sm-12 col-md-6'>
                        <label>{t("Count")}</label>
                        {errors.count?.message && (
                          <p className="text-red-600">
                            {errors.count?.message}
                          </p>
                        )}
                        <input 
                        {...register("count" , {required : t("Count is required!")})}
                        type="number" 
                        className='form-control'
                        />
                    </div>
                </div>
                <div className='row'>
                {
                            getExecutionsLoading
                            ?
                            <p>{t("loading")}...</p>
                            :
                            (
                                executionsData
                                ?
                                (
                                  executionsList && executionsList.length >= 1
                                  ?
                                  <div className="col-sm-12">
                                      <div className="form-group mb-3 col-sm-12">
                                          <label>{t("Course Excutions")}</label>
                                          {errors.course_execution_id?.message && (
                                          <p className="text-red-600">
                                          {errors.course_execution_id?.message}
                                          </p>
                                          )}
                                          <span 
                                              className="relative flex justify-between items-center px-2 w-full h-[45px] rounded-md border cursor-pointer bg-white"
                                              onClick={()=>setChooseExcution(!chooseExcution)}
                                          >
                                              <div className="relative flex items-center">
                                                  {
                                                      <img src={excutionIcon} alt="teacher chooses" className="relative w-[20px] mr-2" />
                                                  }
                                                  {excutionWhichChoosed}
                                              </div>
                                              <i className={`fa-solid fa-circle-chevron-left transition-all duration-500 text-[20px] text-[#075985] ${chooseExcution ? "rotate-[-90deg] opacity-100" : "rotate-[90deg] opacity-70"} `}></i>
                                          </span>
                                      </div>
                                      <div className={`instructors-list overflow-y-auto transition-all duration-500 w-[98%] cursor-pointer ${chooseExcution ? "h-[150px]" : "h-0"}`}>
                                      { executionsList &&
                                          executionsList.map((excution , index) =>{
                                          return (
                                              <div key={index} className="input-div relative flex items-center my-1 p-2 pl-0">
                                              <input
                                              form="new-course-form"
                                              id={excution.id} 
                                              type="radio" 
                                              name="instructor-name" 
                                              value={excution.id}
                                              className=""
                                              data-name={excution.title}
                                              onChange={(e)=>{
                                                  setChooseExcution(false);
                                                  setExcutionId(e.target.value);
                                                  setExcutionWhichChoosed(e.target.dataset.name);
                                              }}
                                              />
                                              <label htmlFor={excution.id} className="cursor-pointer mr-2 flex items-center translate-y-[2px]">
                                                  <img src={excutionIcon} alt="img" className="w-[20px] mx-2 rounded-[50%]"/>
                                                  {excution.title}
                                              </label>
                                              </div>
                                          )
                                          })
                                      }
                                      </div>
                                  </div>
                                  :
                                  <p>{t("No executions for this course")}</p>
                                )
                                :
                                <p className="m-0">{t("Feild Load Executions!")}</p>
                            )

                        }
                </div>
            </form>
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{t("Close")}</Button>
        <Button type="submit" form="session-form" className={`${addBulckLoading ? "pointer-events-none opacity-80" : ""}`}>
          {!addBulckLoading ? (
            t("Submit")
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                {t("loading")}...
                <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white"></div>
              </div>
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default NewBulckForm