import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const addActivationCodeFunc = createAsyncThunk(
    "add new code function",
    async ({api , data}) => {
        try {
            const response = await axios.post(api , data );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            checkErrors(error.response , error.message);
        }
        
    }
)

const addActivationCodeSlicer = createSlice({
    name : "add new code slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(addActivationCodeFunc.pending , (state) => {
            state.loading = true
            state.data = false
            state.error = false
        });
        builder.addCase(addActivationCodeFunc.rejected , (state) => {
            state.loading = false
            state.data = null
            state.error = true
        });
        builder.addCase(addActivationCodeFunc.fulfilled , (state , action) => {
            state.loading = null
            state.data = action.payload
            state.error = null
        });
    }
});

export default addActivationCodeSlicer.reducer;