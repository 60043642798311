import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
// images
import qrcode from "../../../images/frame.png";
import { useDispatch, useSelector } from "react-redux";

// import EmptyData from "../../../components/empty-data/EmptyData";
import { Link, useParams } from "react-router-dom";
import { getSingleSubscriptionFunc } from "../../../store/custom-actions/subscription-actions/GetSingleSubscriptionSlicer";
import ContextApi from "../../../store/ContextApi";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PageTitle from "../../layouts/PageTitle";
import { useReactToPrint } from 'react-to-print';

const SubscriptionDetails = () => {
    const {t} = useTranslation();
    const {id} = useParams();
    const {loading , data:subscriptionData} = useSelector(
        state => state.getSingleSubscriptionSlicer
    )
    const {loading:getBasicSettingsLoading , data:settingsData} = useSelector(
        state => state.getBasicSettingsSlicer
    )
    const contextApi = useContext(ContextApi);
    const subscription_API_URL = `${contextApi.dashboard_URL_API}/subscriptions/${id}`;

    const dispatch = useDispatch();
    const [order , setOrder] = useState({})
    const [year , stYear] = useState("");
    const [month , setMonth] = useState("");
    const [day , setDay] = useState("");
    const [execution , setExecution] = useState({});
    const [orderOwn , setorderOwn] = useState({});
    useEffect(() => {
        const getSingleOrder = async () => {
          try {
            dispatch(getSingleSubscriptionFunc(subscription_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getSingleOrder();
    }, []);
    useEffect(() => {
        if(subscriptionData) {
            setOrder(subscriptionData)
            const date = new Date(subscriptionData.created_at)
            const year = date.getFullYear();
            const month = date.getMonth()+1;
            const day = date.getDate();
            stYear(year);
            setMonth(month);
            setDay(day);
            setExecution(subscriptionData.course_execution);
            setorderOwn(subscriptionData.student) 
        }
    },[subscriptionData])

    const switchOrderStatus = (status) => {
        switch(status) {
          case 0:
            return (
                "Pending"
            );
          case 1:
            return (
                "Active"
            );
          case 2:
            return (
                "Delivery"
            );
          case 3:
            return (
                "completed"
            );
          default :
            return (
                "Pending"
            );
            
        }
    }
      
    const printRef = useRef();
    const handlePrint = useReactToPrint({
      content : () => printRef.current
    })
  return (
    <>
      <PageTitle activeMenu="Subscription Details" motherMenu="Subscriptions" />
      <Link
        to={"#"} 
        onClick={() => {
            handlePrint();
        }}
        className="btn btn-primary btn-sm mt-2 mb-2"
        >
            Print
            <i className="fa-solid fa-print ml-1"></i>
        </Link>
      {
        loading
        ?
        <>
            <div className="row d-flex justify-content-center gap-2">
                <Spinner animation="grow" className="bg-primary" />
                <Spinner animation="grow" className="bg-primary" />
                <Spinner animation="grow" className="bg-primary" />
                <Spinner animation="grow" className="bg-primary" />
            </div>
        </>
        :
        (
            subscriptionData && Object.keys(order).length >= 1
            ?
            <div className="row" ref={printRef}>
                <div className="col-lg-12">
                <div className="card">
                    <div className="card-header">
                    <span className="float-right">
                    <strong>Payment :</strong>
                    {" "}
                    {subscriptionData?.type_payment?.replace(/_/ig , " ")} 
                    </span>
                    <strong>{year} / {month} / {day}</strong>{" "}
                    <span className="float-right">
                        <strong>Status:</strong> {subscriptionData?.payment_status}
                    </span>{" "}
                    </div>
                    <div className="card-body">
                    <div className="row mb-5">
                        <div className="mt-4 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                        <h6>From:</h6>
                        <div>
                            {" "}
                            <strong>{settingsData?.site_title}</strong>{" "}
                        </div>
                        <div>{settingsData?.contact_address}</div>
                        {/* <div>71-101 Szczecin, Poland</div> */}
                        <div>Email: {settingsData?.site_global_email}</div>
                        <div>Phone: {settingsData?.contact_phone}</div>
                        </div>
                        <div className="mt-4 col-xl-3 col-lg-6 col-md-6 col-sm-6">
                        <h6>To:</h6>
                        <div>
                            {" "}
                            <strong>{orderOwn.name}</strong>{" "}
                        </div>
                        {/* <div>Attn: {orderOwn.first_name} {orderOwn.last_name}</div> */}
                        {/* <div>43-190 Mikolow, Poland</div> */}
                        <div>Email: {orderOwn.email}</div>
                        <div>Phone: {orderOwn.phone}</div>
                        </div>
                        <div className="mt-4 col-xl-6 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-lg-end justify-content-md-center justify-content-xs-start">
                        <div className="row align-items-center">
                            <div className="col-sm-9">
                                <div className="brand-logo mb-3">
                                    <img src={settingsData?.site_logo} alt="site logo" className="relative logo-compact" style={{width:'120px'}} />
                                </div>
                                {/* <span>
                                    Please send exact amount:{" "}
                                    <strong className="d-block">0.15050000 BTC</strong>
                                    <strong>1DonateWffyhwAjskoEwXt83pHZxhLTr8H</strong>
                                </span>
                                <br />
                                <small className="text-muted">
                                    Current exchange rate 1BTC = $6590 USD
                                </small> */}
                            </div>
                            {/* <div className="col-sm-3 ">
                            {" "}
                            <img
                                src={qrcode}
                                className="img-fluid"
                                alt="qr"
                                style={{width:'220px'}}
                            />{" "}
                            </div> */}
                        </div>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table table-striped">
                        <thead>
                            <tr>
                            {/* <th className="center">#</th> */}
                            <th>Execution</th>
                            <th>Course</th>
                            <th className="">Category</th>
                            <th className="">Instructor</th>
                            <th className="">Discount</th>
                            <th className="">Price</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                <tr>
                                    <td>{execution?.title}</td>
                                    <td>{execution?.course?.name}</td>
                                    <td>{execution?.course?.category?.name}</td>
                                    <td>{execution?.course?.user?.name}</td>
                                    <td>{subscriptionData?.discount >= 1 ? subscriptionData?.discount : "--"}</td>
                                    <td>{subscriptionData?.total_price} {settingsData?.site_global_currency}</td>
                                </tr>
                            }
                        </tbody>
                        </table>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-sm-5"> </div>
                        <div className="col-lg-4 col-sm-5 ms-auto">
                        <table className="table table-clear">
                            <tbody>
                            {/* <tr>
                                <td className="left">
                                <strong>Subtotal</strong>
                                </td>
                                <td className="right">
                                    {parseInt(order.payable_amount) + parseInt(order.discount)} {settingsData?.site_global_currency}
                                </td>
                            </tr> */}
                            <tr>
                                <td className="left">
                                <strong>Discount</strong>
                                </td>
                                <td className="right">{subscriptionData?.discount >= 1 ? subscriptionData?.discount : "--"}</td>
                            </tr>
                            {/* <tr>
                                <td className="left">
                                <strong>TAX</strong>
                                </td>
                                <td className="right">--</td>
                            </tr> */}
                            <tr>
                                <td className="left">
                                <strong>Total</strong>
                                </td>
                                <td className="right">
                                <strong>{subscriptionData?.total_price} {settingsData?.site_global_currency}</strong>
                                <br />
                                </td>
                            </tr>
                            {/* <tr>
                                <td className="left">
                                <strong>Total Refund Price</strong>
                                </td>
                                <td className="right">
                                    <strong>
                                        {
                                        parseInt(totalRefudProductsPrice) >= 1
                                        ?
                                        `-${parseInt(totalRefudProductsPrice).toFixed(2)} ${settingsData?.site_global_currency}`
                                        :
                                        totalRefudProductsPrice
                                        }
                                    </strong>
                                <br />
                                </td>
                            </tr> */}
                            </tbody>
                        </table>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            :
            <h3>{t("No data avilable")}</h3>
        )
      }
    </>
  );
};

export default SubscriptionDetails;
