import Swal from "sweetalert2";
import logoutFunction from "../../global-functions/logoutFunction";

export default function UnAuthAlert () {
    Swal.fire({
        title: "Un Authinticated!",
        text: "Will Navigate To Login Page After 2 Seconds",
        icon: 'error',
        confirmButtonText: 'OK'
    });
    setTimeout(() => logoutFunction() , 2000)
}