import { Button, Spinner } from "react-bootstrap";
import emptyImg from "../../../media/images/planet.png"
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import ContextApi from "../../../store/ContextApi";
import { readAllNotficationsFunc } from "../../../store/custom-actions/notfications-slicer/ReadAllNotficationsSlicer";
import { getNotficationsFunc } from "../../../store/custom-actions/notfications-slicer/GetNotficationSlicer";
import { useTranslation } from "react-i18next";
export default function NotficationsPage() {
    const {t} = useTranslation();
    const {loading:getNotficationsLoading , data} = useSelector(state => state.getNotficationSlicer);
    const {loading:readAllNotsLoading} = useSelector(state => state.readAllNotficationsSlicer);
    const contextApi = useContext(ContextApi)
    const notficationsApi = `${contextApi.dashboard_URL_API}/notifications`;
    const [notficationsList , setNotficationsList] = useState([]);
    const [unReadNotficationsCount , setUnReadNotficationsCount] = useState(0);
    const [unReadNotfications , setUnReadNotfications] = useState([]);
    const [readNotfications , setReadNotfications] = useState([]);
    const dispatch = useDispatch();
    const getNotfications = async () => {
        dispatch(getNotficationsFunc(notficationsApi))
    }
    const [animate , setAnimate] = useState();
    useEffect(() => {
        !getNotficationsLoading ? setAnimate(true) : setAnimate(false)
    },[getNotficationsLoading])
    useEffect(() => {
        data && data.read_notifications && setNotficationsList(data.read_notifications)
        data && data.unread_notifications_count && setUnReadNotficationsCount(data.unread_notifications_count)
        data && 
        data.unread_notifications && 
        setUnReadNotfications(data.unread_notifications)
        data && 
        data.read_notifications && 
        setReadNotfications(data.read_notifications)
    },[data])
    const readAllNotfications = async () => {
      dispatch(readAllNotficationsFunc(notficationsApi))
      .then(result => {
        if(result.payload.success === true) {
          getNotfications();
          setUnReadNotficationsCount(0)
        }
      })
    }
    return (
        <>
            {
                getNotficationsLoading
                ?
                <>
                <div className="row d-flex justify-content-center gap-2">
                    <Spinner animation="grow" className="bg-primary" />
                    <Spinner animation="grow" className="bg-primary" />
                    <Spinner animation="grow" className="bg-primary" />
                    <Spinner animation="grow" className="bg-primary" />
                </div>
                </>
                :
                (   
                    unReadNotfications.length >= 1
                    ?
                    <div className='notfications-content overflow-x-hidden relative w-full max-h-[70vh] overflow-y-auto'>
                        {
                            unReadNotfications &&
                            unReadNotfications.map((note , index) => {
                                const noteDate = new Date(note.created_at);
                                const sendingDay = noteDate.getDate();
                                const sendingMonth = noteDate.getMonth()+1;
                                const sendingYear = noteDate.getFullYear();
                                
                                const readingDate = new Date(note.read_at);
                                const readingDay = readingDate.getDate();
                                const readingMonth = readingDate.getMonth()+1;
                                const readingYear = readingDate.getFullYear();

                                const {data:noteData} = note;
                                const {title , subject , sender} = noteData;
                                return (
                                    <div style={{animationDelay : `.${index}s`}} key={index} className={`notfication-box transition-all duration-500 relative w-full px-2 py-3 mb-1 flex justify-between items-center text-black bg-slate-200 rounded-md ${animate ? "active" : ""}`}>
                                      <div className='relative flex flex-col'>
                                        <h4 className={"m-0 text-primary"}>
                                          <i className="fa-brands fa-cuttlefish mr-1"></i>
                                          {title}
                                        </h4>
                                        <h5 className='m-0 text-[#555758] flex flex-col'>
                                          {subject}
                                          <div className='relative flex flex-col mt-1'>
                                            <div className='note-date relative flex items-center text-sm font-normal'>
                                              <i className="fa-solid fa-user mr-1 opacity-90"></i>
                                              <p className='m-0'>{t("Sender")}  <strong className='text-primary'>{sender}</strong></p>
                                            </div>
                                            <div className='note-date relative flex items-center text-sm font-normal'>
                                              <i className="fa-solid fa-calendar-days mr-1 opacity-90"></i>
                                              <p className='m-0 text-[10px]'>{t("Sent on")} {`${sendingYear} / ${sendingMonth} / ${sendingDay}`}</p>
                                            </div>
                                            <div className='read-note-date relative flex items-center text-sm font-normal'>
                                              <i className="fa-solid fa-eye mr-1 opacity-90"></i>
                                              <p className='m-0 text-[10px]'>{t("Read in")} {`${readingYear} / ${readingMonth} / ${readingDay}`}</p>
                                            </div>
                                          </div>
                                        </h5>
                                      </div>
                                      <i className="fa-solid fa-comments text-primary text-[18px]"></i>
                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    <div className='relative flex flex-col justify-center items-center'>
                        <img src={emptyImg} alt="empty img" className='w-[100px]' />
                        <p className='mt-3'>{t("No data available")}</p>
                    </div>
                )
            }
            <div className="relative flex mt-4">
                <Button className="border-0 text-white"
                onClick={() => window.history.back()}
                >
                    {t("Back")}
                </Button>
                {
                    unReadNotfications?.length>= 1
                    &&
                    <Button className="bg-primary border-0 text-white mx-2"
                    onClick={() => {
                        readAllNotfications()
                    }}
                    >
                        {
                            readAllNotsLoading
                            ?
                            <>{t("loading")}...</>
                            :
                            t("Read All")
                        }
                    </Button>
                }
            </div>
        </>
    )
}