import { Modal } from "react-bootstrap";
import ReactPlayer from "react-player";
import Plyr from "plyr-react"
import "plyr-react/plyr.css"
export default function ShowLessonVideo({show , onHide , lessonTitle , url , lessonType}) {
//   const [videoOptions , setVideoOptions] = useState({
//     type: "video",
//     sources: [
//       {
//         src: url,
//         type: 'video/mp4',
//         size: 720,
//       },
//     ],
// })
// useEffect(() => {
//   if(url) {
//     setVideoOptions({
//       type: "video",
//       sources: [
//         {
//           src: url,
//           type: 'video/mp4',
//           size: 720,
//         },
//       ],
//   })
//   }
// },[url])
  const videoOptions = {
    type: "video",
    sources: [
      {
        src: url,
        type: 'video/mp4',
        size: 720,
      },
    ],
};
    return (
      <>
        <Modal
          size="lg"
          show={show}
          onHide={onHide}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              {lessonTitle}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="video-content relative flex justify-center items-center  w-full h-[400px]"> */}
              {/* <ReactPlayer
                  url={url}
                  // url={`https://www.youtube.com/watch?v=gmc4b433y3k`}
                  controls={true}
                  width="800px"
                  height="400px"
              /> */}
              {
                lessonType === "youtube"
                ?
                <iframe width="100%" height="415" className="rounded-md" src={url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                :
                <Plyr
                  source={videoOptions}
                  controls
                  autoPlay
                  // className="rounded-md"
                  style={{borderRadius: "10px"}}
                />
              }
            {/* </div> */}
          </Modal.Body>
        </Modal>
      </>
    );
  }

// const PlyrComponent = ({ url }) => {

//     const videoOptions = {
//         type: "video",
//         sources: [
//           {
//             src: url,
//             type: 'video/mp4',
//             size: 720,
//           },
//         ],
//     };
//   return (
//     <div className="player-wrapper rounded-md overflow-hidden">
//       <Plyr
//         source={videoOptions}
//         controls
//       />
//     </div>
//   );
// };