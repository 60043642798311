import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';

function ShowImageModal(props) {
    const {t} = useTranslation();
    const {image , modalTitle} = props;
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {modalTitle ? modalTitle : "Show Image"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='image-modal relative w-full flex justify-center items-center'>
            <img src={image} alt="img modal" className='relative' />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{t("Close")}</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ShowImageModal