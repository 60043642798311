import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const showLessonVideoFunc = createAsyncThunk(
    "lessons/show/video",
    async (api) => {
        try {
            const response = await axios.get(api );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            // Swal.fire({
            //     title: "Oops!",
            //     text: error,
            //     icon: "error"
            // });
            // checkErrors(error.response , error.message);
            return error.response
        }
        
    }
);

const showLessonVideoSlicer = createSlice({
    name : "lessons/show/video",
    initialState : {
        loading : null,
        data : [],
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(showLessonVideoFunc.pending , (state)=>{
            state.loading = true
            state.data = []
            state.error = null
        })
        builder.addCase(showLessonVideoFunc.rejected , (state , action)=>{
            state.loading = false
            state.data = action.payload
            state.error = true
        })
        builder.addCase(showLessonVideoFunc.fulfilled , (state , action)=>{
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
});
export default showLessonVideoSlicer.reducer;