import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import checkErrorsFunctions from "../../global-functions/checkErrorsFunction";
import axios from "axios";
import { useTranslation } from "react-i18next";

export default function EditLessonMatrialForm(props) {
    const {t} = useTranslation();
    const {lesson_title , refetch_course_lessons , upload_files_url , lesson_type} = props;
    const [lessonMatrial  ,setLessonMatrial] = useState();
    const [uploadMatrialPercent , setUploadMatrialPercent] = useState();
    const [matrialPercent , setMatrialPercent] = useState(0);
    const [loadingMatrial , setLoadingMatrial] = useState();
    const [accept , setAccept] = useState("");
    const handleUploadMatrial = async () => {
        if(lessonMatrial ){
            const matrialFormData = new FormData();
            matrialFormData.append("file" , lessonMatrial)
        axios.post(upload_files_url , matrialFormData , {onUploadProgress : (data) => {
            setUploadMatrialPercent(Math.floor((data.loaded / data.total) * 100))
            setLoadingMatrial(true)
            }})
            .then((response) => {
                console.log(response)
                checkErrorsFunctions(response , response.data.errors);
                if(response.data.success === true) {
                    setLoadingMatrial(false);
                    refetch_course_lessons();
                    setLoadingMatrial(false);
                    props.onHide();
                }else {
                    setLoadingMatrial(false);
                }
            })
        }
    }

    useEffect(() => {
        setMatrialPercent(uploadMatrialPercent);
    },[uploadMatrialPercent , matrialPercent]);

    const checkAcceptLessonFiles = () => {
        if(lesson_type !== "") {
            switch(lesson_type) {
                case "pdf":
                    setAccept("application/pdf");
                    break;
                case "file":
                    setAccept(".zip,.rar");
                    break;
                case "rich_text":
                    setAccept(".doc,.docx");
                    break;
                default:
                    setAccept("application/pdf");
            }
        } 
    }
    useEffect(() => {
        checkAcceptLessonFiles();
    },[lesson_type])
    return (
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            {t("Matrial")}
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form className="matrial-form"
                id="update-matrial-form"
                onSubmit={(e)=>{
                    e.preventDefault();
                    handleUploadMatrial();
                }}
            >
                <>
                    <div className="form-group mb-3 col-sm-12">
                    <label>{t("Lesson File")} ({accept})</label>
                    <input
                        type="file"
                        required
                        className="form-control"
                        accept={accept}
                        onChange={(e)=>{
                            setLessonMatrial(e.target.files[0])
                        }}
                    />
                    </div>
                    <div className={`relative ${matrialPercent > 0 ? "flex" : "hidden"} w-full h-[30px] rounded-md overflow-hidden bg-orange-300`}>
                        <span
                    role="progressbar"
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{width : `${matrialPercent}%`}}
                        className={`absolute top-0 text-white transition-all duration-700 left-0 h-full bg-primary flex justify-center items-center ${!loadingMatrial ? "upload-done" : ""}`}>
                        {matrialPercent}%
                        </span>
                    </div>
                </>
            </form>
        </Modal.Body>
        <Modal.Footer>
        <button onClick={props.onHide} className="">{t("Close")}</button>
        <Button form="update-matrial-form" type="submit" className={`primary ${loadingMatrial ? "pointer-events-none opacity-80" : ""}`}>
            {loadingMatrial
                ?
                <>
                <div className="relative flex justify-center items-center">
                  {t("loading")}..
                  <div className="loading-spin-data w-[13px] h-[13px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-2"></div> 
                </div>
                </>
                :
                t("Submit" )
              }
        </Button>
        </Modal.Footer>
    </Modal>
    );
}