import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editUserImageFunc } from "../../../store/custom-actions/users-actions/UpdateUserImageSlicer";
import { fetchUsersFunc } from "../../../store/custom-actions/users-actions/GetUsersSlicer";
import { Button, Modal } from "react-bootstrap";
import ContextApi from "../../../store/ContextApi";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function UpdateUserImageForm(props) {
    const {t} = useTranslation();
    const {editImageLoading} = useSelector(state => state.editUserImageSlicer);
    const dispatch = useDispatch();
    const {
      usersapi , 
      userid
    
    } = props;
  
    const [updatingUserImage , setUpdatingUserImage] = useState();
    const contextApi = useContext(ContextApi)
    const users_API = `${contextApi.dashboard_URL_API}/users/upload-image/${userid}`;
    const handleUploadUserImage = () => {
      if(updatingUserImage) {
        const imageForm = new FormData();
        imageForm.append("image" , updatingUserImage)
  
        dispatch(editUserImageFunc(
          {user_api : users_API , image : imageForm}
        ))
        .then((result) => {
          if(result.payload.success === true){
            dispatch(fetchUsersFunc(usersapi));
            props.onHide();
          }
        })
      }else {
        toast.error(t("Image Should not be empty!"))
      }
    }
  
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("Update Image")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" w-full">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <form id="upload-user-image-form" className="upload-user-image-form"
                  onSubmit={(e)=>{
                    e.preventDefault();
                    handleUploadUserImage()
                  }}
                >
                  <div className="row">
                    <div className="form-group mb-3 col-sm-12">
                      <label>{t("User Image")} ({t("Best Size")} 200 * 200)</label>
                      <input 
                        type="file" 
                        className="form-control"
                        onChange={(e)=>setUpdatingUserImage(e.target.files[0])}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>{
          props.onHide();
        }}>{t("Close")}</Button>
        <Button type="submit" form="upload-user-image-form" className={`${editImageLoading ? "pointer-events-none opacity-80" : ""}`}>
          {!editImageLoading 
          ? 
          t("Submit")
          : 
            <div className="relative flex justify-center items-center">
              {t("loading")}...
              <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-1 "></div>
            </div>
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
  }