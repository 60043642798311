import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
import Swal from "sweetalert2";
export const unApproveQuizeFunc = createAsyncThunk(
    "un approve quize function",
    async ({quizeApi}) => {
        try {
            const response = await axios.put(quizeApi , {publish : "unpublish"} );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
        
    }
)

const unApproveQuizeSlicer = createSlice({
    name : "un approve quize slicer",
    initialState : {
        un_approve_quize_loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(unApproveQuizeFunc.pending , (state) => {
            state.un_approve_quize_loading = true
            state.data = {}
            state.error = false
        });
        builder.addCase(unApproveQuizeFunc.rejected , (state) => {
            state.un_approve_quize_loading = false
            state.data = {}
            state.error = true
        });
        builder.addCase(unApproveQuizeFunc.fulfilled , (state , action) => {
            state.un_approve_quize_loading = false
            state.data = action.payload
            state.error = false
        });
    }
});

export default unApproveQuizeSlicer.reducer;