import { Modal } from "react-bootstrap";
import sendImg from "../../../media/images/send.png";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ApproveItemNofication({
  show_approve_notfication,
  hide_approve_notfication,
  item,
  approve_function,
  approve_loading
}) {
  const {t} = useTranslation();
  return (
    <>
      <Modal
        size="md"
        show={show_approve_notfication}
        onHide={() => hide_approve_notfication()}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-lg">
            {t("Publish")} {item.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="relative w-full h-[400px] flex flex-col justify-center items-center">
            <div className="relative flex flex-col justify-center items-center">
              <img
                src={sendImg}
                alt="sendig img"
                className="relative my-2 w-[70px] publish-icon"
              />
              <p className="relative text-[17px]">
                {t("Publish")} <strong>{item.name}</strong> {t("will appear on platform")}
              </p>
              <Button
                className={`btn btn-primary ${approve_loading ? "opacity-80 pointer-events-none" : ""}`}
                onClick={() => approve_function()}
              >
                {approve_loading ? (
                  <>
                    <div className="relative flex justify-center items-center">
                      {t("loading")}...
                      <div className="loading-spin-data w-[13px] h-[13px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-2"></div>
                    </div>
                  </>
                ) : (
                  t("Publish")
                )}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ApproveItemNofication;
