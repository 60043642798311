import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import correctOption from "../../../media/images/checklist.png";
import flaseOption from "../../../media/images/cross.png";
import ShowImageModal from "../show-image-modal/ShowImageModal";
function QuestionBox({question , questionType , questionImg , title , answers , correct_answer , additionFunc , undoAddition , arrayWillSend}){
    const [droped , setDroped] = useState(true);
    const [showImageModal , setShowImageModal] = useState();
    const [checked , setChecked] = useState();
    const handleCheck = (e)=>{
        setChecked(!checked)
        if(e.target.checked){
            additionFunc()
        }
        else{
            undoAddition()
        }
    }
    useEffect(() => {
        const find = arrayWillSend && arrayWillSend.length >= 1 && arrayWillSend.find(Q => Q.id === question.id);
        find ? setChecked(true) : setChecked(false)
    },[arrayWillSend , question])

    return (
            questionType !== 3
            ?
            (
                <Accordion defaultActiveKey="0" onSelect={()=>setDroped(!droped)}>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header className="p-0">
                            <div className={`relative flex justify-between  items-center w-full`}>
                                <div className="title flex relative items-center">
                                    {
                                        additionFunc
                                        &&
                                        <input 
                                            checked = {checked}
                                            type="checkbox" 
                                            className="mr-2"
                                            onChange={(e)=>{
                                                handleCheck(e)
                                            }}
                                        />
                                    }
                                    <h5 className="m-0">{title}</h5>
                                </div>
                                <i className={`fa-solid fa-circle-chevron-left text-[20px] ${droped ? "rotate-[90deg] opacity-50" : "rotate-[-90deg] opacity-100"} transition-all duration-500 text-primary`}></i>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className="">
                            {
                                answers
                                &&
                                answers.length >= 1
                                ?
                                answers.map((answer , index)=>{
                                    return (
                                        <div key={index} className="relative flex items-center rounded-md p-2 bg-primary mb-2">
                                            {
                                            index === correct_answer
                                            ?
                                            <img src={correctOption} alt="correct option" className="w-[17px] h-[17px] mr-2" />
                                            :
                                            <img src={flaseOption} alt="correct option" className="w-[17px] h-[17px] mr-2" />
                                            }
                                            <span className={`${index === correct_answer ? "text-green-500" : "text-white"} text-[17px] flex`}>
                                            {answer}
                                            </span>
                                        </div>
                                    )
                                })
                                :
                                <p className="my-2 text-center">No Answers For This Question!</p>
                            }
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            )
            :
            (
                <div className={`relative w-full flex p-1 items-center mb-[20px]`}>
                    {
                        additionFunc
                        &&
                        <input 
                            checked = {checked}
                            type="checkbox" 
                            className="mr-2 scale-125 cursor-pointer" 
                            onChange={(e)=>{
                                handleCheck(e)
                            }}
                        />
                    }
                    <div className="relative flex flex-col w-[90%] ml-2">
                        <h4 className="relative flex items-center cursor-pointer"
                            onClick={()=>setShowImageModal(true)}
                        >
                            <i className="fa-solid fa-circle-dot mr-1 text-sm text-primary"></i>
                            {title}</h4>
                        <div className="question-div relative rounded-md overflow-hidden w-full h-[220px] cursor-pointer"
                            onClick={()=>setShowImageModal(true)}
                        >
                            <img src={questionImg} alt="question img" className="absolute top-0 left-0 w-full h-full transition-all duration-500 hover:scale-110" />
                        </div>
                    </div>
                    <ShowImageModal
                        show = {showImageModal}
                        onHide = {() => setShowImageModal(false)}
                        image =  {questionImg}
                    />
                </div>
            )
    )
}

export default QuestionBox;