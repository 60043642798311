import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const getQuestionsFunc = createAsyncThunk(
    "get questions function",
    async (api) => {
        try {
            const response = await axios.get(api );
            checkErrorsFunctions(response , response.data.errors);
            return response.data.data.questions;
        }
        catch (error) {
            checkErrors(error.response , error.message);
            return error
        }
        
    }
);

const getQuestionsSlicer = createSlice({
    name : "get questions slicer",
    initialState : {
        getQuestionsLoading : null,
        data : [],
        error : false
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(getQuestionsFunc.pending , (state)=>{
            state.getQuestionsLoading = true
            state.data = []
            state.error = null
        })
        builder.addCase(getQuestionsFunc.rejected , (state)=>{
            state.getQuestionsLoading = false
            state.data = []
            state.error = true
        })
        builder.addCase(getQuestionsFunc.fulfilled , (state , action)=>{
            state.getQuestionsLoading = false
            state.data = action.payload
            state.error = null
        })
    }
});
export default getQuestionsSlicer.reducer;