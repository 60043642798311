import { Modal , Button } from "react-bootstrap";
import { useState , useEffect, useContext } from "react";
import { useSelector , useDispatch } from "react-redux";
import ContextApi from "../../../store/ContextApi";
import correctIcon from "../../../media/images/checklist.png";
import videoIcon from "../../../media/images/video-camera.png";
import quizIcon from "../../../media/images/quiz.png";
import fileIcon from "../../../media/images/google-docs.png";
import pdfIcon from "../../../media/images/file.png";
import rich_textIcon from "../../../media/images/files.png";
import { updateLessonRequirmentFunc } from "../../../store/custom-actions/lessons-actions/lessons-requirments-actions/UpdateLessonRequirmentSlicer";
import { useTranslation } from "react-i18next";
import { fetchCourseLessonsFunc } from "../../../store/custom-actions/lessons-actions/GetCourseLessons";
import { getChaptersFunc } from "../../../store/custom-actions/chapters/GetChaptersSlicer";
import { TreeSelect } from "antd";

const convertAllChildrenKey = (data) => {
    if (!Array.isArray(data)) return [];
    return data.map((item) => {
      const { name, id, all_children, ...rest } = item;
      return {
        ...rest,
        title: name,
        value: id,
        children: convertAllChildrenKey(all_children),
      };
    });
  };
  
  const TreeSelectExam = ({treeData , setNewIdFunc , oldValue}) => {
    const [value, setValue] = useState();
    const onChange = (newValue) => {
      setNewIdFunc(newValue)
      setValue(newValue);
    };
    useEffect(() => {
        oldValue && setValue(oldValue);
    },[oldValue])
    const modifiedTreeData = convertAllChildrenKey(treeData);
  
    return (
      <TreeSelect
        style={{
          width: '100%',
          height: '40px',
        }}
        value={value}
        dropdownStyle={{
          maxHeight: 400,
          overflow: 'auto',
        }}
        treeData={modifiedTreeData}
        placeholder="Please select"
        treeDefaultExpandAll
        onChange={onChange}
        treeDataSimpleMode={{
          id: 'value',
          pId: 'parentId',
          rootPId: null,
        }}
      />
    );
  };

function UpdateRequirmentForm(props) {
    const {t} = useTranslation();
    const {
        lesson_id  , 
        lessons , 
        old_require_lesson,
        old_require_lesson_id , 
        old_require_lesson_name ,
        old_min_grade_pass,
        lesson_requirement_id,
        lessons_loading,
        reftchData,
    } = props;
    const {loading:updateRequirmentLoading} = useSelector(
        state => state.updateLessonRequirmentSlicer
    );
    const dispatch = useDispatch();
    const contextApi = useContext(ContextApi);
    const updateRequirmentApi = `${contextApi.dashboard_URL_API}/lesson-requirements/${lesson_requirement_id}`;
    const [chooseLesson , setChooseLesson] = useState();
    const [lessonWhichChoosed , setLessonWhichChoosed] = useState();
    const [lessonWhichChoosedType , setLessonWhichChoosedType] = useState("");
    const [lessonsList , setLessonsList] = useState([]);
    const [lessonId , setLessonId] = useState(old_require_lesson_id);
    const [minPassingGrade , setMinPassingGrade] = useState();
    const [chapterId , setChapterId] = useState(null);
    const [chaptersList , setChaptersList] = useState([]);
    const { loading: getChaptersLoading, data:cahptersData } = useSelector(
        (state) => state.getChaptersSlicer
    );
    const { loadingCourseLessons: getLessonsLoading} = useSelector(
        (state) => state.getCourseLessonsSlicer
    );
    useEffect(() => {
        if(old_require_lesson) {
            setChapterId(old_require_lesson?.chapter_id)
        }
    },[old_require_lesson])
    const handleUpdateRequirment = () => {
        dispatch(updateLessonRequirmentFunc({api : updateRequirmentApi , data : 
            {...(minPassingGrade && { min_passing_grade : minPassingGrade}), required_lesson_id : lessonId}}))
        .then(result => {
            if(result.payload.success === true) {
                props.onHide();
                // setTimeout(() => window.location.reload() , 3000);
                reftchData()
            }
        })
    }
    useEffect(() => {
        if(lessons?.length >= 1) {
            if(!cahptersData) {
                const getChapters = async () => {
                    dispatch(getChaptersFunc(`${contextApi.dashboard_URL_API}/courses/${lessons[0]?.course_id}/chapters`));
                };
                getChapters();
            }else {
                setChaptersList(cahptersData);
            }
        }
    },[lessons, cahptersData])
    useEffect(() => {
        if(chapterId) {
            const lessonsApi = `${contextApi.dashboard_URL_API}/chapters/${chapterId}/lessons`;
            const getLessons = () => {
                dispatch(fetchCourseLessonsFunc(lessonsApi))
                .then((result) => {
                    const filtredLessons = result?.payload?.lessons?.filter(ls => ls.id !== lesson_id);
                    setLessonsList(filtredLessons)
                    // setLessonsList(result?.payload?.lessons);
                })
            }
            getLessons()
        }
    },[chapterId , lesson_id])
    const handleSetChapterId = (id) => {
        setChapterId(id)
    }
    const checkLessonType = (lessonType) => {
        if(lessonType) {
            switch(lessonType) {
                case "video" :
                return <img src={videoIcon} alt="icon" className="relative mr-1 w-[20px]" />
                case "quiz" :
                return <img src={quizIcon} alt="icon" className="relative mr-1 w-[20px]" />
                case "file" :
                return <img src={fileIcon} alt="icon" className="relative mr-1 w-[20px]" />
                case "pdf" :
                return <img src={pdfIcon} alt="icon" className="relative mr-1 w-[20px]" />
                case "rich_text" :
                return <img src={rich_textIcon} alt="icon" className="relative mr-1 w-[20px]" />
                default :
                return <img src={videoIcon} alt="icon" className="relative mr-1 w-[20px]" />
            }
        }
    }
    useEffect(() => {
        const filtredLessons = lessons && lessons.filter(ls => ls.id !== lesson_id);
        setLessonsList(filtredLessons)
    },[lesson_id , lessons ])

    useEffect(() => {
        console.log(old_require_lesson);
        old_require_lesson_name && setLessonWhichChoosed(old_require_lesson_name);
        old_min_grade_pass && setMinPassingGrade(old_min_grade_pass);
        old_require_lesson_id && setLessonId(old_require_lesson_id);
    },[old_min_grade_pass , old_require_lesson_name , old_require_lesson_id])
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="relative flex items-center">
            <img src={correctIcon} alt="corect icon" className="relative mr-2 w-[16px]" />
            <h4 className="m-0">{t("Update Requirement")}</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
            <div className="basic-form">
                <div className="form-group mb-3 col-sm-12">
                  {
                      getChaptersLoading ? (
                          <div className="form-group flex items-center">
                              <p>{t("loading")}...</p>
                          </div>
                      ) : cahptersData &&
                          chaptersList &&
                          chaptersList.length <= 0 ? (
                          <div className="form-group flex items-center">
                              <p>{t("No data available")}</p>
                          </div>
                      ) : (
                          <div
                              className={`form-group mb-3 col-sm-12`}
                          >
                          <label>{t("Choose Chapter")}</label>
                            <TreeSelectExam 
                            treeData={chaptersList} 
                            setNewIdFunc = {handleSetChapterId}
                            oldValue={chapterId}
                            />
                          </div>
                      )
                  }
                </div>
                {
                    getLessonsLoading
                    ?
                    <div className="form-group flex items-center">
                        <p>{t("loading")}...</p>
                    </div>
                    :
                    (
                        lessonsList?.length >= 1
                        ?
                        <form 
                        className="passing-lesson-form" 
                        id="passing-lesson-form"
                        onSubmit={(e)=>{
                            e.preventDefault()
                            handleUpdateRequirment();
                        }}
                        >
                            {
                                (lessonWhichChoosedType === "quiz" || old_min_grade_pass)
                                &&
                                <div className="row">
                                    <div className="form-group mb-3 col-sm-12">
                                        <label className="relative flex items-center">
                                            {t("Update Min Passing Score")}
                                        </label>
                                        <input
                                            required
                                            type="number"
                                            min={1}
                                            value={minPassingGrade}
                                            className="form-control"
                                            onChange={(e) => setMinPassingGrade(e.target.value)}
                                    />
                                    </div>
                                </div>
                            }
                            <div className="row">
                                <div className="form-group mb-3 col-sm-12">
                                    <label>{t("Update Lesson")}</label>
                                    <span 
                                        className="relative flex justify-between items-center px-2 w-full h-[45px] rounded-md border cursor-pointer bg-white"
                                        onClick={()=>setChooseLesson(!chooseLesson)}
                                    >
                                        <div className="relative flex items-center">
                                            {lessonWhichChoosed}
                                        </div>
                                        <i className={`fa-solid fa-circle-chevron-left transition-all duration-500 text-[20px] text-[#075985] ${chooseLesson ? "rotate-[-90deg] opacity-100" : "rotate-[90deg] opacity-70"} `}></i>
                                    </span>
                                </div>
                                <div className={`instructors-list overflow-y-auto transition-all duration-500 w-[98%] cursor-pointer ${chooseLesson ? "h-[150px]" : "h-0"}`}>
                                { 
                                lessons_loading
                                ?
                                <h4>{t("loading")}...</h4>
                                :
                                (
                                lessonsList &&
                                    lessonsList.map((lesson , index) =>{
                                    return (
                                        <div key={index} className="input-div relative flex items-center my-1 p-2 pl-0">
                                        <input
                                        form="new-course-form"
                                        id={lesson.id} 
                                        type="radio"
                                        name="lesson-id" 
                                        value={lesson.id}
                                        className="opacity-0"
                                        data-name={lesson.name}
                                        onChange={(e)=>{
                                            setLessonWhichChoosedType(lesson.type)
                                            setLessonWhichChoosed(e.target.dataset.name);
                                            setChooseLesson(false)
                                            console.log(lesson);
                                            setLessonId(e.target.value)
                                        }}
                                        />
                                        <label htmlFor={lesson.id} className="cursor-pointer mr-2 flex items-center">
                                            {checkLessonType(lesson.type)}
                                            {lesson.name}
                                        </label>
                                        </div>
                                    )
                                    })
                                )
                                }
                                
                                </div>
                            </div>
                        </form>
                        :
                        <div className="form-group flex items-center">
                            <p>{t("No data available")}</p>
                        </div>
                    )
                }
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>{
            props.onHide();
          }}>{t("Close")}</Button>
        <Button type="submit" form="passing-lesson-form" className={`btn btn-primary flex items-center justify-center ${updateRequirmentLoading ? "pointer-events-none" : ""}`}>
            {!updateRequirmentLoading ? (
            t("Submit")
            ) : (
            <div className="relative flex justify-center items-center">
                {t("loading")}...
                <div className="loading-spin-data w-[15px] h-[15px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-1"></div>
            </div>
            )}
        </Button>
        </Modal.Footer>
      </Modal>
    );
  }

export default UpdateRequirmentForm;