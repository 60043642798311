import { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import ContextApi from '../../../store/ContextApi';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { updateMailTemplateFunc } from '../../../store/custom-actions/mail/UpdateMailTemplateSlicer';

export default function UpdateTemplateModale({show , onHide , template , refetchData}) {
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const updateApi = `${contextApi.dashboard_URL_API}/settings/general-settings/update`;
    const {loading:updateTemplateLoading} = useSelector(
        state => state.updateMailTemplateSlicer
    )
    const {register , handleSubmit , control , setValue} = useForm();
    const dispatch = useDispatch();
    const handleUpdate = (data) => {
        console.log(data)
        if(data) {
            dispatch(updateMailTemplateFunc({api : updateApi , data}))
            .then((result) => {
                refetchData && refetchData();
                onHide();
            })
        }
    }
    const colors = [
        '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
        '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
    ];
    const modules = {
        toolbar: {
          container: [
            [{ 'font': [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': colors }, { 'background': colors }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'align': [] }],
            ['blockquote', 'code-block'],
            ['link', 'image', 'video'],
            ['clean']
          ],
        }
      };
      useEffect(() => {
        if(template) {
            console.log(template)
            Object.entries(template?.values).forEach(([key , value]) => {
                setValue(key , value)
            })
        }
      } , [template])
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show = {show}
      fullscreen = {true}
      onHide = {onHide}
      className='z-[999999999999]'
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            {t(template?.name?.replace(/_/ig , " "))}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <div className='row'> */}
            <form
            id={"update-form"}
            onSubmit={handleSubmit((data) => handleUpdate(data))}
            >
                {
                    template?.values
                    &&
                    Object.entries(template?.values).map(([key , value]) => {
                        console.log(key)
                        if(key !== "prompts" && key?.includes("subject")) {
                            return (
                            <div className='row'>
                                <div className='form-group mb-2 col-sm-12'>
                                    <label>{t("Subject")}</label>
                                    <input type='text' {...register(key)} placeholder={`${t('Mail Subject')}...`} className='form-control col-sm-12' defaultValue={value ? value : ""}/>
                                </div>
                            </div>
                            )
                        }
                    })
                }
                {
                    template?.values
                    &&
                    Object.entries(template?.values).map(([key , value]) => {
                        console.log(key)
                        if(key !== "prompts" && key?.includes("message")) {
                            return (
                                <div className="row m-0 p-0">
                                    <Controller
                                        name={key}
                                        control={control}
                                        defaultValue={value ? value : ""}
                                        render={({ field }) => (
                                            <ReactQuill
                                            {...field}
                                            modules={modules}
                                            className="p-0 border-none outline-none form-control bg-white mt-3"
                                            placeholder="Message..."
                                            />
                                        )}
                                    />
                                </div>
                            )
                        }
                    })
                }
                <div className='row flex flex-col my-2'>
                    <p className='mx-0 mb-2'><span className='text-primary'>@name </span> will be replaced by dynamically with name.</p>
                    <p className='mx-0 mb-2'><span className='text-primary'>@email </span> will be replaced by dynamically with email.</p>
                </div>
            </form>
        {/* </div> */}
      </Modal.Body>
      <Modal.Footer>
        <Button type='submit' form={"update-form"} className={`${updateTemplateLoading ? "pointer-events-none opacity-75" : ""}`}>{updateTemplateLoading ? `${t("loading")}...` : t("Submit")}</Button>
        <Button onClick={onHide} className={`bg-danger border-danger ${updateTemplateLoading ? "pointer-events-none opacity-75" : ""}`}>{t("Close")}</Button>
      </Modal.Footer>
    </Modal>
  );
}