import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
import Swal from "sweetalert2";
export const unApproveStudentFunc = createAsyncThunk(
    "un approve student function",
    async ({studentApi}) => {
        try {
            const response = await axios.put(studentApi , {publish : "unpublish"} );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
        
    }
)

const unApproveStudentSlicer = createSlice({
    name : "un approve student slicer",
    initialState : {
        unapprove_student_loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(unApproveStudentFunc.pending , (state) => {
            state.unapprove_student_loading = true
            state.data = {}
            state.error = false
        });
        builder.addCase(unApproveStudentFunc.rejected , (state) => {
            state.unapprove_student_loading = false
            state.data = {}
            state.error = true
        });
        builder.addCase(unApproveStudentFunc.fulfilled , (state , action) => {
            state.unapprove_student_loading = false
            state.data = action.payload
            state.error = false
        });
    }
});

export default unApproveStudentSlicer.reducer;