import { Button, Modal } from "react-bootstrap";
import { fetchUsersFunc } from "../../../store/custom-actions/users-actions/GetUsersSlicer";
import { editUserFunc } from "../../../store/custom-actions/users-actions/EditUserSlicer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Bounce, toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function EditUserForm(props) {
    const {t} = useTranslation();
    const {register , handleSubmit , setValue , formState : {errors} , watch , reset} = useForm();
    const {editUserTextLoading} = useSelector(state => state.editUserSlicer);
    const dispatch = useDispatch();
    const {
      usersapi , 
      oldname , 
      oldemail , 
      oldphone , 
      oldactive,
      oldBaio,
      userapi,
      userRole
    } = props;
    // const validEmail = /^[a-zA-Z0-9]+@[a-zA-Z]+\.[a-zA-Z]{2,4}$/;
    // const validPhone = /[0-9]{11}/;
    // const validName = /[a-zA-Z]{3,}/;
    useEffect(() => {
      oldname && console.log(oldname)
      setValue("name" , oldname);
      setValue("email" , oldemail);
      setValue("phone" , oldphone);
      setValue("baio" , oldBaio);
      setValue("publish" , oldactive === "publish" ? "publish" : "unpublish");
    },[setValue , oldname , oldemail , oldphone , oldactive , oldBaio]);
    const [newInstructorStatus , setNewInstructorStatus] = useState(oldactive)
  
    const handleUploadUserTextData = (data) => {
      if(data) {
        // if (!data.name.match(validName)) {
        //   toast.error(t("User name must be more than 3 letters!"));
        //   return;
        // }
        // if (!data.phone.match(validPhone)) {
        //   toast.error(t("Phone must be 11 numbers!"));
        //   return;
        // }
        // if (!data.email.match(validEmail)) {
        //   toast.error(t("Please enter a valid email!"));
        //   return;
        // }
        dispatch(editUserFunc(
          {user_api : userapi , new_data : 
          {...data , publish : newInstructorStatus}
          }
        ))
        .then((result) => {
          if(result.payload.success === true){
            dispatch(fetchUsersFunc(usersapi));
            props.onHide();
          }
        })
      }
    }
    useEffect(() => {
      if(errors) {
        Object.keys(errors).forEach(key => {
          toast.error(
            errors[key].message , {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          })
        })
      }
    },[errors])

    // useEffect(() => {
    //   newInstructorStatus === "publish"
    //   ?
    //   toast.success(t("User an active now"))
    //   :
    //   toast.warning(t("User un active now"))
    // },[newInstructorStatus])
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("Update Info")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" w-full">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <form 
                id="edit-user-text-data-form"
                onSubmit={handleSubmit((data)=>{
                    handleUploadUserTextData(data);
                })}>
                  <div className="row">
                    <div className="form-group mb-3 col-sm-12 col-md-6">
                      <label>{t("Name")}</label>
                      {errors.name?.message &&
                        <p className="text-red-600">{errors.name?.message}</p>
                        }
                      <input
                        {...register("name")}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3 col-sm-12 col-md-6">
                      <label>{t("Email")}</label>
                      {errors.email?.message &&
                        <p className="text-red-600">{errors.email?.message}</p>
                        }
                      <input
                        {...register("email" , {required : t("User Email Is Required!") })}
                        type="text"
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group mb-3 col-sm-12 col-md-6">
                      <label>{t("Phone Number")}</label>
                      {errors.phone?.message &&
                        <p className="text-red-600">{errors.phone?.message}</p>
                        }
                      <input 
                        {...register("phone" , {required : "Phone Must Be 11 Numbers!"})}
                        type="text" 
                        className="form-control"
                        max={11}
                      />
                    </div>
                    <div className="form-group col-sm-12 col-md-6 flex items-cneter">
                      <input 
                          id="check-instructor-status"
                          type="checkbox" 
                          onChange={(e) => {
                              if(e.target.checked) {
                                  setNewInstructorStatus("publish");
                              }else {
                                  setNewInstructorStatus("unpublish");
                              }
                          }}
                          checked = {newInstructorStatus === "publish" ? true : false}
                          // value={newInstructorStatus}
                          className=" scale-125 cursor-pointer"
                      />
                      <label htmlFor="check-instructor-status" className="flex items-center ml-2 translate-y-[2px] cursor-pointer">
                          {
                            newInstructorStatus === "publish"
                            ?
                            <i className="fa-solid fa-circle-check text-green-500 mr-1"></i>
                            :
                            <i className="fa-solid fa-circle-xmark text-red-500 mr-1"></i>
                          }
                          {t("Instructor Status")}
                      </label>
                      {/* <select
                          {...register("can_view_without_pay", {
                              required: "view_count is Required"
                          })}
                          className="form-control"
                      >
                          <option value={1}>Avilable</option>
                          <option value={0}>Not Avilable</option>
                      </select> */}
                    </div>
                  </div>
                  {
                    userRole === "teacher"
                    ?
                    <div className="row">
                        <div className="form-group mb-3 col-sm-12">
                            <label className="flex items-center">
                                <i class="fa-solid fa-barcode mr-1"></i>
                                {t("User Baio")}
                            </label>
                            <textarea
                            {...register("baio")}
                            className="form-control"
                            ></textarea>
                        </div>
                    </div>
                    :
                    ""
                  }
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>{
          props.onHide();
          reset()
        }}>{t("Close")}</Button>
        
        <Button type="submit" form="edit-user-text-data-form" className={`${editUserTextLoading ? "opacity-80 pointer-events-none" : ""}`}>
          {!editUserTextLoading 
          ? 
          t("Submit") 
          : 
          <div className="relative flex justify-center items-center">
            {t("loading")}...
            <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-1 "></div>
          </div>
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
  }