import { Modal, Table } from "react-bootstrap";
import defaultStImg from "../../../media/images/graduated.png"
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
export default function ShowCouponUsers (props) {
    const {t} = useTranslation();
    const {usages} = props;
    const [users , setUsers] = useState([]);
    useEffect(() => {
        const uniqeUsers = usages && usages.length >= 1 && usages.flatMap(usage => usage.student);
        setUsers(uniqeUsers)
    },[usages])
    return (
        <>
          <Modal
            size="lg"
            show={props.show}
            onHide={props.onHide}
            aria-labelledby="example-modal-sizes-title-lg"
          >
            <Modal.Header closeButton>
              <Modal.Title id="example-modal-sizes-title-lg">
                {t("Coupon Usages")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    usages
                    &&
                    users
                    &&
                    users.length > 0 
                    &&
                    <Table responsive>
                        <thead>
                          <tr>
                            <th className="width80">
                              <strong>#{t("ID")}</strong>
                            </th>
                            <th>
                              <strong>{t("Name")}</strong>
                            </th>
                            <th>
                              <strong>{t("Email")}</strong>
                            </th>
                            <th>
                              <strong>{t("Profile")}</strong>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                            {users && users.map((user,index)=>{
                                console.log(user)
                                return (
                                <tr key={index}>
                                    <td>
                                    <strong>{user.id}</strong>
                                    </td>
                                    <td className="text-slate-700">
                                        <span className="relative flex items-center">
                                            <img src={user.image_url ? user.image_url : defaultStImg} alt="st img" className="mr-1 w-[20px]" />
                                            {user.name}
                                        </span>
                                    </td>
                                    <td className="text-slate-700">{user.email}</td>
                                    <td>
                                        <Link to={`student-profile/${user.id}`} class="relative inline-flex mr-2 justify-center items-center cursor-pointer">
                                            <div class="group flex justify-center transition-all rounded-full bg-[#4d5fe3e0] p-2"
                                            >
                                                <i className="fa-solid fa-eye text-white"></i>
                                                <span class="absolute opacity-0 group-hover:opacity-100 group-hover:-translate-y-7 duration-700 text-sm"
                                                >{t("Profile")}</span>
                                            </div>
                                        </Link>
                                    </td>
                                </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                }
            </Modal.Body>
          </Modal> 
        </>
      );
}