import { useState , useEffect, useContext, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { Button, Spinner } from "react-bootstrap";
import faildImg from "../../../media/images/planet.png"
import ContextApi from "../../../store/ContextApi";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
import { deleteCouponeFunc } from "../../../store/custom-actions/couponse-actions/DeleteCouponseSlicer";
import Swal from "sweetalert2";
// import ShowCouponUsers from "./ShowCouponUsers";
import { fetchAllCoursesFunc } from "../../../store/custom-actions/courses-actions/GetAllCoursesSlicer";
import checkPermissions from "../../global-functions/checkPermissionsFunc";
// import NewCouponeForm from "./couponse-forms/NewCouponForm";
import { useTranslation } from "react-i18next";
import { getMailsTemplatesFunc } from "../../../store/custom-actions/mail/GetMailsTemplatesSlicer";
import UpdateTemplateModale from "./UpdateTemplateModale";

function EmailsTemplatesPage() {
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const coupons_API_URL = `${contextApi.dashboard_URL_API}/coupons`;
    const [templatesList , setTemplatesList] = useState([]);
    const dispatch = useDispatch();
    const [searchValue , setSearchValue] = useState("");
    const [showCouponUsers , setShowCouponUsers] = useState();
    const [couponUsers , setCouponUsers] = useState([]);
    const templatesApi = `${contextApi.dashboard_URL_API}/email-templates`;
    const [coursesList , setCoursesList] = useState([]);
    const [showUpdate , setShowUpdate] = useState();
    const [template , setTemplate] = useState();

    const {loading:getTemplatesLoading , data} = useSelector(
        state => state.getMailsTemplatesSlicer
    )
    const getData = async () => {
        try {
            dispatch(getMailsTemplatesFunc(templatesApi))
        }
        catch (error){
            alert(error)
        }
    }
    useEffect(() => {
        data && setTemplatesList(data)
    },[data])
    // const deleteCoupon = async (couponId) => {
    //     const deleteFunc = () => {
    //         try {
    //             dispatch(deleteCouponeFunc(`${coupons_API_URL}/${couponId}`))
    //             .then(result => {
    //                 if(result.payload.success === true) {
    //                     getData();
    //                 }
    //             })
    //         }
    //         catch (error){
    //             alert(error)
    //         }
    //     }
    //     swal({
    //         title: t("Confirm Coupon Deletion"),
    //         text: t(`Once deleted, this will be not avilable in this Platform!`),
    //         icon: "warning",
    //         buttons: true,
    //         dangerMode: true,
    //     }).then((willDelete) => {
    //         if (willDelete) {
    //             deleteFunc()
    //         }
    //     });
    // }
    useEffect(() => {
        let api = `${templatesApi}?${searchValue ? `search=${searchValue}` : ""}`;
        let searchTimeout;
        let getData = async ()=>{
          if(searchValue !== "") {
            clearTimeout(searchTimeout);
            searchTimeout = setTimeout(()=>{
              dispatch(getMailsTemplatesFunc(api))
            },1000)
          }else {
            dispatch(getMailsTemplatesFunc(api))
          }
        }
        getData()
        return () => clearTimeout(searchTimeout)
      },[searchValue])

    const handleShowUpdateTemplateForm = (template) => {
        setShowUpdate(true);
        setTemplate(template)
    }

    return (
        <>
            <PageTitle activeMenu="Mails Templates" motherMenu="Dashboard" />
            <div className="card">
                <div className="card-header flex justify-between items-center">
                    <h4 className="card-title">{t("Templates List")}</h4>
                    <div className="relative add-and-search flex items-center">
                    <div className="input-group search-area w-auto mr-2">
                        <span className="input-group-text"
                        //   onClick={() => search()}
                        >
                            <Link to={"#"}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                fill="var(--primary)"
                                ></path>
                            </svg>
                            </Link>
                        </span>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t("Search here...")}
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }}
                        />
                    </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="content relative w-full">
                        {
                            getTemplatesLoading
                            ?
                            (
                                <>
                                    <div className="row d-flex justify-content-center gap-2">
                                        <Spinner animation="grow" className="bg-primary" />
                                        <Spinner animation="grow" className="bg-primary" />
                                        <Spinner animation="grow" className="bg-primary" />
                                        <Spinner animation="grow" className="bg-primary" />
                                    </div>
                                </>
                            )
                            :
                            (
                                data
                                ?
                                (
                                    <div className="table-responsive">
                                        <div
                                            id="student_wrapper"
                                            className="dataTables_wrapper no-footer"
                                        >
                                            <table
                                            className="table display mb-4 dataTablesCard order-table card-table text-black application "
                                            id="application-tbl1_next"
                                            >
                                            <thead>
                                                <tr>
                                                {/* <th className="">#{t("ID")}</th> */}
                                                <th className="">{t("Title")}</th>
                                                <th className="">
                                                    {t("Decision")}
                                                </th>
                                                </tr>
                                            </thead>
                                                <tbody>
                                                    {data &&
                                                    Object.keys(data).length > 0 
                                                    ?
                                                    Object.entries(data).map(([key , value]) => {
                                                        if(key !== "example_info") {
                                                            return (
                                                                <tr>
                                                                   <td>
                                                                    <h5>{t(key?.replace(/_/ig , " "))}</h5>
                                                                    <p className="m-0 text-primary">{key === "student_register_welcome" ? t("Message Which Tell User About Success Regestration") : t("Message Which Tell User About Success Subscription")}</p>
                                                                    </td>  
                                                                   <td className="">
                                                                    <Button className="bg-primary border-primary"
                                                                    onClick={() => {
                                                                        handleShowUpdateTemplateForm({name : key , values : value})
                                                                    }}
                                                                    >{t("Update Template")}</Button>
                                                                    </td>  
                                                                </tr>
                                                            )
                                                        }
                                                    })
                                                    :
                                                    <td colSpan={4} className="">
                                                        <p className="m-0 border-none">{t("No data available")}</p> 
                                                    </td>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )
                                :
                                <div className='relative w-full h-[60vh] flex flex-col justify-center items-center'>
                                    <img src={faildImg} alt="faild img" className='w-[150px] mb-3'/>
                                    <p>{t("No data available")} 
                                    <span className="text-[#4d5fe3] cursor-pointer"
                                    onClick={()=> getData()}
                                    >
                                    {t("Try again")}
                                    </span>
                                    </p>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <UpdateTemplateModale 
            show={showUpdate}
            onHide={() => setShowUpdate(false)}
            template = {template}
            refetchData={() => getData()}
            />
        </>
    )
}

export default EmailsTemplatesPage;