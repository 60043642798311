import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom"
import ContextApi from "../../../store/ContextApi";
import {Controller , useForm } from "react-hook-form";
import { addNewQuestionFunc } from "../../../store/custom-actions/Question-actions/AddQuestionSlicer";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import HandleErrorsListFunc from "../../global-functions/handleErrorsListFunc";
import axios from "axios";
import { Button } from "react-bootstrap";
import PageTitle from "../../layouts/PageTitle";
import ReactQuill from "react-quill";
import { useTranslation } from "react-i18next";

export default function  NewQuestionPage() {
    const {t} = useTranslation();
    const {addQuestionLoading} = useSelector(state => state.addQuestionSlicer);
    const dispatch = useDispatch();
    const contextApi = useContext(ContextApi);
    const token = window.localStorage.getItem("token")
    const {
        register,
        handleSubmit,
        formState: { errors },
        reset,
        control,
        setValue,
        watch,
      } = useForm();
      const {lessonId} = useParams();
      const questions_api_url = `${contextApi.dashboard_URL_API}/lessons/${lessonId}/questions`
    const [questionType , setquestionType] = useState("");
    const [questionId , setQuestionId] = useState();
    const upload_question_video_api = `${contextApi.dashboard_URL_API}/questions/${questionId}/upload-video`
    const [explainQuestionVidForm , setExplainQuestionVidForm] = useState(false)
    const [videoType , setVideoType] = useState("file")
    const [video , setVideo] = useState("file");
    const [uploadVideoPercent , setUploadVideoPercent] = useState();
    const [videoPercent , setVideoPercent] = useState(0);
    const [loadingVideo , setLoadingVideo] = useState();
    const [additionDone , setAdditionDone] = useState();
    const [onSubmitionQuestion , setOnSubmitionQuestion] = useState();
    const [questionTitle , setQuestionTitle] = useState("");
    const [correctAnswer , setCorrectAnswer] = useState(t("Choose Correct Answer.."));
    const colors = [
        '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
        '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
    ];
    const modules = {
        toolbar: {
          container: [
            [{ 'font': [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': colors }, { 'background': colors }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'align': [] }],
            ['blockquote', 'code-block'],
            ['link', 'image', 'video'],
            ['clean']
          ],
        }
      };

    const handleSubmitQuestion = (data , options) => {
        if(data) {
        const question = {
            type : questionType,
            correct_option : parseInt(data.correct_option),
            grade : parseInt(data.grade),
            title : data.title,
            options : questionType === "1" ? ["true" , "false"] : options,
        }
        if(!correctAnswer || correctAnswer === t("Choose Correct Answer..")) {
            toast.error(t("Please Choose Correct Answer..!"))
        }else {
            dispatch(addNewQuestionFunc({api : questions_api_url , QuestionData : question}))
            .then(result => {
            result.payload.data.question && setQuestionId(result.payload.data.question.id)
            if(result.payload.success === true) {
                reset();
                window.history.back()
            }
            })
        }
        }
      }
      const handleSubmitImageQuestion = (data) => {
        if(data) {
          const imageForm = new FormData()
          imageForm.append("type" , questionType)
          imageForm.append("title" , questionTitle)
        //   imageForm.append("image" , questionImage)
          imageForm.append("grade" , data.grade)
        //   imageForm.append("category_id" , categoriesId)
          if(!questionType){
            toast.error(t("Question Category Must not be empty!"))
          }else{
            dispatch(addNewQuestionFunc({api : questions_api_url , QuestionData : imageForm}))
            .then(result => {
              result.payload.data.question && setQuestionId(result.payload.data.question.id)
              if(result.payload.success === true) {
                //   setQuestionTitle("")
                //   setQuestionMainDataForm(false)
                //   setExplainQuestionVidForm(true)
                //   setAdditionDone(true)
                  reset();
                //   dispatch(getQuestionsFunc(questions_api_url));
                window.history.back();
              }
            })
          }
        }
      }
      const handleUploadVideo = async (videoFile) => {
        if (videoType) {
          const formData = new FormData();
          formData.append('video', videoFile);
          axios.post(upload_question_video_api , formData , {  headers: {
            Authorization: `Bearer ${token}`, // include the token in the headers
          } , onUploadProgress : (data) => {
            setUploadVideoPercent(Math.floor((data.loaded / data.total) * 100))
            setLoadingVideo(true);
            setOnSubmitionQuestion(true)
          }})
          .then (response => {
              if(response.data.success === true) {
                // dispatch(getQuestionsFunc(questions_api_url));
                setLoadingVideo(false)
                window.history.back();
                reset();
                // props.onHide();
                setExplainQuestionVidForm(false);
                setAdditionDone(false)
                setquestionType("");
                setVideoPercent(0);
                setQuestionTitle("")
              }
              else {
                setVideoPercent(0);
                setLoadingVideo(false);
              }
          })
        }
      };
  
      useEffect(() => {
        setVideoPercent(uploadVideoPercent);
      },[uploadVideoPercent]);
      useEffect(() => {
        addQuestionLoading ? setOnSubmitionQuestion(true) : setOnSubmitionQuestion(false)
      },[addQuestionLoading]);
      useEffect(() => {
        loadingVideo ? setOnSubmitionQuestion(true) : setOnSubmitionQuestion(false)
      },[loadingVideo]);
  
      useEffect(() => {
        HandleErrorsListFunc(errors)
      },[errors])
    return (
        <>
        <PageTitle motherMenu={"Questions"} activeMenu={"New Question"} />
            <div className="card p-3">
                <div className="basic-form">
                    <div className="row question-type-and-lesson-id">
                        <div className="form-group col-sm-12">
                            <select 
                            defaultValue={0}
                            className={` form-control col-sm-12 ${additionDone ? "pointer-events-none opacity-50" : ""}`}
                            onChange={(e)=>setquestionType(e.target.value)}
                            >
                            <option value={0} disabled>{t("Question type")}</option>
                            <option value={1}>{t("True & False")}</option>
                            <option value={2}>{t("Choose Question")}</option>
                            <option value={3}>{t("Essay Question")}</option>
                            </select>
                        </div>
                    </div>
                {
                parseInt(questionType) === 2 && lessonId !== 0 && !explainQuestionVidForm && 
                <form 
                    className="choose-question-form" 
                    id="choose-question-form"
                    onSubmit={handleSubmit((data) => {
                        handleSubmitQuestion(data , [data.optionOne,data.optionTwo , data.optionThree , data.optionFour])
                    })
                    }
                >
                    <div className="row question-row my-2">
                        <div className="col-sm-12">
                            <label>{t("Question")}</label>
                            {errors.title?.message && (
                            <p className="text-red-600">{errors.title?.message}</p>
                            )}
                            <Controller
                                name={`title`}
                                control={control}
                                rules={{
                                    required : t("Field Is Required")
                                }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <ReactQuill
                                        onChange={(content) => {
                                            setValue("title" , content);
                                        }}
                                        onBlur={onBlur}
                                        value={watch("title")}
                                        modules={modules}
                                        className="p-0 m-0 border-none outline-none form-control bg-white"
                                        theme="snow"
                                        placeholder={t("Question Content...")}
                                        
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row answer row mb-3">
                        <div className="col-sm-12 mb-4">
                            {errors.optionOne?.message && (
                            <p className="text-red-600">{errors.optionOne?.message}</p>
                            )}
                            <Controller
                                name={`optionOne`}
                                control={control}
                                rules={{
                                    required : t("Field Is Required")
                                }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <ReactQuill
                                        onChange={(content) => {
                                            setValue("optionOne" , content);
                                        }}
                                        onBlur={onBlur}
                                        value={watch("optionOne")}
                                        modules={modules}
                                        className="p-0 m-0 border-none outline-none form-control bg-white"
                                        theme="snow"
                                        placeholder={t("First Answer...")}
                                        
                                    />
                                )}
                            />
                        </div>
                        <div className="col-sm-12 mb-4">
                            {errors.optionTwo?.message && (
                            <p className="text-red-600">{errors.optionTwo?.message}</p>
                            )}
                            <Controller
                                name={`optionTwo`}
                                control={control}
                                rules={{
                                    required : t("Field Is Required")
                                }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <ReactQuill
                                        onChange={(content) => {
                                            setValue("optionTwo" , content);
                                        }}
                                        onBlur={onBlur}
                                        value={watch("optionTwo")}
                                        // ref={ref}
                                        modules={modules}
                                        className="p-0 m-0 border-none outline-none form-control bg-white"
                                        theme="snow"
                                        placeholder={t("Second Answer...")}
                                        
                                    />
                                )}
                            />
                        </div>
                        <div className="col-sm-12 mb-4">
                            {errors.optionThree?.message && (
                            <p className="text-red-600">{errors.optionThree?.message}</p>
                            )}
                            <Controller
                                name={`optionThree`}
                                control={control}
                                rules={{
                                    required : t("Field Is Required")
                                }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <ReactQuill
                                        onChange={(content) => {
                                            setValue("optionThree" , content);
                                        }}
                                        onBlur={onBlur}
                                        value={watch("optionThree")}
                                        modules={modules}
                                        className="p-0 m-0 border-none outline-none form-control bg-white"
                                        theme="snow"
                                        placeholder={t("Third Answer...")}
                                        
                                    />
                                )}
                            />
                        </div>
                        <div className="col-sm-12 mb-4">
                            {errors.optionFour?.message && (
                            <p className="text-red-600">{errors.optionFour?.message}</p>
                            )}
                            <Controller
                                name={`optionFour`}
                                control={control}
                                rules={{
                                    required : t("Field Is Required")
                                }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <ReactQuill
                                        onChange={(content) => {
                                            setValue("optionFour" , content);
                                        }}
                                        onBlur={onBlur}
                                        value={watch("optionFour")}
                                        modules={modules}
                                        className="p-0 m-0 border-none outline-none form-control bg-white"
                                        theme="snow"
                                        placeholder={t("Fourth Answer...")}
                                        
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="form-group mb-3 col-sm-12 col-md-6 grade-input">
                            <label>{t("Choose Correct Answer index")}</label>
                            {errors.correct_option?.message && (
                            <p className="text-red-600">{errors.correct_option?.message}</p>
                            )}
                            <select
                            {...register("correct_option", {
                                required: t("Feild is required!"),
                            })}
                            className="form-control"
                            onChange={(e) => {
                                setCorrectAnswer(e.target.value)
                            }}
                            defaultValue={""}
                            >
                            <option value={""} disabled>{t("Choose Correct Answer..")}</option>
                            <option value={0}>{t("first answer")}</option>
                            <option value={1}>{t("second answer")}</option>
                            <option value={2}>{t("third answer")}</option>
                            <option value={3}>{t("fourth answer")}</option>
                            </select>
                        </div>
                        <div className="form-group mb-3 col-sm-12 col-md-6 marks-input">
                            <label>{t("Question Grade")}</label>
                            {errors.grade?.message && (
                            <p className="text-red-600">{errors.grade?.message}</p>
                            )}
                            <input
                            {...register("grade", {
                                required: t("Marks feild is required!"),
                            })}
                            type="number"
                            className="form-control"
                            placeholder={t('Question Marks...')}
                            min={1}
                            />
                        </div>
                    </div>
                    <div className="flex items-center my-2 justify-end">
                        <Button
                            type="submit"
                            className={`btn btn-primary btn-sm ${addQuestionLoading ? "pointer-events-none opacity-60" : ""}`}
                            // onClick={() => setShowNew}
                        >
                            {
                                addQuestionLoading
                                ?
                                <>{("loading")}...</>
                                :
                                t("Submit")
                            }
                            
                        </Button>
                    </div>
                </form>
                }
                {
                parseInt(questionType) === 1 && lessonId !== 0 && !explainQuestionVidForm &&
                <form 
                    className="true&false-question-form" 
                    id="true&false-question-form"
                    onSubmit={handleSubmit((data) => {
                    handleSubmitQuestion(data , [data.option1 , data.option2])
                    })}
                    >
                    <div className="row question-row">
                        <div className="col-sm-12">
                            <label>{t("Question")}</label>
                            {errors.title?.message && (
                            <p className="text-red-600">{errors.title?.message}</p>
                            )}
                            <Controller
                                name={`title`}
                                control={control}
                                rules={{
                                    required : t("Field Is Required")
                                }}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <ReactQuill
                                        onChange={(content) => {
                                            setValue("title" , content);
                                        }}
                                        onBlur={onBlur}
                                        value={watch("title")}
                                        modules={modules}
                                        className="p-0 m-0 border-none outline-none form-control bg-white"
                                        theme="snow"
                                        placeholder={t("Question Content...")}
                                        
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className="row">
                    <div className="form-group mb-3 col-sm-12 col-md-6 grade-input">
                        <label>Choose Answer</label>
                        {errors.correct_option?.message && (
                        <p className="text-red-600">{errors.correct_option?.message}</p>
                        )}
                        <select
                        {...register("correct_option", {
                            required: t("Feild is required!"),
                        })}
                        className="form-control"
                        onChange={(e) => {
                            setCorrectAnswer(e.target.value)
                        }}
                        defaultValue={""}
                        >
                        <option value={""} disabled>{t("Choose Correct Answer...")}</option>
                        <option value={0}>{t("True")}</option>
                        <option value={1}>{t("False")}</option>
                        </select>
                    </div>
                    <div className="form-group mb-3 col-sm-12 col-md-6 marks-input">
                        <label>{t("Question Grade")}</label>
                        {errors.grade?.message && (
                        <p className="text-red-600">{errors.grade?.message}</p>
                        )}
                        <input
                        {...register("grade", {
                            required: t("Marks feild is required!"),
                        })}
                        type="number"
                        className="form-control"
                        placeholder={t("Question Marks")}
                        min={1}
                        />
                    </div>
                    </div>
                    <div className="flex items-center my-2 justify-end">
                        <Button
                            type="submit"
                            className={`btn btn-primary btn-sm ${addQuestionLoading ? "pointer-events-none opacity-60" : ""}`}
                            // onClick={() => setShowNew}
                        >
                            {
                                addQuestionLoading
                                ?
                                <>{("loading")}...</>
                                :
                                t("Submit")
                            }
                            
                        </Button>
                    </div>
                </form>
                }
                {
                parseInt(questionType) === 3 && lessonId !== 0 && !explainQuestionVidForm &&
                <form 
                    className="articale-question-form bg-ima" 
                    id="articale-question-form"
                    onSubmit={handleSubmit((data) => {
                    handleSubmitImageQuestion(data)
                    })}
                >
                    <div className="row">
                        <div className="form-group mb-3 col-sm-12 marks-input">
                            <label>{t("Question Grade")}</label>
                            {errors.grade?.message && (
                            <p className="text-red-600">{errors.grade?.message}</p>
                            )}
                            <input
                            {...register("grade", {
                                required: t("Marks feild is required!"),
                            })}
                            type="number"
                            className="form-control"
                            placeholder={t("Question Marks")}
                            min={1}
                            />
                        </div>
                    </div>
                    <div className="row p-0 mb-2 mx-0">
                        <label className="flex items-center">
                            <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                            {t("Question Content")}
                        </label>
                        <ReactQuill
                        modules = {modules}
                        className="p-0 m-0 border-none outline-none form-control bg-white " 
                        value={questionTitle} 
                        theme="snow" 
                        onChange={(content) => setQuestionTitle(content)} />
                    </div>
                    <div className="flex items-center my-2 justify-end">
                        <Button
                            type="submit"
                            className={`btn btn-primary btn-sm ${addQuestionLoading ? "pointer-events-none opacity-60" : ""}`}
                            // onClick={() => setShowNew}
                        >
                            {
                                addQuestionLoading
                                ?
                                <>{("loading")}...</>
                                :
                                t("Submit")
                            }
                            
                        </Button>
                    </div>
                </form>
                }

                {
                explainQuestionVidForm
                &&
                <form
                id="video-form"
                className="video-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    if (video && videoType) {
                    handleUploadVideo(video);
                    }
                }}
                >
                <div className="form-group mb-3 col-sm-12">
                    <label htmlFor="explain video">{t("Explain Video")} ({t("optional")})</label>
                    <input
                        type="file"
                        className="form-control"
                        accept="video/mp4"
                        onChange={(e) => {
                        setVideo(e.target.files[0]);
                        }}
                        id = "explain video"
                    />
                    <div className={`relative my-2 ${videoPercent > 0 ? "flex" : "hidden"} w-full h-[20px] bg-blue-300 rounded-md overflow-hidden`}>
                    <span
                    role="progressbar"
                    aria-valuemin={0}
                    aria-valuemax={100}
                    style={{width : `${videoPercent}%`}}
                        className={`absolute top-0 text-white transition-all duration-700 left-0 h-full bg-blue-600 flex justify-center items-center`}>
                        {videoPercent}%
                        </span>
                    </div>
                </div>
                {
                    video !== "file"
                    &&
                <Button form="video-form" type="submit" className={`primary ${loadingVideo ? "opacity-60 pointer-events-none" : ""}`}>
                    {loadingVideo ? (
                    <>
                        <div className="relative flex justify-center items-center">
                        {t("Uploading Video")}..
                        <div className="loading-spin-data w-[13px] h-[13px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-2"></div>
                        </div>
                    </>
                    ) : (
                    t("Next")
                    )}
                </Button>
                }
                </form>
                }
                </div>
            </div>
        </>
    )
}