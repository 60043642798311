import { useState , useEffect, useContext } from "react";
import { useSelector , useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ContextApi from "../../../../store/ContextApi";
import Swal from "sweetalert2";
import excutionIcon from "../../../../media/images/gear.png";
import "./lesson-schuadles-form.css";
import { addNewSchuadlesFunc } from "../../../../store/custom-actions/lessons-actions/lessons-schuadles-actions/NewSchuadlesSlicer";
import CheckBox from "./CheckBox";
import { useParams } from "react-router-dom";
import { fetchCourseLessonsFunc } from "../../../../store/custom-actions/lessons-actions/GetCourseLessons";
import { getCourseExcutionFunc } from "../../../../store/custom-actions/courses-actions/excutions-actions/GetCourseExcutionsSlicer";
import PageTitle from "../../../layouts/PageTitle";
import { useTranslation } from "react-i18next";
function LessonSchadulesPage() {
    const {t} = useTranslation();
    const {
      register,
      handleSubmit,
      formState: { errors },
      reset,
    } = useForm();
    const {chapterId} = useParams();
    const {loading:addNewSchuadleLoading} = useSelector(state => state.addNewSchuadlesSlicer);
    const dispatch = useDispatch();
    const contextApi = useContext(ContextApi);
    const [courseId , setCourseId] = useState()
    const [excutionId , setExcutionId] = useState();
    const courseExtintionsApi = `${contextApi.dashboard_URL_API}/courses/${courseId}/course-executions`;
    const extintionsApi = `${contextApi.dashboard_URL_API}/course-executions/${excutionId}/lesson-schadules`;
    const lessonsApi = `${contextApi.dashboard_URL_API}/chapters/${chapterId}/lessons`;
    const [lessonsArray , setLessonsArray] = useState([]);
    const [excutionsList , setExcutionsList] = useState([]);
    const [lessonsWillSend , setLessonsWillSend] = useState({});
    const [chooseExcution , setChooseExcution] = useState(false);
    const [excutionWhichChoosed , setExcutionWhichChoosed] = useState("Choose Excution..");
    const {loadingCourseLessons , data:lessonsData} = useSelector(
        (state) => state.getCourseLessonsSlicer
    );
    const { data:excutionsData} = useSelector(
        (state) => state.getCourseExcutionsSlicer
    );
    useEffect(() => {
        dispatch(fetchCourseLessonsFunc(lessonsApi));
    },[])

    useEffect(() => {
        if(lessonsData) {
            setLessonsArray(lessonsData.lessons)
            setCourseId(lessonsData.chapter?.course_id)
        }
    },[lessonsData])

    useEffect(() => {
        courseId && dispatch(getCourseExcutionFunc(courseExtintionsApi));
    },[courseId])
    useEffect(() => {
        excutionsData && setExcutionsList(excutionsData)
    },[excutionsData])


    const handleSubmitExtintions = (data) => {
            if(!excutionId) {
                Swal.fire({
                    title: t("Oops!"),
                    text: t("Excution Should not be empty!"),
                    icon: "warning"
                });
            }else {
                if(Object.keys(lessonsWillSend).length < 1) {
                    Swal.fire({
                        title: t("Oops!"),
                        text: t("You Can not Submit without lessons!"),
                        icon: "warning"
                    });
                }else{
                    dispatch(addNewSchuadlesFunc({api : extintionsApi , data : {...data , lessons : lessonsWillSend}}))
                    .then(result => {
                        console.log(result)
                        if(result.payload.success === true) {
                            setTimeout(() => window.location.reload() , 2000);
                            reset();
                        }
                    })
                }
            }
    }
    
    const pushLessonsFunction = (lessonId , object) => {
        if(lessonId && object) {
            setLessonsWillSend(prevObjects => Object.assign({} , prevObjects , {[lessonId] : object}))
        }else {
            setLessonsWillSend((prevObjects) => {
                const newObj = { ...prevObjects };
                delete newObj[lessonId]; // Remove the lesson object associated with the lessonId
                return newObj;
            });
        }
    }
    
    return (
        <>
        <PageTitle motherMenu={"Lessons Schudales"} activeMenu={"New Schudales"} customClasses={"mb-0"} />
        <div className="rowrelative flex items-center justify-between my-3">
            <div className="relative flex items-center">
                <button 
                    type="submit"
                    form="schaudle-form"
                    class={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 hover:gap-2 hover:translate-x-3
                    ${addNewSchuadleLoading ? "pointer-events-none opacity-80" : ""}
                    `}
                    >
                        {
                            addNewSchuadleLoading
                            ?
                            <>{("loading")}...</>
                            :
                            <>
                                {t("Submit")} 
                                <svg
                                    class="w-5 h-5"
                                    stroke="currentColor"
                                    stroke-width="1.5"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                    d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                                    stroke-linejoin="round"
                                    stroke-linecap="round"
                                    ></path>
                                </svg>
                            </>
                        }
                </button>
            </div>
        </div>
        <div className="card p-3">
            <div className="form-content relative">
                <div className="basic-form">
                    <form 
                        className="course-extintions-form" 
                        id="schaudle-form"
                        onSubmit={handleSubmit((data) => {
                            handleSubmitExtintions(data)
                        })
                        }
                    >
                    <div className="row relative">
                        <div className="form-group mb-3 col-sm-12 col-md-6">
                            <label className="relative flex items-center">
                                {t("Title")}
                            </label>
                            {errors.title?.message && (
                                <p className="text-red-600">{errors.title?.message}</p>
                            )}
                            <input
                                {...register("title", {
                                required: t("title Should not be empty!"),
                                })}
                                type="text"
                                className="form-control"
                        />
                        </div>
                        {
                            loadingCourseLessons
                            ?
                            <p>{t("loading")}...</p>
                            :
                            (
                                excutionsData && excutionsList && excutionsList.length >= 1
                                ?
                                <div className="col-sm-12 col-md-6">
                                    <div className="form-group mb-3 col-sm-12">
                                        <label>{t("Course Excutions")}</label>
                                        {errors.type?.message && (
                                        <p className="text-red-600">
                                        {errors.type?.message}
                                        </p>
                                        )}
                                        <span 
                                            className="relative flex justify-between items-center px-2 w-full h-[45px] rounded-md border cursor-pointer bg-white"
                                            onClick={()=>setChooseExcution(!chooseExcution)}
                                        >
                                            <div className="relative flex items-center">
                                                {
                                                    <img src={excutionIcon} alt="teacher chooses" className="relative w-[20px] mr-2" />
                                                }
                                                {excutionWhichChoosed}
                                            </div>
                                            <i className={`fa-solid fa-circle-chevron-left transition-all duration-500 text-[20px] text-[#075985] ${chooseExcution ? "rotate-[-90deg] opacity-100" : "rotate-[90deg] opacity-70"} `}></i>
                                        </span>
                                    </div>
                                    <div className={`instructors-list overflow-y-auto transition-all duration-500 w-[98%] cursor-pointer ${chooseExcution ? "h-[150px]" : "h-0"}`}>
                                    { excutionsList &&
                                        excutionsList.map((excution , index) =>{
                                        return (
                                            <div key={index} className="input-div relative flex items-center my-1 p-2 pl-0">
                                            <input
                                            form="new-course-form"
                                            id={excution.id} 
                                            type="radio" 
                                            name="instructor-name" 
                                            value={excution.id}
                                            className=""
                                            data-name={excution.title}
                                            onChange={(e)=>{
                                                setChooseExcution(false);
                                                setExcutionId(e.target.value);
                                                setExcutionWhichChoosed(e.target.dataset.name);
                                            }}
                                            />
                                            <label htmlFor={excution.id} className="cursor-pointer mr-2 flex items-center translate-y-[2px]">
                                                <img src={excutionIcon} alt="img" className="w-[20px] mx-2 rounded-[50%]"/>
                                                {excution.title}
                                            </label>
                                            </div>
                                        )
                                        })
                                    }
                                    </div>
                                </div>
                                :
                                <p className="m-0 flex justify-center items-center">{t('No Excutions!')}</p>
                            )
                        }
                    </div>
                    <div className="schuadles-lessons-content row h-[300px] overflow-y-auto">
                        {
                            loadingCourseLessons
                            ?
                            <p>{t("loading")}...</p>
                            :
                            (
                            lessonsData
                            &&
                            (
                                lessonsArray && lessonsArray.length >=1 
                                ?
                                lessonsArray.map((lesson , index) => {
                                    return (
                                        <CheckBox 
                                            key={index}
                                            lesson_id = {lesson.id}
                                            name={lesson.name} 
                                            pushingLessonsFunction={pushLessonsFunction}
                                        />
                                    )
                                })
                                :
                                <p className="m-0 flex justify-center items-center">{t("No Lessons")}</p>
                            )
                            )
                        }
                    </div>
                    </form>
                </div>
            </div>
        </div>
        </>
    )
}

export default LessonSchadulesPage