import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {useContext, useEffect, useState} from 'react';
import HandleErrorsListFunc from '../../global-functions/handleErrorsListFunc';
import ContextApi from '../../../store/ContextApi';
import { updateReviewsFunc } from '../../../store/custom-actions/reviews/UpdateReviewsSlicer';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export default function UpdateReviewForm({show , onHide , rateId , rateComment , rate , oldVisabilaty , refetchData}) {
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi)
    const singlereviewApi = `${contextApi.dashboard_URL_API}/reviews/${rateId}`;
    const {register , formState : {errors} , handleSubmit , setValue} = useForm();
    const {loading:updatereviewLoading} = useSelector(state => state.updateReviewsSlicer);
    const dispatch = useDispatch();
    const [activatyStatus , setActivatyStatus] = useState(null)
    const maxRateCount = 5
    useEffect(() => {
        setValue("rate" , rate)
        setValue("comment" , rateComment)
        console.log("publish" , oldVisabilaty)
        oldVisabilaty === "publish" ? setActivatyStatus(true) : setActivatyStatus(false)
    },[setValue , rateComment, rate , oldVisabilaty])
    useEffect(() => {
        console.log(activatyStatus)
    },[activatyStatus])
    const handleSubmitData = (data) => {
        dispatch(updateReviewsFunc({api : singlereviewApi , data : {...data}}))
        .then(result => {
            if(result.payload.success === true) {
            //   dispatch(fetchAllCategoriesFunc(categoriesApi));
              onHide();
            //   toast.success(`${result.payload.message}!`, {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            // })
            refetchData()
            }
        })
    }

  useEffect(() => {
    HandleErrorsListFunc(errors);
  },[errors])
  return (
    <>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Update Review")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form 
            id="update-rate-form"
            onSubmit={handleSubmit((data) => {
                handleSubmitData(data);
            })}
            >
              <div className='row'>
                  <div className="form-group mb-3 col-sm-12">
                      <label>{t("Rate")} </label>
                      {errors.rate?.message && (
                          <p className="text-red-600">{errors.rate?.message}</p>
                      )}
                      {/* <input
                      {...register("name", {required : "name should not be empty!"})}
                      type="text"
                      className="form-control"
                      /> */}
                      <select
                      className="form-control"
                      {...register("rate", {required : "Rate should not be empty!"})}
                      >
                        {/* {
                            handleReturnOptions()
                        } */}
                        <option value={1}>{t("One Star")}</option>
                        <option value={2}>{t("Two Stars")}</option>
                        <option value={3}>{t("Three Stars")}</option>
                        <option value={4}>{t("Four Stars")}</option>
                        <option value={5}>{t("Five Stars")}</option>
                      </select>
                  </div>
                  <div className="form-group mb-3 col-sm-12">
                      <label>{t("Comment")} </label>
                      {errors.comment?.message && (
                          <p className="text-red-600">{errors.comment?.message}</p>
                        )}
                      <textarea 
                        className="form-control"
                        {...register("comment", {required : "Rate should not be empty!"})}
                        ></textarea>
                  </div>
                  {/* <div className="form-check custom-checkbox mb-3">
                    <input
                    //   {...register("publish")}
                      type="checkbox"
                      className="form-check-input m-0 cursor-pointer"
                      id="customCheckBox1"
                      checked = {activatyStatus}
                    //   value={activatyStatus}
                      onChange={(e) => e.target.checked ? setActivatyStatus(true) : setActivatyStatus(false)}
                    //   required
                    />
                    <label
                      className="form-check-label translate-y-[-4px] cursor-pointer"
                      htmlFor="customCheckBox1"
                    >
                      {t("Visabilaty")}
                    </label>
                  </div> */}
              </div>
            </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            {t("Close")}
          </Button>
          <Button type="submit" form="update-rate-form" className={`${updatereviewLoading ? "pointer-events-none opacity-80" : ""}`}>
          {!updatereviewLoading 
          ? (
            t("Submit")
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                {t("loading")}...
                <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white"></div>
              </div>
            </>
          )}
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}