import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addNewSessionFunc } from '../../../../store/custom-actions/students-actions/GetAllStudentsSlicer';
import { useContext, useEffect, useState } from 'react';
import ContextApi from '../../../../store/ContextApi';
import { fetchAllStudentsFromApi } from '../../../../store/custom-actions/students-actions/GetAllStudentsSlicer';
import { esitSessionFunc } from '../../../../store/custom-actions/sessions-actions/EditSessionSlicer';
import Swal from 'sweetalert2';
import { getAllSessionsFunc } from '../../../../store/custom-actions/sessions-actions/GetAllSessionsSlicer';

import CheckboxBtn from './CheckboxInput';
import { getSingleExcutionFunc } from '../../../../store/custom-actions/courses-actions/excutions-actions/GetSingleExcution';
function EditSessionForm(props) {
    const {sessionData , courseId , execution_students , single_execution_id} = props;
    const contextApi = useContext(ContextApi)
    const sessionsApi = `${contextApi.dashboard_URL_API}/courses/sessions`;
    const editSessionsApi = `${contextApi.dashboard_URL_API}/sessions`;
    const studentsApi = `${contextApi.dashboard_URL_API}/students`;
    const getSessionsApi = `${contextApi.dashboard_URL_API}/courses/${courseId}/sessions`
    const {register , handleSubmit , formState : {errors} , reset , setValue} = useForm();
    const dispatch = useDispatch();
    const {loading:editSessionLoading} = useSelector(state => state.editSessionSlicer);
    const [arrayWillSend , setarrayWillSend] = useState([]);
    const [studentsList , setStudentsList] = useState([]);
    const {loading:getSingleExecutionLoading , data:sigleExecutionData} = useSelector(
      (state) => state.getSingleExcutionSlicer
      );
    useEffect(() => {
      dispatch(fetchAllStudentsFromApi(studentsApi))
    },[])

    useEffect(() => {
      const singleExcutionApi = `${contextApi.dashboard_URL_API}/course-executions/${single_execution_id}`;
        const getSingleExcution = async () => {
            dispatch(getSingleExcutionFunc(singleExcutionApi))
        }
        getSingleExcution();
    },[single_execution_id])
    useEffect(() => {
      if(sigleExecutionData) {
        setStudentsList(sigleExecutionData.students)
      }
    },[sigleExecutionData])
    useEffect(() => {
        setValue("session_date" , sessionData && sessionData.session_date)
        setValue("details" , sessionData && sessionData.details)
        const sessionStudents = sessionData && sessionData.students && sessionData.students.flatMap(st => st)
        const alreadyPresent = sessionStudents && sessionStudents.length >= 1 && sessionStudents.filter(st => st.pivot.status === "present")
        setarrayWillSend(alreadyPresent)
    },[sessionData , setValue])
    const handleUpdateSession = async (data) => {
      if(data && arrayWillSend) {
        if(arrayWillSend.length >= 1) {
          const studentsIdList = arrayWillSend.flatMap(st => st.id);
          dispatch(esitSessionFunc(
            {
              sessionApi : `${editSessionsApi}/${sessionData.id}` ,
              newSessionData : {...data , students : studentsIdList}
            }
            ))
            .then(result => {
                if(result.payload.success === true) {
                    dispatch(getAllSessionsFunc(getSessionsApi));
                    props.onHide();
                    reset();
                  }
            })
            }else {
              Swal.fire({
                title: "No Students",
                text: "You Should Select Students!",
                icon: "warning",
                confirmButtonColor: "#3085d6",
              })
            }
      }
    }
    const removingSt = (arrayAfterRemoving) => {
      setarrayWillSend(arrayAfterRemoving)
    }
    const additionSt = (st) => {
      setarrayWillSend([...arrayWillSend , st])
    }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Update Session
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='session-form-div'>
            <form
                id="session-form"
                onSubmit={handleSubmit((data) => {
                    const dateNow = new Date()
                    const session_date = new Date(data.session_date)
                    if (
                      dateNow.getFullYear() > session_date.getFullYear() ||
                      (dateNow.getFullYear() === session_date.getFullYear() && dateNow.getMonth() > session_date.getMonth()) ||
                      (dateNow.getFullYear() === session_date.getFullYear() && dateNow.getMonth() === session_date.getMonth() && dateNow.getDate() > session_date.getDate())
                      )
                      {
                        Swal.fire({
                          title: "Date Validation",
                          text: "Can not add session with past date",
                          icon: "warning",
                          confirmButtonColor: "#3085d6",
                        });
                      }
                      else {
                        handleUpdateSession(data);
                      }
                })}
            >
                <div className='row'>
                    <div className='form-group col-sm-12 col-md-6'>
                        <label>Session Date</label>
                        <input 
                        {...register("session_date" , {required : "session date is required!"})}
                        type="session_date" 
                        className='form-control'/>
                    </div>
                    <div className='form-group col-sm-12 col-md-6'>
                        <label>Details</label>
                        <input 
                        {...register("details" , {required : "session details is required!"})}
                        type="text" 
                        className='form-control'
                        />
                    </div>
                </div>
                <div className='session-students'>
                <h4 className="my-2">Students <span className='text-sm font-normal'>(Not in session)</span></h4>
                <div className='row mt-2'>
                  {
                    getSingleExecutionLoading
                    ?
                    <p>Getting Students..</p>
                    :
                    (
                      studentsList && studentsList.length >= 1
                      ?
                      sessionData.students.map((student , index) => {
                        return (
                          <CheckboxBtn 
                            key={index}
                            student = {student}
                            arrayWillSend = {arrayWillSend}
                            removing = {removingSt}
                            additionDone = {additionSt}
                          />
                        )
                      })
                      :
                      <p>No Students..</p>
                    )
                  }
                </div>
                </div>
            </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
        <Button type="submit" form="session-form" className={`${editSessionLoading ? "opacity-80 pointer-events-none" : ''}`}>
          {!editSessionLoading ? (
            "Update Session"
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                loading..
                <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white"></div>
              </div>
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default EditSessionForm