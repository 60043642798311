import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
import Swal from "sweetalert2";

export const AddNewLessonFunc = createAsyncThunk(
    "add lesson func",
    async ({lessonsApi , lessonData}) => {
        try {
            const response = await axios.post(lessonsApi , lessonData );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
    }
);

const AddNewLessonSlicer = createSlice({
    name : "add lesson slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(AddNewLessonFunc.pending , (state) => {
            state.loading = true;
            state.data = null;
            state.error = null;
        });
        builder.addCase(AddNewLessonFunc.rejected , (state) => {
            state.loading = false;
            state.data = null;
            state.error = true;
        });
        builder.addCase(AddNewLessonFunc.fulfilled , (state , action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = false;
        });
    }
})
export default AddNewLessonSlicer.reducer;