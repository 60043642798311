import { useContext, useEffect, useState } from "react";
import { addNewCourseFunc } from "../../store/custom-actions/courses-actions/AddCourseSlicer";
import { fetchAllCoursesFunc } from "../../store/custom-actions/courses-actions/GetAllCoursesSlicer";
import { fetchUsersFunc } from "../../store/custom-actions/users-actions/GetUsersSlicer";
import { fetchAllCategoriesFunc } from "../../store/custom-actions/categories-actions/GetAllCategoriesSlicer";
import { useDispatch, useSelector } from "react-redux";
import ContextApi from "../../store/ContextApi";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import checkUserRole from "../global-functions/checkUserRole";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import defaultTeacherImg from "../../images/logo512.png"
import { TreeSelect } from "antd";
import { useTranslation } from "react-i18next";
const convertAllChildrenKey = (data) => {
  if (!Array.isArray(data)) return [];
  return data.map((item) => {
    const { name, id, all_children, ...rest } = item;
    return {
      ...rest,
      title: name,
      value: id,
      children: convertAllChildrenKey(all_children),
    };
  });
};

const TreeSelectExam = ({treeData , setParentCategryFunc}) => {
  const [value, setValue] = useState();
  const onChange = (newValue) => {
    console.log(newValue);
    setParentCategryFunc(newValue)
    setValue(newValue);
  };
  const modifiedTreeData = convertAllChildrenKey(treeData);

  return (
    <TreeSelect
      style={{
        width: '100%',
        height : "40px"
      }}
      value={value}
      dropdownStyle={{
        maxHeight: 400,
        overflow: 'auto',
      }}
      treeData={modifiedTreeData}
      placeholder="Please select"
      treeDefaultExpandAll
      onChange={onChange}
      treeDataSimpleMode={{
        id: 'value',
        pId: 'parentId',
        rootPId: null,
      }}
    />
  );
};
export default function NewCourseForm() {
    const {t} = useTranslation();
    const {courseType} = useParams();
    useEffect(() => {
        console.log(courseType)
    },[courseType])
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        watch,
        reset,
      } = useForm({
        defaultValues: {
          type: courseType === "offline" ? "offline" : "",
        },
      });
      const user = JSON.parse(window.localStorage.getItem("user"));
      const userRole = user && user.roleName;
      const contextApi = useContext(ContextApi);
      const { loading } = useSelector((state) => state.addNewCourseSlicer);
      const { loading: getCategoriesLoading, categoriesData } = useSelector(
        (state) => state.getAllCategoriesSlicer
      );
      const { loading: getUsersLoading, data: users } = useSelector(
        (state) => state.fetchUsersSlicer
      );
      const dispatch = useDispatch();
      const courseInstructor = watch("user_id");
      const [invalidCourseInstructor, setInvalidCourseInstructor] = useState();
      const [chooseInstructor, setChooseInstructor] = useState();
      const [instructorWhichChoosed, setInstructorWhichChoosed] = useState("");
      const categories_api = `${contextApi.dashboard_URL_API}/categories`;
      const [categoriesList, setCategoriesList] = useState([]);
      const courses_API_URL = `${contextApi.dashboard_URL_API}/courses`;
      const instructors_API_URL = `${contextApi.dashboard_URL_API}/users?role=teacher`;
      const [instructorsList, setInstructorsList] = useState([]);
      const [instructorWhichChoosedImg, setInstructorWhichChoosedImg] =
        useState("");
      const [courseDescription, setCourseDescription] = useState("");
      const coursesAPi = `${contextApi.dashboard_URL_API}/courses`;
      const [categoryId , setCategoryId] = useState(null);
      useEffect(() => {
        courseInstructor > 0
          ? setInvalidCourseInstructor(false)
          : setInvalidCourseInstructor(true);
      }, [courseInstructor]);
      useEffect(() => {
        const getCategoriesFunc = async () => {
          dispatch(fetchAllCategoriesFunc(`${categories_api}`));
        };
        getCategoriesFunc();
      }, [categories_api]);
    
      useEffect(() => {
        if (userRole && userRole.toLowerCase() === "manager") {
          const getAllInstructors = async () => {
            try {
              dispatch(fetchUsersFunc(instructors_API_URL));
            } catch (error) {
              console.log(error);
            }
          };
          getAllInstructors();
        }
      }, []);
    
      useEffect(() => {
        const instructors =
          users &&
          users.filter((user) => user.roles[0].name.toLowerCase() === "teacher");
        setInstructorsList(instructors);
      }, [users]);
    
      useEffect(() => {
        categoriesData && setCategoriesList(categoriesData);
      }, [categoriesData]);
    
      const handleSubmitData = (data) => {
        console.log(data)
        if (courseDescription !== "") {
          if(categoryId) {
            try {
              dispatch(
                addNewCourseFunc({
                  coursesApi: coursesAPi,
                  courseData: {
                    ...data,
                    type : "online",
                    category_id : categoryId,
                    description: courseDescription,
                    user_id: !data.user_id ? user.id : data.user_id,
                  },
                })
              ).then((result) => {
                if (result.payload.success === true) {
                  dispatch(fetchAllCoursesFunc(courses_API_URL));
                  reset();
                  setCourseDescription("")
                }
              });
            } catch (error) {
              console.log(error);
            }
          }else {
            toast.error(t("Category Must Not be Empty!"));
          }
        } else {
          toast.error(t("Course Description Is Required!"));
        }
      };
    
      const colors = [
        '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
        '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
    ];
    const modules = {
      toolbar: {
        container: [
          [{ 'font': [] }],
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ 'color': colors }, { 'background': colors }],
          [{ 'script': 'sub'}, { 'script': 'super' }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'indent': '-1'}, { 'indent': '+1' }],
          [{ 'direction': 'rtl' }],
          [{ 'align': [] }],
          ['blockquote', 'code-block'],
          ['link', 'image', 'video'],
          ['clean']
        ],
      }
    };
    const changeCategoryParetIdFunc = (id) => {
      setCategoryId(id)
    }
    return (
        <>
        <div className="row justify-content-center h-100 align-items-center" data-select2-id="15">
          <div className="col-xl-12 col-lg-12" data-select2-id="14">
             <div className="card" data-select2-id="13">
                <div className="card-header">
                   <h4 className="card-title">{t("New Course")}</h4>
                </div>
                <div className="card-body" data-select2-id="12">
                   <div className="basic-form" data-select2-id="11">
                    <form
                    id="new-course-form"
                    onSubmit={handleSubmit((data) => {
                        if (userRole && userRole.toLowerCase() === "manager") {
                        if (invalidCourseInstructor) {
                            Swal.fire(t("choose course instructor!"), "");
                        } else {
                            handleSubmitData(data);
                        }
                        } else {
                        handleSubmitData(data);
                        }
                    })}
                    >
                    <div className="row">
                        <div className="form-group mb-3 col-sm-12">
                        <label>{t("Course Name")}</label>
                        {errors.name?.message && (
                            <p className="text-red-600">{errors.name?.message}</p>
                        )}
                        <input
                            {...register("name", {
                            required: t("Course Name Is Required!"),
                            })}
                            type="text"
                            className="form-control"
                        />
                        </div>
                    </div>
                    <div className="row m-0">
                        <ReactQuill
                          modules={modules}
                          className="p-0 border-none outline-none form-control bg-white my-3"
                          value={courseDescription}
                          theme="snow"
                          onChange={setCourseDescription}
                        />
                    </div>
                    <div className="row">
                        {checkUserRole(
                        "manager",
                        <div className="form-group mb-3 col-sm-12 col-md-6 flex flex-col">
                            {getUsersLoading ? (
                            <p>{t("loading")}...</p>
                            ) : instructorsList && instructorsList.length >= 1 ? (
                            <div className="form-group mb-3 col-sm-12">
                                <label>{t("Course Instructor")}</label>
                                {errors.user_id?.message && (
                                <p className="text-red-600">
                                    {errors.user_id?.message}
                                </p>
                                )}
                                <span
                                className="relative flex items-center px-1 w-full h-[45px] rounded-md border border-[#7a4a4a] cursor-text"
                                onClick={() => setChooseInstructor(true)}
                                >
                                {instructorWhichChoosedImg !== "" && (
                                    <img
                                    src={
                                        instructorWhichChoosedImg !== null
                                        && instructorWhichChoosedImg
                                    }
                                    alt="instructor img"
                                    className="relative w-[20px] mr-2"
                                    />
                                )}
                                {instructorWhichChoosed}
                                </span>
                            </div>
                            ) : (
                            <div className="form-group flex items-center">
                                <p>{t("No Instructors")}</p>
                            </div>
                            )}
                            <div
                            className={`instructors-list overflow-y-auto transition-all duration-500 ${
                                chooseInstructor ? "h-[150px]" : "h-0"
                            }`}
                            >
                            {users &&
                                instructorsList &&
                                instructorsList.map((instructor) => {
                                return (
                                    <div
                                    key={instructor.id}
                                    className="input-div relative flex items-center my-1 p-2 pl-0"
                                    >
                                    <input
                                        {...register("user_id", {
                                        required: t("Course instructor is required"),
                                        })}
                                        form="new-course-form"
                                        id={instructor.name}
                                        type="radio"
                                        name="instructor-name"
                                        value={instructor.id}
                                        className=""
                                        data-name={instructor.name}
                                        onChange={(e) => {
                                        setValue("user_id", e.target.value);
                                        setInstructorWhichChoosed(
                                            e.target.dataset.name
                                        );
                                        setChooseInstructor(false);
                                        setInstructorWhichChoosedImg(
                                            instructor.image_url
                                        );
                                        }}
                                    />
                                    <label
                                        htmlFor={instructor.name}
                                        className="cursor-pointer mr-2 flex items-center"
                                    >
                                        <img
                                        src={
                                            instructor.image_url === null
                                            ? defaultTeacherImg
                                            :
                                            instructor.image_url
                                        }
                                        alt="img"
                                        className="w-[35px] h-[35px] mx-2 rounded-[50%]"
                                        />
                                        {instructor.name}
                                    </label>
                                    </div>
                                );
                                })}
                            </div>
                        </div>
                        )}
                        {
                        getCategoriesLoading ? (
                        <div className="form-group flex items-center">
                            <p>{t("loading")}...</p>
                        </div>
                        ) : categoriesData &&
                        categoriesList &&
                        categoriesList.length <= 0 ? (
                        <div className="form-group flex items-center">
                            <p>{t("No Categories")}</p>
                        </div>
                        ) : (
                        <div
                            className={`form-group mb-3 ${
                            userRole && userRole.toLowerCase() === "manager"
                                ? "col-sm-12 col-md-6"
                                : "col-sm-12"
                            }`}
                        >
                            <label>{t("course Category")}</label>
                            <TreeSelectExam treeData={categoriesList} setParentCategryFunc = {changeCategoryParetIdFunc}/>
                        </div>
                        )}
                    </div>
                    </form>
                    <div className="relative flex items-center">
                        <Button
                        onClick={() => {
                            window.history.back();
                        }}
                        >
                        {t("Back")}
                        </Button>
                        <Button type="submit" form="new-course-form" className="mx-2">
                        {!loading ? (
                            t("Submit")
                        ) : (
                            <>
                            <div className="relative flex justify-center items-center">
                                {t("loading")}..
                                <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white"></div>
                            </div>
                            </>
                        )}
                        </Button>
                    </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
        </>
    )
}