import "./ui-settings-page.css"
import ReactQuill from "react-quill";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ContextApi from "../../../store/ContextApi";
import { Button, Modal } from "react-bootstrap";
import { updateSliderFunc } from "../../../store/custom-actions/ui-settings-actions/UpdateSliderSlicer";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
function UpdateSliderForm(props) {
    const {t} = useTranslation();
    const {refetch_data , old_slider_data} = props;
    const contextApi = useContext(ContextApi);
    const ui_settings_URL_APi = `${contextApi.dashboard_URL_API}/settings/slider-images`;
    const dispatch = useDispatch();
    const [sliderId , setSliderId] = useState();
    const [sliderImg , setSliderImg] = useState();
    const [sliderMobileImg , setSliderMobileImg] = useState();
    const [sliderDescription , setSliderDescription] = useState("");
    const {register , setValue , formState : {errors} , handleSubmit , reset} = useForm();
    const {loading:updateSliderLoading} = useSelector(
        state => state.updateSliderSlicer
    );

    useEffect(() => {
        if(old_slider_data) {
            setSliderDescription(old_slider_data.description)
            setSliderImg(old_slider_data.image);
            setSliderMobileImg(old_slider_data.mobile_image);
            setSliderId(old_slider_data.id);
            old_slider_data.link ? setValue("link" , old_slider_data.link) : setValue("link" , "")
            old_slider_data.title ? setValue("title" , old_slider_data.title) : setValue("title" , "")
            old_slider_data.description ? setValue("description" , old_slider_data.description) : setValue("description" , "") 
        }
    },[old_slider_data , setValue])
  const handleUpdateSlider = async (data) => {
    if(data) {
        console.log(data)
        const sliderFormData = new FormData();
        sliderImg && sliderFormData.append("image" , sliderImg);
        sliderMobileImg && sliderFormData.append("mobile_image" , sliderMobileImg);
        sliderFormData.append("title" , data.title)
        sliderFormData.append("description" , data.description)
        sliderFormData.append("link" , data.link)
        sliderFormData.append("_method" , "PUT");
        dispatch(updateSliderFunc({api : `${ui_settings_URL_APi}/${sliderId}` , data : sliderFormData}))
        .then(result => {
            if(result.payload.success === true) {
                reset();
                setSliderDescription("");
                setSliderImg("");
                refetch_data();
                props.onHide();
            }
        })
        // if(sliderDescription !== "") {
        // }else {
        //     Swal.fire({
        //         title: t("Oops!"),
        //         text: t("Slider Description Shoud not be empty!"),
        //         icon: "warning"
        //     });
        // }
    }
}

    const imagesTypes = ['image/png', 'image/jpeg', 'image/gif', 'image/webp'];

    const checkImageSize = (e , setFunc) => {
        const image = e.target.files[0];
        const imageSize = image.size / 1024 / 1024;
        const maxSize = 100
        if(imageSize > maxSize) {
            Swal.fire({
                title: t("Size Validation!"),
                text: t("Max Size Of Slider Image Is 100 megabytes!"),
                icon: "warning"
            });
            e.target.value = ''
        }else {
            if(!imagesTypes.includes(image.type)) {
                Swal.fire({
                    title: t("Type Validation!"),
                    text: t("Please Choose a Valid Image Type!"),
                    icon: "warning"
                });
            }else {
                setFunc();
            }
        }
    }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter flex items-center">
          <i className="fa-brands fa-discourse mr-1"></i>
          {t("Update Slider")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="relative settings-form-content">
            <div className="new-lesson-form-div relative w-full ">
                <form 
                    className="update-slider-form"
                    id="update-slider-form"
                    onSubmit={handleSubmit((data) => {
                        handleUpdateSlider(data)
                    })}
                >
                    {
                        <>
                            <div className={'row'}>
                                <div className="form-group logo-input mb-3 col-sm-12 col-md-6">
                                    <label className="flex items-center">
                                        <i className="fa-brands fa-cuttlefish mr-1"></i>
                                        {t("Title")}
                                    </label>
                                    <input
                                        {...register("title")}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                    <label className="flex items-center">
                                        <i className="fa-solid fa-link mr-1"></i>
                                        {t("Path Link")}
                                    </label>
                                    <input
                                    {...register("link")}
                                    type="text"
                                    className="form-control"
                                    />
                                </div>
                            </div>
                            <div className={`row`}>
                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                    <label className="flex items-center">
                                        <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                        {t("Slider Image")}
                                    </label>
                                    <input
                                    type="file"
                                    className="form-control"
                                    onChange={(e) => {
                                        checkImageSize(
                                            e , 
                                            () => setSliderImg(e.target.files[0]),
                                        )
                                    }}
                                    />
                                </div>
                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                    <label className="flex items-center">
                                        <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                        {t("Slider Mobile Image")}
                                    </label>
                                    <input
                                    type="file"
                                    className="form-control"
                                    onChange={(e) => {
                                        checkImageSize(
                                            e , 
                                            () => setSliderMobileImg(e.target.files[0]),
                                        )
                                    }}
                                    />
                                </div>
                            </div>
                            <div className="slider-description-line mb-3 relative my-3 flex justify-center items-center after:absolute after:left-[50%] after:translate-x-[-50%] after:top-[50%] after:px-2 after:bg-[#eaebef]  after:translate-y-[-50%] bg-slate-300 w-full h-[1px]"></div>
                            {/* <div className="row">
                                <ReactQuill 
                                    modules = {modules}
                                    className="p-0 border-none outline-none form-control bg-white mt-3" 
                                    value={sliderDescription} theme="snow" 
                                    onChange={(content) => setSliderDescription(content)} />
                            </div> */}
                                    <div className="row m-0 p-0">
                                        <div className="form-group mb-3 col-sm-12">
                                            <label className="flex items-center">
                                                <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                {t("Slider Description")}
                                            </label>
                                            <textarea
                                                {...register("description")}
                                                // value={sliderDescription}
                                                className="form-control col-sm-12"
                                                // onChange={(e) => setSliderDescription(e.target.value)}
                                            >
                                            </textarea>
                                        </div>
                                        {/* <ReactQuill 
                                        modules = {modules}
                                        className="p-0 border-none outline-none form-control bg-white mt-3" 
                                        value={sliderDescription} theme="snow" 
                                        onChange={(content) => setSliderDescription(content)} /> */}
                                    </div>
                        </>
                    }
                </form>
            </div>
            </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>{
          props.onHide();
          reset();
        }}>Close</Button>
        <Button type="submit" form="update-slider-form">
          {!updateSliderLoading ? (
            "Update"
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                loading..
                <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white"></div>
              </div>
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default UpdateSliderForm;