import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const switchLanguageFunc = createAsyncThunk(
    "languageSettings/switch",
    async (slug) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_VERSEL_API}?lang=${slug}`);
            // const response = await axios.get(`https://apis-lms.topgrowth-eg.com?lang=${slug}`);
            // const response = await axios.get(`https://apis.dracademyeg.com?lang=${slug}`);
            // const response = await axios.get(`https://api.warkawkalm.net?lang=${slug}`);
            return response.data.data
        }
        catch(error) {
            checkErrors(error.response , error.message);
            return error
        }
    }
)
const switchLanguageSlicer = createSlice({
    name : "languageSettings/switch",
    initialState : {
        loading : false,
        data : null,
        error : false
    },
    extraReducers : builder => {
        builder.addCase(switchLanguageFunc.pending , (state) => {
            state.loading = true
            state.data = null
            state.error = false
        })
        builder.addCase(switchLanguageFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = false
        })
        builder.addCase(switchLanguageFunc.rejected , (state) => {
            state.loading = false
            state.data = null
            state.error = true
        })
    }
})
export default switchLanguageSlicer.reducer;



// size / 1048576