import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import Swal from "sweetalert2";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const postAttempGradeFunc = createAsyncThunk(
    "post attemp grade func",
    async ({attempApi , attempData}) => {
        try {
            const response = await axios.put(attempApi , attempData );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            // Swal.fire({
            //     title: "Oops!",
            //     text: error,
            //     icon: "error"
            // });
            checkErrors(error.response , error.message);
        }
    }
);

const postAttempGradeSlicer = createSlice({
    name : "post attemp grade slicer",
    initialState : {
        loadingpostAttempGrade : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(postAttempGradeFunc.pending , (state) => {
            state.loadingpostAttempGrade = true;
            state.data = null;
            state.error = null;
        });
        builder.addCase(postAttempGradeFunc.rejected , (state) => {
            state.loadingpostAttempGrade = false;
            state.data = null;
            state.error = true;
        });
        builder.addCase(postAttempGradeFunc.fulfilled , (state , action) => {
            state.loadingpostAttempGrade = false;
            state.data = action.payload;
            state.error = false;
        });
    }
})
export default postAttempGradeSlicer.reducer;