import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import Swal from "sweetalert2";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const addNewCategoryFunc = createAsyncThunk(
    "add new category function",
    async ({categoryApi , categoryData}) => {
        const config = {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
            },
        }
        try {
            const response = await axios.post(categoryApi , categoryData , config);
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            // Swal.fire({
            //     title: "Oops!",
            //     text: error,
            //     icon: "error"
            // });
            checkErrors(error.response , error.message);
        }
        
    }
)

const addCategorySlicer = createSlice({
    name : "add new category slicer",
    initialState : {
        addCategoryLoading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(addNewCategoryFunc.pending , (state) => {
            state.addCategoryLoading = true
            state.data = {}
            state.error = false
        });
        builder.addCase(addNewCategoryFunc.rejected , (state , action) => {
            state.addCategoryLoading = false
            state.data = action.payload
            state.error = true
        });
        builder.addCase(addNewCategoryFunc.fulfilled , (state , action) => {
            state.addCategoryLoading = false
            state.data = action.payload
            state.error = false
        });
    }
});

export default addCategorySlicer.reducer;