import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const addNewQuestionFunc = createAsyncThunk(
    "add new question function",
    async ({api , QuestionData}) => {
        try {
            const response = await axios.post(api , QuestionData );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            checkErrors(error.response , error.message);
            return error
        }
        
    }
);

const addQuestionSlicer = createSlice({
    name : "add new question slicer",
    initialState : {
        addQuestionLoading : null,
        data : [],
        error : false
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(addNewQuestionFunc.pending , (state)=>{
            state.addQuestionLoading = true
            state.data = []
            state.error = null
        })
        builder.addCase(addNewQuestionFunc.rejected , (state)=>{
            state.addQuestionLoading = false
            state.data = []
            state.error = true
        })
        builder.addCase(addNewQuestionFunc.fulfilled , (state , action)=>{
            state.addQuestionLoading = false
            state.data = action.payload
            state.error = null
        })
    }
});
export default addQuestionSlicer.reducer;