import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import Swal from "sweetalert2";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const approveCourseFunc = createAsyncThunk(
    "approve course function",
    async ({coursesApi}) => {
        try {
            const response = await axios.put(coursesApi , {publish : "publish"} );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            // Swal.fire({
            //     title: "Oops!",
            //     text: error,
            //     icon: "error"
            // });
            checkErrors(error.response , error.message);
        }
        
    }
)

const approveCourseSlicer = createSlice({
    name : "approve course slicer",
    initialState : {
        approve_course_loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(approveCourseFunc.pending , (state) => {
            state.approve_course_loading = true
            state.data = {}
            state.error = false
        });
        builder.addCase(approveCourseFunc.rejected , (state) => {
            state.approve_course_loading = false
            state.data = {}
            state.error = true
        });
        builder.addCase(approveCourseFunc.fulfilled , (state , action) => {
            state.approve_course_loading = false
            state.data = action.payload
            state.error = false
        });
    }
});

export default approveCourseSlicer.reducer;