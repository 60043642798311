import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import Swal from "sweetalert2";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const readMessageFunc = createAsyncThunk(
    "read message function",
    async (messageApi) => {
        try {
            const response = await axios.put(messageApi , {} , {
                withCredentials : true
            })
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            // Swal.fire({
            //     title: "Oops!",
            //     text: error,
            //     icon: "error"
            // });
            checkErrors(error.response , error.message);
        }
        
    }
)

const readMessageSlicer = createSlice({
    name : "read message slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(readMessageFunc.pending , (state) => {
            state.loading = true
            state.data = {}
            state.error = false
        });
        builder.addCase(readMessageFunc.rejected , (state) => {
            state.loading = false
            state.data = {}
            state.error = true
        });
        builder.addCase(readMessageFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = false
        });
    }
});

export default readMessageSlicer.reducer;