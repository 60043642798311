import { Link } from "react-router-dom";
import notFoundImg from "../../../media/images/not-found-page.png"
import { useTranslation } from "react-i18next";
function NotFoundPage() {
    const {t} = useTranslation();
    return (
        <>
        
        <div className="page">
            <div className="container flex flex-col justify-content-center items-center">
                <img src={notFoundImg} alt="not found img" className="w-[300px]" />
                <p className="flex items-center">{t("Oops, Can not found page")}
                    <span 
                    className="text-orange-700 cursor-pointer ml-1"
                    onClick={() => window.history.back()}
                    >
                    {t("Back")}
                    </span>
                </p>
            </div>
        </div>
        
        </>
    )
}
export default NotFoundPage;