import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const getQuizeAttemptsFunc = createAsyncThunk(
    "get quize attempts function",
    async (api) => {
        try {
            const response = await axios.get(api );
            checkErrorsFunctions(response , response.data.errors);
            return response.data.data.attempts;
        }
        catch (error) {
            // Swal.fire({
            //     title: "Oops!",
            //     text: error,
            //     icon: "error"
            // });
            checkErrors(error.response , error.message);
        }
        
    }
)

const getQuizeAttemptsSlicer = createSlice({
    name : "get quize attempts slicer",
    initialState : {
        quizeAttemptsloading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(getQuizeAttemptsFunc.pending , (state) => {
            state.quizeAttemptsloading = true
            state.data = false
            state.error = false
        });
        builder.addCase(getQuizeAttemptsFunc.rejected , (state) => {
            state.quizeAttemptsloading = false
            state.data = null
            state.error = true
        });
        builder.addCase(getQuizeAttemptsFunc.fulfilled , (state , action) => {
            state.quizeAttemptsloading = false
            state.data = action.payload
            state.error = null
        });
    }
});

export default getQuizeAttemptsSlicer.reducer;