import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const PageTitle = ({ motherMenu, activeMenu , customClasses , thirdActiveMenu , navigateFunc , motherPath}) => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  const {data:languageData} = useSelector(
		state => state.getLanguageSettingsSlicer
	)
  return (
    <div className={`row page-titles mx-0 ${customClasses && customClasses}`}>
			<ol className="breadcrumb">
				<li className="breadcrumb-item px-1 active before:hidden after:hidden"><Link to={motherPath ? motherPath : `#`}>{t(motherMenu)}</Link></li>
        {" "}
        {
          languageData?.defaultLang?.slug === "ar"
          ?
          "\\"
          :
          "/"
        }
				<li className="breadcrumb-item px-1 before:hidden after:hidden"><Link to="#" onClick={()=>{
				}}>{t(activeMenu)}</Link></li>
        {
          thirdActiveMenu
          &&
          <>
            {
              languageData?.defaultLang?.slug === "ar"
              ?
              "\\"
              :
              "/"
            }
            <li className="breadcrumb-item before:hidden after:hidden"><Link to="#" onClick={()=>{
            }}>{t(thirdActiveMenu)}</Link></li>
          </>
        }
			</ol>
    </div>
  );
};
export default PageTitle;
