import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getChaptersFunc } from "../../../store/custom-actions/chapters/GetChaptersSlicer";
import ContextApi from "../../../store/ContextApi";
import { addChapterFunc } from "../../../store/custom-actions/chapters/AddChapterSlicer";
import { TreeSelect } from 'antd';
import { useTranslation } from "react-i18next";
const convertAllChildrenKey = (data) => {
  if (!Array.isArray(data)) return [];
  return data.map((item) => {
    const { name, id, all_children, ...rest } = item;
    return {
      ...rest,
      title: name,
      value: id,
      children: convertAllChildrenKey(all_children),
    };
  });
};
const TreeSelectExam = ({treeData , changeParentId}) => {
  const [value, setValue] = useState();
  const onChange = (newValue) => {
    console.log(newValue);
    changeParentId(newValue)
    setValue(newValue);
  };
  const modifiedTreeData = convertAllChildrenKey(treeData);

  return (
    <TreeSelect
      style={{
        width: '100%',
      }}
      value={value}
      dropdownStyle={{
        maxHeight: 400,
        overflow: 'auto',
      }}
      treeData={modifiedTreeData}
      placeholder="Please select"
      treeDefaultExpandAll
      onChange={onChange}
      treeDataSimpleMode={{
        id: 'value',
        pId: 'parentId',
        rootPId: null,
      }}
    />
  );
};
export default function NewChapterForm() {
    const {t} = useTranslation()
    const {courseId} = useParams();
    useEffect(() => {
        console.log(courseId)
    },[courseId])
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
      } = useForm();
      const user = JSON.parse(window.localStorage.getItem("user"));
      const userRole = user && user.roleName;
      const contextApi = useContext(ContextApi);
      const { loading:addChapterLoading } = useSelector((state) => state.addChapterSlicer);
      const { loading: getChaptersLoading, data:chaptersData } = useSelector(
        (state) => state.getChaptersSlicer
      );
      const dispatch = useDispatch();
      const [chaptersList, setChaptersList] = useState([]);
      const courses_API_URL = `${contextApi.dashboard_URL_API}/courses`;
      const [parentChapterId, setParentChapterId] = useState("");
      const [description, setDescription] = useState("");
      const chaptersApi = `${contextApi.dashboard_URL_API}/courses/${courseId}/chapters`;
      useEffect(() => {
        const getChapters = async () => {
          dispatch(getChaptersFunc(`${chaptersApi}`));
        };
        getChapters();
      }, [chaptersApi]);
      useEffect(() => {
        chaptersData && setChaptersList(chaptersData);
      }, [chaptersData]);
    
      const handleSubmitData = (data) => {
        // console.log(data)
        // if (description !== "") {
          try {
            dispatch(
              addChapterFunc({
                api: chaptersApi,
                data: {
                  ...data,
                  description: description,
                  ...(parentChapterId && {parent_id: parentChapterId})
                },
              })
            ).then((result) => {
              if (result.payload.success === true) {
                dispatch(getChaptersFunc(`${chaptersApi}`));
                reset();
                setDescription("");
              }
            });
          } catch (error) {
            toast.error(error);
          }
        // } else {
        //   toast.error(t("Course Description Is Required!"));
        // }
      };
    
      const colors = [
        '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
        '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
    ];
    const modules = {
        toolbar: {
        container: [
        [{"color" : colors}],
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'align': [] }],
        ['clean']
        ],
    }
    };
    // const attendance = watch("type");
    useEffect(() => chaptersList && console.log(chaptersList), [ chaptersList])
    const changeParentId = (id) => {
      setParentChapterId(id)
    }
    return (
        <>
        <div className="row justify-content-center h-100 align-items-center" data-select2-id="15">
          <div className="col-sm-12" data-select2-id="14">
             <div className="card" data-select2-id="13">
                <div className="card-header">
                   <h4 className="card-title">{t("New Chapter")}</h4>
                </div>
                <div className="card-body" data-select2-id="12">
                   <div className="basic-form" data-select2-id="11">
                    <form
                    id="new-chapter-form"
                    onSubmit={handleSubmit((data) => {
                        handleSubmitData(data)
                    })}
                    >
                    <div className="row">
                        <div className="form-group mb-3 col-sm-12 col-md-6">
                        <label>{t("Name")}</label>
                        {errors.name?.message && (
                            <p className="text-red-600">{errors.name?.message}</p>
                        )}
                        <input
                            {...register("name", {
                            required: t("Name Is Required"),
                            })}
                            type="text"
                            className="form-control"
                        />
                        </div>
                        <div className="form-group mb-3 col-sm-12 col-md-6">
                        <label>{t("Order")}</label>
                        {errors.order?.message && (
                            <p className="text-red-600">{errors.order?.message}</p>
                        )}
                        <input
                            {...register("order", {
                            required: t("Order Is Required"),
                            })}
                            type="number"
                            className="form-control"
                        />
                        </div>
                    </div>
                    <div className="row m-0">
                        <ReactQuill
                          modules={modules}
                          className="p-0 border-none outline-none form-control bg-white my-3"
                          value={description}
                          theme="snow"
                          onChange={setDescription}
                        />
                    </div>
                    <div className="row">
                      <div className="form-group mb-3 col-sm-12">
                      {
                          getChaptersLoading ? (
                              <div className="form-group flex items-center">
                                  <p>{t("loading")}...</p>
                              </div>
                          ) : chaptersData &&
                              chaptersList &&
                              chaptersList.length <= 0 ? (
                              <div className="form-group flex items-center">
                                  <p>{t("No Chapters")}</p>
                              </div>
                          ) : (
                              <div
                                  className={`form-group mb-3 col-sm-12`}
                              >
                              <label>{t("Chapter")}</label>
                              <TreeSelectExam treeData={chaptersList} changeParentId = {changeParentId}/>
                              </div>
                          )
                      }
                      </div>
                    </div>
                    </form>
                    <div className="relative flex items-center justify-end">
                        <Button
                        onClick={() => {
                            window.history.back();
                        }}
                        >
                        {t("Back")}
                        </Button>
                        <Button type="submit" form="new-chapter-form" className={`mx-2 ${addChapterLoading ? "pointer-events-none opacity-75" : ""}`}>
                        {!addChapterLoading ? (
                            t("Submit")
                        ) : (
                            <>
                            <div className="relative flex justify-center items-center">
                                {t("loading")}..
                                <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white"></div>
                            </div>
                            </>
                        )}
                        </Button>
                    </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
        </>
    )
}