import "./instructors-page.css";
import { Button, Spinner, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import { fetchSingleUserFunc } from "../../../store/custom-actions/users-actions/GetSingleUserSlicer";
import ContextApi from "../../../store/ContextApi";
import { useContext } from "react";
import axios from "axios";
import faildImg from "../../../media/images/fail-img.svg";
import reward1 from "../../../media/images/achivement-1.png";
import reward2 from "../../../media/images/achivement-2.png";
import reward3 from "../../../media/images/star.png";
import reward4 from "../../../media/images/achivement.png";
import reward5 from "../../../media/images/trophey.png";
import coursesIcon from "../../../media/images/course-img.png";
import UserCourseBox from "./UserCourseBox";
import { Link , useParams} from "react-router-dom";
import UserCoursesChart from "./UserCoursesChart";
import { editUserImageFunc } from "../../../store/custom-actions/users-actions/UpdateUserImageSlicer";
import correctIcon from "../../../media/images/checklist.png"
import warningIcon from "../../../media/images/warning.png"
import { approveTeacherFunc } from "../../../store/custom-actions/users-actions/ApproveTeacherSlicer";
import { unApproveTeacherFunc } from "../../../store/custom-actions/users-actions/UnApproveTeacherSlicer";
import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
import { useTranslation } from "react-i18next";
import { deleteUserFunc } from "../../../store/custom-actions/users-actions/DeleteUserSlicer";
import PageTitle from "../../layouts/PageTitle";
import { useForm } from "react-hook-form";
import checkPermissions from "../../global-functions/checkPermissionsFunc";
import CourseCard from "../courses-page/CustomCourseCard";
import coverBG from "../../../images/blue-globe-rests-open-book-symbolizing-education-learning-knowledge-exploration_924727-64713.webp"
import checkUserRole from "../../global-functions/checkUserRole";
// descion drop menu
function DecisionsDropMenu(props) {
  const {t} = useTranslation();
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          as="a"
          className="btn-link i-false btn sharp tp-btn-light btn-dark"
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0012 9.86C11.6544 9.86 11.3109 9.92832 10.9905 10.061C10.67 10.1938 10.3789 10.3883 10.1336 10.6336C9.88835 10.8788 9.6938 11.17 9.56107 11.4905C9.42834 11.8109 9.36002 12.1544 9.36002 12.5012C9.36002 12.848 9.42834 13.1915 9.56107 13.5119C9.6938 13.8324 9.88835 14.1236 10.1336 14.3688C10.3789 14.6141 10.67 14.8086 10.9905 14.9413C11.3109 15.0741 11.6544 15.1424 12.0012 15.1424C12.7017 15.1422 13.3734 14.8638 13.8687 14.3684C14.3639 13.873 14.642 13.2011 14.6418 12.5006C14.6417 11.8001 14.3632 11.1284 13.8678 10.6332C13.3724 10.138 12.7005 9.85984 12 9.86H12.0012ZM3.60122 9.86C3.25437 9.86 2.91092 9.92832 2.59048 10.061C2.27003 10.1938 1.97887 10.3883 1.73361 10.6336C1.48835 10.8788 1.2938 11.17 1.16107 11.4905C1.02834 11.8109 0.960022 12.1544 0.960022 12.5012C0.960022 12.848 1.02834 13.1915 1.16107 13.5119C1.2938 13.8324 1.48835 14.1236 1.73361 14.3688C1.97887 14.6141 2.27003 14.8086 2.59048 14.9413C2.91092 15.0741 3.25437 15.1424 3.60122 15.1424C4.30171 15.1422 4.97345 14.8638 5.46866 14.3684C5.96387 13.873 6.24198 13.2011 6.24182 12.5006C6.24166 11.8001 5.96324 11.1284 5.46781 10.6332C4.97237 10.138 4.30051 9.85984 3.60002 9.86H3.60122ZM20.4012 9.86C20.0544 9.86 19.7109 9.92832 19.3905 10.061C19.07 10.1938 18.7789 10.3883 18.5336 10.6336C18.2884 10.8788 18.0938 11.17 17.9611 11.4905C17.8283 11.8109 17.76 12.1544 17.76 12.5012C17.76 12.848 17.8283 13.1915 17.9611 13.5119C18.0938 13.8324 18.2884 14.1236 18.5336 14.3688C18.7789 14.6141 19.07 14.8086 19.3905 14.9413C19.7109 15.0741 20.0544 15.1424 20.4012 15.1424C21.1017 15.1422 21.7734 14.8638 22.2687 14.3684C22.7639 13.873 23.042 13.2011 23.0418 12.5006C23.0417 11.8001 22.7632 11.1284 22.2678 10.6332C21.7724 10.138 21.1005 9.85984 20.4 9.86H20.4012Z"
              fill="#A098AE"
            />
          </svg>
        </Dropdown.Toggle>
        <Dropdown.Menu
          className="dropdown-menu dropdown-menu-end"
          align="right"
        >
          <Dropdown.Item
            onClick={() => {
              swal({
                title: t("Confirm User Deletion"),
                text: t(`Once deleted, will not be user in platform!`),
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  props.deleteUserFunc();
                }
              });
            }}
          >
            {t("Delete")}
          </Dropdown.Item>
          {
            props.userImg !== ""
            &&
          <Dropdown.Item
            onClick={() => {
              props.removeUserImg();
            }}
          >
            {t("Remove Photo")}
          </Dropdown.Item>
          }
          {
            props.teacher_status === "publish"
            ?
          <Dropdown.Item
            onClick={() => {
              props.un_approve_user();
            }}
          >
            {t("Un Active")}
          </Dropdown.Item>
          :
          <Dropdown.Item
          onClick={() => {
            props.approve_user();
          }}
        >
          {t("Active")}
        </Dropdown.Item>
          }
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
}

// function UserProfilePage() {
  // const {t} = useTranslation();
  // const dispatch = useDispatch();
  // const contextApi = useContext(ContextApi);
  // const params = useParams();
  // const all_users_URL_APi = `${contextApi.dashboard_URL_API}/users`;
  // const user_URL_APi = `${all_users_URL_APi}/${params.userId}`;
  // // const user_URL_APi = `${all_users_URL_APi}/${params.userId}`;
  // const { loading, userData } = useSelector(
  //   (state) => state.getSingleUserSlicer
  // );
  // const { approve_teacher_loading } = useSelector(
  //   (state) => state.approveTeacherSlicer
  // );
  // const { unapprove_teacher_loading } = useSelector(
  //   (state) => state.unApproveTeacherSlicer
  // );
  // const {loading:deleteUserLoading} = useSelector(state => state.deleteUserSlicer)
  // const [sendMessage, setSendMessage] = useState();
  // const [showPhone, setShowPhone] = useState(false);
  // const [userCourses, setUserCourses] = useState([]);
  // const [userImg, setUserImg] = useState("");
  // const [userImage , setUserImage] = useState("https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?w=740&t=st=1702935704~exp=1702936304~hmac=0cf7808c31a16122cc1e8bfbd1b2e33c4f86a93e80af95f3eba31a9428f2ea2c");

  // useEffect(() => {
  //   const getUserData = async () => {
  //     dispatch(fetchSingleUserFunc(user_URL_APi))
  //   };
  //   const getUserCourses = async () => {
  //     await axios
  //       .get(user_URL_APi )
  //       .then(response => {
  //         console.log(response)
  //         if(response.data.data.courses) {
  //           setUserCourses(response.data.data.courses)
  //         }
  //       })
  //   };
  //   getUserData();
  //   getUserCourses();
  // }, []);

  // const removeUserImg = () => {
  //   setUserImg("");
  // };
  // const deleteUser = async () => {
  //   dispatch(deleteUserFunc(user_URL_APi))
  //   .then(result => {
  //     result?.payload?.success && window.history.back();
  //   })
  // };

  // useEffect(() => {
  //   userData && userData.image_url !== null && setUserImage(userData.image_url)
  // },[userData])


  // const handleUploadUserImage = (image) => {
  //   if(image){
  //     const imageForm = new FormData();
  //     imageForm.append("image" , image);
  //     // imageForm.append("_method" , "PUT");
  //     dispatch(editUserImageFunc(
  //       {user_api : `${all_users_URL_APi}/upload-image` , image : imageForm}
  //     ))
  //     .then((result) => {
  //       if(result.payload.message === "The Image has been uploaded successfully"){
  //         dispatch(fetchSingleUserFunc(user_URL_APi));
  //       //   swal({
  //       //     title: "Done!",
  //       //     text: `User image uploaded Successfully !`,
  //       //     icon: "success",
  //       //     button: "Done",
  //       //   });
  //       // }else {
  //       //   swal({
  //       //     title: "Oops!",
  //       //     text: `Failed to Update user image. Please Retry !`,
  //       //     icon: "warning",
  //       //     button: "Done",
  //       //   });
  //       }
  //     })
  //   }
  // }

  //     // for approve lessons
  //     const approve_teacher_api = `${contextApi.dashboard_URL_API}/users/${userData && userData.id}/approve`;
  //     const approveUser = async () => {
  //         // if(userData){
  //             await dispatch(approveTeacherFunc({teacherApi: approve_teacher_api}))
  //             .then(result => {
  //                 if(result.payload.success === true){
  //                     dispatch(fetchSingleUserFunc(user_URL_APi))
  //                 }
  //             })
  //         // }
  //     }
  //     const unApproveUser = () => {
  //           swal({
  //             title: t("Confirm User Un Active"),
  //             text: t("Once un active,  will not be active in platform!"),
  //             icon: "warning",
  //             buttons: true,
  //             dangerMode: true,
  //           }).then((willDelete) => {
  //             if (willDelete) {
  //               dispatch(unApproveTeacherFunc({teacherApi: approve_teacher_api}))
  //               .then(result => {
  //                   if(result.payload.success === true){
  //                       dispatch(fetchSingleUserFunc(user_URL_APi));
  //                   }
  //               })
  //             }
  //           });

  //     }
//   return (
//     <>
//       {loading ? (
//         <>
//           <div className="row d-flex justify-content-center gap-2">
//             <Spinner animation="grow" className="bg-primary" />
//             <Spinner animation="grow" className="bg-primary" />
//             <Spinner animation="grow" className="bg-primary" />
//             <Spinner animation="grow" className="bg-primary" />
//           </div>
//         </>
//       ) : 
//       userData 
//       ? 
//       (
//         <div className="prfile-page flex flex-col lg:flex-row justify-between items-start">
//           <div className="relative flex flex-col col-sm-12 col-md-12 col-lg-6">
//             <div className="image-and-paio relative flex py-5 h-fit items-center flex-col rounded-md col-sm-12 col-md-12 px-4 bg-white sm:mb-3">
//               <div className="absolute top-[10px] right-[10px]">
//                 {/* {userRole === "manger" && ( */}
//                   <DecisionsDropMenu
//                     userImg = {userImg}
//                     removeUserImg={removeUserImg}
//                     deleteUserFunc={deleteUser}
//                     teacher_status = {userData && userData.publish}
//                     approve_user = {approveUser}
//                     un_approve_user = {()=>unApproveUser()}
//                   />
//                 {/* )} */}
//               </div>
//               <div className="absolute top-[10px] left-[10px] flex items-center">
//                   <img src={userData && userData.publish === "publish" ? correctIcon : warningIcon} alt="status img" className="w-[20px] mr-1"/>
//                   {userData && userData.publish === "publish" ? <p className="m-0 text-slate-700">{t("Active")}</p> : <p className="m-0 text-slate-700">{t("Not Active")}</p>} 
//                 </div>
//               <div className="user-img relative w-[100px] h-[100px] rounded-md mb-4">
//                 <img
//                   src={userImage}
//                   alt="user-img"
//                   className="absolute w-full h-full top-0 left-0 rounded-md"
//                 />
//                 <label htmlFor="user-img-input">
//                   <i className="fa-solid fa-camera bg-slate-200 absolute left-[50%] translate-x-[-50%] text-black rounded-[50%] bottom-[-17px] cursor-pointer text-[18px] p-2"></i>
//                 </label>
//                 <input
//                   type="file"
//                   id="user-img-input"
//                   className="hidden"
//                   onChange={(e) => {
//                     handleUploadUserImage(e.target.files[0])
//                   }}
//                 />
//               </div>
//               <div className="relative flex flex-col justify-center items-center my-3">
//                 <h4>{userData.name}</h4>
//                 <p>{t("Memper Since")} {userData.created_at.slice(0, 4)}</p>
//               </div>
//               <div className="contact-div relative flex">
//                 <Link
//                   to={`mailto:${userData.emai}`}
//                   className="btn btn-primary relative show-phone-btn ml-4"
//                   // onClick={() => {
//                   //   setSendMessage(true);
//                   // }}
//                 >
//                   <span className="absolute top-[-20px] bg-red-500 left-[50%] rounded-md py-1 px-2 transition-all w-fit duration-300 opacity-0 text-[10px] translate-x-[-50%]">
//                     {t("send to")} {userData && userData.email}
//                   </span>
//                   {t("Message")}
//                 </Link>
//                 <Link
//                   to={`https://wa.me/+2${userData.phone}`}
//                   target="_blank"
//                   className="btn btn-primary relative show-phone-btn ml-4"
//                   onClick={() => {
//                     setShowPhone(!showPhone);
//                   }}
//                 >
//                   <span className="absolute top-[-20px] bg-red-500 left-[50%] rounded-md py-1 px-2 transition-all duration-300 opacity-0 text-[10px] translate-x-[-50%]">
//                     {userData && userData.phone}
//                   </span>
//                   {t("Phone")}
//                 </Link>
//               </div>
//               <div className="achivement-div w-full flex flex-col my-4">
//                 <h4>{t("Achievment")}</h4>
//                 <div className="achive-boxes relative flex flex-wrap my-3">
//                   <div className="achive-box relative flex justify-center items-center bg-slate-100 mr-2 rounded-md transition-all duration-300 w-[50px] h-[50px]">
//                     <img
//                       src={reward1}
//                       alt="achive img"
//                       className="transition-all duration-[1.5s] opacity-70 hover:opacity-100 hover:rotate-[360deg] w-[30px]"
//                     />
//                   </div>
//                   <div className="achive-box relative flex justify-center items-center bg-slate-100 mr-2 rounded-md transition-all duration-300 w-[50px] h-[50px]">
//                     <img
//                       src={reward2}
//                       alt="achive img"
//                       className="transition-all duration-[1.5s] opacity-70 hover:opacity-100 hover:rotate-[360deg] w-[30px]"
//                     />
//                   </div>
//                   <div className="achive-box relative flex justify-center items-center bg-slate-100 mr-2 rounded-md transition-all duration-300 w-[50px] h-[50px]">
//                     <img
//                       src={reward3}
//                       alt="achive img"
//                       className="transition-all duration-[1.5s] opacity-70 hover:opacity-100 hover:rotate-[360deg] w-[30px]"
//                     />
//                   </div>
//                   <div className="achive-box relative flex justify-center items-center bg-slate-100 mr-2 rounded-md transition-all duration-300 w-[50px] h-[50px]">
//                     <img
//                       src={reward4}
//                       alt="achive img"
//                       className="transition-all duration-[1.5s] opacity-70 hover:opacity-100 hover:rotate-[360deg] w-[30px]"
//                     />
//                   </div>
//                   <div className="achive-box relative flex justify-center items-center bg-slate-100 mr-2 rounded-md transition-all duration-300 w-[50px] h-[50px]">
//                     <img
//                       src={reward5}
//                       alt="achive img"
//                       className="transition-all duration-[1.5s] opacity-70 hover:opacity-100 hover:rotate-[360deg] w-[30px]"
//                     />
//                   </div>
//                 </div>
//               </div>
//               <div className="paio-div w-full relative flex flex-col">
//                 <h4>{t("Baio")}</h4>
//                 <p>
//                   {userData?.baio}
//                 </p>
//               </div>
//             </div>
//           </div>
//           {
//             userData
//             &&
//             userData.roles
//             &&
//             userData.roles[0].name
//             &&
//             userData.roles[0].name.toLowerCase() === "teacher"
//             &&
//           <div className="relative flex flex-col col-sm-12 col-lg-5">
//             <div className="courses-count-and-other flex flex-col md:flex-row justify-between col-sm-12 col-md-12">
//               <div className="card bg-secondary col-sm-12 sm:my-3 md:my-0 md:mb-3">
//                 <div className="card-body">
//                   <div className="student-box d-flex justify-content-between align-items-center flex-wrap">
//                     <div>
//                       <span className="courses-icon">
//                         <img
//                           src={coursesIcon}
//                           alt="courses icon"
//                           className="w-[50px]"
//                         />
//                       </span>
//                       <h4>{userCourses.length}</h4>
//                       <span className="pb-3 d-block">{t("Total Courses")}</span>
//                     </div>
//                     <UserCoursesChart />
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="courses-content relative flex flex-col col-sm-12">
//               {userCourses ? (
//                 userCourses.map((course) => {
//                   return (
//                     <UserCourseBox
//                       key={course.id}
//                       courseId={course.id}
//                       courseImg={course.image_url}
//                       courseName={course.name}
//                       courseDesc={course.description}
//                     />
//                   );
//                 })
//               ) : (
//                 <h2>{t("this instructor no have any courses yet!")}</h2>
//               )}
//             </div>
//           </div>
//           }
//         </div>
//       ) 
//       : 
//       (
//         <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
//           <img src={faildImg} alt="faild img" className="w-[150px] mb-3" />
//           <p>
//             {t("No data available")}
//             <span
//               className="text-[#4d5fe3] cursor-pointer"
//               onClick={() => dispatch(fetchSingleUserFunc(user_URL_APi))}
//             >
//               {t("Try again")}
//             </span>
//           </p>
//         </div>
//       )}
//       {/* send message form */}
//       <Modal className="modal fade" show={sendMessage}>
//         <div className="modal-content">
//           <div className="modal-header">
//             <h5 className="modal-title">
//               {t("Send Message to")} {userData && userData.name}
//             </h5>
//             <Button
//               variant=""
//               type="button"
//               className="btn-close"
//               data-dismiss="modal"
//               onClick={() => setSendMessage(false)}
//             ></Button>
//           </div>
//           <div className="modal-body">
//             <form
//               className="comment-form"
//               onSubmit={(e) => {
//                 e.preventDefault();
//                 setSendMessage(false);
//               }}
//             >
//               <div className="row">
//                 <div className="col-lg-6">
//                   <div className="form-group mb-3">
//                     <label htmlFor="author" className="text-black font-w600">
//                       {" "}
//                       {t("Name")} <span className="required">*</span>{" "}
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       defaultValue="Author"
//                       name="Author"
//                       placeholder="Author"
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-6">
//                   <div className="form-group mb-3">
//                     <label htmlFor="email" className="text-black font-w600">
//                       {" "}
//                       {t("Email")} <span className="required">*</span>
//                     </label>
//                     <input
//                       type="text"
//                       className="form-control"
//                       defaultValue="Email"
//                       placeholder="Email"
//                       name="Email"
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-12">
//                   <div className="form-group mb-3">
//                     <label htmlFor="comment" className="text-black font-w600">
//                       {t("Comment")}
//                     </label>
//                     <textarea
//                       rows={8}
//                       className="form-control"
//                       name="comment"
//                       placeholder="Comment"
//                       defaultValue={""}
//                     />
//                   </div>
//                 </div>
//                 <div className="col-lg-12">
//                   <div className="form-group mb-3">
//                     <input
//                       type="submit"
//                       value="Send Message"
//                       className="submit btn btn-primary"
//                       name="submit"
//                     />
//                   </div>
//                 </div>
//               </div>
//             </form>
//           </div>
//         </div>
//       </Modal>
      // <OverLayLoading
      //   loading={unapprove_teacher_loading}
      //   loadingText={`Un Approving ${userData && userData.name}`}
      // />
      // <OverLayLoading
      //   loading={approve_teacher_loading}
      //   loadingText={`Approving ${userData && userData.name}`}
      // />
      // <OverLayLoading
      //   loading={deleteUserLoading}
      // />
//     </>
//   );
// }
// export default UserProfilePage;













function UserProfilePage() {
  const {t} = useTranslation();
  const {
    register,
    watch,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();
  const dispatch = useDispatch();
  const contextApi = useContext(ContextApi);
  const params = useParams();
  const all_users_URL_APi = `${contextApi.dashboard_URL_API}/users`;
  const user_URL_APi = `${all_users_URL_APi}/${params.userId}`;
  const userCoursesAPi = `${contextApi.dashboard_URL_API}/courses?user_id=${params.userId}`;
  const { loading, userData } = useSelector(
    (state) => state.getSingleUserSlicer
  );
  const { approve_teacher_loading } = useSelector(
    (state) => state.approveTeacherSlicer
  );
  const { unapprove_teacher_loading } = useSelector(
    (state) => state.unApproveTeacherSlicer
  );
  const {loading:deleteUserLoading} = useSelector(state => state.deleteUserSlicer)
  const [sendMessage, setSendMessage] = useState();
  const [showPhone, setShowPhone] = useState(false);
  const [userCourses, setUserCourses] = useState([]);
  const [userImg, setUserImg] = useState("");
  const [activeToggle, setActiveToggle] = useState("aboutMe");
  const [userImage , setUserImage] = useState("https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?w=740&t=st=1702935704~exp=1702936304~hmac=0cf7808c31a16122cc1e8bfbd1b2e33c4f86a93e80af95f3eba31a9428f2ea2c");
  const [getUserCoursesLoading , setGetUserCoursesLoading] = useState(true)
  useEffect(() => {
    const getUserData = async () => {
      dispatch(fetchSingleUserFunc(user_URL_APi))
    };
    const getUserCourses = async () => {
      await axios
        .get(userCoursesAPi )
        .then(response => {
          setGetUserCoursesLoading(false)
          if(response.data.data.courses) {
            setUserCourses(response.data.data.courses)
          }
        })
    };
    getUserData();
    getUserCourses();
  }, []);

  const removeUserImg = () => {
    setUserImg("");
  };
  const deleteUser = async () => {
    dispatch(deleteUserFunc(user_URL_APi))
    .then(result => {
      result?.payload?.success && window.history.back();
    })
  };

  useEffect(() => {
    userData && userData.image_url !== null && setUserImage(userData.image_url)
  },[userData])


  const handleUploadUserImage = (image) => {
    if(image){
      const imageForm = new FormData();
      imageForm.append("image" , image);
      // imageForm.append("_method" , "PUT");
      dispatch(editUserImageFunc(
        {user_api : `${all_users_URL_APi}/upload-image` , image : imageForm}
      ))
      .then((result) => {
        if(result.payload.message === "The Image has been uploaded successfully"){
          dispatch(fetchSingleUserFunc(user_URL_APi));
        }
      })
    }
  }

      // for approve lessons
      const approve_teacher_api = `${contextApi.dashboard_URL_API}/users/${userData && userData.id}/approve`;
      const approveUser = async () => {
        await dispatch(approveTeacherFunc({teacherApi: approve_teacher_api}))
        .then(result => {
            if(result.payload.success === true){
                dispatch(fetchSingleUserFunc(user_URL_APi))
            }
        })
      }
      const unApproveUser = () => {
        swal({
          title: t("Confirm User Un Active"),
          text: t("Once un active,  will not be active in platform!"),
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            dispatch(unApproveTeacherFunc({teacherApi: approve_teacher_api}))
            .then(result => {
                if(result.payload.success === true){
                    dispatch(fetchSingleUserFunc(user_URL_APi));
                }
            })
          }
        });
      }

  return (
    <>
      <PageTitle activeMenu="Instructor Profile" motherMenu="Instructors" />
      {
      loading
      ?
      <>
        <div className="row d-flex justify-content-center gap-2">
          <Spinner animation="grow" className="bg-primary" />
          <Spinner animation="grow" className="bg-primary" />
          <Spinner animation="grow" className="bg-primary" />
          <Spinner animation="grow" className="bg-primary" />
        </div>
      </>
      :
      (
        userData && (
          <>
            <div className="row">
              <div className="col-sm-12 student-img">
                <div className="profile card card-body px-3 pt-3 pb-0">
                  <div className="profile-head">
                    <div className="photo-content">
                      <div className=" h-[250px] relative rounded overflow-hidden">
                        <img
                          src={coverBG}
                          alt="cover"
                          className="absolute w-full h-[250px] top-0 left-0"
                        />
                      </div>
                    </div>
                    <div className="profile-info">
                      <div className="relative main-img-div mt-0 translate-y-[-60px] bg-slate-400 flex justify-center items-center rounded-[50%]">
                        <img
                          src={userData.image_url !== null ? userData.image_url : userImage}
                          className=" w-[100px] h-[90px] rounded-[50%]"
                          alt="profile img"
                        />
                      </div>
                      <div className="profile-details">
                        <div className="profile-name px-3 pt-2">
                          <h4 className="text-primary mb-0 flex items-center">
                            <p className="m-0">{userData.name}</p>
                            <span className="mx-2">
                              <img src={userData && userData.publish === "publish" ? correctIcon : warningIcon} alt="status img" className="w-[15px] mr-1"/>
                            </span>
                          </h4>
                          <p>{"Instructor"}</p>
                        </div>
                        <div className="profile-email px-2 pt-2">
                          <h4 className="text-muted mb-0">{userData.email}</h4>
                          <p>{t("Email")}</p>
                        </div>
                        {
                          checkUserRole(
                            ["manager"] 
                            , 
                            <DecisionsDropMenu
                              userImg = {userImg}
                              removeUserImg={removeUserImg}
                              deleteUserFunc={deleteUser}
                              teacher_status = {userData && userData.publish}
                              approve_user = {approveUser}
                              un_approve_user = {()=>unApproveUser()}
                            />
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="about-st-section flex flex-col md:flex-row justify-between">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="profile-tab">
                      <div className="custom-tab-1">
                        <ul className="nav nav-tabs">
                          <li
                            className="nav-item"
                            onClick={() => setActiveToggle("aboutMe")}
                          >
                            <Link
                              to="#about-me"
                              data-toggle="tab"
                              className={`nav-link ${
                                activeToggle === "aboutMe" ? "active show" : ""
                              }`}
                            >
                              {t("About")}
                            </Link>
                          </li>
                          {/* <li className="nav-item">
                            <Link
                              to="#profile-settings"
                              data-toggle="tab"
                              onClick={() => setActiveToggle("setting")}
                              className={`nav-link ${
                                activeToggle === "setting" ? "active show" : ""
                              }`}
                            >
                              {t("Settings")}
                            </Link>
                          </li> */}
                          <li className="nav-item">
                            <Link
                              to="#profile-settings"
                              data-toggle="tab"
                              onClick={() => setActiveToggle("instructorCourses")}
                              className={`nav-link ${
                                activeToggle === "instructorCourses" ? "active show" : ""
                              }`}
                            >
                              {t("Courses")} ( {getUserCoursesLoading ? t("loading") : userCourses?.length} )
                            </Link>
                          </li>
                        </ul>
                        <div className="tab-content">
                          <div
                            id="about-me"
                            className={`tab-pane fade ${
                              activeToggle === "aboutMe" ? "active show" : ""
                            }`}
                          >
                            <div className="profile-about-me">
                              <div className="pt-4 border-bottom-1 pb-3">
                                <h4 className="text-primary">
                                  {t("About")} {userData.name}
                                </h4>
                                <p className="mb-2">
                                  {userData?.baio}
                                </p>
                              </div>
                            </div>
                            <div className="profile-personal-info">
                              <h4 className="text-primary mb-4">
                                {t("Personal Information")}
                              </h4>
                              <div className="row mb-2">
                                <div className="col-4">
                                  <h5 className="f-w-500">
                                    {t("Name")}
                                    <span className="pull-right">:</span>
                                  </h5>
                                </div>
                                <div className="col-8">
                                  <span>{userData.name}</span>
                                </div>
                              </div>
                              <div className="row mb-2">
                                <div className="col-4">
                                  <h5 className="f-w-500">
                                    {t("Email")}
                                    <span className="pull-right">:</span>
                                  </h5>
                                </div>
                                <div className="col-8">
                                  <span>{userData.email}</span>
                                </div>
                              </div>
                              <div className="row mb-2">
                                <div className="col-4">
                                  <h5 className="f-w-500">
                                    {" "}
                                    {t("Phone")}
                                    <span className="pull-right">:</span>
                                  </h5>
                                </div>
                                <div className="col-8">
                                  <span>{userData.phone}</span>
                                </div>
                              </div>
                              <div className="row mb-2">
                                <div className="col-4">
                                  <h5 className="f-w-500">
                                    {t("Created At")}
                                    <span className="pull-right">:</span>
                                  </h5>
                                </div>
                                <div className="col-8">
                                  <span>{userData.created_at}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div
                            id="profile-settings"
                            className={`tab-pane fade ${
                              activeToggle === "setting" ? "active show" : ""
                            }`}
                          >
                            <div className="pt-3">
                              <div className="settings-form">
                                <h4 className="text-primary">{t("Account Setting")}</h4>
                                <form
                                  id="update-student-form"
                                  // onSubmit={handleSubmit((data) => {
                                  //     dispatch(
                                  //       editStudentFunc({
                                  //         api: student_URL_APi,
                                  //         newData: data,
                                  //       })
                                  //     ).then(result => {
                                  //       if(result.payload.success === true){
                                  //         window.location.reload();
                                  //         reset();

                                  //       }
                                  //     })
                                  // })}
                                >
                                  <div className="row">
                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                      <label>{t("New Name")}</label>
                                      {errors.name?.message && (
                                        <p className="text-red-600">
                                          {errors.name?.message}
                                        </p>
                                      )}
                                      <input
                                        {...register("name", {
                                          required: true,
                                          // pattern: validName,
                                          minLength: {
                                            value: 3,
                                            message:
                                              t("Name must be more than 3 letters!"),
                                          },
                                        })}
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                    <div className="form-group mb-3 col-sm-12 col-md-6">
                                      <label>{t("New Phone")}</label>
                                      {errors.phone?.message && (
                                        <p className="text-red-600">
                                          {errors.studentPhone?.message}
                                        </p>
                                      )}
                                      <input
                                        {...register("phone", {
                                          required: t("Phone is required"),
                                          // pattern: validPhone,
                                        })}
                                        type="text"
                                        className="form-control"
                                      />
                                    </div>
                                  </div>
                                  <button
                                    type="submit"
                                    // className={`btn btn-primary ${editStudentloading ? "opacity-80 pointer-events-none" : ""}`}
                                    className={`btn btn-primary`}
                                  >
                                    {!editStudentloading 
                                      ? 
                                      t("Update") 
                                      : 
                                      <div className="relative flex justify-center items-center">
                                        {t("Updating")}...
                                        <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-1 "></div>
                                      </div>
                                    }
                                    Update
                                  </button>
                                </form>
                              </div>
                            </div>
                          </div> */}
                          <div
                            id="profile-settings"
                            className={`tab-pane fade ${
                              activeToggle === "instructorCourses" ? "active show" : ""
                            }`}
                          >
                            <div className="pt-3">
                              <div className="settings-form">
                                {/* <h4 className="text-primary">{t("courses")}</h4> */}
                                <div className="row">
                                  {
                                    getUserCoursesLoading
                                    ?
                                    <h3>{t("loading")}...</h3>
                                    :
                                    (
                                      userCourses?.length >= 1
                                      ?
                                      userCourses?.map((course , index) => (
                                        <CourseCard
                                          key={course.id}
                                          course={course}
                                          id={course.id}
                                          course_name={course.name}
                                          course_price={course.price}
                                          coures_instructor={course.user}
                                          course_img={course.image_url}
                                          course_rating={course.rating}
                                          lessonsCount = {course.lessons_count}
                                          loading = {loading}
                                          courseType = {course.type}
                                          studentsCount={course.students && course.students.length}
                                          course_description = {course.description}
                                        />
                                      ))
                                      :
                                      <h3>{t("No data avilable")}</h3>
                                    )
                                  }
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      )
      }
      <OverLayLoading
        loading={unapprove_teacher_loading}
        loadingText={`Un Approving ${userData && userData.name}`}
      />
      <OverLayLoading
        loading={approve_teacher_loading}
        loadingText={`Approving ${userData && userData.name}`}
      />
      <OverLayLoading
        loading={deleteUserLoading}
      />
    </>
  );
}

export default UserProfilePage;
