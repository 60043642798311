import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Footer = () => {
  var d = new Date();
  const {loading:getBasicSettingsLoading , data:settingsData} = useSelector(
    state => state.getBasicSettingsSlicer
  )
  return (
    <div className="footer">
      <div className="copyright">
        {
          getBasicSettingsLoading
          ?
          <p>Loading..</p>
          :
          <p>
            <div dangerouslySetInnerHTML={{__html: settingsData?.site_footer_copyright}} />
          </p>
        }
        {/* <p>
          Copyright © Designed &amp; Developed body
          <a href="/">TOPGROWTH</a>
          {d.getFullYear()}
        </p> */}
        {/* 
				<p>Copyright © Designed &amp; Developed by{" "}
					<a href="http://dexignzone.com/" target="_blank"  rel="noreferrer">
						DexignZone
					</a>{" "}
					2022
				</p> */}
      </div>
    </div>
  );
};

export default Footer;
