import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../../jsx/global-functions/checkErrorsFunction";
import Swal from "sweetalert2";
import { checkErrors } from "../../../../jsx/global-functions/checkErrors";
// import { checkErrors } from "../../../../jsx/global-functions/checkErrors";
export const addNewExtintionFunc = createAsyncThunk(
    "add new extintion function",
    async ({extintionsApi , extintionData}) => {
        try {
            const response = await axios.post(extintionsApi , extintionData );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            // Swal.fire({
            //     title: "Oops!",
            //     text: error,
            //     icon: "error"
            // });
            checkErrors(error.response , error.message);
        }
        
    }
)

const addNewExtintionSlicer = createSlice({
    name : "add new extintion slicer",
    initialState : {
        loading : null,
        course : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(addNewExtintionFunc.pending , (state) => {
            state.loading = true
            state.course = {}
            state.error = false
        });
        builder.addCase(addNewExtintionFunc.rejected , (state) => {
            state.loading = false
            state.course = {}
            state.error = true
        });
        builder.addCase(addNewExtintionFunc.fulfilled , (state , action) => {
            state.loading = false
            state.course = action.payload
            state.error = false
        });
    }
});

export default addNewExtintionSlicer.reducer;