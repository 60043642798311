import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import Swal from "sweetalert2";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const updateCategoryFunc = createAsyncThunk(
    "edit data function",
    async ({categoryApi , newCategoryData}) => {
        try {
            const response = await axios.post(categoryApi , newCategoryData);
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            // Swal.fire({
            //     title: "Oops!",
            //     text: error,
            //     icon: "error"
            // });
            checkErrors(error.response , error.message);
        }
        
    }
)

const updateCategorySlicer = createSlice({
    name : "edit course slicer",
    initialState : {
        updateCategoryloading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(updateCategoryFunc.pending , (state) => {
            state.updateCategoryloading = true
            state.data = {}
            state.error = false
        });
        builder.addCase(updateCategoryFunc.rejected , (state) => {
            state.updateCategoryloading = false
            state.data = {}
            state.error = true
        });
        builder.addCase(updateCategoryFunc.fulfilled , (state , action) => {
            state.updateCategoryloading = false
            state.data = action.payload
            state.error = false
        });
    }
});

export default updateCategorySlicer.reducer;