import ReactQuill from "react-quill";
import PageTitle from "../../layouts/PageTitle";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import "./ui-settings-page.css"
import { useDispatch, useSelector } from "react-redux";
import ContextApi from "../../../store/ContextApi";
import { addNewUiSliderFunc } from "../../../store/custom-actions/ui-settings-actions/AddUiSliderSlicer";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function NewSliderPage() {
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const ui_settings_URL_APi = `${contextApi.dashboard_URL_API}/settings/slider-images`;
    const dispatch = useDispatch();
    const [sliderImg , setSliderImg] = useState();
    const [sliderMobileImg , setSliderMobileImg] = useState();
    const [sliderDescription , setSliderDescription] = useState("");
    const {register , formState : {errors} , handleSubmit , reset} = useForm();
    const {loading:postSliderDataLoading} = useSelector(
        state => state.addNewUiSliderSlicer
    );
    const colors = [
        '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
        '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
    ];
const modules = {
  toolbar: {
    container: [
      [{ 'font': [] }],
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'color': colors }, { 'background': colors }],
      [{ 'script': 'sub'}, { 'script': 'super' }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'indent': '-1'}, { 'indent': '+1' }],
      [{ 'direction': 'rtl' }],
      [{ 'align': [] }],
      ['blockquote', 'code-block'],
      ['link', 'image', 'video'],
      ['clean']
    ],
  }
};

    const handleSubmitSlider = async (data) => {
        if(data && sliderImg) {
            const sliderFormData = new FormData();
            sliderFormData.append("image" , sliderImg);
            sliderFormData.append("mobile_image" , sliderMobileImg);
            data.title ? sliderFormData.append("title" , data.title) : sliderFormData.append("title" , "")
            sliderFormData.append("description" , sliderDescription);
            data.link ? sliderFormData.append("link" , "") : sliderFormData.append("link" , data.link)
            dispatch(addNewUiSliderFunc({api : ui_settings_URL_APi , data : sliderFormData}))
            .then(result => {
                if(result.payload.success === true) {
                    setTimeout(() => window.history.back() , 2000);
                    reset();
                    setSliderDescription("");
                    setSliderImg("");
                    NewSliderPage("/update-ui-settings")
                }
            })
        }
    }

    return (
        <>
            <PageTitle motherMenu={"Home"} activeMenu={"UI Settings"} customClasses={"my-0"} />
            <div className="relative flex items-center justify-end my-3">
                <div className="relative flex items-center">
                    <button 
                        type="submit"
                        form="new-slider-form"
                        class={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-blue-400 duration-300 ml-1 hover:gap-2 hover:translate-x-3
                        ${postSliderDataLoading ? "pointer-events-none opacity-80" : ""}
                        `}
                        >
                            {
                                postSliderDataLoading
                                ?
                                <>{t("loading")}...</>
                                :
                                <>
                                    {t("Submit")}
                                    <i className="fa-solid fa-gear text-white ml-1"></i>
                                </>
                            }
                    </button>
                </div>
            </div>
            <div className="card p-3">
                <div className="relative settings-form-content">
                    <div className="new-lesson-form-div relative w-full ">
                        <form 
                            className="new-slider-form"
                            id="new-slider-form"
                            onSubmit={handleSubmit((data) => {
                                handleSubmitSlider(data)
                            })}
                        >
                            {
                                <>
                                    <div className={'row'}>
                                        <div className="form-group logo-input mb-3 col-sm-12 col-md-6">
                                            <label className="flex items-center">
                                                <i className="fa-brands fa-cuttlefish mr-1"></i>
                                                {t("Title")}
                                            </label>
                                            {errors.title?.message && (
                                            <p className="text-red-600">
                                                {errors.title?.message}
                                            </p>
                                            )}
                                            <input
                                                {...register("title")}
                                                type="text"
                                                className="form-control"
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-sm-12 col-md-6">
                                            <label className="flex items-center">
                                                <i className="fa-solid fa-link mr-1"></i>
                                                {t("Path Link")}
                                            </label>
                                            {errors.link?.message && (
                                            <p className="text-red-600">
                                                {errors.link?.message}
                                            </p>
                                            )}
                                            <input
                                            {...register("link")}
                                            type="text"
                                            className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className={`row`}>
                                        <div className="form-group mb-3 col-sm-12 col-md-6">
                                            <label className="flex items-center">
                                                <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                {t("Slider Image")}
                                            </label>
                                            <input
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => setSliderImg(e.target.files[0])}
                                            />
                                        </div>
                                        <div className="form-group mb-3 col-sm-12 col-md-6">
                                            <label className="flex items-center">
                                                <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                {t("Slider Mobile Image")}
                                            </label>
                                            <input
                                            type="file"
                                            className="form-control"
                                            onChange={(e) => setSliderMobileImg(e.target.files[0])}
                                            />
                                        </div>
                                    </div>
                                    <div className="slider-description-line mb-3 relative my-3 flex justify-center items-center after:absolute after:left-[50%] after:translate-x-[-50%] after:top-[50%] after:px-2 after:bg-[#eaebef]  after:translate-y-[-50%] bg-slate-300 w-full h-[1px]"></div>
                                    <div className="row m-0 p-0">
                                        <div className="form-group mb-3 col-sm-12">
                                            <label className="flex items-center">
                                                <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                {t("Slider Description")}
                                            </label>
                                            <textarea
                                            className="form-control col-sm-12"
                                            onChange={(e) => setSliderDescription(e.target.value)}
                                            >
                                            </textarea>
                                        </div>
                                        {/* <ReactQuill 
                                        modules = {modules}
                                        className="p-0 border-none outline-none form-control bg-white mt-3" 
                                        value={sliderDescription} theme="snow" 
                                        onChange={(content) => setSliderDescription(content)} /> */}
                                    </div>
                                </>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
export default NewSliderPage;