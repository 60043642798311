import { Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import swal from "sweetalert";

export default function DescionDrop(props) {
    const {lesson_order , lesson_id , lesson_type , quizzes_array , deleteFunction , showEditOrderForm , showEditNameAndDescForm , showLessonVideo} = props;
    return (
        <>
        <Dropdown>
            <Dropdown.Toggle
            as="a"
            className="btn-link i-false btn sharp tp-btn-light btn-dark"
            >
            <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                d="M12.0012 9.86C11.6544 9.86 11.3109 9.92832 10.9905 10.061C10.67 10.1938 10.3789 10.3883 10.1336 10.6336C9.88835 10.8788 9.6938 11.17 9.56107 11.4905C9.42834 11.8109 9.36002 12.1544 9.36002 12.5012C9.36002 12.848 9.42834 13.1915 9.56107 13.5119C9.6938 13.8324 9.88835 14.1236 10.1336 14.3688C10.3789 14.6141 10.67 14.8086 10.9905 14.9413C11.3109 15.0741 11.6544 15.1424 12.0012 15.1424C12.7017 15.1422 13.3734 14.8638 13.8687 14.3684C14.3639 13.873 14.642 13.2011 14.6418 12.5006C14.6417 11.8001 14.3632 11.1284 13.8678 10.6332C13.3724 10.138 12.7005 9.85984 12 9.86H12.0012ZM3.60122 9.86C3.25437 9.86 2.91092 9.92832 2.59048 10.061C2.27003 10.1938 1.97887 10.3883 1.73361 10.6336C1.48835 10.8788 1.2938 11.17 1.16107 11.4905C1.02834 11.8109 0.960022 12.1544 0.960022 12.5012C0.960022 12.848 1.02834 13.1915 1.16107 13.5119C1.2938 13.8324 1.48835 14.1236 1.73361 14.3688C1.97887 14.6141 2.27003 14.8086 2.59048 14.9413C2.91092 15.0741 3.25437 15.1424 3.60122 15.1424C4.30171 15.1422 4.97345 14.8638 5.46866 14.3684C5.96387 13.873 6.24198 13.2011 6.24182 12.5006C6.24166 11.8001 5.96324 11.1284 5.46781 10.6332C4.97237 10.138 4.30051 9.85984 3.60002 9.86H3.60122ZM20.4012 9.86C20.0544 9.86 19.7109 9.92832 19.3905 10.061C19.07 10.1938 18.7789 10.3883 18.5336 10.6336C18.2884 10.8788 18.0938 11.17 17.9611 11.4905C17.8283 11.8109 17.76 12.1544 17.76 12.5012C17.76 12.848 17.8283 13.1915 17.9611 13.5119C18.0938 13.8324 18.2884 14.1236 18.5336 14.3688C18.7789 14.6141 19.07 14.8086 19.3905 14.9413C19.7109 15.0741 20.0544 15.1424 20.4012 15.1424C21.1017 15.1422 21.7734 14.8638 22.2687 14.3684C22.7639 13.873 23.042 13.2011 23.0418 12.5006C23.0417 11.8001 22.7632 11.1284 22.2678 10.6332C21.7724 10.138 21.1005 9.85984 20.4 9.86H20.4012Z"
                fill="#A098AE"
                />
            </svg>
            </Dropdown.Toggle>
            <Dropdown.Menu
            className="dropdown-menu dropdown-menu-end"
            align="right"
            >
            {
                lesson_type === "video"
                &&
                <Dropdown.Item
                    onClick={() => {
                    showLessonVideo()
                    }}
                >
                    <i className="fa-solid fa-video mr-1"></i>
                    Show Video
                </Dropdown.Item>

            }
            {
                lesson_order > 1
                &&
                <>
                    <Dropdown.Item
                        onClick={() => {
                        props.showPassLessonRequirmentsForm();
                        }}
                    >
                        <i className="fa-solid fa-lock-open mr-1"></i>
                        Passing Requirments
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => {
                        props.showRequirmentsList();
                        }}
                    >
                        <Link 
                        to={`lesson-requirments/${lesson_id}`}
                        className="relative flex items-center">
                            <i className="fa-solid fa-eye mr-1"></i>
                            Show Requirments
                        </Link>
                    </Dropdown.Item>
                </>
            }
            {/* {
                lesson_type === "quiz"
                &&
                <>
                    <Dropdown.Item
                        onClick={() => {
                        props.showLinkQuizeForm();
                        }}
                    >
                        <i className="fa-solid fa-arrow-up-z-a mr-1"></i>
                        New Quize
                    </Dropdown.Item>
                    {
                        quizzes_array.length >= 1 
                        &&
                        <Dropdown.Item
                            onClick={() => {
                            props.showUnlinkQuizForm();
                            }}
                        >
                            <i className="fa-solid fa-link-slash mr-1"></i>
                            Un Link Quize
                        </Dropdown.Item>

                    }
                </>
            } */}
            {
                lesson_type === "quiz"
                &&
                <Dropdown.Item
                    onClick={() => {
                    props.showLinkQuizeForm();
                    }}
                >
                    <i className="fa-solid fa-arrow-up-z-a mr-1"></i>
                    Add Questions
                </Dropdown.Item>
            }
            {
                lesson_type !== "quiz" && lesson_type !== "rich_text"
                &&
                <Dropdown.Item
                onClick={() => {
                props.showShareFilesForm();
                }}
                >
                    <i className="fa-solid fa-share-nodes mr-1"></i>
                    {
                        lesson_type === "video" ? "Share Video" : "Share Files"
                    }
                </Dropdown.Item>
            }
            <Dropdown.Item
                onClick={() => {
                showEditOrderForm()
                console.log("order form")
                }}
            >
                <i className="fa-solid fa-arrow-up-z-a mr-1"></i>
                Lesson Order
            </Dropdown.Item>
            <Dropdown.Item
                onClick={() => {
                showEditNameAndDescForm();
                }}
            >
                <i className="fa-solid fa-pen-to-square mr-1"></i>
                Update Lesson Data
            </Dropdown.Item>
            {
                lesson_type === "video"
                &&
                <Dropdown.Item
                    onClick={() => {
                    props.showEditVideoForm();
                    }}
                >
                    <i className="fa-solid fa-video mr-1"></i>
                    Update video
                </Dropdown.Item>
            }
            {
                (lesson_type === "file" || lesson_type === "pdf")
                &&
                <>
                    <Dropdown.Item
                        onClick={() => {
                        props.showEditMatrialForm();
                        }}
                    >
                        <i className="fa-solid fa-file-pdf mr-1"></i>
                        Update matrial
                    </Dropdown.Item>
                    <Dropdown.Item
                        onClick={() => {
                        props.showLessonMatrial();
                        }}
                    >
                        <i className="fa-solid fa-file-pdf mr-1"></i>
                        Download matrial
                    </Dropdown.Item>
                </>
            }
            {props.lessonStatus === "publish" &&
                <Dropdown.Item
                    onClick={() => {
                        swal({
                            title: "Confirm Lesson Unpublish",
                            text: `Once Unpublish, will not be glopaly in platform!`,
                            icon: "warning",
                            buttons: true,
                            dangerMode: true,
                            }).then((willDelete) => {
                                if (willDelete) {
                                    props.unapprovelessonfunction();
                                } else {
                                    swal("Lesson still active!");
                                }
                            });
                    }}
                >
                    <i className="fa-solid fa-ban mr-1"></i>
                    Unpublish
                </Dropdown.Item>
            }
            {props.lessonStatus === "unpublish" &&
                <Dropdown.Item
                    onClick={() => {
                    props.showapprovelessonnotficationFunc();
                    }}
                >
                    <i className="fa-solid fa-upload mr-1"></i>
                    Publish
                </Dropdown.Item>
            }
                <Dropdown.Item
                    onClick={() => {
                    deleteFunction()
                    }}
                >
                <i className="fa-solid fa-ban mr-1"></i>
                Delete
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
        </>
    );
}
