import { useTranslation } from "react-i18next";
import noHavePermissionImg from "../../../media/images/wepik-export-202403110808380Rmc.png"
function HavNotPermissionPage() {
    const {t} = useTranslation();
    return (
        <>
            <div className="page">
                <div className="container flex flex-col justify-content-center items-center">
                    <img src={noHavePermissionImg} alt="not found img" className="w-[350px]" />
                    <p className="flex items-center">{t("Sorry, you do not have permission to access this page!")}
                        <span 
                        className="text-blue-700 cursor-pointer ml-1"
                        onClick={() => window.history.back()}
                        >
                        {t("Back")}
                        </span>
                    </p>
                </div>
            </div>
        </>
    )
}

export default HavNotPermissionPage;