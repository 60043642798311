import { useContext, useEffect, useState } from "react";

import React from 'react';
// import { Switch, Route, Routes } from "react-router-dom";


import { Routes , Route , useNavigate } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./custom-pages/home-page/Home";
import { ThemeContext } from "../context/ThemeContext";


import CoursesPage from "./custom-pages/courses-page/CustomCoursesPage"
import StudentsPage from "./custom-pages/students-page/StudentsPage";
import InstructorsPage from "./custom-pages/instructors-page/InstructorsPage";
import CourseDetailsPage from "./custom-pages/courses-page/CourseDetailsPage";

import LoginPage from "./custom-pages/login-page/LoginPage";
import UserProfilePage from "./custom-pages/instructors-page/UserProfilePage";
import StudentProfilePage from "./custom-pages/students-page/studentProfilePage";
import Protect from "../store/custom-actions/ProtectRoutes";
import RegisterPage from "./custom-pages/register-page/RegisterPage";
import ForgotPassword from "./pages/ForgotPassword";
import MessagesPage from "./custom-pages/contact-us-page/MessagesPage";
import LogOut from "../store/custom-actions/LogoutComponent";
// import QuizzesAttemptsPage from "./custom-pages/quizzes-pages.js/QuizzesAttemptsPage";
import AttemptPage from "./custom-pages/quizzes-pages.js/AttempPage";
import CategoriesPage from "./custom-pages/categories-page/CategoriesPage";
import NotFoundPage from "./custom-pages/error-page/NotFoundPage";
import CourseSessionsPage from "./custom-pages/courses-page/course-sessions-page/CourseSessionsPage";
import ShowSingleSessionPage from "./custom-pages/courses-page/course-sessions-page/SingleSession";
import AdminAllAttemptsPage from "./custom-pages/courses-page/admin-all-attempts/AdminAllAttemptsPage";
import OfflinePage from "./custom-pages/offline-page/OfflinePage";
import LoadingScreen from "./global-compnents/loading-screen/LoadingScreen";
import NewLessonPage from "./global-compnents/new-lesson-form/NewLessonPage";
import ExcutionPage from "./custom-pages/courses-page/excution-pages/ExcutionsPage";
import SingleExcutionPage from "./custom-pages/courses-page/excution-pages/SingleExcutionPage";
import SendNotficationPage from "./global-compnents/notfication-components/SendNotficationPage";
import LessonSchadulesPage from "./global-compnents/course-extintions-form/lesson-schuadles-form/LessonSchadulesPage";
import ShowLessonsSchuadles from "./global-compnents/lesson-box-components/show-lesson-schuadles/ShowLessonSchuadles";
import UpdateLessonsSchualdesPage from "./global-compnents/course-extintions-form/lesson-schuadles-form/UpdateLessonsSchuadles";
// import LessonRequirmentsPage from "./global-compnents/lesson-box-components/lesson-requirments-page/LessonRequirmentsPage";
import NewSliderPage from "./custom-pages/settings-pages/NewSliderPage";
import AllSlidersPage from "./custom-pages/settings-pages/AllSlidersPage";
// import QuizzesPage from "./custom-pages/quizzes-page/QuizzesPage";
import QuizzesPage from "./custom-pages/quizzes-page/QuizzesPage";
import QuestionsPage from "./custom-pages/questions-page/QuestionsPage";
import CodeBulcksPage from "./custom-pages/code-bulcks-page/CodeBulcksPage";
import RolesPage from "./custom-pages/roles-page/RolesPage";
import InvoicesPage from "./custom-pages/invoices-page/InvoicesPage";
import SingleInvoicePage from "./custom-pages/invoices-page/SingleInvoicePage";
import CouponsePage from "./custom-pages/couponse-page/CouponsePage";
import PaymentsPage from "./custom-pages/payments-page/PaymentsPage";
import InvoicesResultsPage from "./custom-pages/invoices-results-page/InvoicesResultsPage";
import ContactSettingsPage from "./custom-pages/contact-settings-page/ContactSettingsPage";
import HavNotPermissionPage from "./global-compnents/havnot-permission-page/HavNotPermissionPage";
import checkPermissions from "./global-functions/checkPermissionsFunc";

import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import InvoicesResultsMoreDetailsPage from "./custom-pages/invoices-results-page/MoreInvoicesDetailsPage";
import NewCourseForm from "./custom-forms/NewCourseForm";
import NewChapterForm from "./custom-forms/chapters/NewChapterForm";
import UpdateChapterForm from "./custom-forms/chapters/UpdateChapterForm";
import LessonsPage from "./custom-pages/lessons-page/LessonsPage";
import NewUserForm from "./custom-forms/instructors/NewUserForm";
import StuffPage from "./custom-pages/users-pages/stuff-page/StuffPage";
import SingleChapterPage from "./custom-pages/single-chapter/SingleChapterPage";
import NewQuestionPage from "./custom-pages/questions-page/NewQuestionPage";
import LessonRequirments from "./custom-pages/single-chapter/ChapterLessons/lesson-requirments/LessonRequirments";
import SubscriptionsPage from "./custom-pages/subscriptions/SubscriptionsPage";
import CourseSubscriptions from "./custom-pages/courses-page/course-subscriptions/CourseSubscriptions";
import BasicSettingsPage from "./custom-forms/settings/BasicSettingsPage";
import NewExecutionPage from "./custom-forms/executions/NewExecutionPage";
import SingleCategoryPage from "./custom-pages/categories-page/single-category/SingleCategoryPage";
import NotficationsPage from "./custom-pages/notfications/NotficationsPage";
import LanguagesPage from "./custom-pages/languages-page/LanguagesPage";
import UpdateLaguageWordsPage from "./custom-pages/languages-page/UpdateLaguageWordsPage";
import { useSelector } from "react-redux";
import AttachedsPage from "./custom-pages/attached-page/AttachedsPage";
import MonthlyEarningsPage from "./custom-pages/earnings/MonthlyEarningsPage";
import TeachersEarningsMonthlyPage from "./custom-pages/earnings/TeachersEarningsMonthlyPage";
import SubscriptionDetails from "./custom-pages/subscription-details/SubscriptionDetails";
import Setting from "./layouts/Setting";
import checkUserRole from "./global-functions/checkUserRole";
import SendMailPage from "./global-compnents/send-mail-page/SendMailPage";
import EmailsTemplatesPage from "./custom-pages/emails/EmailsTemplatesPage";

const Markup = () => {
  const expire_date = window.localStorage.getItem("expire_date");
  const user = JSON.parse(window.localStorage.getItem("user"))
  const userRoleName = user && user.roleName && user.roleName.toLowerCase();
  const { menuToggle } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [loadingDone , setLoadingDone] = useState();

  useEffect(()=>{
    if(!expire_date){
      navigate("/login-page");
    }
  },[navigate , expire_date])
	const {loading:switchLangLoading} = useSelector(
		state => state.getLanguageSettingsSlicer
	)

  const routes = (
    <>
      <Route path="/" element={<Home/>}/>
      <Route path="/courses" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <CoursesPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/new-course/:courseType" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <NewCourseForm/> : <HavNotPermissionPage/>
          }/>
      <Route path="/new-chapter/:courseId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <NewChapterForm/> : <HavNotPermissionPage/>
          }/>
      <Route path="/update-chapter/:chapterId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <UpdateChapterForm/> : <HavNotPermissionPage/>
          }/>
      {/* <Route path="/course-details/:courseId"  */}
      <Route path="/course-details/:courseId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <CourseDetailsPage/> : <HavNotPermissionPage/>
          }/>
          {/* all-attempts */}
      <Route path="/students/student-profile/:studentId/course-details-page/:courseId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <CourseDetailsPage/> : <HavNotPermissionPage/> 
          }/>
      <Route path="/instructors/instructor-profile/:userId/course-details-page/:courseId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <CourseDetailsPage/> : <HavNotPermissionPage/> 
          }/>
      <Route path="/course-details/:courseId/student-profile/:studentId/course-details-page/:courseId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <CourseDetailsPage/> : <HavNotPermissionPage/> 
          }/>
      <Route path="/instructors" 
        element={
          userRoleName === "manager" ? <InstructorsPage/> : <HavNotPermissionPage/> 
          }/>
      <Route path="/stuff" 
        element={
          userRoleName === "manager" ? <StuffPage/> : <HavNotPermissionPage/> 
          }/>
      <Route path="/instructors" update-schuadle
        element={
          userRoleName === "manager" ? <InstructorsPage/> : <HavNotPermissionPage/> 
          }/>
      <Route path="/students" 
        element={
          userRoleName === "manager" ? <StudentsPage/> : <HavNotPermissionPage/>
        }/>
      <Route path="/students/student-profile/:studentId" 
        element={
        userRoleName === "manager" ? <StudentProfilePage/> : <HavNotPermissionPage/>
        }/>
      <Route path="courses/course-details-page/:courseId/coupons/:courseId/student-profile/:studentId" 
        element={
        userRoleName === "manager" ? <StudentProfilePage/> : <HavNotPermissionPage/>
        }/>
      <Route path="/student-profile/:studentId" 
        element={
        userRoleName === "manager" ? <StudentProfilePage/> : <HavNotPermissionPage/>
        }/>
      <Route path="/course-details/:courseId/student-profile/:studentId" 
        element={
          userRoleName === "manager" ? <StudentProfilePage/> : <HavNotPermissionPage/>
        }/>
      <Route path="/course-details/:courseId/excutions-page/:courseId/show-excution/:excutionId/student-profile/:studentId" 
        element={
          userRoleName === "manager" ? <StudentProfilePage/> : <HavNotPermissionPage/>
        }/>
      <Route path="/subscriptions/student-profile/:studentId" 
        element={
          userRoleName === "manager" ? <StudentProfilePage/> : <HavNotPermissionPage/>
        }/>
      <Route path="/invoices/student-profile/:studentId" 
        element={
          userRoleName === "manager" ? <StudentProfilePage/> : <HavNotPermissionPage/>
        }/>
      <Route path="/profile/:userId" 
        element={
        <UserProfilePage/>
        }/>
      <Route path="/sessions-page/:courseId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <CourseSessionsPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/users-messages" 
        element={
        checkPermissions("contacts_read" , <MessagesPage/> , <HavNotPermissionPage/>) 
        }/>
      <Route path="/invoices/single-invoice/:invoiceId/instructor-profile/:userId" 
        element={
        <UserProfilePage/>
        }/>
      <Route path="/roles" 
        element={
          userRoleName === "manager" ? <RolesPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/invoices" 
        element={
          checkPermissions("invoices_read" , <InvoicesPage/> , <HavNotPermissionPage/>) 
          }/>
      <Route path="/invoices/single-invoice/:invoiceId" 
        element={
          checkPermissions("invoices_read" , <SingleInvoicePage/> , <HavNotPermissionPage/>)
          }/>
      <Route path="/invoices/single-invoice/:invoiceId/course-details-page/:courseId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <CourseDetailsPage/> : <HavNotPermissionPage/> 
          }/>
      <Route path="/all-attempts/:lessonId/attemp-page/:attempId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <AttemptPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/attemp-page/:attempId" quizzes
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <AttemptPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/offline-page/sessions-page/:courseId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <CourseSessionsPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/course-details/:courseId/sessions-page/:courseId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <CourseSessionsPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/categories" 
        element={
          userRoleName === "manager" ? <CategoriesPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/new-lesson/:chapterId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ?  <NewLessonPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/show-session/:sessionId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <ShowSingleSessionPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/excutions-page/:courseId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <ExcutionPage/> : <HavNotPermissionPage/>
          }/>

      <Route path="courses/course-details-page/:courseId/excutions-page/:courseId/show-excution/:excutionId" 
        element={
        <SingleExcutionPage/>
        }/>
      <Route path="/invoices-earnings" 
        element={
          userRoleName === "manager" ? <InvoicesResultsPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/invoices-details" 
        element={
          userRoleName === "manager" ? <InvoicesResultsMoreDetailsPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/offline-page" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <OfflinePage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/send-notfications" 
        element={
          // checkPermissions("notifications_create" , <SendNotficationPage/> , <HavNotPermissionPage/>)
          (userRoleName === "manager" || userRoleName === "teacher") ? <SendNotficationPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="quizzes-page" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <QuizzesPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/questions/:lessonId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <QuestionsPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/new-question/:lessonId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <NewQuestionPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/quizzes/:lessonId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <QuizzesPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/requirments/:lessonId" lesson-requirments
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <LessonRequirments/> : <HavNotPermissionPage/>
          }/>
      <Route path="/activation-codes/:courseId" 
        element={
        (userRoleName === "manager" || userRoleName === "teacher") ? <CodeBulcksPage/> : <HavNotPermissionPage/>
        }/>
      <Route path="coupons" 
        element={
          checkPermissions("coupons_read" , <CouponsePage/> , <HavNotPermissionPage/>) 
          }/>
      <Route path="payments" 
        element={
          userRoleName === "manager" ? <PaymentsPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/subscriptions" 
        element={
          userRoleName === "manager" ? <SubscriptionsPage/> : <HavNotPermissionPage/>
          }/>
          {/* new-execution */}
      <Route path="/subscriptions/:courseId" 
        element={
          userRoleName === "manager" ? <CourseSubscriptions/> : <HavNotPermissionPage/>
          }/>
      <Route path="/new-lesson-schuadles/:chapterId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <LessonSchadulesPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/update-schuadle/:schuadleId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <UpdateLessonsSchualdesPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/ui-settings" 
        element={
          userRoleName === "manager" ? <NewSliderPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/all-sliders" 
        element={
          userRoleName === "manager" ? <AllSlidersPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/contacts-settings" 
        element={
          userRoleName === "manager" ? <ContactSettingsPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/show-lesson-schuadles/:chapterId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <ShowLessonsSchuadles/> : <HavNotPermissionPage/>
          }/>
      <Route path="/all-attempts/:courseId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <AdminAllAttemptsPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/lessons/:chapterId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <LessonsPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/new-user/:roleName" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <NewUserForm/> : <HavNotPermissionPage/>
          }/>
      <Route path="/chapter-details/:chapterId" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <SingleChapterPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/basic-settings" 
        element={
          (userRoleName === "manager") ? <BasicSettingsPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/new-execution/:courseId" 
        element={
          (userRoleName === "manager") ? <NewExecutionPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/sub-categories/:categoryId" 
        element={
          (userRoleName === "manager") ? <SingleCategoryPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/lang-settings" 
        element={
          (userRoleName === "manager") ? <LanguagesPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/single-lang/:langId" 
        element={
          (userRoleName === "manager") ? <UpdateLaguageWordsPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/attacheds-page" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <AttachedsPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/monthly-earnings" 
        element={
          (userRoleName === "manager") ? <MonthlyEarningsPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/teachers-monthly-sales" 
        element={
          (userRoleName === "manager") ? <TeachersEarningsMonthlyPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/send-mail" 
        element={
          (userRoleName === "manager" || userRoleName === "teacher") ? <SendMailPage/> : <HavNotPermissionPage/>
          }/>
      <Route path="/notfications" 
        element={
          <NotficationsPage/>
          }/>
      <Route path="/subscription-details/:id" 
        element={
          <SubscriptionDetails/>
          }/>
      <Route path="/email-templates-page" 
        element={
          <EmailsTemplatesPage/>
          }/>
    </>
  );

  useEffect(() => {
    setTimeout(() => setLoadingDone(true) , 100)
  },[])
  const {data:languageData} = useSelector(
		state => state.getLanguageSettingsSlicer
	)
  return (
    loadingDone && !switchLangLoading
    ?
    <>
      <ToastContainer/>
      <div
        id={`${expire_date ? "main-wrapper" : ""}`}
        className={`${expire_date ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
        // style={{direction: "rtl"}}
      >
        {
          expire_date
          &&
          <Nav />
        }
        <div
          className={`${expire_date ? "content-body" : ""} ${languageData?.defaultLang?.slug === "ar" ? "ar-mode" : "en-mode"}`}
          style={expire_date && { minHeight: window.screen.height - 45}}
        >
          <div className={`${expire_date ? "container-fluid" : ""}`}>
            <Routes>
              <Route path="*" element={<NotFoundPage/>}/>
              <Route element={<Protect/>}>
                <Route path="/login-page" element={<LoginPage/>}/>
                <Route path="/register-page" element={<RegisterPage/>} />
                <Route path="/page-forgot-password" element={<ForgotPassword/>} />
              </Route>
              {routes}
            </Routes>
            {
              expire_date
              &&
              <LogOut/>
            }
          </div>
        </div>
        {
          expire_date
          &&
          <>
          <Footer />
          {
            checkUserRole(
              ["manager"],
              <Setting/>
            )
          }
          </>
        }
      </div>
    </>
    :
    <LoadingScreen/>
  );
};

export default Markup;
