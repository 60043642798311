import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import Swal from "sweetalert2";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const changeUserPasswordUserFunc = createAsyncThunk(
    "change user pasword function",
    async ({user_api , new_data}) => {
        try {
            const response = await axios.put(user_api , new_data);
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
    }
)

const changeUserPasswordSlicer = createSlice({
    name : "change user pasword slicer",
    initialState : {
        changePasswordLoading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(changeUserPasswordUserFunc.pending , (state) => {
            state.changePasswordLoading = true
            state.data = null
            state.error = false
        });
        builder.addCase(changeUserPasswordUserFunc.rejected , (state) => {
            state.changePasswordLoading = false
            state.data = null
            state.error = true
        });
        builder.addCase(changeUserPasswordUserFunc.fulfilled , (state , action) => {
            state.changePasswordLoading = null
            state.data = action.payload
            state.error = null
        });
    }
})

export default changeUserPasswordSlicer.reducer