import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import Swal from "sweetalert2";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const deleteSliderFunc = createAsyncThunk(
    "delete slider function",
    async (api) => {
        try {
            const response = await axios.delete(api );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
    }
)

const deleteSliderSlicer = createSlice({
    name : "delete slider slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducer :{},
    extraReducers : (builder) => {
        builder.addCase(deleteSliderFunc.pending , (state) => {
            state.loading = true;
            state.data = null;
            state.error = null;
        })
        builder.addCase(deleteSliderFunc.rejected , (state) => {
            state.loading = null;
            state.data = null;
            state.error = true;
        })
        builder.addCase(deleteSliderFunc.fulfilled , (state , action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = null;
        })
    }
})
export default deleteSliderSlicer.reducer;