import { useState, useEffect, useContext, useRef } from "react";
import { Link , useParams } from "react-router-dom";
import ContextApi from "../../../../store/ContextApi";
import { useDispatch, useSelector } from "react-redux";
import { getCourseExcutionFunc } from "../../../../store/custom-actions/courses-actions/excutions-actions/GetCourseExcutionsSlicer";
import excutionIcon from "../../../../media/images/gear.png";
import { deleteCourseExcutionFunc } from "../../../../store/custom-actions/courses-actions/excutions-actions/DeleteCourseExcutionSlicer";
import swal from "sweetalert";
import OverLayLoading from "../../custome-overlay-loading/OverLayLoading";
import UpdateExecutionForm from "../../../global-compnents/course-extintions-form/UpdateExecutionFom";
import PageTitle from "../../../layouts/PageTitle";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";

function ExcutionPage() {
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const { loading, data } = useSelector((state) => state.getCourseExcutionsSlicer);
  const { loading:deleteExcutionLoading } = useSelector((state) => state.deleteCourseExcutionSlicer);
  const params = useParams();
  const courseId = params.courseId;
  const contextApi = useContext(ContextApi);
  const excutions_URL_APi = `${contextApi.dashboard_URL_API}/courses/${courseId}/course-executions`;
  const singleExcutionApi = `${contextApi.dashboard_URL_API}/course-executions`;
  const [allExcutions, setAllExcutions] = useState([]);
  const [allExcutionsCount, setAllExcutionsCount] = useState();
  const [showExcutionsForm, setShowExcutionsForm] = useState();
  const [visibleFilter, setVisibleFilter] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [oldExecution, setOldExecution] = useState();
  const [showUpdateExcutionsForm, setShowUpdateExcutionsForm] = useState();
  const {loading:getBasicSettingsLoading , data:settingsData} = useSelector(
    state => state.getBasicSettingsSlicer
  )
  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(getCourseExcutionFunc(excutions_URL_APi));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    data && setAllExcutions(data);
  }, [data]);
  useEffect(() => {
    allExcutions && setAllExcutionsCount(allExcutions.length);
  }, [allExcutions]);


  const [filtredExecutions, setFiltredExecutions] = useState([]);
  const sort = 20;
  const activePag = useRef(0);

  useEffect(() => {
      if (data) {
          setAllExcutions(data);
          setFiltredExecutions(data.slice(
              activePag.current * sort,
              (activePag.current + 1) * sort
          ));
      }
  }, [data]);

  const onClick = (i) => {
      activePag.current = i;
      setFiltredExecutions(allExcutions.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
      ));
  };
      let paggination = Array(Math.ceil(allExcutions.length / sort))
    .fill()
    .map((_, i) => i + 1);

  useEffect(() => {
      if (searchValue !== "" && data && data.length >= 1) {
          const searchResult = data &&
              data.length >= 1 && data.filter((role) =>
                  role.title.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
              );
          setFiltredExecutions(searchResult);
      } else {
          data && data.length >= 1 && setFiltredExecutions(data.slice(
              activePag.current * sort,
              (activePag.current + 1) * sort
          ));
      }
  }, [searchValue, data]);



  // useEffect(() => {
  //   let excutionsApi = `${excutions_URL_APi}?${visibleFilter ? `is_visible=${visibleFilter}&` : ""}${excutionSearchValue ? `search=${excutionSearchValue}` : ""}`;

  //   let searchTimeout;
  //   let fetchCoursesExcutionsData = async ()=>{
  //     if(excutionSearchValue !== "") {
  //       clearTimeout(searchTimeout);
  //       searchTimeout = setTimeout(()=>{
  //         dispatch(getCourseExcutionFunc(excutionsApi))
  //       },2000)
  //     }else {
  //       dispatch(getCourseExcutionFunc(excutionsApi))
  //     }
  //   }
  //   fetchCoursesExcutionsData()
  //   return () => clearTimeout(searchTimeout)
  // },[visibleFilter , excutionSearchValue]);

  const deleteExcution = async (excutionId) => {
    const deleteFunction = () => {
      dispatch(deleteCourseExcutionFunc(`${singleExcutionApi}/${excutionId}`))
      .then(result => {
        if(result.payload.success === true) {
          dispatch(getCourseExcutionFunc(excutions_URL_APi));
        }
      })
    }
      swal({
          title: t("Confirm Excution Deletion"),
          text: t(`Once deleted, will not be Avilable in Course!`),
          icon: "warning",
          buttons: true,
          dangerMode: true,
          })
          .then((willDelete) => {
          if (willDelete) {
            deleteFunction();
          }
          })
  }
  return (
    <>
      <PageTitle motherMenu={"Course"} activeMenu={"Course Executions"}/>
      {
        loading
        ?
        <div className="row d-flex justify-content-center gap-2">
            <Spinner animation="grow" className="bg-primary" />
            <Spinner animation="grow" className="bg-primary" />
            <Spinner animation="grow" className="bg-primary" />
            <Spinner animation="grow" className="bg-primary" />
        </div>
        :
        <div className="col-xl-12">
          <div className="card mt-3">
            <div className="card-header flex justify-between items-center">
                <h4 className="card-title">{t("Executions")}</h4>
                <div className="relative add-and-search flex items-center">
                    <div className="input-group search-area w-auto mr-2">
                        <span className="input-group-text">
                            <Link to={"#"}>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                        fill="var(--primary)"
                                    ></path>
                                </svg>
                            </Link>
                        </span>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t("Search here...")}
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                        />
                    </div>
                
                    <Link
                        to={`/new-execution/${courseId}`}
                        className="btn btn-primary btn-sm"
                        // onClick={() => setShowExcutionsForm(true)}
                    >
                        {t("Create")}
                        <i className="fa-solid fa-plus ml-1"></i>
                    </Link>
                    
                </div>
            </div>
                <div className="card-body">
                    <div className="w-100 table-responsive lessons-table">
                        <div id="example_wrapper" className="dataTables_wrapper">
                            <table id="example" className="display w-100 dataTable">
                              <thead>
                                <tr>
                                  {/* <th className="">{t("ID")}</th> */}
                                  <th className="">{t("Title")}</th>
                                  <th className="">{t("Price")}</th>
                                  <th className="">{t("Discount")}</th>
                                  <th className="">{t("Visibility")}</th>
                                  <th className="">{t("Action")}</th>
                                </tr>
                              </thead>
                                <tbody>
                            {filtredExecutions &&
                            filtredExecutions.length > 0 ?
                            filtredExecutions.map((excution, index) => {
                                const creationDate = new Date(excution.created_at)
                                const creationYear = creationDate.getFullYear();
                                const creationMonth = creationDate.getMonth()+1;
                                const creationDay = creationDate.getDate();
                                const latestUpdateDate = new Date(excution.updated_at)
                                const latestUpdateYear = latestUpdateDate.getFullYear();
                                const latestUpdateMonth = latestUpdateDate.getMonth()+1;
                                const latestUpdateDay = creationDate.getDate();
                                return (
                                  <tr key={index}>
                                    {/* <td className="font-bold">
                                      #{excution.id}
                                    </td> */}
                                    <td className="">
                                      <h4 className="m-0 flex items-center">
                                        <img src={excutionIcon} alt="ex img" className="relative mr-2 w-[20px]" />
                                        {excution.title}
                                      </h4>
                                    </td>
                                    <td>
                                      <h4 className="mb-0 fs-16 font-w500 ml-2">
                                          {excution.price} {settingsData?.site_global_currency}
                                        </h4>
                                    </td>
                                    <td>
                                      <h4 className="mb-0 fs-16 font-w500 ml-2">
                                          {excution.discount ? excution.discount : "No Discount"}
                                        </h4>
                                    </td>
                                    <td className="">
                                      {
                                        excution.is_visible === 1
                                        ?
                                        <span className="p-2 rounded-md text-white bg-green-500">Visible</span>
                                        :
                                        <span className="p-2 rounded-md text-white bg-red-500">Not Visible</span>
                                      }
                                    </td>
                                    <td className="">
                                      <section class="inline-flex justify-center items-center mx-1">
                                        <button
                                          class="group flex justify-center p-2 rounded-md drop-shadow-xl bg-primary from-gray-800 to-black text-white font-semibold hover:rounded-[50%] transition-all duration-500 hover:from-[#331029] hover:to-[#310413]"
                                          onClick={() => {
                                            setOldExecution(excution)
                                            setShowUpdateExcutionsForm(true)
                                          }}
                                        >
                                          <i className="fa-solid fa-pen text-white"></i>
                                          <span
                                            class="pointer-events-none absolute opacity-0 group-hover:opacity-100 group-hover:text-gray-700 group-hover:text-sm group-hover:-translate-y-10 duration-700"
                                          >
                                            {t("Update")}
                                          </span>
                                        </button>
                                      </section>
                                      <section class="inline-flex justify-center items-center mx-1">
                                        <button
                                          href="/"
                                          class="group flex justify-center p-2 rounded-md drop-shadow-xl bg-primary from-gray-800 to-black text-white font-semibold hover:rounded-[50%] transition-all duration-500 hover:from-[#331029] hover:to-[#310413]"
                                          onClick={() => deleteExcution(excution.id)}
                                        >
                                          <i className="fa-solid fa-trash text-white"></i>
                                          <span
                                            class="pointer-events-none absolute opacity-0 group-hover:opacity-100 group-hover:text-gray-700 group-hover:text-sm group-hover:-translate-y-10 duration-700"
                                          >
                                            {t("Delete")}
                                          </span>
                                        </button>
                                      </section>
                                    </td>
                                  </tr>
                                );
                            })
                            :
                            searchValue === ""
                            &&
                            <td colSpan={8} className="flex flex-col justify-center items-center">
                                {/* <img src={noRequirmentsIcon} alt="no subscription" className="w-[50px] border-none"/> */}
                                <p className="m-0 border-none">{t("No Executions!")}</p> 
                            </td>
                            }
                                    {
                                        searchValue !== "" &&filtredExecutions &&filtredExecutions.length < 1 
                                        &&
                                        <tr>
                                            <td colSpan={3}>{t("No Executions!")}</td>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                                <div className="dataTables_info">
                                    {t("Showing")} {activePag.current * sort + 1} to{" "}
                                    {allExcutions.length > (activePag.current + 1) * sort
                                        ? (activePag.current + 1) * sort
                                        : allExcutions.length}{" "}
                                    {t("of")} {allExcutions.length} {t("entries")}
                                </div>
                                <div
                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                    id="example5_paginate"
                                >
                                    <Link
                                        className="paginate_button previous disabled"
                                        to="#"
                                        onClick={() =>
                                            activePag.current > 0 && onClick(activePag.current - 1)
                                        }
                                    >
                                        <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                    </Link>
                                    <span>
                                        {paggination.map((number, i) => (
                                            <Link
                                                key={i}
                                                to="#"
                                                className={`paginate_button  ${activePag.current === i ? "current" : ""
                                                    } `}
                                                onClick={() => onClick(i)}
                                            >
                                                {number}
                                            </Link>
                                        ))}
                                    </span>
                                    <Link
                                        className="paginate_button next"
                                        to="#"
                                        onClick={() =>
                                            activePag.current + 1 < paggination.length &&
                                            onClick(activePag.current + 1)
                                        }
                                    >
                                        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          </div> 
        </div>
      }
      <UpdateExecutionForm
        show={showUpdateExcutionsForm}
        onHide={() => setShowUpdateExcutionsForm(false)}
        course_id = {courseId}
        old_execution = {oldExecution}
      />
      <OverLayLoading loading={deleteExcutionLoading} loadingText={"Deleting Excution..."}/>
    </>
  );
}
export default ExcutionPage;
