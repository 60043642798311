import { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ContextApi from '../../../store/ContextApi';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { addSessionExamFunc } from '../../../store/custom-actions/offline-exams-actions/AddSessionExamSlicer';
import Swal from 'sweetalert2';

function RecordOfflineExamForm(props) {
    const {sessionId , refetchSessions} = props
    const contextApi = useContext(ContextApi);
    const sessionApi = `${contextApi.dashboard_URL_API}/sessions/${sessionId}/offline-exams`;
    const studentsApi = `${contextApi.dashboard_URL_API}/students`
    const {register , handleSubmit , formState : {errors} , reset } = useForm();
    const dispatch = useDispatch();
    const {loading , data} = useSelector(state => state.addSessionExamSlicer);
    const [examImages , setExamsImages] = useState([]);
    const handleSubmitExam = async (data) => {
        console.log(examImages)
        if(data && examImages && examImages.length > 0) {
            const imagesFormData = new FormData();
            for(const examImg of examImages){
                imagesFormData.append("images[]" , examImg)
            }
            imagesFormData.append("max_grade" , data.max_grade)
            dispatch(addSessionExamFunc({api : sessionApi , examData : imagesFormData}))
            .then(result => {
              if(result.payload.success === true) {
                props.onHide();
                reset();
                refetchSessions();
                Swal.fire({
                    title: "Good job!",
                    text: "Addition Exam Is Done!",
                    icon: "success"
                });
              }
            })
        }
    }
    return (
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            New Offline Exam
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className='relative w-full'>
            <form 
                className="choose-question-form" 
                id="choose-question-form"
                onSubmit={handleSubmit((data) => {
                    handleSubmitExam(data)
                  })
                }
              >
                <div className="row question-row">
                  <div className="form-group mb-3 col-sm-12 col-md-6">
                    <label>Student Exams</label>
                    {errors.images?.message && (
                      <p className="text-red-600">{errors.images?.message}</p>
                    )}
                    <input
                    //   {...register("images", {
                    //     required: "Exams Should not be empty!",
                    //   })}
                      type="file"
                      multiple
                      className="form-control bg-blue-500"
                      onChange={(e)=>{
                        setExamsImages(Array.from(e.target.files))
                      }}
                    />
                  </div>
                  <div className="form-group mb-3 col-sm-12 col-md-6">
                    {errors.max_grade?.message && (
                      <p className="text-red-600">{errors.max_grade?.message}</p>
                    )}
                    <label>Exam Grade</label>
                    <input
                      {...register("max_grade", {
                        required: "Exaam grade is required!",
                      })}
                      type="number"
                      min={0}
                      className="form-control"
                    />
                  </div>
                </div>
              </form>
            </div>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={props.onHide}>Close</Button>
            <Button type="submit" form="choose-question-form" className="btn btn-primary flex items-center justify-center">
              {!loading ? (
                "Submit"
              ) : (
                <div className="relative flex justify-center items-center">
                  loading..
                  <div className="loading-spin-data w-[15px] h-[15px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-1"></div>
                </div>
              )}
            </Button>
        </Modal.Footer>
    </Modal>
    );
}
export default RecordOfflineExamForm;