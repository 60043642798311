import { Modal } from "react-bootstrap";
import { useState , useEffect, useContext } from "react";
import { useSelector , useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {Button} from "react-bootstrap";
import swal from "sweetalert"
import { changeUserPasswordUserFunc } from "../../../store/custom-actions/users-actions/ChangeUserPasswordSlicer";
import ContextApi from "../../../store/ContextApi";
import HandleErrorsListFunc from "../../global-functions/handleErrorsListFunc";
import { useTranslation } from "react-i18next";
function ChangeStudentPassForm(props) {
    const {t} = useTranslation();
    const {refetchFunction , userId , student_id} = props;
    const contextApi = useContext(ContextApi)
    const users_API_URL = `${contextApi.dashboard_URL_API}/students`;
    const {changePasswordLoading} = useSelector(state => state.changeUserPasswordSlicer);
    const {register , handleSubmit , formState : {errors} , watch , reset} = useForm();
    const dispatch = useDispatch();
    const [showNewPass , setshowNewPass] = useState(false);
    const [showConfirmPass , setshowConfirmPass] = useState(false);
    const [showNewPassIcon , setShowNewPassIcon] = useState(false);
    const [showConfirmPassIcon , setShowConfirmPassIcon] = useState(false);
    
    const validPass = /[a-zA-Z0-9]{8,}/
    const [newPass , setNewPass] = useState();
    const [confirmnewPass , setConfirmnewPass] = useState();
  
    const handleChangePassword = (data) => {
      if(data) {
        if(newPass !== confirmnewPass) {
          swal(t("Password not confirmed"), t("confirme field should be the same new field!"))
        }else{
          dispatch(changeUserPasswordUserFunc(
            {user_api : `${users_API_URL}/${student_id}/change-password` , new_data : data}
          ))
          .then((result) => {
              if(result.payload.success === true){
                refetchFunction();
                props.onHide();
                reset()
              }
          })
        }
      }
    }
  
  useEffect(() => {
    HandleErrorsListFunc(errors)
  },[])
  
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <i className="fa-solid fa-key mr-1"></i>
          {t("Change Password")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" w-full">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <form id="upload-user-image-form" className="upload-user-image-form"
                  onSubmit={handleSubmit((data)=>{
                    handleChangePassword(data);
                  })}
                >
                  <div className="row relative overflow-hidden">
                    <div className="form-group mb-3 col-12 relative overflow-hidden">
                      <label>{t("New Password")}</label>
                      {errors.new_password?.message && (
                        <p className="text-red-600">
                          {errors.new_password?.message}
                        </p>
                      )}
                      <input 
                        {...register("new_password" , {required : t("New password is required")})}
                        type={showNewPass ? "text" : "password"} 
                        className="form-control"
                        onChange={(e)=>{
                          e.target.value !== "" 
                          ? setShowNewPassIcon(true) 
                          : setShowNewPassIcon(false)
                          setNewPass(e.target.value);
                        }}
                      />
                      <i className={`fa-regular fa-eye absolute transition-all duration-500 ease-in-out top-[70%] ${showNewPassIcon ? "right-[25px] opacity-100" : "right-[-30px] opacity-0"} ${showNewPass ? "text-blue-600" : ""}  translate-y-[-70%] text-[15px] cursor-pointer`}
                        onClick={()=>setshowNewPass(!showNewPass)}
                      ></i>
                    </div>
                  </div>
                  <div className="row relative overflow-hidden">
                    <div className="form-group mb-3 col-12 relative overflow-hidden">
                      <label>{t("Confirm New password")}</label>
                      {errors.new_password_confirmation?.message && (
                        <p className="text-red-600">
                          {errors.new_password_confirmation?.message}
                        </p>
                      )}
                      <input 
                        {...register("new_password_confirmation" , {required : t("Confirme password is required")})}
                        type={showConfirmPass ? "text" : "password"} 
                        className="form-control"
                        onChange={(e)=>{
                          e.target.value !== "" 
                          ? setShowConfirmPassIcon(true) 
                          : setShowConfirmPassIcon(false)
                          setConfirmnewPass(e.target.value);
                        }}
                      />
                      <i className={`fa-regular fa-eye absolute transition-all duration-500 ease-in-out top-[70%] ${showConfirmPassIcon ? "right-[25px] opacity-100" : "right-[-30px] opacity-0"} ${showConfirmPass ? "text-blue-600" : ""} translate-y-[-70%] text-[15px] cursor-pointer`}
                        onClick={()=>setshowConfirmPass(!showConfirmPass)}
                      ></i>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>{
          props.onHide();
        }}>{t("Close")}</Button>
        <Button type="submit" form="upload-user-image-form">
          {!changePasswordLoading 
          ? 
          t("Submit" )
          : 
            <div className="relative flex justify-center items-center">
              {t("loading")}...
              <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-1 "></div>
            </div>
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
  }

export default ChangeStudentPassForm