import { Button, Modal } from "react-bootstrap";
import { editLessonFunc } from "../../../store/custom-actions/lessons-actions/EditLessonSlicer";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { useContext } from "react";
import ContextApi from "../../../store/ContextApi";
import { useTranslation } from "react-i18next";

export default function ShareMatrialForm(props) {
    const {t} = useTranslation()
    const {lesson_id , refetch_course_lessons , lesson_type} = props;
    const contextApi = useContext(ContextApi)
    const lessons_API_URL = `${contextApi.dashboard_URL_API}/lessons/${lesson_id}`;
    const {register , formState : {errors} , handleSubmit , reset} = useForm();
    const dispatch = useDispatch();
    const {loading} = useSelector(state => state.editLessonSlicer);

    return (
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="relative flex items-center">
            <i className="fa-solid fa-share-nodes mr-1"></i>
            {
                lesson_type === "video"
                ?
                t("Share Video")
                :
                t("Share Files")
            }
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form
            id="update-lesson-name-and-desc-form"
            onSubmit={handleSubmit((data) => {
                dispatch(editLessonFunc({
                    lessonApi : lessons_API_URL , 
                    lessonData : data
                    }
                    )).then(result => {
                        if(result.payload.success === true) {
                            refetch_course_lessons();
                            reset();
                        }
                    })
            })}
        >
            <div className="row">
                <div className="form-group mb-3 col-sm-12">
                    <label>
                        {
                            lesson_type === "video"
                            ?
                            t("Video ID")
                            :
                            t("File ID")
                        }
                    </label>
                    {errors.attached_id?.message && (
                    <p className="text-red-600">
                        {errors.attached_id?.message}
                    </p>
                    )}
                    <input
                        {...register("attached_id")}
                        type="number"
                        className="form-control"
                    />
                </div>
            </div>
        </form>
        </Modal.Body>
        <Modal.Footer>
            <button onClick={props.onHide} className="">{t("Close")}</button>
            <Button form="update-lesson-name-and-desc-form" type="submit" className={`primary ${loading ? "opacity-80 pointer-events-none" : ""}`}>
            {loading
                ?
                <>
                <div className="relative flex justify-center items-center">
                  {t("loading")}..
                  <div className="loading-spin-data w-[13px] h-[13px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-2"></div> 
                </div>
                </>
                :
                t("Submit")
              }
            </Button>
        </Modal.Footer>
    </Modal>
    );
}