import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const logoutFunc = createAsyncThunk(
  "logout", 
  async (logout_url) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
      },
  }
  try {
    const response = await axios.post(
        logout_url,
        {},
        config
    );
    if(response.data.success === true) {
      window.localStorage.removeItem("user")
      window.localStorage.removeItem("expire_data")
      window.localStorage.removeItem("token")
    }
    return response.data;
  } catch (error) {
    // Swal.fire({
    //   title: "Oops!",
    //   text: error,
    //   icon: "error"
    // });
    checkErrors(error.response , error.message);
  }
});

const logoutSlicer = createSlice({
  name: "user logout",
  initialState: {
    loading: false,
    data: null,
    error: false,
  },
  reducers: {
    loginAction: (state, action) => {
      state.loading = false;
      state.data = "logged out";
      state.error = false;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(logoutFunc.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = false;
    });
    builder.addCase(logoutFunc.rejected, (state) => {
      state.loading = false;
      state.data = null;
      state.error = true;
    });
    builder.addCase(logoutFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.data = "logged succses";
      state.error = false;
    });
  },
});
export default logoutSlicer.reducer;
export let { loginAction } = logoutSlicer.actions;
