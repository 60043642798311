import { useContext, useEffect, useState } from "react";
import { Button, Modal, OverlayTrigger , Tooltip} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ContextApi from "../../../../../store/ContextApi";
import { approveLessonFunc } from "../../../../../store/custom-actions/lessons-actions/ApproveLessonSlicer";
import { getSingleChapterFunc } from "../../../../../store/custom-actions/chapters/GetSingleChapterSlicer";
import { unApproveLessonFunc } from "../../../../../store/custom-actions/lessons-actions/UnApproveLessonSlicer";
import { showLessonVideoFunc } from "../../../../../store/custom-actions/lessons-actions/ShowLessonVideoSlicer";
import { deleteCategoryFunc } from "../../../../../store/custom-actions/categories-actions/DeleteCategoriesSlicer";
import swal from "sweetalert";
import axios from "axios";
import OverLayLoading from "../../../custome-overlay-loading/OverLayLoading";
import PassingLessonRequireForm from "../../../../global-compnents/lesson-box-forms/PassingLessonRequireForm";
import EditLessonOrder from "../../../../global-compnents/lesson-box-forms/EditLessonOrder";
import EditLessonMatrialForm from "../../../../global-compnents/lesson-box-forms/EditLessonMatrialForm";
import UploadLessonVideoForm from "../../../../global-compnents/lesson-box-forms/UploadLessonVideoForm";
import EditLessonDataForm from "../../../../global-compnents/lesson-box-forms/EditLessonDataForm";
import ShowLessonVideo from "../../../../global-compnents/lesson-box-components/ShowLessonVideoModal";
import { uploadLessonPosterFunc } from "../../../../../store/custom-actions/lessons-actions/UploadLessonPosterSlicer";
import UploadLessonMatrialModale from "./UploadLessonMatrialModale";
import checkUserRole from "../../../../global-functions/checkUserRole";
import CopyQuestionsModale from "./CopyQuestionsModale";
import { Link } from "react-router-dom";

function UploadPosetrModale(props) {
    const {t} = useTranslation();
    const {id , oldPoster , refetchData} = props;
    const contextApi = useContext(ContextApi);
    const lesson_API_URL = `${contextApi.dashboard_URL_API}/lessons/${id}/upload-poster`
    const dispatch = useDispatch();
    const [poster , setPoster] = useState(null);
    const [posterViewr , setPosterViewr] = useState(null);
    const {loading} = useSelector(
        state => state.uploadLessonPosterSlicer
    )
    useEffect(() => oldPoster && setPosterViewr(oldPoster) ,[oldPoster])
    const handleUploadPosetr = async() => {
        if(poster) {
            const formData = new FormData();
            formData.append("poster" , poster);
            dispatch(uploadLessonPosterFunc({api : lesson_API_URL , data: formData}))
            .then((result) => {
                if(result?.payload?.success) {
                    props.onHide();
                    refetchData && refetchData();
                }
            })
        }
    }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className=""
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("Upload Lesson Poster")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="relative flex flex-col justify-center items-center">
            {
                posterViewr
                ?
                <div className="imageViewr my-2">
                    <img src={posterViewr} className="max-w-[300px]"/>
                </div>
                :
                ""
            }
            <form 
            id="upload-poster-form"
            onSubmit={(e) => {
                e.preventDefault();
                handleUploadPosetr()
            }}>
                <div className="row">
                    <div className="form-group mb-3 col-sm-12">
                        <label className="flex items-center">
                            {/* <i class="fa-brands fa-twitter mr-1"></i> */}
                            {t("Upload Poster")}
                        </label>
                        <input
                        type="file"
                        className="form-control"
                        onChange={(e) => {
                            const file = e.target.files[0];
                            if (file) {
                                const fileURL = URL.createObjectURL(file);
                                setPoster(file)
                                setPosterViewr(fileURL)
                            }
                        }}
                        />
                    </div>
                </div>
            </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{t("Close")}</Button>
        <Button type="submit" form="upload-poster-form">{loading ? t("loading") : t("Submit")}</Button>
      </Modal.Footer>
    </Modal>
  );
}


export default function LessonCard({lesson , refetchSingleChapter}) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const handleNavigate = (path) => {
        navigate(path)
    }
    const token = window.localStorage.getItem("token")
    const contextApi = useContext(ContextApi);
    const allLessonsApi = `${contextApi.dashboard_URL_API}/lessons`;
    const chapterApi = `${contextApi.dashboard_URL_API}/chapters/${lesson.chapter_id}`;
    const dispatch = useDispatch();
    const { deleteLessonLoading } = useSelector(
        (state) => state.deleteLessonSlicer
    )
    const { loading: showVideoLoading} = useSelector(
        state => state.showLessonVideoSlicer
    );
    const [videoUrl, setVideoUrl] = useState(null);
    const [publishStatus, setPublishStatus] = useState(lesson?.publish === "publish" ? true : false);
    const lesson_API_URL = `${contextApi.dashboard_URL_API}/lessons/${lesson.id}/approve`
    const update_lessons_api = `${contextApi.dashboard_URL_API}/lessons/${lesson.id}`;
    const approve_lessons_api = `${contextApi.dashboard_URL_API}/lessons/${lesson.id}/approve`;
    const upload_lessons_files_api = `${contextApi.dashboard_URL_API}/lessons/${lesson.id}/upload-file`;
    const matrialApi = `${contextApi.dashboard_URL_API}/lessons/${lesson.id}/stream`;
    const downloadExportingFileApi = `${contextApi.dashboard_URL_API}/lessons/${lesson.id}/questions/export`;
    const [showVideo, setShowVideo] = useState();
    const [showEditNameAndDescForm, setShowEditNameAndDescForm] = useState();
    const [showEditVideoForm, setShowEditVideoForm] = useState();
    const [showEditMatrialForm, setShowEditMatrialForm] = useState();
    const [showEditOrderform, setShowEditOrderForm] = useState();
    const [showPassingRequireFrom, setShowPassingRequireFrom] = useState();
    const [showUploadForm, setShowUploadForm] = useState();
    const [showUploadPoster, setShowUploadPoster] = useState();
    const [showCopyQuestions, setShowCopyQuestions] = useState();
    const handleShowLessonVideo = () => {
        if(lesson.type === "youtube") {
            setVideoUrl(lesson.contents);
            setShowVideo(true);
        }else {
            setVideoUrl(`${contextApi.dashboard_URL_API}/lessons/${lesson?.id}/stream?token=${token}`);
            setShowVideo(true);
        }
    }
    const approveLesson = async () => {
        if(lesson){
            await dispatch(approveLessonFunc({lessonApi: approve_lessons_api}))

        }
    }
    const unApproveLesson = async () => {
        if(lesson){
            await dispatch(unApproveLessonFunc({lessonApi: lesson_API_URL}))
        }
    }
    const showMatrial = async () => {
        dispatch(showLessonVideoFunc(matrialApi))
        .then((result) => {
            if(result.payload.status && result.payload.status === 404 && result.payload.data) {
                swal({
                    title: t("Error"),
                    text: result.payload.data.error,
                    icon: "error",
                    dangerMode: true,
                })
            }else {
                if(result.meta.arg) {
                    const token = window.localStorage.getItem("token");
                    window.open(`${result.meta.arg}?token=${token}`, "_blank");
                }
            }
        })
    }
    const downloadExportingFileFunc = async () => {
        dispatch(showLessonVideoFunc(downloadExportingFileApi))
        .then((result) => {
            console.log(result)
            if(result.payload.status && result.payload.status === 404 && result.payload.data) {
                swal({
                    title: t("Error"),
                    text: result.payload.data.error,
                    icon: "error",
                    dangerMode: true,
                })
            }else {
                if(result.meta.arg) {
                    const token = window.localStorage.getItem("token");
                    window.open(`${result.meta.arg}?token=${token}`, "_self");
                }
            }
        })
    }
    const handleDeleteLesson = async () => {
        const deleteFunc = () => {
          try {
            dispatch(deleteCategoryFunc(`${allLessonsApi}/${lesson?.id}`))
            .then((result)=>{
              if(result.payload.success === true){
                // refetchSingleChapter();
                
                dispatch(getSingleChapterFunc(chapterApi))
              }
            });
          }catch (error) {
            console.log(error);
          }
        }
        swal({
          title: t("Confirm Item Deletion"),
          text: t(`Once deleted, this will not avilable in platform!`),
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            deleteFunc()
          }
        })
    };
    useEffect(() => {
        const token = window.localStorage.getItem("token")
        axios.defaults.headers.common = {'Authorization' : `Bearer ${token}`};
    },[])
    useEffect(() => console.log(lesson) , [lesson])
    return (
        <>
            <div className="col-sm-12 col-md-4">
                <div className="card p-2">
                    <div className="relative flex justify-between items-center my-2">
                        <div className="flex items-center">
                            <i className="fa-solid fa-book  text-secondary"></i>
                            <h4 className="m-0 text-primary mx-1">{lesson?.name}</h4>
                        </div>
                        <div className="relative flex flex-col">
                            <span className="flex items-center">
                                {lesson?.view_count}
                                <i className="fa-solid fa-chalkboard-user mx-1"></i>
                            </span>
                        </div>
                    </div>
                    <div className="about relative flex flex-col mb-1">
                        <div className="flex items-center">
                            <i className="fa-solid fa-quote-left text-secondary"></i>
                            <p className="my-0 mx-1">{t("Type")} :</p>
                            <p className="m-0">{lesson?.type} </p>
                        </div>
                        <div className="flex items-center mb-1">
                            <i className="fa-solid fa-arrow-up-z-a text-secondary"></i>
                            <p className="my-0 mx-1">{t("Order")} :</p>
                            <p className="m-0">{lesson?.order} </p>
                        </div>
                        <div className="flex items-center mb-1">
                            <i className="fa-solid fa-eye text-secondary"></i>
                            <p className="my-0 mx-1">{t("Can View Free")} : </p>
                            <p className="m-0">{lesson?.can_view_without_pay === 0 ? t("Not avilable") : t("Avilable")}</p>
                        </div>
                        <div className="flex items-center mb-1">
                            <i className="fa-regular fa-clock text-secondary"></i>
                            <p className="my-0 mx-1">{t("Duration")} : </p>
                            <p className="m-0">{lesson?.duration} M</p>
                        </div>
                        {
                            lesson?.type === "quiz" && lesson?.questions_count >= 1
                            &&
                            <div className="flex items-center mb-1">
                                <i className="fa-solid fa-question text-secondary"></i>
                                <p className="my-0 mx-1">{t("Questions")} : </p>
                                <p className="m-0">( {lesson?.questions_count} )</p>
                            </div>
                        }
                    </div>
                    <div className="flex justify-between items-center my-2 py-1">
                        <div className="relative flex flex-wrap">
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("Edit")}</Tooltip>}>
                                <span className="d-inline-block bg-primary m-1 p-2 rounded-md text-white cursor-pointer"
                                onClick={() => {
                                    setShowEditNameAndDescForm(true);
                                }}
                                >
                                    <i className="fa-solid fa-pen-to-square"></i>
                                </span>
                            </OverlayTrigger>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("Requiremnets")}</Tooltip>}>
                                <span className="d-inline-block bg-primary m-1 p-2 rounded-md text-white cursor-pointer"
                                onClick={() => handleNavigate(`/requirments/${lesson?.id}`)}
                                >
                                    <i className="fa-solid fa-asterisk"></i>
                                </span>
                            </OverlayTrigger>
                            {
                                (lesson?.type !== "youtube" && lesson?.type !== "rich_text" && lesson?.type !== "quiz")
                                &&
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("Upload")}</Tooltip>}>
                                    <span className="d-inline-block bg-primary m-1 p-2 rounded-md text-white cursor-pointer"
                                    onClick={() => setShowUploadForm(true)}
                                    >
                                        <i className="fa-solid fa-upload"></i>
                                    </span>
                                </OverlayTrigger>
                            }
                            {
                                (lesson?.type === "video" || lesson?.type === "youtube")
                                &&
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("Show Video")}</Tooltip>}>
                                    <span className="d-inline-block bg-primary m-1 p-2 rounded-md text-white cursor-pointer"
                                    onClick={handleShowLessonVideo}
                                    >
                                        <i className="fa-solid fa-eye"></i>
                                    </span>
                                </OverlayTrigger>
                            }
                            {
                                lesson?.type === "quiz"
                                &&
                                <>
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("CSV")}</Tooltip>}>
                                        <span className="d-inline-block bg-primary m-1 p-2 rounded-md text-white cursor-pointer"
                                        onClick={() => setShowUploadForm(true)}
                                        >
                                            <i className="fa-solid fa-file-csv"></i>
                                        </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("Questions")}</Tooltip>}>
                                        <span className="d-inline-block bg-primary m-1 p-2 rounded-md text-white cursor-pointer"
                                        onClick={() => handleNavigate(`/questions/${lesson?.id}`)}
                                        >
                                            <i className="fa-solid fa-arrow-up-z-a"></i>
                                        </span>
                                    </OverlayTrigger>
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("Quezzies")}</Tooltip>}>
                                        <span className="d-inline-block bg-primary m-1 p-2 rounded-md text-white cursor-pointer"
                                        onClick={() => handleNavigate(`/quizzes/${lesson?.id}`)}
                                        >
                                            <i className="fa-solid fa-clipboard-question"></i>
                                        </span>
                                    </OverlayTrigger>
                                    {/* <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("Download Questions")}</Tooltip>}>
                                        <span className="d-inline-block bg-primary m-1 p-2 rounded-md text-white cursor-pointer"
                                        onClick={downloadExportingFileFunc}
                                        >
                                            <i className="fa-solid fa-download"></i>
                                        </span>
                                    </OverlayTrigger> */}
                                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("Copy Questions")}</Tooltip>}>
                                        <span className="d-inline-block bg-primary m-1 p-2 rounded-md text-white cursor-pointer"
                                        onClick={() => setShowCopyQuestions(true)}
                                        >
                                            <i className="fa-solid fa-copy"></i>
                                        </span>
                                    </OverlayTrigger>
                                </>
                            }
                            {
                                lesson?.type === "pdf"
                                &&
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("Download PDF")}</Tooltip>}>
                                    <span className="d-inline-block bg-primary m-1 p-2 rounded-md text-white cursor-pointer"
                                    onClick={showMatrial}
                                    >
                                        <i className="fa-solid fa-file-pdf"></i>
                                    </span>
                                </OverlayTrigger>

                            }
                            {
                                lesson?.type === "file"
                                &&
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("Download File")}</Tooltip>}>
                                    <span className="d-inline-block bg-primary m-1 p-2 rounded-md text-white cursor-pointer"
                                    onClick={showMatrial}
                                    >
                                        <i className="fa-solid fa-file-pdf"></i>
                                    </span>
                                </OverlayTrigger>

                            }
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("Delete")}</Tooltip>}>
                                <span className="d-inline-block bg-primary m-1 p-2 rounded-md text-white cursor-pointer"
                                onClick={handleDeleteLesson}
                                >
                                    <i className="fa-solid fa-ban"></i>
                                </span>
                            </OverlayTrigger>
                        </div>
                        {
                            checkUserRole(
                                ["manager"] ,
                                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{t("Publish")}</Tooltip>}>
                                    <div class="form-check form-switch flex justify-center items-center">
                                        <input className="form-check-input cursor-pointer" type="checkbox" id="flexSwitchCheckDefault"
                                        checked = {publishStatus}
                                        onChange={(e) => {
                                            if(e.target.checked) {
                                                approveLesson();
                                                setPublishStatus(true)
                                            }else {
                                                setPublishStatus(false)
                                                unApproveLesson()
                                            }
                                        }}
                                        />
                                    </div>
                                </OverlayTrigger>
                            )
                        }

                    </div>
                </div>
            </div>
            {
                videoUrl
                &&
                <ShowLessonVideo
                    show={showVideo}
                    onHide={() => setShowVideo(false)}
                    lessonTitle={lesson.name}
                    url={videoUrl}
                    lessonType = {lesson?.type}
                />
            }
        <UploadPosetrModale
            show={showUploadPoster}
            onHide={() => setShowUploadPoster(false)}
            id={lesson.id}
            oldPoster = {lesson?.poster_url}
            refetchData = {() => refetchSingleChapter()}
        />
        <EditLessonDataForm
            show = {showEditNameAndDescForm}
            onHide = {()=>setShowEditNameAndDescForm(false)}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
            refetch_course_lessons = {() => refetchSingleChapter()}
            // course_id = {courseId}
            lesson = {lesson}
            update_lessons_api = {update_lessons_api}
        />
        <UploadLessonVideoForm
            show = {showEditVideoForm}
            onHide = {()=>setShowEditVideoForm(false)}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
            refetch_course_lessons = {() => refetchSingleChapter()}
            // course_id = {courseId}
            lesson = {lesson}
            upload_video_api = {upload_lessons_files_api}
        />
        <EditLessonMatrialForm
            show = {showEditMatrialForm}
            onHide = {()=>setShowEditMatrialForm(false)}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
            refetch_course_lessons = {() => refetchSingleChapter()}
            // course_id = {courseId}
            lesson = {lesson}
            upload_files_url = {upload_lessons_files_api}
            lesson_type = {lesson.type}
        />
        <EditLessonOrder
            show = {showEditOrderform}
            onHide = {()=>setShowEditOrderForm(false)}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
            refetch_course_lessons = {() => refetchSingleChapter()}
            // course_id = {courseId}
            lesson = {lesson}
            upload_files_url = {upload_lessons_files_api}
        />
        <PassingLessonRequireForm
            show = {showPassingRequireFrom}
            onHide = {()=>setShowPassingRequireFrom(false)}
            lesson_title = {lesson.name}
            lesson_id = {lesson.id}
            refetch_course_lessons = {() => refetchSingleChapter()}
            // course_id = {courseId}
            lesson = {lesson}
            // course_lessons = {courseLessons}
            lesson_name = {lesson.name}
        />
        <UploadLessonMatrialModale 
        show={showUploadForm} 
        onHide={() => setShowUploadForm(false)} 
        lesson={lesson}
        handleExportFileFunc={downloadExportingFileFunc}
        />
        <OverLayLoading loading={deleteLessonLoading} loadingText={"Deleting Lesson..."}/>
        <OverLayLoading loading={showVideoLoading} loadingText={"Loading Video..."}/>
        <CopyQuestionsModale
            show={showCopyQuestions}
            onHide={() => setShowCopyQuestions(false)}
            courseId={lesson?.course_id}
            lessonIdWhichCopyForIt = {lesson?.id}
        />
        </>
    )
}
