import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../../jsx/global-functions/checkErrorsFunction";
import Swal from "sweetalert2";
import { checkErrors } from "../../../../jsx/global-functions/checkErrors";
export const getLessonRequirmentsFunc = createAsyncThunk(
    "get lesson requirments function",
    async (api) => {
        try {
            const response = await axios.get(api );
            checkErrorsFunctions(response , response.data.errors);
            return response.data.data.lessonRequirement;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
        
    }
);

const getLessonRequirmentsSlicer = createSlice({
    name : "get lesson requirments",
    initialState : {
        loading : null,
        data : [],
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(getLessonRequirmentsFunc.pending , (state)=>{
            state.loading = true
            state.data = []
            state.error = null
        })
        builder.addCase(getLessonRequirmentsFunc.rejected , (state)=>{
            state.loading = false
            state.data = []
            state.error = true
        })
        builder.addCase(getLessonRequirmentsFunc.fulfilled , (state , action)=>{
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
});
export default getLessonRequirmentsSlicer.reducer;