import { Button, Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import { editLessonFunc } from "../../../store/custom-actions/lessons-actions/EditLessonSlicer";
import { useContext, useEffect, useState } from "react";
import ContextApi from "../../../store/ContextApi";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import HandleErrorsListFunc from "../../global-functions/handleErrorsListFunc";
import { useTranslation } from "react-i18next";

export default function EditLessonDataForm(props) {
    const {t} = useTranslation();
    const {lesson , lesson_id , lesson_title , refetch_course_lessons} = props;
    const contextApi = useContext(ContextApi)
    const lessons_API_URL = `${contextApi.dashboard_URL_API}/lessons/${lesson_id}`;
    const {register , formState : {errors} , setValue , handleSubmit , reset} = useForm();
    const dispatch = useDispatch();
    const {loading} = useSelector(state => state.editLessonSlicer);
    const [lessonContents , setLessonContents] = useState(lesson && lesson.contents && lesson.contents);
    const [lessonDescription , setLessonDescription] = useState(lesson.description);
    const [lessonOrder , setLessonOrder] = useState();
    const [oldOrder , setOldOrder] = useState();
    useEffect(() => {
        if(lesson){
            setValue("name" , lesson.name);
            setValue("view_count" , lesson.view_count);
            setValue("duration" , lesson.duration);
            setValue("order" , lesson.order);
            setValue("questions_count" , lesson.questions_count);
            setOldOrder(lesson.order)
            setValue("can_view_without_pay" , lesson.can_view_without_pay);
        }
    },[setValue , lesson]);
    const colors = [
        '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
        '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
    ];
    const modules = {
        toolbar: {
        container: [
        [{"color" : colors}],
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['link' , "video"],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'align': [] }],
        ['clean']
        ],
    }
    };
    const handleSubmitData = (data) => {
        dispatch(editLessonFunc({
            lessonApi : lessons_API_URL , 
            lessonData : {...data , contents : lessonContents , description : lessonDescription}
            }
            )).then(result => {
                if(result.payload.success === true) {
                    refetch_course_lessons();
                    // reset();
                    props.onHide();
                }
        })
    }
    useEffect(() => {
        HandleErrorsListFunc(errors)
    },[errors])
    return (
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            {t("Update Data")}
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form
            id="update-lesson-name-and-desc-form"
            onSubmit={handleSubmit((data) => {
                handleSubmitData(data)
            })}
        >
            <div className="row">
                <div className="form-group mb-3 col-sm-12 col-md-4">
                    <label>{t("Title")}</label>
                    {errors.name?.message && (
                    <p className="text-red-600">
                        {errors.name?.message}
                    </p>
                    )}
                    <input
                    {...register("name" , {required : t("Lesson Name Is Required!")})}
                    type="text"
                    className="form-control"
                    />
                </div>
                <div className="form-group mb-3 col-sm-12 col-md-4">
                    <label>{t("View Count")}</label>
                    {errors.view_count?.message && (
                    <p className="text-red-600">
                        {errors.view_count?.message}
                    </p>
                    )}
                    <input
                    {...register("view_count" , {required : t("Lesson view Count Is Required!")})}
                    type="number"
                    className="form-control"
                    />
                </div>
                <div className="form-group mb-3 col-sm-12 col-md-4">
                    <label className="flex items-center">
                        <i className="fa-solid fa-rss mr-1"></i>
                        {t("Avilable For Free")}..
                    </label>
                    {errors.can_view_without_pay?.message && (
                    <p className="text-red-600">
                        {errors.can_view_without_pay?.message}
                    </p>
                    )}
                    <select
                        {...register("can_view_without_pay", {
                            required: "view_count is Required"
                        })}
                        className="form-control"
                    >
                        <option value={1}>{t("Avilable")}</option>
                        <option value={0}>{t("Not Avilable")}</option>
                    </select>
                </div>
            </div>
            <div className="row">
                <div className="form-group mb-3 col-sm-12 col-md-6">
                    <label>{t("Duration")}</label>
                    {errors.duration?.message && (
                    <p className="text-red-600">
                        {errors.duration?.message}
                    </p>
                    )}
                    <input
                    {...register("duration")}
                    type="text"
                    className="form-control"
                    />
                </div>
                <div className="form-group mb-3 col-sm-12 col-md-6">
                    <label>{t("Lesson Order")}</label>
                    <input
                    {...register("order" , {required : t("Order Is Required")})}
                    type="number"
                    min={1}
                    required
                    className="form-control"
                    onChange={(e)=>setLessonOrder(e.target.value)}
                    // placeholder="New Order"
                    />
                </div>
            </div>
            {
                lesson && lesson.type === t("quiz")
                &&
                <div className="form-group mb-3 col-sm-12">
                    <label className="flex items-center">
                        <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                        {t("Questions Count")}
                    </label>
                    {errors.questions_count?.message && (
                    <p className="text-red-600">
                        {errors.questions_count?.message}
                    </p>
                    )}
                    <input
                    {...register("questions_count", {
                        required: "Questions Count is required!"
                    })}
                    type="number"
                    className="form-control"
                    />
                </div>
            }
            {
                lesson && lesson.type === "youtube"
                &&
                <div className="form-group col-sm-12 mb-2">
                    <label htmlFor="check-if-free-lesson" className="flex items-center ml-2 translate-y-[2px] cursor-pointer">
                        <i className="fa-brands fa-youtube mr-1"></i>
                        {t("Youtube Video")}
                    </label>
                    <input 
                        value={lessonContents}
                        type="url" 
                        placeholder={t("Youtube URL...")} 
                        className="form-control col-sm-12"
                        onChange={(e) => {
                            setLessonContents(e.target.value)
                        }}
                    />
                </div>
            }
            <div className="lesson-description-line mb-3 relative my-3 flex justify-center items-center after:absolute after:left-[50%] after:translate-x-[-50%] after:top-[50%] after:px-2 after:bg-[#eaebef] after:content-['Lesson Description'] after:translate-y-[-50%] bg-slate-300 w-full h-[1px]"></div>
            <div className="row">
                <ReactQuill 
                modules = {modules}
                className="p-0 border-none outline-none form-control bg-white mt-3" 
                value={lessonDescription} theme="snow" 
                onChange={setLessonDescription} />
            </div>
            {
                lesson && lesson.type === "rich_text"
                &&
                <>
                    <div className="lesson-text-content-line mb-3 relative my-3 flex justify-center items-center after:absolute after:left-[50%] after:translate-x-[-50%] after:top-[50%] after:px-2 after:bg-[#eaebef] after:content-['Lesson Content'] after:translate-y-[-50%] bg-slate-300 w-full h-[1px]"></div>
                    <div className="row">
                        <ReactQuill
                            modules = {modules}
                            className="p-0 border-none outline-none form-control bg-white" 
                            theme="snow" 
                            value={lessonContents} 
                            onChange={(content) => setLessonContents(content)}
                        />
                    </div>
                </>
            }
        </form>
        </Modal.Body>
        <Modal.Footer>
            <button onClick={props.onHide} className="">{t("Close")}</button>
            <Button form="update-lesson-name-and-desc-form" type="submit" className={`primary ${loading ? "opacity-80 pointer-events-none" : ""}`}>
            {loading
                ?
                <>
                <div className="relative flex justify-center items-center">
                  {t("loading")}...
                  <div className="loading-spin-data w-[13px] h-[13px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-2"></div> 
                </div>
                </>
                :
                t("Submit" )
              }
            </Button>
        </Modal.Footer>
    </Modal>
    );
}