import { CloseButton, Modal, Nav , Tab} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import ContextApi from "../../../../../store/ContextApi";
import { uploadLessonPosterFunc } from "../../../../../store/custom-actions/lessons-actions/UploadLessonPosterSlicer";
import swal from "sweetalert";
import axios from "axios";
import checkErrorsFunctions from "../../../../global-functions/checkErrorsFunction";
export default function UploadLessonMatrialModale({show , onHide , lesson , refetchData , handleExportFileFunc}) {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const contextApi = useContext(ContextApi);
    const imagesApi = `${contextApi.dashboard_URL_API}/uploads`;
    const [activeTab , setActiveTab] = useState((lesson?.type !== "file" && lesson?.type !== "pdf" ? "video" : "file"));
    // const [activeTab , setActiveTab] = useState((lesson?.type === "quiz" && "export") (lesson?.type === "video" && "video") (lesson?.type === "pdf" || lesson?.type === "file" && "file"));
    const lesson_API_URL = `${contextApi.dashboard_URL_API}/lessons/${lesson?.id}/upload-poster`;
    const upload_lessons_files_api = `${contextApi.dashboard_URL_API}/lessons/${lesson?.id}/upload-file`;
    const [poster , setPoster] = useState(null);
    const [posterViewr , setPosterViewr] = useState(null);
    const {loading:uploadPosterLoading} = useSelector(
        state => state.uploadLessonPosterSlicer
    )
    useEffect(() => lesson?.poster && setPosterViewr(lesson?.poster) ,[lesson]);
    useEffect(() => lesson?.type === "quiz" && setActiveTab("import") ,[lesson]);

    // ## upload poster
    const handleUploadPoster = async() => {
        if(poster) {
            const formData = new FormData();
            formData.append("poster" , poster);
            dispatch(uploadLessonPosterFunc({api : lesson_API_URL , data: formData}))
            .then((result) => {
                if(result?.payload?.success) {
                    onHide();
                    refetchData && refetchData();
                    setActiveTab("video");
                }
            })
        }
    }



    // ## upload video
    const [video , setVideo] = useState("file")
    const [videoType , setVideoType] = useState("file")
    const [uploadVideoPercent , setUploadVideoPercent] = useState();
    const [videoPercent , setVideoPercent] = useState(0);
    const [loadingVideo , setLoadingVideo] = useState();
    const token = window.localStorage.getItem("token");
    const upload_video_api = `${contextApi.dashboard_URL_API}/lessons/${lesson?.id}/upload-file`;
    const handleUploadVideo = () => {
        if(token) {
            if(video && videoType){
                const formData = new FormData();
                videoType === "file" 
                ? formData.append('file', video) 
                : formData.append('file', video);
                // formData.append('video_type', videoType);
                    axios.post(
                        upload_video_api , 
                        formData , 
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`, // Add the token to the headers
                                'Content-Type': 'multipart/form-data' // Ensure the content type is set correctly
                            },
                        onUploadProgress : (data) => {
                        setUploadVideoPercent(Math.floor((data.loaded / data.total) * 100))
                        setLoadingVideo(true)
                    }})
                    .then((response) => {
                        console.log(response)
                        checkErrorsFunctions(response , response.data.errors);
                        if(response.data.success === true) {
                            setLoadingVideo(false);
                            refetchData && refetchData();
                            onHide();
                            setActiveTab("video");
                            setUploadVideoPercent(null)
                        }else {
                            setLoadingVideo(false);
                            setUploadVideoPercent(null)
                        }
                    })
            }
        }else {
            swal({
                title: t("Oops!"),
                text: t("Token Expired!"),
                icon: "warning",
                button: "Retry",
            });
        }
    }
    useEffect(() => {
        setVideoPercent(uploadVideoPercent);
    },[uploadVideoPercent]);



    // ## upload matrial
    const [lessonMatrial  ,setLessonMatrial] = useState();
    const [uploadMatrialPercent , setUploadMatrialPercent] = useState();
    const [matrialPercent , setMatrialPercent] = useState(0);
    const [loadingMatrial , setLoadingMatrial] = useState();
    const [accept , setAccept] = useState("");
    const handleUploadMatrial = async () => {
        if(lessonMatrial ){
            const matrialFormData = new FormData();
            matrialFormData.append("file" , lessonMatrial)
        axios.post(upload_lessons_files_api , matrialFormData , {onUploadProgress : (data) => {
            setUploadMatrialPercent(Math.floor((data.loaded / data.total) * 100))
            setLoadingMatrial(true)
            }})
            .then((response) => {
                checkErrorsFunctions(response , response.data.errors);
                if(response.data.success === true) {
                    setLoadingMatrial(false);
                    refetchData && refetchData();
                    setLoadingMatrial(false);
                    onHide();
                    setUploadMatrialPercent(null)
                }else {
                    setLoadingMatrial(false);
                }
            })
        }
    }
    // ## upload importing
    const [exportingFile, setExportingFile] = useState(null);
    const [uploadImportPercent , setUploadImportPercent] = useState();
    const [importingPercent , setImportingPercent] = useState(0);
    const [loadingImporting , setLoadingImporting] = useState();
    const exportingApi = `${contextApi.dashboard_URL_API}/lessons/${lesson?.id}/questions/import`;
    const handleUploadImporting = async () => {
        if(exportingFile ){
            const importingForm = new FormData();
            importingForm.append("file" , exportingFile);
            console.log(importingForm)
        axios.post(exportingApi , importingForm , {onUploadProgress : (data) => {
            setUploadImportPercent(Math.floor((data.loaded / data.total) * 100))
            setLoadingImporting(true)
            }})
            .then((response) => {
                console.log(response)
                checkErrorsFunctions(response , response.data.errors);
                if(response.data.success === true) {
                    setLoadingImporting(false);
                    refetchData && refetchData();
                    setLoadingImporting(false);
                    onHide();
                    setUploadImportPercent(null)
                }else {
                    setLoadingImporting(false);
                }
            })
        }
    }

    useEffect(() => {
        setMatrialPercent(uploadMatrialPercent);
    },[uploadMatrialPercent , matrialPercent]);
    useEffect(() => {
        setImportingPercent(uploadImportPercent);
    },[uploadImportPercent , importingPercent]);

    const checkAcceptLessonFiles = () => {
        if(lesson?.type !== "") {
            switch(lesson?.type) {
                case "pdf":
                    setAccept("application/pdf");
                    break;
                case "file":
                    setAccept(".zip,.rar");
                    break;
                case "rich_text":
                    setAccept(".doc,.docx");
                    break;
                case "quiz":
                    setAccept(".xls,.xlsx");
                    break;
                default:
                    setAccept("application/pdf");
            }
        } 
    }
    useEffect(() => {
        checkAcceptLessonFiles();
    },[lesson])
    return (
        <>
            <Modal show={show} size="lg" className="xxl" onHide={() => onHide()} style={{zIndex : 999999999}}>
                <Modal.Body>
                <div className="">
                    <Tab.Container defaultActiveKey={"video"} activeKey={activeTab}>
                        <Modal.Header closeButton className="p-0">
                            <nav className="flex items-center justify-between">
                                <Nav as='div' className="nav nav-tabs border-none justify-content-start tab-auto w-fit" id="nav-tab" role="tablist">
                                {
                                    lesson?.type === "video"
                                    &&
                                    <>
                                        <Nav.Link as='button' className="nav-link" id="" eventKey='video' onClick={() => setActiveTab("video")}>{t("Video")}</Nav.Link>
                                        <Nav.Link as='button' className="nav-link" id=""  eventKey='poster' onClick={() => setActiveTab("poster")}>{t("Poster")}</Nav.Link>
                                    </>
                                }
                                {
                                    lesson?.type !== "video" && lesson?.type !== "quiz"
                                    &&
                                    <Nav.Link as='button' className="nav-link" id=""  eventKey='file' onClick={() => setActiveTab("file")}>{t("File (PDF* ZIP*)")}</Nav.Link>
                                }
                                {
                                    lesson?.type === "quiz"
                                    &&
                                    <>
                                        <Nav.Link as='button' className="nav-link" id=""  eventKey='import' onClick={() => setActiveTab("import")}>{t("Import")}</Nav.Link>
                                        <Nav.Link as='button' className="nav-link" id=""  eventKey='export' onClick={() => setActiveTab("export")}>{t("Export")}</Nav.Link>
                                    </>
                                }
                                </Nav>
                                {/* <div className="relative w-full flex justify-end items-center my-2"> */}
                                    {/* <CloseButton onClick={onHide}/> */}
                                {/* </div> */}
                            </nav>
                        </Modal.Header>
                      <Tab.Content >
                        <Tab.Pane hidden={activeTab !== "video"} eventKey='video' className="flex justify-center items-center ">
                            <form id="update-video-form" className="update-video-form"
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                    handleUploadVideo()
                                }}
                            >
                                <div className="form-group mb-3 col-sm-12">
                                    <label>
                                        {t("Upload Video")}
                                    </label>            
                                    <input
                                    required
                                    type="file"
                                    className="form-control"
                                    accept="video/mp4"
                                    onChange={(e)=>{
                                        setVideo(e.target.files[0])
                                    }}
                                    />
                                </div>
                                {
                                    videoPercent >= 1
                                    &&
                                    <div className={`relative my-2 w-full h-[20px] bg-orange-300 rounded-md overflow-hidden`}>
                                        <span
                                        role="progressbar"
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                        style={{width : `${videoPercent}%`}}
                                            className={`absolute top-0 text-white transition-all duration-700 left-0 h-full bg-primary flex justify-center items-center`}>
                                            {videoPercent}%
                                        </span>
                                    </div>
                                }
                                <div className="relative w-full flex justify-end items-center my-2">
                                    <button form="update-video-form" type="submit" className={`btn btn-primary sw-btn-next ms-1 ${loadingVideo ? "pointer-events-none opacity-50" : ""}`}
                                    >
                                        {
                                            loadingVideo
                                            ?
                                            t("loading")
                                            :
                                            "Submit"
                                        }
                                    </button>
                                </div>
                            </form>
                        </Tab.Pane>
                        <Tab.Pane hidden={activeTab !== "poster"} eventKey='poster' className="flex flex-col justify-center items-center ">
                            {
                                posterViewr
                                ?
                                <div className="imageViewr my-2">
                                    <img src={posterViewr} className="max-w-[300px]"/>
                                </div>
                                :
                                ""
                            }
                            <form 
                            id="upload-poster-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                handleUploadPoster()
                            }}>
                                <div className="row">
                                    <div className="form-group mb-3 col-sm-12">
                                        <label className="flex items-center">
                                            {t("Upload Poster")}
                                        </label>
                                        <input
                                        type="file"
                                        className="form-control"
                                        onChange={(e) => {
                                            const file = e.target.files[0];
                                            if (file) {
                                                const fileURL = URL.createObjectURL(file);
                                                setPoster(file)
                                                setPosterViewr(fileURL)
                                            }
                                        }}
                                        />
                                    </div>
                                </div>
                                <div className="relative w-full flex justify-end items-center my-2">
                                    <button form="upload-poster-form" type="submit" className={`btn btn-primary sw-btn-next ms-1 ${uploadPosterLoading ? "pointer-events-none opacity-50" : ""}`}
                                    >
                                        {
                                            uploadPosterLoading
                                            ?
                                            t("loading")
                                            :
                                            "Submit"
                                        }
                                    </button>
                                </div>
                            </form>
                        </Tab.Pane>
                        <Tab.Pane hidden={activeTab !== "file"} eventKey='file' className="flex justify-center items-center  ">
                            <form
                                id="update-matrial-form"
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                    handleUploadMatrial();
                                }}
                            >
                                <>
                                    <div className="form-group mb-3 col-sm-12">
                                    <label>{t("Lesson File")} ({accept})</label>
                                    <input
                                        type="file"
                                        required
                                        className="form-control"
                                        accept={accept}
                                        onChange={(e)=>{
                                            setLessonMatrial(e.target.files[0])
                                        }}
                                    />
                                    </div>
                                    <div className={`relative ${matrialPercent > 0 ? "flex" : "hidden"} w-full h-[30px] rounded-md overflow-hidden bg-orange-300`}>
                                        <span
                                        role="progressbar"
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                        style={{width : `${matrialPercent}%`}}
                                        className={`absolute top-0 text-white transition-all duration-700 left-0 h-full bg-primary flex justify-center items-center ${!loadingMatrial ? "upload-done" : ""}`}>
                                        {matrialPercent}%
                                        </span>
                                    </div>
                                </>
                                
                                <div className="relative w-full flex justify-end items-center my-2">
                                    <button form="update-matrial-form" type="submit" className={`btn btn-primary sw-btn-next ms-1 ${loadingMatrial ? "pointer-events-none opacity-50" : ""}`}
                                    >
                                        {
                                        loadingMatrial
                                        ?
                                        t("loading")
                                        :
                                        t("Submit" )
                                        }
                                    </button>
                                </div>
                            </form>
                        </Tab.Pane>
                        <Tab.Pane hidden={activeTab !== "import"} eventKey='import' className="flex justify-center items-center  ">
                            <form
                                id="import-questions-file"
                                onSubmit={(e)=>{
                                    e.preventDefault();
                                    handleUploadImporting();
                                }}
                            >
                                <>
                                    <div className="form-group mb-3 col-sm-12">
                                    <label>{t("Questions File")}</label>
                                    <input
                                        type="file"
                                        required
                                        className="form-control"
                                        accept={accept}
                                        onChange={(e)=>{
                                            setExportingFile(e.target.files[0])
                                        }}
                                    />
                                    </div>
                                    <div className={`relative ${importingPercent > 0 ? "flex" : "hidden"} w-full h-[30px] rounded-md overflow-hidden bg-orange-300`}>
                                        <span
                                        role="progressbar"
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                        style={{width : `${importingPercent}%`}}
                                        className={`absolute top-0 text-white transition-all duration-700 left-0 h-full bg-primary flex justify-center items-center ${!loadingMatrial ? "upload-done" : ""}`}>
                                        {importingPercent}%
                                        </span>
                                    </div>
                                </>
                                
                                <div className="relative w-full flex justify-end items-center my-2">
                                    <button form="import-questions-file" type="submit" className={`btn btn-primary sw-btn-next ms-1 ${(loadingImporting || !exportingFile) ? "pointer-events-none opacity-50" : ""}`}
                                    >
                                        {
                                        loadingImporting
                                        ?
                                        t("loading")
                                        :
                                        t("Import" )
                                        }
                                    </button>
                                </div>
                            </form>
                        </Tab.Pane>
                        <Tab.Pane hidden={activeTab !== "export"} eventKey='export' className="flex justify-center items-center  ">
                            <div className="relative w-full flex justify-center items-center my-2">
                                <button  className={`btn btn-primary sw-btn-next ms-1`}

                                onClick={() => handleExportFileFunc()}
                                >
                                    {
                                    t("Export File" )
                                    }
                                </button>
                            </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>	
                  </div>
                </Modal.Body>
            </Modal>
        </>
    )
}