import { Button, Modal } from "react-bootstrap";
import { updateCourseImageFunc } from "../../../store/custom-actions/courses-actions/UpdateCourseImageSlicer";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import ContextApi from "../../../store/ContextApi";
import { checkErrors } from "../../global-functions/checkErrors";
import 'react-image-crop/dist/ReactCrop.css'
import { useTranslation } from "react-i18next";

function UpdateCourseImageForm(props) {
    const {t} = useTranslation();
    const { updateCourseImageLoading } = useSelector((state) => state.updateCourseImageSlicer);
    const dispatch = useDispatch();
    const [newImage , setNewImage] = useState();
    const contextApi = useContext(ContextApi);
    const courses_API_URL = `${contextApi.dashboard_URL_API}/courses`;
    const {
      course_id,
      refetch_data_func
    } = props;
    const handleSubmitData = () => {
      if(newImage) {
        const courseFormData = new FormData();
        courseFormData.append("image" , newImage);
        try {
          dispatch(
            updateCourseImageFunc({
              courseApi: `${courses_API_URL}/${course_id}/upload-image`,
              courseData: courseFormData,
            })
          ).then(((result) => {
            if(result.payload.success === true){
                refetch_data_func()
              props.onHide();
            }
          }))
        }
        catch(error) {
          checkErrors(error.response , error.message);
        }
      }
    }
    const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if(newImage) {
      const url = URL.createObjectURL(newImage)
      setImageUrl(url);

      // Clean up the object URL when the component unmounts or when newImage changes
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  },[newImage])
  
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("Update Image")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" w-full">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <form id="upload-user-image-form" className="upload-user-image-form"
                  onSubmit={(e)=>{
                    e.preventDefault();
                    handleSubmitData()
                  }}
                >
                  <div className="row">
                    <div className="form-group mb-3 col-sm-12">
                      <label>{t("New Course Image")} ({t("Best Size")} 350 * 220)</label>
                      <input 
                        type="file" 
                        className="form-control"
                        onChange={(e)=>setNewImage(e.target.files[0])}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>{
          props.onHide();
        }}>{t("Close")}</Button>
        <Button type="submit" form="upload-user-image-form" className={`${updateCourseImageLoading ? "pointer-events-none opacity-80" : ""}`}>
          {!updateCourseImageLoading 
          ? 
          t("Submit" )
          : 
            <div className="relative flex justify-center items-center">
              {t("loading")}...
              <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-1 "></div>
            </div>
          }
        </Button>
      </Modal.Footer>
    </Modal>
  );
  }
  export default UpdateCourseImageForm