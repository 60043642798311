import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const fetchAllCoursesFunc = createAsyncThunk(
    "get all courses function",
    async (api) => {
        try {
            const response = await axios.get(api );
            checkErrorsFunctions(response , response.data.errors);
            return response.data.data.courses;
        }
        catch (error) {
            // swal({
            //     title: "Oops!",
            //     text: error,
            //     icon: "warning",
            //     button: "Retry",
            // });
            checkErrors(error.response , error.message);
        }
        
    }
)

const GetAllCoursesSlicer = createSlice({
    name : "get all courses slicer",
    initialState : {
        loading : null,
        courses : null,
        error : null
    },
    reducer : {},
    extraReducers : (builder) => {
        builder.addCase(fetchAllCoursesFunc.pending , (state) => {
            state.loading = true
            state.courses = null
            state.error = null
        })
        builder.addCase(fetchAllCoursesFunc.rejected , (state) => {
            state.loading = null
            state.courses = null
            state.error = true
        })
        builder.addCase(fetchAllCoursesFunc.fulfilled , (state , action) => {
            state.loading = false
            state.courses = action.payload
            state.error = null
        })
    }
})

export default GetAllCoursesSlicer.reducer;