import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
export const filterCoursesFunc = createAsyncThunk(
    "filterv courses function",
    async (api) => {
        try {
            const response = await axios.get(api );
            // if(response.data.status_code === 401 || response.data.status_code === 403) {
            //             window.localStorage.clear();
            //             window.location.reload();
            // }else{
            //     return response.data.data.courses;
            // }
            // checkErrorsFunctions(response , response.data.errors);
            // return response.data.data.courses;
        }
        catch (error) {
            swal({
                title: "Oops!",
                text: error,
                icon: "warning",
                button: "Retry",
            });
        }
        
    }
)

const filterCoursesSlicer = createSlice({
    name : "filterv courses slicer",
    initialState : {
        filterLoading : null,
        filtredCourses : null,
        error : null
    },
    reducer : {},
    extraReducers : (builder) => {
        builder.addCase(filterCoursesFunc.pending , (state) => {
            state.filterLoading = true
            state.filtredCourses = null
            state.error = null
        })
        builder.addCase(filterCoursesFunc.rejected , (state) => {
            state.filterLoading = null
            state.filtredCourses = null
            state.error = true
        })
        builder.addCase(filterCoursesFunc.fulfilled , (state , action) => {
            state.filterLoading = false
            state.filtredCourses = action.payload
            state.error = null
        })
    }
})

export default filterCoursesSlicer.reducer;