import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const getNotficationsFunc = createAsyncThunk(
    "get notfications function",
    async (api) => {
        try {
            const response = await axios.get(api );
            checkErrorsFunctions(response , response.data.errors);
            return response.data.data;
        }
        catch (error) {
            return error
        }
        
    }
);

const getNotficationSlicer = createSlice({
    name : "get notfications slicer",
    initialState : {
        loading : null,
        data : null,
        error : false
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(getNotficationsFunc.pending , (state)=>{
            state.loading = true
            state.data = null
            state.error = null
        })
        builder.addCase(getNotficationsFunc.rejected , (state)=>{
            state.loading = false
            state.data = null
            state.error = true
        })
        builder.addCase(getNotficationsFunc.fulfilled , (state , action)=>{
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
});
export default getNotficationSlicer.reducer;