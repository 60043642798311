import React from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
// import CourseCard from "./CustomCourseCard";
import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContextApi from "../../../store/ContextApi";
import { fetchAllCoursesFunc } from "../../../store/custom-actions/courses-actions/GetAllCoursesSlicer";
import swal from "sweetalert";
import { deleteCourseFunc } from "../../../store/custom-actions/courses-actions/DeleteCourseSlicer";
import { Modal, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { addNewCourseFunc } from "../../../store/custom-actions/courses-actions/AddCourseSlicer";
import faildImg from "../../../media/images/fail-img.svg";
import { fetchUsersFunc } from "../../../store/custom-actions/users-actions/GetUsersSlicer";
import { fetchAllCategoriesFunc } from "../../../store/custom-actions/categories-actions/GetAllCategoriesSlicer";
import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
import OfflineCourseCard from "./OfflineCourseCard";
import defaultTeacherImg from "../../../media/images/instructor.png";
import checkUserRole from "../../global-functions/checkUserRole";
import ReactQuill from "react-quill";
import HandleErrorsListFunc from "../../global-functions/handleErrorsListFunc";
import { toast } from "react-toastify";
import PageTitle from "../../layouts/PageTitle";


function AddNewCourseForm(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm();
  const user = JSON.parse(window.localStorage.getItem("user"));
  const userRole = user && user.roleName
  const contextApi = useContext(ContextApi);
  const { loading } = useSelector((state) => state.addNewCourseSlicer);
  const { loading:getCategoriesLoading , categoriesData } = useSelector(
    (state) => state.getAllCategoriesSlicer
  );
  const { loading:getUsersLoading , data:users } = useSelector(
    (state) => state.fetchUsersSlicer
  );
  const dispatch = useDispatch();
  const { allcourses } = props;
  const courseName = watch("name");
  const courseInstructor = watch("user_id");
  const [invalidCourseInstructor, setInvalidCourseInstructor] = useState();
  const [chooseInstructor, setChooseInstructor] = useState();
  const [instructorWhichChoosed, setInstructorWhichChoosed] = useState("");
  const categories_api = `${contextApi.dashboard_URL_API}/categories`;
  const [categoriesList , setCategoriesList] = useState([]);
  const courses_API_URL = `${contextApi.dashboard_URL_API}/courses`;
  const instructors_API_URL = `${contextApi.dashboard_URL_API}/users?role=teacher`;
  const [instructorsList , setInstructorsList] = useState([]);
  const [instructorWhichChoosedImg , setInstructorWhichChoosedImg] = useState("");
  const [courseDescription , setCourseDescription] = useState("");
  const coursesAPi = `${contextApi.dashboard_URL_API}/courses`;
  useEffect(() => {
    courseInstructor > 0
      ? setInvalidCourseInstructor(false)
      : setInvalidCourseInstructor(true);
  }, [courseInstructor]);
  useEffect(() => {
    const getCategoriesFunc = async () => {
      dispatch(fetchAllCategoriesFunc(`${categories_api}`))
    }
    getCategoriesFunc()
  },[categories_api])
  
  useEffect(()=>{
    if(userRole && userRole.toLowerCase() === "manager") {
      const getAllInstructors = async () => {
          try {
              dispatch(fetchUsersFunc(instructors_API_URL));
          }
          catch(error) {
              console.log(error);
          }
      }
      getAllInstructors();
    }
},[])

useEffect(()=>{
    const instructors = users && users.filter(user => user.roles[0].name.toLowerCase() === "teacher");
    setInstructorsList(instructors)
},[users]);

  useEffect(()=>{
    categoriesData && setCategoriesList(categoriesData)
  },[categoriesData])

  const handleSubmitData = (data) => {
    if(courseDescription !== "") {
      try {
        dispatch(
          addNewCourseFunc({
            coursesApi: coursesAPi,
            courseData: {
              ...data , description : courseDescription,
              user_id: !data.user_id ? user.id : data.user_id,
              type : "offline"
            },
          })
        ).then(((result) => {
          if(result.payload.success === true){
            dispatch(fetchAllCoursesFunc(courses_API_URL))
            reset();
            props.onHide();
          }
        }))
      }
      catch(error) {
        console.log(error)
      }
    }else {
      toast.error("Course Description  Is Required!")
    }
  }

  const modules = {
    toolbar: {
    container: [
    [{ 'header': [1, 2, 3, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    ['link'],
    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
    [{ 'align': [] }],
    ['clean']
    ],
  }
  };
  useEffect(() => {
    HandleErrorsListFunc(errors)
  },[errors])

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter flex items-center">
          <i className="fa-brands fa-discourse mr-1"></i>
          New Offline Course
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" w-full">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <form
                  id="new-course-form"
                  onSubmit={handleSubmit((data) => {
                    if(userRole && userRole.toLowerCase() === "manager") {
                      if (invalidCourseInstructor) {
                        swal("choose course instructor!", "");
                      } else {
                        handleSubmitData(data);
                      }
                    }else {
                      handleSubmitData(data);
                    }
                  })}
                >
                  <div className="row">
                    <div className="form-group mb-3 col-sm-12">
                      <label>Course Name</label>
                      {errors.name?.message && (
                        <p className="text-red-600">{errors.name?.message}</p>
                      )}
                      <input
                        {...register("name", {
                          required: "Course Name Is Required!"})}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    {/* <div className="form-group mb-3 col-sm-12 col-md-6">
                      <label>course Attendance Type</label>
                      {errors.type?.message && (
                        <p className="text-red-600">
                          {errors.type?.message}
                        </p>
                      )}
                      <select
                        {...register("type", {
                          required: "course attendance type is required",
                        })}
                        className="form-control"
                      >
                        <option value={"online"}>Online</option>
                        <option value={"offline"}>Offline</option>
                      </select>
                    </div> */}
                  </div>
                  <div className="row">
                      <div className="lesson-description-line mb-3 relative my-3 flex justify-center items-center after:absolute after:left-[50%] after:translate-x-[-50%] after:top-[50%] after:px-2 after:bg-[#eaebef] after:content-['Lesson Description'] after:translate-y-[-50%] bg-slate-300 w-full h-[1px]"></div>
                      <ReactQuill 
                      modules = {modules}
                      className="p-0 border-none outline-none form-control bg-white my-3" 
                      value={courseDescription} theme="snow" 
                      onChange={setCourseDescription} />
                  </div>
                  <div className="row">
                    {
                      checkUserRole("manager",
                      <div className="form-group mb-3 col-sm-12 col-md-6 flex flex-col">
                        {
                          getUsersLoading
                          ?
                          <p>Loading Instructors Platform...</p>
                          :
                          (
                            instructorsList && instructorsList.length >= 1
                            ?
                            <div className="form-group mb-3 col-sm-12">
                              <label>Course Instructor</label>
                              {errors.user_id?.message && (
                              <p className="text-red-600">
                                {errors.user_id?.message}
                              </p>
                              )}
                              <span 
                              className="relative flex items-center px-1 w-full h-[45px] rounded-md border border-[#7a4a4a] cursor-text"
                              onClick={()=>setChooseInstructor(true)}
                              >
                                {
                                  instructorWhichChoosedImg !== ""
                                  &&
                                  <img src={instructorWhichChoosedImg !== null ? instructorWhichChoosedImg : defaultTeacherImg} alt="teacher chooses" className="relative w-[20px] mr-2" />
                                }
                                {instructorWhichChoosed}
                              </span>
                            </div>
                            :
                            <div className="form-group flex items-center">
                              <p>No Instructors</p>
                            </div>
                          )
                        }
                        <div className={`instructors-list overflow-y-auto transition-all duration-500 ${chooseInstructor ? "h-[150px]" : "h-0"}`}>
                          {users && instructorsList &&
                            instructorsList.map(instructor =>{
                              return (
                                <div key={instructor.id} className="input-div relative flex items-center my-1 p-2 pl-0">
                                  <input 
                                  {...register("user_id", {
                                    required: "Course instructor is required",
                                  })}
                                  form="new-course-form"
                                  id={instructor.name} 
                                  type="radio" 
                                  name="instructor-name" 
                                  value={instructor.id}
                                  className=""
                                  data-name={instructor.name}
                                  onChange={(e)=>{
                                    setValue("user_id" , e.target.value)
                                    setInstructorWhichChoosed(e.target.dataset.name);
                                    setChooseInstructor(false)
                                    setInstructorWhichChoosedImg(instructor.image_url)
                                  }}
                                  />
                                  <label htmlFor={instructor.name} className="cursor-pointer mr-2 flex items-center">
                                    <img src={instructor.image_url === null ? defaultTeacherImg : instructor.image_url} alt="img" className="w-[35px] h-[35px] mx-2 rounded-[50%]"/>
                                    {instructor.name}
                                  </label>
                                </div>
                              )
                            })
                          }
                        </div>
                      </div>
                      )
                    }
                    {
                      getCategoriesLoading
                      ?
                      <div className="form-group flex items-center">
                        <p>loading Categories..</p>
                      </div>
                      :
                      (
                        categoriesData && categoriesList && categoriesList.length <= 0
                        ?
                        <div className="form-group flex items-center">
                          <p>No Categories</p>
                        </div>
                        :
                        <div className={`form-group mb-3 ${userRole && userRole.toLowerCase() === "manager" ? "col-sm-12 col-md-6" : "col-sm-12"}`}>
                          <label>course Category</label>
                          {errors.category_id?.message && (
                            <p className="text-red-600">
                              {errors.category_id?.message}
                            </p>
                          )}
                          <select
                            {...register("category_id", {
                              required: "category is required",
                            })}
                            className="form-control"
                          >
                            {
                              categoriesList &&
                              categoriesList.map((cat , index) => {
                                return (
                                  <option key={cat.id} value={cat.id}>{cat.name}</option>
                                )
                              })
                            }
                          </select>
                        </div>
                      )
                    }
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>{
          props.onHide();
          reset();
        }}>Close</Button>
        <Button type="submit" form="new-course-form">
          {!loading ? (
            "Add Course"
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                loading..
                <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white"></div>
              </div>
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}




function OfflinePage() {
  const user = JSON.parse(window.localStorage.getItem("user"));
  const userRole = user && user.roleName && user.roleName.toLowerCase();
  const userId = user && user.id;
  const dispatch = useDispatch();
  const { loading, courses } = useSelector(
    (state) => state.getAllCoursesSlicer
  );
  const { categoriesData , loading:getCategoriesLoading} = useSelector(
    (state) => state.getAllCategoriesSlicer
  );
  const { loading:getUsersLoading ,  data:users } = useSelector(
    (state) => state.fetchUsersSlicer
  );
  const { deleteCourseLoading } = useSelector((state) => state.deleteCourseSlicer);
  const [showNewCourseForm, setShowNewCourseForm] = useState();
  const [allCourses, setAllCourses] = useState([]);
  const [allInstructors, setAllInstructors] = useState([]);
  const [categories, setCategories] = useState([]);
  const contextApi = useContext(ContextApi);
  const courses_API_URL = `${contextApi.dashboard_URL_API}/courses`;
  const instructors_API_URL = `${contextApi.dashboard_URL_API}/users?role=teacher`;
  const categories_API_URL = `${contextApi.dashboard_URL_API}/categories`;
  const [showFilterSidebar, setShowFilterSidebar] = useState(false);
  const filterCoursesApi = `${contextApi.dashboard_URL_API}/courses`
  const [categortyFilter , setCategoryFilter] = useState();
  const [instructorFilter , setInstructorFilter] = useState();
  const [levelFilter , setLevelFilter] = useState();
  const [publishFilter , setPublishFilter] = useState();
  const [semesterFilter , setSemesterFilter] = useState();
  const [searchValue , setSearchValue] = useState("");
  const [getFilteringData , setGetFilteringData] = useState();
  const users_API_URL = `${contextApi.dashboard_URL_API}/users`;

  useEffect(() => {
    courses && setAllCourses(courses);
    const instructors = users && users.filter(user => user.roles[0].name.toLowerCase() === "teacher");
    setAllInstructors(instructors)
    categoriesData && setCategories(categoriesData);
  }, [courses, users , categoriesData]);

  useEffect(() => {
    let coursesApi = `${courses_API_URL}?${categortyFilter ? `category_id=${categortyFilter}&` : ""}${instructorFilter ? `user_id=${instructorFilter}&` : ""}${publishFilter ? `publish=${publishFilter}&` : ""}${semesterFilter ? `semester=${semesterFilter}&`: ""}${searchValue ? `search=${searchValue}` : ""}`;

    let teacherCoursesApi = `${filterCoursesApi}?${(userId && userRole === "teacher") ? `user_id=${userId}` : ""}${categortyFilter ? `category_id=${categortyFilter}&` : ""}${publishFilter ? `publish=${publishFilter}&` : ""}${semesterFilter ? `semester=${semesterFilter}&`: ""}${searchValue ? `search=${searchValue}` : ""}`;

    let searchTimeout;
    let fetchNewStudentsData = async ()=>{
      if(searchValue !== "") {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(()=>{
          userRole === "manager"
          ?
          dispatch(fetchAllCoursesFunc(coursesApi))
          :
          dispatch(fetchAllCoursesFunc(teacherCoursesApi))
        },2000)
      }else {
        userRole === "manager"
        ?
        dispatch(fetchAllCoursesFunc(coursesApi))
        :
        dispatch(fetchAllCoursesFunc(teacherCoursesApi))
      }
    }
    fetchNewStudentsData()
    return () => clearTimeout(searchTimeout)
  },[dispatch , categortyFilter , categortyFilter , publishFilter , instructorFilter,  semesterFilter , searchValue , levelFilter])

  useEffect(() => {
    const getAllData = async () => {
      try {
        getFilteringData && userRole === "manager" && dispatch(fetchUsersFunc(users_API_URL));
        getFilteringData && dispatch(fetchAllCategoriesFunc(categories_API_URL))
      } catch (error) {
        console.log(error);
      }
    };
    getAllData();
  }, [getFilteringData]);

  useEffect(() => {
      const offlineCourses = courses && courses.length > 0 && courses.filter(course => course.type === "offline");
    courses && setAllCourses(offlineCourses);
    categoriesData && setCategories(categoriesData);
  }, [courses , categoriesData]);

  const deleteCourseFunction = async (courseId) => {
    swal({
      title: "Confirm Course Deletion",
      text: `Once deleted, course will not be student in platform!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteCourseFunc(`${courses_API_URL}/${courseId}`))
        .then((result) => {
          if(result.payload.success === true){
            dispatch(fetchAllCoursesFunc(courses_API_URL));
          }
        })
      } else {
        swal("Your course is safe!");
      }
    });
  };


  return (
    <>
    <PageTitle activeMenu={"Offline Courses"} motherMenu={"Dashboard"}/>
    <div className="courses-section flex flex-col min-h-[2000px]">
      <div className={`filter-sidbar transition-all duration-500 absolute z-[9999] py-4   bg-white  rounded-tl-[20px] pl-2 rounded-bl-[20px]  shadow-lg shadow-slate-400 flex top-[170px] flex-col ${showFilterSidebar ? "active" : ""}`}>
        <div className="input-group search-area w-auto mr-2">
            <span className="input-group-text">
                <Link to={"#"}>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                    d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                    fill="var(--primary)"
                    ></path>
                </svg>
                </Link>
            </span>
            <input
                type="text"
                className="form-control"
                placeholder="Search here..."
                onChange={(e) => {
                setSearchValue(e.target.value)
                console.log(e.target.value)
                }}
            />
        </div>
        <div className="filter-box relative flex flex-col p-2 mb-3">
            <h5>Filter with category</h5>
            <div className="filter-input flex items-center mb-2">
                <input type="radio" name="category" value={""} id="all-categories"
                    onChange={(e)=>setCategoryFilter("")}
                />
                <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor="all-categories">All Categories</label>
            </div>
            {
                getCategoriesLoading
                ?
                <p>Loading Categories..</p>
                :
                (
                categories &&
                categories.map((cat , index) => {
                    return (
                        <div key={index} className="filter-input flex items-center mb-2">
                            <input type="radio" name="category" id={cat.name} value={cat.id} 
                                onChange={(e)=>setCategoryFilter(e.target.value)}
                            />
                            <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor={cat.name}>{cat.name}</label>
                        </div>
                    )
                })
                )
            }
        </div>
        {
          checkUserRole
          (
            ["manager"]
            ,
            <div className="filter-box relative flex flex-col p-2 mb-3">
                <h5>Filter with Instructor</h5>
                <div className="filter-input flex items-center mb-2">
                    <input type="radio" name="instructor-filter" value={""} id="all-instructors"
                        onChange={(e)=>setInstructorFilter("")}
                    />
                    <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor="all-instructors">All Instructors</label>
                </div>
                {
                    getUsersLoading
                    ?
                    <p>Loading Instructors..</p>
                    :
                    (
                    users && allInstructors &&
                    allInstructors.map((instructor , index) => {
                        return (
                            <div key={index} className="filter-input flex items-center mb-2">
                                <input type="radio" name="instructor-filter" id={instructor.id} value={instructor.id}
                                    onChange={(e)=>setInstructorFilter(e.target.value)}
                                />
                                <label className="translate-y-[3px] flex items-center cursor-pointer" htmlFor={instructor.id}>
                                    <img src={instructor.image_url === null ? "https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?w=740&t=st=1702935704~exp=1702936304~hmac=0cf7808c31a16122cc1e8bfbd1b2e33c4f86a93e80af95f3eba31a9428f2ea2c" : instructor.image_url} alt="instructor img" className="w-[30px] mr-1"/>
                                    {instructor.name}
                                </label>
                            </div>
                        )
                    })
                    )
                }
            </div>
          )
        }
        <div className="filter-box relative flex flex-col p-2 mb-3">
            <h5>Filter with status</h5>
            <div className="filter-input flex items-center mb-2">
                <input type="radio"name="status" id={"all-status"} value={"publish"} 
                    onChange={(e)=>setPublishFilter("")}
                />
                <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor={"all-status"}>All</label>
            </div>
            <div className="filter-input flex items-center mb-2">
                <input type="radio" name="status" id={"active"} value={"publish"} 
                    onChange={(e)=>setPublishFilter(e.target.value)}
                />
                <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor={"active"}>Active</label>
            </div>
            <div className="filter-input flex items-center mb-2">
                <input type="radio" name="status" id={"unactive"} value={"unpublish"} 
                    onChange={(e)=>setPublishFilter(e.target.value)}
                />
                <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor={"unactive"}>Not Active</label>
            </div>
        </div>
        <div className="filter-box relative flex flex-col p-2 mb-3">
            <h5>Filter with Semester</h5>
            <div className="filter-input flex items-center mb-2">
                <input type="radio" name="semester" id={"empty semester"} value={""} 
                    onChange={(e)=>setSemesterFilter("")}
                />
                <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor={"empty semester"}>All</label>
            </div>
            <div className="filter-input flex items-center mb-2">
                <input type="radio" name="semester" id={"first semester"} value={"first semester"} 
                    onChange={(e)=>setSemesterFilter(e.target.value)}
                />
                <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor={"first semester"}>First Semester</label>
            </div>
            <div className="filter-input flex items-center mb-2">
                <input type="radio" name="semester" id={"second semester"} value={"second semester"} 
                    onChange={(e)=>setSemesterFilter(e.target.value)}
                />
                <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor={"second semester"}>second semester</label>
            </div>
            <div className="filter-input flex items-center mb-2">
                <input type="radio" name="semester" id={"third semester"} value={"third semester"} 
                    onChange={(e)=>setSemesterFilter(e.target.value)}
                />
                <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor={"third semester"}>third semester</label>
            </div>
        </div>
      </div>
      <>
        <div className="widget-heading d-flex justify-content-between align-items-center">
          <h3 className="m-0 header-text">Offline Courses</h3>
          {(
          <div className="flex">
            <Link
              to="/new-course/offline"
              className="custom-button text-white submit-rivew-btn rounded-md py-[10px] px-4 relative flex justify-center items-center shadow-md hover:shadow-slate-500 transition-all duration-500 bg-primary hover:translate-y-[-4px] overflow-hidden"
              // onClick={() => {
              //   setShowNewCourseForm(true);
              // }}
            >
                <span className="mx-[1px] relative transition-all duration-500 delay-[.5s]">N</span>
                <span className="mx-[1px] relative transition-all duration-500 delay-[.4s]">e</span>
                <span className="mx-[1px] relative transition-all duration-500 delay-[.3s]">w</span>
                {/* <span className="mx-[1px] relative transition-all duration-500 delay-[.2s]"> </span>
                <span className="mx-[1px] relative transition-all duration-500 delay-[.2s]"> </span>
                <span className="mx-[1px] relative transition-all duration-500 delay-[.1s]">O</span>
                <span className="mx-[1px] relative transition-all duration-500 delay-[.1s]">f</span>
                <span className="mx-[1px] relative transition-all duration-500 delay-[.1s]">f</span>
                <span className="mx-[1px] relative transition-all duration-500 delay-[0s]">l</span>
                <span className="mx-[1px] relative transition-all duration-500 delay-[.1s]">i</span>
                <span className="mx-[1px] relative transition-all duration-500 delay-[.2s]">n</span>
                <span className="mx-[1px] relative transition-all duration-500 delay-[.3s]">e</span>
                <span className="mx-[1px] relative transition-all duration-500 delay-[.2s]"> </span>
                <span className="mx-[1px] relative transition-all duration-500 delay-[.1s]">C</span>
                <span className="mx-[1px] relative transition-all duration-500 delay-[0s]">o</span>
                <span className="mx-[1px] relative transition-all duration-500 delay-[.1s]">u</span>
                <span className="mx-[1px] relative transition-all duration-500 delay-[.2s]">r</span>
                <span className="mx-[1px] relative transition-all duration-500 delay-[.3s]">s</span>
                <span className="mx-[1px] relative transition-all duration-500 delay-[.4s]">e</span> */}
                <i className="fa-solid fa-plus text-lg transition-all duration-700  absolute left-[50%] translate-x-[-50%] top-[50px]"></i>
            </Link>
            <div className="relative ml-2 flex justify-center items-center cursor-pointer">
              <i className={`fa-solid fa-filter text-[20px] ${showFilterSidebar ? "text-blue-700" : ""} transition-all duration-300  `}
                onClick={()=> {
                  setShowFilterSidebar(!showFilterSidebar);
                  setGetFilteringData(true)
                }}
              ></i>
            </div>
          </div>
          )}
        </div>

        {loading ? (
          <>
            <div className="row d-flex justify-content-center gap-2">
              <Spinner animation="grow" className="bg-primary" />
              <Spinner animation="grow" className="bg-primary" />
              <Spinner animation="grow" className="bg-primary" />
              <Spinner animation="grow" className="bg-primary" />
            </div>
          </>
        ) : (
          <>
            <div className="courses-section h-fit relative flex flex-wrap gap-y-4 gap-x-5">
              {
                courses
                &&
                allCourses.length > 0
                ?
                allCourses.map((course) => {
                  return (
                    <OfflineCourseCard
                      key={course.id}
                      course={course}
                      id={course.id}
                      course_title={course.name}
                      course_price={course.price}
                      coures_instructor={course.user}
                      course_img={course.image_url}
                      course_rating={4.3}
                      lessonsCount = {course.lessons_count}
                      loading = {loading}
                      courseType = {course.type}
                      sessionsCount = {course.sessions_count}
                      deleteCourseFunc={() => deleteCourseFunction(course.id)}
                      studentsCount={course.students && course.students.length}
                    />
                  );
                })
                :
                <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                  <img
                    src={faildImg}
                    alt="faild img"
                    className="w-[150px] mb-3"
                  />
                  <p>
                    No courses!
                  </p>
                </div>
              }
            </div>
          </>
        )}
        {
          showNewCourseForm
          &&
          <AddNewCourseForm
            show={showNewCourseForm}
            onHide={() => setShowNewCourseForm(false)}
            coursesapi={filterCoursesApi}
            allcourses={allCourses}
            allinstructors={allInstructors}
            user_role = {userRole && userRole.toLowerCase()}
            user_id = {userId}
          />
        }
      </>
      <OverLayLoading loading={deleteCourseLoading} loadingText={"Deleting..."}/>
    </div>
    </>
  );
}
export default OfflinePage;
