import React, { useContext , useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import loginbg from "../../../images/bg-1.jpg";
import logo from "../../../images/log.png";
import { loginFunc } from "../../../store/custom-actions/login-action/LoginSlicer";
import { useForm } from "react-hook-form";
import ContextApi from "../../../store/ContextApi";
import { useTranslation } from "react-i18next";
function LoginPage(props) {
  const {t} = useTranslation();
  const {loading , status} = useSelector(state => state.loginStatus);
  const {loading:switchLangLoading , data:languageData} = useSelector(
		state => state.getLanguageSettingsSlicer
	)
  const dispatch = useDispatch();
  const {register , watch , formState : {errors} , handleSubmit} = useForm();
  const contextApi = useContext(ContextApi);
  const login_url = `${contextApi.dashboard_URL_API}/login`;
  const [showPass , setShowPass] = useState(false);
  const pass = watch("password")
  const loginFunction = async (formData) => {
    try {
      await dispatch(loginFunc(
        {
          api : login_url,
          userData : formData
        }
      ))
      // .then(result => {
      //   if(result.payload && result.payload.success === true) {
      //     swal("Good job!", "submission is successfully!", "success");
      //     // navigate("/")
      //   }
      // })
    }
    catch (error) {
      console.log(error)
    }
  }

  return (
    <div
      className="login-main-page w-full flex justify-center items-center"
      style={{
        backgroundImage: "url(" + loginbg + ")",
        backgroundSize: "cover",
      }}
    >
      <div className="relative row login-container flex justify-center items-cenetr bg-white rounded-md py-2">
        <div className="col-xl-6 col-md-6 text-center">
          <div>
            <img src={logo} className="education-img" alt="ll"></img>
          </div>
        </div>
        <div className="col-xl-6 col-md-6">
          <div className="sign-in-your">
            <h4 className="fs-20 font-w800 text-black">
              {t("Sign in your account")}
            </h4>
            {/* <span>
              {t("Welcome back! Login with your data that you entered")}
              <br /> {t("during registration")}
            </span> */}
            <form 
            id="login-form"
            onSubmit={handleSubmit((data) => {
              loginFunction(data);
            })}>
              <div className="mb-3">
                <label className="mb-1">
                  <strong>{t("Email")}</strong>
                </label>
                <input
                  {...register("email" , {required : t("Email is required")})}
                  type="email"
                  className="form-control"
                />
                {errors.email?.message && 
                  <p className="text-danger fs-12">
                    {errors.email?.message}
                  </p>
                }
              </div>
              <div className="mb-3 relative overflow-hidden">
                <label className="mb-1">
                  <strong>{t("Password")}</strong>
                </label>
                <input
                  {...register("password" , {required : t("Password is required")})}
                  type={`${showPass ? "text" : "password"}`}
                  className="form-control"
                  form="login-form"
                />
                {errors.password?.message && 
                  <p className="text-danger fs-12">
                    {errors.password?.message}
                  </p>
                }
                <i 
                  className={`fa-regular fa-eye absolute transition-all duration-500 text-[20px] cursor-pointer top-[50%] w-fit
                    ${
                      pass ? "opacity-100" : "opacity-0"} ${showPass ? "text-blue-600" : ""
                      
                    } ${languageData.defaultLang.slug === "en" ? "right-[10px]" : "left-[10px]"}`
                    }
                  onClick={()=>setShowPass(!showPass)}
                ></i>
              </div>
              <button
                  type="submit"
                  className={`btn btn-primary btn-block mt-1 ${loading ? "opacity-80 pointer-events-none" : ""}`}
                >
                {loading
                  ?
                  <>
                  <div className="relative flex justify-center items-center">
                    {t("loading...")}
                    <div className="loading-spin-data w-[13px] h-[13px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-2"></div> 
                  </div>
                  </>
                  :
                  t("Sign In Me") 
                }
              </button>
            </form>
          </div>
        </div>
        </div>
    </div>
  );
}
export default LoginPage;
