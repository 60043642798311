import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import checkErrorsFunctions from "../../global-functions/checkErrorsFunction";
import { useTranslation } from "react-i18next";

export default function UploadLessonVideoForm(props) {
    const {t} = useTranslation();
    const {lesson_title , refetch_course_lessons , upload_video_api} = props;
    const [video , setVideo] = useState("file")
    const [videoType , setVideoType] = useState("file")
    const [uploadVideoPercent , setUploadVideoPercent] = useState();
    const [videoPercent , setVideoPercent] = useState(0);
    const [loadingVideo , setLoadingVideo] = useState();
    const token = window.localStorage.getItem("token")

    const handleUploadVideo = () => {
        if(token) {
            if(video && videoType){
                const formData = new FormData();
                videoType === "file" 
                ? formData.append('file', video) 
                : formData.append('file', video);
                // formData.append('video_type', videoType);
                    axios.post(
                        upload_video_api , 
                        formData , 
                        {
                            headers: {
                                'Authorization': `Bearer ${token}`, // Add the token to the headers
                                'Content-Type': 'multipart/form-data' // Ensure the content type is set correctly
                            },
                        onUploadProgress : (data) => {
                        setUploadVideoPercent(Math.floor((data.loaded / data.total) * 100))
                        setLoadingVideo(true)
                    }})
                    .then((response) => {
                        console.log(response)
                        checkErrorsFunctions(response , response.data.errors);
                        if(response.data.success === true) {
                            setLoadingVideo(false);
                            refetch_course_lessons();
                            props.onHide();
                        }else {
                            setLoadingVideo(false);
                        }
                    })
            }
        }else {
            swal({
                title: t("Oops!"),
                text: t("Token Expired!"),
                icon: "warning",
                button: "Retry",
            });
        }
    }
    useEffect(() => {
        setVideoPercent(uploadVideoPercent);
    },[uploadVideoPercent]);
    return (
    <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
            {t("Upload video")}
        </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form id="update-video-form" className="update-video-form"
            onSubmit={(e)=>{
                e.preventDefault();
                handleUploadVideo()
            }}
          >
                <div className="form-group mb-3 col-sm-12">
                <label>
                    {/* <select 
                        onChange={(e)=>{
                            setVideoType(e.target.value)
                        }}
                    >
                    <option value={"file"}>upload video as File</option>
                    <option value={"url"}>upload video as URL</option>
                    <option value={"shared"}>Share Video</option>
                    </select> */}
                    {t("Upload Video")}
                </label>
                {
                    // videoType === "file"
                    // ?
                    // (                   
                        <input
                        required
                        type="file"
                        className="form-control"
                        accept="video/mp4"
                        onChange={(e)=>{
                            setVideo(e.target.files[0])
                        }}
                        />
                    // )
                    // :
                    // <input
                    //     type="number"
                    //     className="relative block placeholder:text-[14px] border-[1px] border-[#DBDBDB] rounded-md indent-2 col-sm-12 h-[53px]"
                    //     placeholder="Video Id"
                    //     onChange={(e)=>setVideo(e.target.value)}
                    //     min={0}
                    // />
                }
                </div>
                {
                    videoPercent >= 1
                    &&
                    <div className={`relative my-2 w-full h-[20px] bg-orange-300 rounded-md overflow-hidden`}>
                        <span
                        role="progressbar"
                        aria-valuemin={0}
                        aria-valuemax={100}
                        style={{width : `${videoPercent}%`}}
                            className={`absolute top-0 text-white transition-all duration-700 left-0 h-full bg-primary flex justify-center items-center`}>
                            {videoPercent}%
                        </span>
                    </div>
                }
            </form>
        </Modal.Body>
        <Modal.Footer>
        <button onClick={()=>{
            props.onHide();
            setVideo("")
        }} className="">{t("Close")}</button>
        <Button form="update-video-form" type="submit" className={`primary ${loadingVideo ? "opacity-80 pointer-events-none" : ""}`}>
            {loadingVideo
                ?
                <>
                <div className="relative flex justify-center items-center">
                  {t("loading")}..
                  <div className="loading-spin-data w-[13px] h-[13px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-2"></div> 
                </div>
                </>
                :
                t("Submit" )
              }
        </Button>
        </Modal.Footer>
    </Modal>
    );
}