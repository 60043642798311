import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
import Swal from "sweetalert2";
export const addNewStudentFunc = createAsyncThunk(
    "add new student function",
    async ({api , studentData}) => {
        try {
            const response = await axios.post(api , studentData );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            // Swal.fire({
            //     title: "Oops!",
            //     text:error,
            //     icon: "error"
            // });
            checkErrors(error.response , error.message);
        }
        
    }
);

const addStudentSlicer = createSlice({
    name : "add new student slicer",
    initialState : {
        loading : null,
        students : [],
        error : false
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(addNewStudentFunc.pending , (state)=>{
            state.loading = true
            state.students = []
            state.error = null
        })
        builder.addCase(addNewStudentFunc.rejected , (state)=>{
            state.loading = false
            state.students = []
            state.error = true
        })
        builder.addCase(addNewStudentFunc.fulfilled , (state , action)=>{
            state.loading = false
            state.students = action.payload
            state.error = null
        })
    }
});
export default addStudentSlicer.reducer;