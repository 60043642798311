import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
import Swal from "sweetalert2";
export const deleteStudentFunc = createAsyncThunk(
    "delete student function",
    async (api) => {
        try {
            const response = await axios.delete(api );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            // Swal.fire({
            //     title: "Oops!",
            //     text:error,
            //     icon: "error"
            // });
            checkErrors(error.response , error.message);
        }
    }
)

const deleteStudentSlicer = createSlice({
    name : "delete student slicer",
    initialState : {
        deleteStudentLoading : null,
        data : null,
        error : "error" 
    },
    reducer :{},
    extraReducers : (builder) => {
        builder.addCase(deleteStudentFunc.pending , (state) => {
            state.deleteStudentLoading = true
            state.data = null
            state.error = null
        })
        builder.addCase(deleteStudentFunc.rejected , (state) => {
            state.deleteStudentLoading = false
            state.data = null
            state.error = true
        })
        builder.addCase(deleteStudentFunc.fulfilled , (state , action) => {
            state.deleteStudentLoading = false
            state.data = action.payload
            state.error = null
        })
    }
})
export default deleteStudentSlicer.reducer;