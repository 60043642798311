// import "./chapter.css";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// import checkPermissions from "../../../global-functions/checkPermissionsFunc";
// import RowOfLesson from "./RowOfLesson";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import LessonCard from "../lesson-card/LessonCard";
import checkUserRole from "../../../../global-functions/checkUserRole";

export default function LessonsContent({ chapterId , refetchSingleChapter }) {
    const {t} = useTranslation();
    const [lessonsList, setLessonsList] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [filteredLessons, setFilteredLessons] = useState([]);
    const { loading: getChapterLoading, data: chapterData } = useSelector(
        state => state.getSingleChapterSlicer
    );
    const sort = 20;
    const activePag = useRef(0);

    useEffect(() => {
        if (chapterData.lessons) {
            setLessonsList(chapterData.lessons);
            setFilteredLessons(chapterData.lessons.slice(
                activePag.current * sort,
                (activePag.current + 1) * sort
            ));
        }
    }, [chapterData.lessons]);

    const onClick = (i) => {
        activePag.current = i;
        setFilteredLessons(lessonsList.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
        ));
    };
        let paggination = Array(Math.ceil(lessonsList.length / sort))
      .fill()
      .map((_, i) => i + 1);

    useEffect(() => {
        if (searchValue !== "") {
            const searchResult = chapterData.lessons &&
                chapterData.lessons.length >= 1 && chapterData.lessons.filter((lesson) =>
                    lesson.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase())
                );
            setFilteredLessons(searchResult);
        } else {
            setFilteredLessons(chapterData.lessons.slice(
                activePag.current * sort,
                (activePag.current + 1) * sort
            ));
        }
    }, [searchValue, chapterData.lessons]);

    return (
        <>
            {
                (
                    <div className="col-12 mt-0">
                        <div className="card-header flex justify-between items-center">
                            <h4 className="card-title">{t("Lessons")}</h4>
                            <div className="relative add-and-search flex items-center">
                                <div className="input-group search-area w-auto mr-2">
                                    <span className="input-group-text">
                                        <Link to={"#"}>
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 32 32"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                                    fill="var(--primary)"
                                                ></path>
                                            </svg>
                                        </Link>
                                    </span>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Search here..."
                                        value={searchValue}
                                        onChange={(e) => setSearchValue(e.target.value)}
                                    />
                                </div>
                                {/* {checkPermissions(
                                    "create lessons",
                                    <Link
                                        to={`/new-lesson/${chapterId}`}
                                        className="btn btn-primary btn-sm"
                                    >
                                        {t("Create")}
                                        <i className="fa-solid fa-plus ml-1"></i>
                                    </Link>
                                    )} */}
                                {
                                    checkUserRole(
                                        ["manager" , "teacher"],
                                        <Link
                                            to={`/new-lesson/${chapterId}`}
                                            className="btn btn-primary btn-sm"
                                        >
                                            {t("Create")}
                                            <i className="fa-solid fa-plus ml-1"></i>
                                        </Link>

                                    )
                                }
                            </div>
                        </div>
                        <div className="row mt-3">
                            {
                                filteredLessons && filteredLessons.length >= 1
                                ?
                                filteredLessons.map((lesson, index) => (
                                    <LessonCard 
                                    key={index} 
                                    lesson={lesson} 
                                    refetchSingleChapter = {() => refetchSingleChapter()}
                                    />
                                ))
                                :
                                (
                                    searchValue !== "" 
                                    &&
                                    <tr>
                                        <td colSpan={3}>{t("No data available")}</td>
                                    </tr>
                                )
                            }
                        </div>
                    </div>
                )
            }
        </>
    );
}
