import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
import Swal from "sweetalert2";
export const editLessonFunc = createAsyncThunk(
    "edit lesson func",
    async ({lessonApi , lessonData}) => {
        try {
            const response = await axios.put(lessonApi , lessonData );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
    }
);

const editLessonSlicer = createSlice({
    name : "edit lesson slicer",
    initialState : {
        loading : null,
        lesson : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(editLessonFunc.pending , (state) => {
            state.loading = true;
            state.lesson = null;
            state.error = null;
        });
        builder.addCase(editLessonFunc.rejected , (state) => {
            state.loading = false;
            state.lesson = null;
            state.error = true;
        });
        builder.addCase(editLessonFunc.fulfilled , (state , action) => {
            state.loading = false;
            state.lesson = action.payload;
            state.error = false;
        });
    }
})
export default editLessonSlicer.reducer;