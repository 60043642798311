import { Modal , Button } from "react-bootstrap";
import { useState , useEffect, useContext } from "react";
import { useSelector , useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ContextApi from "../../../store/ContextApi";
import { getQuestionsFunc } from "../../../store/custom-actions/Question-actions/GetQuestionsSlicer";
import axios from "axios";
import Swal from "sweetalert2";
import questionIcon from '../../../media/images/question-mark.png';
import HandleErrorsListFunc from "../../global-functions/handleErrorsListFunc";
import { useTranslation } from "react-i18next";


function UpdateQuestionVideo(props) {
    const {t} = useTranslation();
    const {
      formState: { errors },
      reset,
    } = useForm();
    const {question_id} = props
    const {addQuestionLoading} = useSelector(state => state.addQuestionSlicer);
    const dispatch = useDispatch();
    const contextApi = useContext(ContextApi);
    const questions_api_url = `${contextApi.dashboard_URL_API}/questions`;
    const upload_question_video_api = `${contextApi.dashboard_URL_API}/questions/${question_id}/upload-video`
    const [videoType , setVideoType] = useState("file")
    const [video , setVideo] = useState("file");
    const [uploadVideoPercent , setUploadVideoPercent] = useState();
    const [videoPercent , setVideoPercent] = useState(0);
    const [loadingVideo , setLoadingVideo] = useState();
    const [additionDone , setAdditionDone] = useState();
    const [onSubmitionQuestion , setOnSubmitionQuestion] = useState();


    const handleUploadVideo = async (videoFile) => {
      if (videoType) {
        const formData = new FormData();
        formData.append('video', videoFile);
        axios.post(upload_question_video_api , formData , {withCredentials: true , onUploadProgress : (data) => {
          setUploadVideoPercent(Math.floor((data.loaded / data.total) * 100))
          setLoadingVideo(true);
          setOnSubmitionQuestion(true)
        }})
        .then (response => {
            if(response.data.success === true) {
              dispatch(getQuestionsFunc(questions_api_url));
              setLoadingVideo(false)
              reset();
              props.onHide();
              setAdditionDone(false);
              setVideoPercent(0);
              Swal.fire({
                title: t("Good job!"),
                text: t("Upload Video Is Done!"),
                icon: "success"
              });
            }
            else {
              setVideoPercent(0);
              setLoadingVideo(false);
            }
        })
      }
    };

    useEffect(() => {
      setVideoPercent(uploadVideoPercent);
    },[uploadVideoPercent]);
    useEffect(() => {
      addQuestionLoading ? setOnSubmitionQuestion(true) : setOnSubmitionQuestion(false)
    },[addQuestionLoading]);
    useEffect(() => {
      loadingVideo ? setOnSubmitionQuestion(true) : setOnSubmitionQuestion(false)
    },[loadingVideo]);

    useEffect(() => {
      HandleErrorsListFunc(errors)
    },[errors])
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="flex items-center">
            <img src={questionIcon} className="relative w-[20px] mr-2" alt="form img" />
            {t("Upload Video")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          
          <div className="basic-form">
          <form
              id="video-form"
              className="video-form"
              onSubmit={(e) => {
                e.preventDefault();
                if (video && videoType) {
                  handleUploadVideo(video);
                }
              }}
            >
              <div className="form-group mb-3 col-sm-12">
                <label htmlFor="explain video">{t("Explain Video")} ({t("optional")})</label>
                <input
                    type="file"
                    className="form-control"
                    accept="video/mp4"
                    onChange={(e) => {
                      setVideo(e.target.files[0]);
                    }}
                    id = "explain video"
                  />
                <div className={`relative my-2 ${videoPercent > 0 ? "flex" : "hidden"} w-full h-[20px] bg-blue-300 rounded-md overflow-hidden`}>
                  <span
                  role="progressbar"
                  aria-valuemin={0}
                  aria-valuemax={100}
                  style={{width : `${videoPercent}%`}}
                    className={`absolute top-0 text-white transition-all duration-700 left-0 h-full bg-blue-600 flex justify-center items-center`}>
                      {videoPercent}%
                    </span>
                </div>
              </div>
              {
                video !== "file"
                &&
              <Button form="video-form" type="submit" className={`primary ${loadingVideo ? "opacity-60 pointer-events-none" : ""}`}>
                {loadingVideo ? (
                  <>
                    <div className="relative flex justify-center items-center">
                      {t("Uploading Video")}..
                      <div className="loading-spin-data w-[13px] h-[13px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-2"></div>
                    </div>
                  </>
                ) : (
                  t("Next")
                )}
              </Button>
              }
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>{
            props.onHide();
            reset();
            setAdditionDone(false)
          }}>{t("Close")}</Button>
          
        </Modal.Footer>
      </Modal>
    );
  }

export default UpdateQuestionVideo;