import React from "react";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import CourseCard from "./CustomCourseCard";
import { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContextApi from "../../../store/ContextApi";
import { fetchAllCoursesFunc } from "../../../store/custom-actions/courses-actions/GetAllCoursesSlicer";
import swal from "sweetalert";
import { deleteCourseFunc } from "../../../store/custom-actions/courses-actions/DeleteCourseSlicer";
import faildImg from "../../../media/images/fail-img.svg";
import { fetchUsersFunc } from "../../../store/custom-actions/users-actions/GetUsersSlicer";
import { fetchAllCategoriesFunc } from "../../../store/custom-actions/categories-actions/GetAllCategoriesSlicer";
import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
import checkUserRole from "../../global-functions/checkUserRole";
import PageTitle from "../../layouts/PageTitle";
import { useTranslation } from "react-i18next";

function CustomCoursesPage() {
  const {t} = useTranslation();
  const user = JSON.parse(window.localStorage.getItem("user"));
  const userRole = user && user.roleName && user.roleName.toLowerCase();
  const userId = user && user.id;
  const dispatch = useDispatch();
  const { loading, courses } = useSelector(
    (state) => state.getAllCoursesSlicer
  );
  const { categoriesData , loading:getCategoriesLoading} = useSelector(
    (state) => state.getAllCategoriesSlicer
  );
  const { deleteCourseLoading } = useSelector(
    (state) => state.deleteCourseSlicer
  );
  const { loading:getUsersLoading ,  data:users } = useSelector(
    (state) => state.fetchUsersSlicer
  );
  const [showNewCourseForm, setShowNewCourseForm] = useState();
  const [allCourses, setAllCourses] = useState();
  const [allInstructors, setAllInstructors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [showEditForm, setShowEditForm] = useState();
  const contextApi = useContext(ContextApi);
  const courses_API_URL = `${contextApi.dashboard_URL_API}/courses`;
  const users_API_URL = `${contextApi.dashboard_URL_API}/users`;
  const categories_API_URL = `${contextApi.dashboard_URL_API}/categories`;
  const [showFilterSidebar, setShowFilterSidebar] = useState(false);
  const filterCoursesApi = `${contextApi.dashboard_URL_API}/courses`
  const [categortyFilter , setCategoryFilter] = useState();
  const [instructorFilter , setInstructorFilter] = useState();
  const [publishFilter , setPublishFilter] = useState();
  const [semesterFilter , setSemesterFilter] = useState();
  const [searchValue , setSearchValue] = useState("");
  const [getFilteringData , setGetFilteringData] = useState();
  const [instructorsList , setInstructorsList] = useState([]);

  useEffect(() => {
    let coursesApi = `${filterCoursesApi}?${categortyFilter ? `category_id=${categortyFilter}&` : ""}${instructorFilter ? `user_id=${instructorFilter}` : ""}${publishFilter ? `publish=${publishFilter}&` : ""}${semesterFilter ? `semester=${semesterFilter}&`: ""}${searchValue ? `search=${searchValue}` : ""}`;

    let teacherCoursesApi = `${filterCoursesApi}?${(userId && userRole === "teacher") ? `user_id=${userId}` : ""}${categortyFilter ? `category_id=${categortyFilter}&` : ""}${publishFilter ? `publish=${publishFilter}&` : ""}${semesterFilter ? `semester=${semesterFilter}&`: ""}${searchValue ? `search=${searchValue}` : ""}`;

    let searchTimeout;
    let fetchCoursesData = async ()=>{
      if(searchValue !== "") {
        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(()=>{
          userRole === "manager"
          ?
          dispatch(fetchAllCoursesFunc(coursesApi))
          :
          dispatch(fetchAllCoursesFunc(teacherCoursesApi))
        },2000)
      }else {
        userRole === "manager"
        ?
        dispatch(fetchAllCoursesFunc(coursesApi))
        :
        dispatch(fetchAllCoursesFunc(teacherCoursesApi))
      }
    }
    fetchCoursesData()
    return () => clearTimeout(searchTimeout)
  },[dispatch , categortyFilter , categortyFilter , publishFilter , instructorFilter,  semesterFilter , searchValue])

  useEffect(() => {
    const getAllData = async () => {
      try {
        getFilteringData && userRole === "manager" && dispatch(fetchUsersFunc(users_API_URL));
        getFilteringData && dispatch(fetchAllCategoriesFunc(categories_API_URL));
      } catch (error) {
        console.log(error);
      }
    };
    getAllData();
  }, [getFilteringData]);

  useEffect(() => {
    courses && setAllCourses(courses);
    const instructors = users && users.filter(user => user.roles[0].name.toLowerCase() === "teacher");
    setInstructorsList(instructors)
    categoriesData && setCategories(categoriesData);
  }, [courses, users , categoriesData]);
  return (
    <>
    <PageTitle activeMenu={"Courses"} motherMenu={"Dashboard"} customClasses={"mb-2"}/>
    <div className="courses-section flex flex-col min-h-[2000px]">
        <div className={`filter-sidbar transition-all duration-500 absolute z-[9999] py-4   bg-white  rounded-tl-[20px] pl-2 rounded-bl-[20px]  shadow-lg shadow-slate-400 flex top-[170px] flex-col ${showFilterSidebar ? "active" : ""}`}>
          <div className="input-group search-area w-auto mr-2">
              <span className="input-group-text">
                  <Link to={"#"}>
                  <svg
                      width="24"
                      height="24"
                      viewBox="0 0 32 32"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                  >
                      <path
                      d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                      fill="var(--primary)"
                      ></path>
                  </svg>
                  </Link>
              </span>
              <input
                  type="text"
                  className="form-control"
                  placeholder={t("Search here...")}
                  onChange={(e) => {
                  setSearchValue(e.target.value);
                  }}
              />
          </div>
          <div className="filter-box relative flex flex-col p-2 mb-3">
              <h5>{t("Filter with category")}</h5>
              <div className="filter-input flex items-center mb-2">
                  <input type="radio" name="category" value={""} id="all-categories"
                      onChange={(e)=>setCategoryFilter("")}
                  />
                  <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor="all-categories">All Categories</label>
              </div>
              {
                  getCategoriesLoading
                  ?
                  <p>{t("loading")}...</p>
                  :
                  (
                  categories &&
                  categories.map((cat , index) => {
                      return (
                          <div key={index} className="filter-input flex items-center mb-2">
                              <input type="radio" name="category" id={cat.name} value={cat.id} 
                                  onChange={(e)=>setCategoryFilter(e.target.value)}
                              />
                              <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor={cat.name}>{cat.name}</label>
                          </div>
                      )
                  })
                  )
              }
          </div>
          {
            checkUserRole
            (
              ["manager"]
              ,
              <div className="filter-box relative flex flex-col p-2 mb-3">
                  <h5>{t("Filter with Instructor")}</h5>
                  <div className="filter-input flex items-center mb-2">
                      <input type="radio" name="instructor-filter" value={""} id="all-instructors"
                          onChange={(e)=>setInstructorFilter("")}
                      />
                      <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor="all-instructors">All Instructors</label>
                  </div>
                  {
                      getUsersLoading
                      ?
                      <p>{t("loading")}...</p>
                      :
                      (
                        users
                        &&
                      instructorsList &&
                      instructorsList.map((instructor , index) => {
                          return (
                              <div key={index} className="filter-input flex items-center mb-2">
                                  <input type="radio" name="instructor-filter" id={instructor.id} value={instructor.id}
                                      onChange={(e)=>setInstructorFilter(e.target.value)}
                                  />
                                  <label className="translate-y-[3px] flex items-center cursor-pointer" htmlFor={instructor.id}>
                                      <img src={instructor.image_url === null ? "https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?w=740&t=st=1702935704~exp=1702936304~hmac=0cf7808c31a16122cc1e8bfbd1b2e33c4f86a93e80af95f3eba31a9428f2ea2c" : instructor.image_url} alt="instructor img" className="w-[30px] mr-1"/>
                                      {instructor.name}
                                  </label>
                              </div>
                          )
                      })
                      )
                  }
              </div>
            )
          }
          <div className="filter-box relative flex flex-col p-2 mb-3">
              <h5>{t("Filter with status")}</h5>
              <div className="filter-input flex items-center mb-2">
                  <input type="radio"name="status" id={"all-status"} value={"publish"} 
                      onChange={(e)=>setPublishFilter("")}
                  />
                  <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor={"all-status"}>All</label>
              </div>
              <div className="filter-input flex items-center mb-2">
                  <input type="radio" name="status" id={"active"} value={"publish"} 
                      onChange={(e)=>setPublishFilter(e.target.value)}
                  />
                  <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor={"active"}>Active</label>
              </div>
              <div className="filter-input flex items-center mb-2">
                  <input type="radio" name="status" id={"unactive"} value={"unpublish"} 
                      onChange={(e)=>setPublishFilter(e.target.value)}
                  />
                  <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor={"unactive"}>Not Active</label>
              </div>
          </div>
          <div className="filter-box relative flex flex-col p-2 mb-3">
              <h5>{t("Filter with Semester")}</h5>
              <div className="filter-input flex items-center mb-2">
                  <input type="radio" name="semester" id={"empty semester"} value={""} 
                      onChange={(e)=>setSemesterFilter("")}
                  />
                  <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor={"empty semester"}>All</label>
              </div>
              <div className="filter-input flex items-center mb-2">
                  <input type="radio" name="semester" id={"first semester"} value={"first semester"} 
                      onChange={(e)=>setSemesterFilter(e.target.value)}
                  />
                  <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor={"first semester"}>First Semester</label>
              </div>
              <div className="filter-input flex items-center mb-2">
                  <input type="radio" name="semester" id={"second semester"} value={"second semester"} 
                      onChange={(e)=>setSemesterFilter(e.target.value)}
                  />
                  <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor={"second semester"}>second semester</label>
              </div>
              <div className="filter-input flex items-center mb-2">
                  <input type="radio" name="semester" id={"third semester"} value={"third semester"} 
                      onChange={(e)=>setSemesterFilter(e.target.value)}
                  />
                  <label className="translate-y-[3px] cursor-pointer ml-1" htmlFor={"third semester"}>third semester</label>
              </div>
          </div>
        </div>
      <>
        <div className="widget-heading d-flex justify-content-between align-items-center">
          <h3 className="m-0 header-text">{t("All Courses")}</h3>
          {(
          <div className="flex">
            {checkUserRole("manager")}
            <Link 
            to="/new-course/global"
            className="btn btn-primary btn-sm"
            >
              {t("Create")}
              <i className="fa-solid fa-plus ml-1"></i>
            </Link>
            {/* <div className="relative ml-2 flex justify-center items-center cursor-pointer">
              <i className={`fa-solid fa-filter text-[20px] ${showFilterSidebar ? "text-blue-700" : ""} transition-all duration-300  `}
                onClick={() => {
                  setShowFilterSidebar(!showFilterSidebar)
                  setGetFilteringData(true)
                }}
              ></i>
            </div> */}
          </div>
          )}
        </div>

        {loading ? (
          <>
            <div className="row d-flex justify-content-center gap-2">
              <Spinner animation="grow" className="bg-primary" />
              <Spinner animation="grow" className="bg-primary" />
              <Spinner animation="grow" className="bg-primary" />
              <Spinner animation="grow" className="bg-primary" />
            </div>
          </>
        ) 
        :
        (
          courses
          ?
          (
          <>
            <div className="courses-section h-fit relative row">
              {
                allCourses
                &&
                allCourses.length > 0
                ?
                allCourses.map((course) => {
                  console.log(course)
                  return (
                    <CourseCard
                      key={course.id}
                      course={course}
                      id={course.id}
                      course_name={course.name}
                      course_price={course.price}
                      coures_instructor={course.user}
                      course_img={course.image_url}
                      course_rating={course.rating}
                      lessonsCount = {course.lessons_count}
                      loading = {loading}
                      courseType = {course.type}
                      // deleteCourseFunc={() => deleteCourseFunction(course.id)}
                      studentsCount={course.students && course.students.length}
                      course_description = {course.description}
                    />
                  );
                })
                :
                <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                  <img
                    src={faildImg}
                    alt="faild img"
                    className="w-[150px] mb-3"
                  />
                  <p>
                    {t("No data available")}
                  </p>
                </div>
              }
            </div>
          </>
          )
          :
          <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
            <img
              src={faildImg}
              alt="faild img"
              className="w-[150px] mb-3"
            />
            <p>
              {t("No data available")}
              <span
                className="text-[#4d5fe3] cursor-pointer"
                onClick={() =>
                  dispatch(fetchAllCoursesFunc(courses_API_URL))
                }
              >
                {t("Try again")}
              </span>
            </p>
          </div>
        )}
      </>
      <OverLayLoading loading={deleteCourseLoading} loadingText={"Deleting..."}/>
    </div>
    </>
  );
}
export default CustomCoursesPage;
