import React, { Fragment, useEffect, useState } from "react";
import Ellipse from "./Ellipse";
import AdminLeftSideBar from "./AdminLeftSideBar";
import TeacherLeftSidebar from "./TeacherLeftSideBar";
import AssistantLeftSidebar from "./AssistantLeftSideBar";
import NavHader from "./NavHader";
import Header from "./Header";
// import RightSideBar from "./RightSideBar";
// import ChatBox from "../ChatBox";
import "./nav.css";
// import RightSidebar from "./RightSidbar";
import RightSidebar from "./right-side-bar/RightSideBar";


const JobieNav = ({ title, onClick: ClickToAddEvent, onClick2, onClick3 }) => {
  const [toggle, setToggle] = useState("");
  const user = JSON.parse(window.localStorage.getItem("user"))
  const userRoleName = user?.roles;
  // const [userRoleName, setUserRoleName] = useState("");
  const onClick = (name) => setToggle(toggle === name ? "" : name);
  const [showRightSidebar , setShowRightSidebar] = useState();
  const showRightSidebarFunc = () => {
    setShowRightSidebar(!showRightSidebar)
  }

  useEffect(() => {
    console.log(userRoleName)
  },[userRoleName]);

  let leftsidebar;

  switch (userRoleName) {
    case "Manager":
    leftsidebar = <AdminLeftSideBar/>;
    break;

    case "Teacher":
      leftsidebar = <TeacherLeftSidebar/>
      break;

    case "Assistant":
      leftsidebar = <AssistantLeftSidebar/>
      break;

      default:
        console.log("no user role")
  }
  return (
    <Fragment>
      <NavHader />
	  <Header
        onNotification={() => onClick("notification")}
        onProfile={() => onClick("profile")}
        toggle={toggle}
        title={title}
        onClick={() => ClickToAddEvent()}
        showRightSidebar = {showRightSidebarFunc}
      /> 

      {/* {
        leftsidebar 
      } */}
      <AdminLeftSideBar/>
      <RightSidebar
        showRightSidebar = {showRightSidebar}
      />
      {/* <RightSideBar/> */}
    </Fragment>
  );
};

export default JobieNav;
