import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ContextApi from "../../../store/ContextApi";
import videoIcon from "../../../media/images/video-camera.png";
import quizIcon from "../../../media/images/quiz.png";
import fileIcon from "../../../media/images/google-docs.png";
import pdfIcon from "../../../media/images/file.png";
import rich_textIcon from "../../../media/images/files.png";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./new-lesson-page.css";
import Swal from "sweetalert2";
import { AddNewLessonFunc } from "../../../store/custom-actions/lessons-actions/AddNewLessonSlicer";
import OverLayLoading from "../../custom-pages/custome-overlay-loading/OverLayLoading";
import HandleErrorsListFunc from "../../global-functions/handleErrorsListFunc";
import { toast } from "react-toastify";
import PageTitle from "../../layouts/PageTitle";
import { useTranslation } from "react-i18next";

function NewLessonPage() {
    const {t} = useTranslation();
    const params = useParams();
    const chapterId = params.chapterId;
    const contextApi = useContext(ContextApi);
    const lessonsApi = `${contextApi.dashboard_URL_API}/chapters/${chapterId}/lessons`;
    const dispatch = useDispatch();
    const {register , handleSubmit , reset , formState : {errors}} = useForm();
    const [lessonType , setLessonType] = useState("rich_text");
    const [lessonContents , setLessonContents] = useState();
    const [lessonDescription , setLessonDescription] = useState("");
    const [avilableForFree , setAvilableForFree] = useState(0);
    const {loading:addNewLessonLoading} = useSelector(state => state.addNewLessonSlicer)
    const lessonTypes = [
        {typeName : t("video") , value : "video" , icon : videoIcon},
        {typeName : t("quiz") , value : "quiz" , icon : quizIcon},
        {typeName : t("file") , value : "file" , icon : fileIcon},
        {typeName : t("pdf") , value : "pdf" , icon : pdfIcon},
        {typeName : t("rich_text") , value : "rich_text" , icon : rich_textIcon},
        {typeName : t("youtube") , value : "youtube" , icon : rich_textIcon},
    ];
    const handleSubmitNewLesson = async (data) => {
        if(data) {
            if(lessonType !== "") {
                if(lessonDescription === "") {
                    toast.error(t("Lesson Descripton Is Required!"))
                }else {
                    if(lessonType === t("quiz") && data.duration <= 1) {
                        toast.error(t("Duration Of Quiz Must be More Than 1 Munite!"))
                    }else {
                        dispatch(AddNewLessonFunc(
                            {
                                lessonsApi : lessonsApi , 
                                lessonData : {
                                    can_view_without_pay : avilableForFree,
                                    ...data , 
                                    type : lessonType ,
                                    description : lessonDescription,
                                    ...(lessonContents !== "" && { contents: lessonContents })
                                }
                            }
                        ))
                        .then (result => {
                            if(result.payload.success === true) {
                                setLessonType("");
                                reset();
                                setTimeout(() => window.history.back() , 2000)
                            }
                        })
                    }
                }
            }
            else {
                Swal.fire({
                    title: t("Oops!"),
                    text: t("You Should Choose Lesson type!"),
                    icon: "warning"
                });
            }
        }
    }
    const colors = [
        '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
        '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
    ];
    const modules = {
        toolbar: {
          container: [
            [{ 'font': [] }],
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'color': colors }, { 'background': colors }],
            [{ 'script': 'sub'}, { 'script': 'super' }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'indent': '-1'}, { 'indent': '+1' }],
            [{ 'direction': 'rtl' }],
            [{ 'align': [] }],
            ['blockquote', 'code-block'],
            ['link', 'image', 'video'],
            ['clean']
          ],
        }
      };
    useEffect(() => {
        HandleErrorsListFunc(errors)
    },[errors])
    useEffect(() => console.log(lessonType) , [lessonType])
    return (
        <>
            <PageTitle activeMenu={"New Lesson"} motherMenu={"Chapter"}/>
            <div className="card p-3">
                <div className="relative flex justify-between items-center">
                    <h4>{t("New Lesson")}</h4>
                    <div className="row  flex items-center justify-end">
                        <button type="submit" form="new-lesson-form" className="add-student-btn px-3 mr-2 relative text-white py-2 rounded-md bg-primary flex justify-center items-center transition-all duration-500 border overflow-hidden">Submit</button>
                        <button type="reset" className="" form="new-lesson-form"
                            onClick={() => setLessonContents("")}
                        >{t("Reset Values")}</button>
                    </div>
                </div>
                <div className="new-lesson-form-div relative w-full ">
                    <form 
                        className="new-lesson-form"
                        id="new-lesson-form"
                        onSubmit={handleSubmit((data) => {
                            handleSubmitNewLesson(data);
                        })}
                    >
                        <div className="row">
                            <div className="form-group mb-3 col-sm-12">
                                <label>{t("Lesson Type")}</label>
                                {errors.type?.message && (
                                <p className="text-red-600">
                                {errors.type?.message}
                                </p>
                                )}
                                <select
                                className="form-control"
                                value={lessonType}
                                onChange={(e) => setLessonType(e.target.value)}
                                >
                                    {
                                        lessonTypes.map((type , index) => {
                                            return (
                                                <option value={type.value} key={index}
                                                >{type.typeName}</option>
                                            )
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        {
                            (lessonType !== "" && lessonType !== "Choose Lesson Type..")
                            &&
                            <>
                                <div className={'row'}>
                                    <div className={`form-group mb-3 col-sm-12 ${lessonType === t("quiz") ? "col-md-4" : "col-md-6"}`}>
                                        <label className="flex items-center">
                                            <i className="fa-brands fa-cuttlefish mr-1"></i>
                                            {t("Title")}
                                        </label>
                                        {errors.name?.message && (
                                        <p className="text-red-600">
                                            {errors.name?.message}
                                        </p>
                                        )}
                                        <input
                                        {...register("name", {
                                            required: "lesson name is required !"
                                        })}
                                        type="text"
                                        className="form-control"
                                        />
                                    </div>
                                    <div className={`form-group mb-3 col-sm-12 ${lessonType === t("quiz") ? "col-md-4" : "col-md-6"}`}>
                                        <label className="flex items-center">
                                            <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                            {t("Order")}
                                        </label>
                                        {errors.order?.message && (
                                        <p className="text-red-600">
                                            {errors.order?.message}
                                        </p>
                                        )}
                                        <input
                                        {...register("order", {
                                            required: "Lesson Order Is Required!"
                                        })}
                                        type="number"
                                        className="form-control"
                                        />
                                    </div>
                                    {
                                        lessonType === "quiz"
                                        &&
                                        <div className="form-group mb-3 col-sm-12 col-md-4">
                                            <label className="flex items-center">
                                                <i className="fa-solid fa-arrow-up-9-1 mr-1"></i>
                                                {t("Questions Count")}
                                            </label>
                                            {errors.questions_count?.message && (
                                            <p className="text-red-600">
                                                {errors.questions_count?.message}
                                            </p>
                                            )}
                                            <input
                                            {...register("questions_count", {
                                                required: "Questions Count is required!"
                                            })}
                                            type="number"
                                            className="form-control"
                                            />
                                        </div>
                                    }
                                </div>
                                <div className={`row`}>
                                    <div className="form-group mb-3 col-sm-12 col-md-4">
                                        <label className="flex items-center">
                                            <i className="fa-solid fa-clock mr-1"></i>
                                            {t("Duration")} ({t("With Minutes")})
                                        </label>
                                        <input
                                        {...register("duration")}
                                        type="number"
                                        className="form-control"
                                        defaultValue={0}
                                        />
                                    </div>
                                    <div className="form-group mb-3 col-sm-12 col-md-4">
                                        <label className="flex items-center">
                                            <i className="fa-solid fa-users-viewfinder mr-1"></i>
                                            {t("View Count")} ({t("if 0 will be unlimted views")})
                                        </label>
                                        {errors.view_count?.message && (
                                        <p className="text-red-600">
                                            {errors.view_count?.message}
                                        </p>
                                        )}
                                        <input
                                        {...register("view_count", {
                                            required: "view_count is Required"
                                        })}
                                        type="number"
                                        className="form-control"
                                        defaultValue={0}
                                        />
                                    </div>
                                    <div className="form-group col-sm-12 col-md-4 flex items-cneter">
                                        <input 
                                            id="check-if-free-lesson"
                                            type="checkbox"
                                            onChange={(e) => {
                                                if(e.target.checked) {
                                                    setAvilableForFree(1);
                                                }else {
                                                    setAvilableForFree(0);
                                                }
                                            }}
                                            value={avilableForFree}
                                            className=" scale-125 cursor-pointer"
                                        />
                                        <label htmlFor="check-if-free-lesson" className="flex items-center ml-2 translate-y-[2px] cursor-pointer">
                                            <i className="fa-solid fa-rss mr-1"></i>
                                            {t("Avilable For Free")}...
                                        </label>
                                    </div>
                                </div>
                                {
                                    lessonType === "youtube"
                                    &&
                                    <div className="row">
                                        <div className="form-group col-sm-12 mb-2">
                                            <label htmlFor="check-if-free-lesson" className="flex items-center ml-2 translate-y-[2px] cursor-pointer">
                                                <i className="fa-brands fa-youtube mr-1"></i>
                                                {t("Youtube Video")}
                                            </label>
                                            <input 
                                                type="url" 
                                                placeholder={t("Youtube URL...")} 
                                                className="form-control col-sm-12"
                                                onChange={(e) => {
                                                    setLessonContents(e.target.value)
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="lesson-description-line mb-3 relative my-3 flex justify-center items-center after:absolute after:left-[50%] after:translate-x-[-50%] after:top-[50%] after:px-2 after:bg-[#eaebef] after:content-['Lesson Description'] after:translate-y-[-50%] bg-slate-300 w-full h-[1px]"></div>
                                <div className="row m-0 p-0">
                                    <ReactQuill 
                                    modules = {modules}
                                    className="p-0 border-none outline-none form-control bg-white mt-3" 
                                    value={lessonDescription} theme="snow" 
                                    onChange={setLessonDescription} />
                                </div>
                                {
                                    lessonType === "rich_text"
                                    &&
                                    <>
                                        <div className="lesson-text-content-line relative my-3 flex justify-center items-center after:absolute after:left-[50%] after:translate-x-[-50%] after:top-[50%] after:px-2 after:bg-[#eaebef] after:content-['Lesson Content'] after:translate-y-[-50%] bg-slate-300 w-full h-[1px]"></div>
                                        <div className="row p-0 m-0">
                                            <ReactQuill 
                                            modules = {modules}
                                            className="p-0 border-none outline-none form-control bg-white mt-3" 
                                            value={lessonContents} theme="snow" 
                                            onChange={setLessonContents} />
                                        </div>
                                    </>
                                }
                            </>
                        }
                    </form>
                </div>
            </div>
            <OverLayLoading loading={addNewLessonLoading} loadingText={t("loading...")}/>
        </>
        
    )
}

export default NewLessonPage;