import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import Swal from "sweetalert2";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const updateCourseImageFunc = createAsyncThunk(
    "edit user image function",
    async ({courseApi , courseData}) => {
        try {
            const response = await axios.post(courseApi , courseData);
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            // Swal.fire({
            //     title: "Oops!",
            //     text: error,
            //     icon: "error"
            // });
            checkErrors(error.response , error.message);
        }
    }
)

const updateCourseImageSlicer = createSlice({
    name : "edit user image slicer",
    initialState : {
        updateCourseImageLoading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(updateCourseImageFunc.pending , (state) => {
            state.updateCourseImageLoading = true
            state.data = null
            state.error = false
        });
        builder.addCase(updateCourseImageFunc.rejected , (state) => {
            state.updateCourseImageLoading = false
            state.data = null
            state.error = true
        });
        builder.addCase(updateCourseImageFunc.fulfilled , (state , action) => {
            state.updateCourseImageLoading = null
            state.data = action.payload
            state.error = null
        });
    }
})

export default updateCourseImageSlicer.reducer