import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
import Swal from "sweetalert2";
export const approveStudentFunc = createAsyncThunk(
    "approve student function",
    async ({studentApi}) => {
        try {
            const response = await axios.put(studentApi , {publish : "publish"} );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            // Swal.fire({
            //     title: "Oops!",
            //     text:error,
            //     icon: "error"
            // });
            checkErrors(error.response , error.message);
        }
        
    }
)

const approveStudentSlicer = createSlice({
    name : "approve student slicer",
    initialState : {
        approve_student_loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(approveStudentFunc.pending , (state) => {
            state.approve_student_loading = true
            state.data = {}
            state.error = false
        });
        builder.addCase(approveStudentFunc.rejected , (state) => {
            state.approve_student_loading = false
            state.data = {}
            state.error = true
        });
        builder.addCase(approveStudentFunc.fulfilled , (state , action) => {
            state.approve_student_loading = false
            state.data = action.payload
            state.error = false
        });
    }
});

export default approveStudentSlicer.reducer;