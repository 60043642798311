import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const addSessionExamFunc = createAsyncThunk(
    "add session exam function",
    async ({api , examData}) => {
        try {
            const response = await axios.post(api , examData );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            checkErrors(error.response , error.message);
            return error
        }
        
    }
);

const addSessionExamSlicer = createSlice({
    name : "add session exam slicer",
    initialState : {
        loading : null,
        data : [],
        error : false
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(addSessionExamFunc.pending , (state)=>{
            state.loading = true
            state.data = []
            state.error = null
        })
        builder.addCase(addSessionExamFunc.rejected , (state)=>{
            state.loading = false
            state.data = []
            state.error = true
        })
        builder.addCase(addSessionExamFunc.fulfilled , (state , action)=>{
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
});
export default addSessionExamSlicer.reducer;
