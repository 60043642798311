import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContextApi from "../../../store/ContextApi";
import { useParams } from "react-router-dom";
import noSubscripeIcon from "../../../media/images/page.png";
import { getSingleInvoicesFunc } from "../../../store/custom-actions/invoices-actions/GetSingleInvoiceSlicer";
import PageTitle from "../../layouts/PageTitle";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import faildImg from "../../../media/images/planet.png";
import defaultStImg from "../../../media/images/graduated.png";
import ReactToPrint from "react-to-print";
import ShowImageModal from "../../global-compnents/show-image-modal/ShowImageModal";
import Swal from "sweetalert2";
function SingleInvoicePage() {
    const params = useParams();
    const invoiceId = params.invoiceId;
    const dispatch = useDispatch();
    const contextApi = useContext(ContextApi);
    const singleInvoiceApi = `${contextApi.dashboard_URL_API}/invoices/${invoiceId}`
    const {loading:getSingleInvoiceLoading , data:invoiceData} = useSelector(state => state.getSingleInvoicesSlicer)
    const [executionsList , setExecutionsList] = useState([]);
    const [invoiceDetails , setInvoiceDetails] = useState({})
    const [creation , setCreation] = useState("");
    const [lastUpdate , setLastUpdate] = useState("");
    const [receiptImage , setReceiptImage] = useState("");
    const [showImage , setShowImage] = useState();
    const [invoiceOwner , setInvoiceOwner] = useState({})
    const printRef = useRef()
    const getInvoice = async () => {
        dispatch(getSingleInvoicesFunc(singleInvoiceApi))
    }
    useEffect(() => {
        getInvoice();
    },[])
    useEffect(()=>{
      invoiceData && setInvoiceDetails(invoiceData)
      invoiceData && invoiceData.student && setInvoiceOwner(invoiceData.student)
      invoiceData && invoiceData.course_executions && setExecutionsList(invoiceData.course_executions);
      if(invoiceData) {
        const creation = new Date(invoiceData.created_at)
        const creationYear = creation.getFullYear();
        const creationMonth = creation.getMonth()+1;
        const creationDay = creation.getDate();
        const updated = new Date(invoiceData.updated_at)
        const lastUpdatedYear = updated.getFullYear();
        const lastUpdatedMonth = updated.getMonth()+1;
        const lastUpdateDay = updated.getDate();
        setCreation(`${creationYear} / ${creationMonth} / ${creationDay}`);
        setLastUpdate(`${lastUpdatedYear} / ${lastUpdatedMonth} / ${lastUpdateDay}`);
    }
    },[invoiceData])


    const checkPaymentStatus = (paymentStatus) => {
      if(paymentStatus) {
        switch (paymentStatus) {
          case "rejected" :
            return   (
            <span className={`relative w-fit p-1 text-white rounded-md bg-red-500`}>
              <>
                <i className="fa-solid fa-circle-xmark mr-1"></i>
                {paymentStatus}
              </>
            </span> 
            )
          case "unconfirmed" :
            return   (
            <span className={`relative w-fit p-1 text-white rounded-md bg-orange-500`}>
              <>
                <i className="fa-solid fa-hourglass-half mr-1"></i>
                Pending
              </>
            </span> 
            )
          case "confirmed" :
            return   (
            <span className={`relative w-fit p-1 text-white rounded-md bg-green-500`}>
              <>
                <i className="fa-solid fa-circle-check mr-1"></i>
                {paymentStatus}
              </>
            </span> 
            )
            default :
            return   (
              <span className={`relative w-fit p-1 text-white rounded-md bg-orange-500`}>
                <>
                  <i className="fa-solid fa-circle-xmark mr-1"></i>
                  {paymentStatus}
                </>
              </span> 
              )
        }
      }
    }
    
    return (
        <>
        <PageTitle motherMenu={"Invoices"} activeMenu={"Single Invoice"}/>
        <div className="relative mb-2 flex justify-between items-center p-2">
          <h5 className="relative">Invoice Details</h5>
          <ReactToPrint
            trigger={() => (
              <Button>
                Print
                <i className="fa-solid fa-print ml-1"></i>
              </Button>
            )}
            content={() => printRef.current}
          />
        </div>
        <div className="all-content relaive flex flex-col" ref={printRef}>
          <div className="receipt-content relative flex flex-col">
            {
              getSingleInvoiceLoading 
              ?
              <>
              <div className="row d-flex flex-row justify-content-center gap-2 my-4 pl-4">
                <h4 className="text-slate-600">Loading Invoice..</h4>
              </div>
              </>
              :
              (
                invoiceData
                ?
                (
                  Object.keys(invoiceDetails).length > 1 
                  ?
                  (
                  <div className="receipt-header relative flex flex-col ">
                    <div className="relative w-full flex justify-between items-center border-b border-slate-500">
                      <h2>INVOICE TO :</h2>
                      <h2>INVOICE</h2>
                    </div>
                    <div className="relative w-full flex justify-between items-center">
                      <div className="invoice-owner relative flex flex-col mt-3">
                        <div className="owner relative flex justify-center items-center flex-col">
                          <div className="owner-img relative w-[100px] h-[100px] mb-2">
                            <img src={invoiceOwner.image_url ? invoiceOwner.image_url : defaultStImg} alt="owner img" className="absolute top-0 left-0 w-full h-full" />
                          </div>
                          <h3>{invoiceOwner.name}</h3>
                        </div>
                        <div className="relative mt-1 flex items-center">
                          <h5 className="mb-0 mr-1 text-slate-600">Email</h5>
                          :
                          <p className="mb-0 ml-1">
                            <Link to={`mailto:${invoiceOwner.email}`}>{invoiceOwner.email}</Link>
                          </p>
                        </div>
                      </div>
                      <div className="invoice-owner relative flex flex-col">
                        <div className="relative mt-1 flex items-center">
                          <h5 className="mb-1 mr-1 text-slate-600">Date</h5>
                          :
                          <p className="mb-0 ml-1">{creation}</p>
                        </div>
                        <div className="relative mt-1 flex items-center">
                          <h5 className="mb-1 mr-1 text-slate-600">Payment Type</h5>
                          :
                          <p className="mb-0 ml-1">{invoiceDetails.type_payment}</p>
                        </div>
                        <div className="relative mt-1 flex items-center">
                          <h5 className="mb-1 mr-1 text-slate-600">Payment Status</h5>
                          :
                          <p className="mb-0 ml-1">{checkPaymentStatus(invoiceDetails.status_payment)}</p>
                        </div>
                        <div className="relative flex items-center mb-1">
                          <div className="relative mt-1 flex items-center mr-1">
                            <h5 className="mb-1 mr-1 text-slate-600">Total Price</h5>
                            :
                            <p className="mb-0 ml-1">{parseInt(invoiceDetails.total_price)}</p>
                          </div>
                          <div className="relative mt-1 flex items-center mr-1">
                            <h5 className="mb-1 mr-1 text-slate-600">Descount</h5>
                            :
                            <p className="mb-0 ml-1">{parseInt(invoiceDetails.descount) === 0 ? "No Descount" : parseInt(invoiceDetails.descount)}</p>
                          </div>
                        </div>
                        {
                          invoiceDetails.order_id
                          &&
                          <div className="relative mt-1 flex items-center">
                            <h5 className="mb-1 mr-1 text-slate-600">Order</h5>
                            :
                            <p className="mb-0 ml-1">{invoiceDetails.order_id}</p>
                          </div>
                        }
                        {
                          invoiceDetails.type_payment === "send_receipt"
                          &&
                          <div className="relative mt-1 flex items-center">
                            <h5 className="mb-1 mr-1 text-slate-600">Receipt</h5>
                            :
                            <button className="ml-1 flex justify-center items-center bg-primary text-white rounded-md py-1 px-2"
                              onClick={() => {
                                if(invoiceDetails.receipt_image) {
                                  setReceiptImage(invoiceDetails.receipt_image)
                                  setShowImage(true)
                                }else {
                                  Swal.fire({
                                    title: "Oops!",
                                    text: "There is now receipt image!",
                                    icon: "error"
                                  });
                                }
                              }}
                            >
                              Receipt
                              <i className="fa-solid fa-image ml-1"></i>
                            </button>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  )
                  :
                  <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                  <img
                    src={faildImg}
                    alt="faild img"
                    className="w-[150px] mb-3"
                  />
                  <p>
                    No Data For This Invoice!
                  </p>
                  </div>
                  
                )
                :
                <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                <img
                  src={faildImg}
                  alt="faild img"
                  className="w-[150px] mb-3"
                />
                <p>
                  Faild load Invoice!
                  <span
                    className="text-[#4d5fe3] cursor-pointer"
                    onClick={() =>
                      getInvoice()
                    }
                  >
                    Try again
                  </span>
                </p>
                </div>
              )
            }
          </div>
          <div className="row mt-3">
              <>
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-body py-0">
                      <div className="table-responsive">
                        <div
                          id="student_wrapper"
                          className="dataTables_wrapper no-footer"
                        >
                          <table
                            className="table display mb-4 dataTablesCard order-table card-table text-black application "
                            id="application-tbl1_next"
                          >
                            <thead>
                              <tr>
                                <th className="text-[#4d5fe3]">Title</th>
                                <th className="text-[#4d5fe3]">Course</th>
                                <th className="text-[#4d5fe3]">Category</th>
                                <th className="text-[#4d5fe3]">Teacher</th>
                                <th className="text-[#4d5fe3]">starts Student date</th>
                                <th className="text-[#4d5fe3]">end Student date</th>
                                <th className="text-[#4d5fe3]">Status date</th>
                                <th className="text-[#4d5fe3]">Price</th>
                                <th className="text-[#4d5fe3]">Discount</th>
                                <th className="text-[#4d5fe3] text-end">
                                  Show
                                </th>
                              </tr>
                            </thead>
                            {
                              getSingleInvoiceLoading 
                              ?
                              <>
                              <div className="row d-flex flex-row justify-content-center gap-2 my-4 pl-4">
                                <h3 className="text-slate-700">Loading..</h3>
                              </div>
                              </>
                              :
                              <tbody>
                                {executionsList &&
                                  executionsList.length > 0 ?
                                  executionsList.map((execution, index) => {
                                      const {pivot , course} = execution;
                                      const {price , descount} = pivot;
                                      const {name:courseName , id:courseId , user , category} = course;
                                      const {name:teacherName , id:teacherId} = user;
                                    return (
                                      <tr key={index}>
                                        <td className="font-bold text-[#4d5fe3]">
                                          {execution.title}
                                        </td>
                                        <td>
                                          {courseName}
                                        </td>
                                        <td>
                                          {category && category.name && category.name}
                                        </td>
                                        <td>
                                          {teacherName}
                                        </td>
                                        <td>
                                          {execution.start_date_student}
                                        </td>
                                        <td>
                                          {execution.end_date_student}
                                        </td>
                                        <td>
                                          <span className={`relaive p-1 rounded-md ${execution.date_student_status === "active" ? "bg-green-500" : "bg-red-500"} text-white`}>
                                              {execution.date_student_status}
                                          </span>
                                        </td>
                                        <td className="">
                                          <p className="m-0">
                                              {price}&pound;
                                          </p>
                                        </td>
                                        <td>
                                          {descount ? <>{descount}&pound;</> : "No Discount" }
                                        </td>
                                        <td>
                                          <Link
                                              to={`instructor-profile/${teacherId}`}
                                              className="ml-1 w-fit inline-flex my-1"
                                          >
                                              <div class="group flex justify-center transition-all rounded-full bg-[#4d5fe3e0] p-2"
                                              >
                                                  <i className="fa-solid fa-chalkboard-user text-white"></i>
                                              </div>
                                          </Link>
                                          <Link
                                              to= {`course-details-page/${courseId}`}
                                              className="ml-1 w-fit inline-flex my-1"
                                          >
                                              <div class="group flex justify-center transition-all rounded-full bg-[#4d5fe3e0] p-2"
                                              >
                                                  <i className="fa-solid fa-graduation-cap text-white"></i>
                                              </div>
                                          </Link>
                                        </td>
                                      </tr>
                                    );
                                  })
                                  :
                                  <td colSpan={3} className="flex flex-col justify-center items-center">
                                          <img src={noSubscripeIcon} alt="no subscription" className="w-[50px] border-none"/>
                                          <p className="m-0 border-none">No Executions For This Invoice!</p> 
                                  </td>
                                  }
                              </tbody>
                            }
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
          </div>
        </div>
        <ShowImageModal
          show = {showImage}
          onHide = {() => setShowImage(false)}
          image = {receiptImage}
        
        />
      </>
    )
}

export default SingleInvoicePage;