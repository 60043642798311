import { useState , useEffect, useContext, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import faildImg from "../../../media/images/planet.png"
import { getActivationCodesFunc } from "../../../store/custom-actions/code-bulcks-actions/GetCodesSlicer";
import ContextApi from "../../../store/ContextApi";
import { useParams } from "react-router-dom";
import { deleteActivationCodeFunc } from "../../../store/custom-actions/code-bulcks-actions/DeleteCodesSlicer";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import ShowActivationCodes from "./ShowActivationCodes";
import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
import NewBulckForm from "./bulcksCodeforms/NewBulckForm";
import { useTranslation } from "react-i18next";

function CodeBulcksPage() {
    const {t} = useTranslation();
    const params = useParams();
    const courseId = params.courseId;
    const contextApi = useContext(ContextApi);
    const codes_API_URL = `${contextApi.dashboard_URL_API}/courses/${courseId}/code-bulks`;
    const delete_codes_API_URL = `${contextApi.dashboard_URL_API}/code-bulks`;
    const [bulcksList , setBulcksList] = useState([]);
    const dispatch = useDispatch();
    const [searchValue , setSearchValue] = useState("");
    const [showActivationCodes , setShowActivationCodes] = useState();
    const [bulckName , setBulckName] = useState();
    const [activationCodes , setActivationCodes] = useState([]);
    const [showNewBuclkForm , setShowNewBuclkForm] = useState();
    const [execution , setExecution] = useState();
    const {loading:getCodesLoading , data} = useSelector(
        state => state.getCodesSlicer
    )
    const {loading:deleteCodeLoading} = useSelector(
        state => state.deleteActivationCodeSlicer
    )
    const getCodes = async () => {
        try {
            dispatch(getActivationCodesFunc(codes_API_URL))
            .then(result => {
                console.log(result)
            })
        }
        catch (error){
            alert(error)
        }
    }
    useEffect(() => {
        data && setBulcksList(data);
        data && (jobData.current = data.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
          ));
      }, [data]);
      const sort = 20;
      let paggination = Array(Math.ceil(bulcksList.length / sort))
        .fill()
        .map((_, i) => i + 1);
    
      const activePag = useRef(0);
      const jobData = useRef(
        bulcksList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        )
      );
      const onClick = (i) => {
        activePag.current = i;
        jobData.current = bulcksList.slice(
          activePag.current * sort,
          (activePag.current + 1) * sort
        );
      };
    const deleteCode = async (codeId) => {
        const deleteFunc = () => {
            try {
                dispatch(deleteActivationCodeFunc(`${delete_codes_API_URL}/${codeId}`))
                .then(result => {
                    if(result.payload.success === true) {
                        getCodes();
                    }
                })
            }
            catch (error){
                alert(error)
            }
        }
        swal({
            title: t("Confirm Activation Code Deletion"),
            text: t(`Once deleted, this will be not avilable in this Course!`),
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteFunc()
            }
        });
    }


    useEffect(() => {
        let api = `${codes_API_URL}?${searchValue ? `search=${searchValue}` : ""}`;
        let searchTimeout;
        let fetchCoudes = async ()=>{
          if(searchValue !== "") {
            clearTimeout(searchTimeout);
            searchTimeout = setTimeout(()=>{
              dispatch(getActivationCodesFunc(api))
            },2000)
          }else {
            dispatch(getActivationCodesFunc(api))
          }
        }
        fetchCoudes()
        return () => clearTimeout(searchTimeout)
      },[searchValue])



    return (
        <>
            <PageTitle activeMenu="Codes List" motherMenu="Course" />
            <div className="content relative w-full">
                {
                    getCodesLoading
                    ?
                    (
                        <>
                            <div className="row d-flex justify-content-center gap-2">
                                <Spinner animation="grow" className="bg-primary" />
                                <Spinner animation="grow" className="bg-primary" />
                                <Spinner animation="grow" className="bg-primary" />
                                <Spinner animation="grow" className="bg-primary" />
                            </div>
                        </>
                    )
                    :
                    (
                        data
                        ?
                        (
                            <div className="card">
                                <div className="card-header flex justify-between items-center">
                                    <h4 className="card-title">{t("Codes List")}</h4>
                                    <div className="relative add-and-search flex items-center">
                                    <div className="input-group search-area w-auto mr-2">
                                        <span className="input-group-text"
                                            // onClick={() => search()}
                                        >
                                            <Link to={"#"}>
                                            <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 32 32"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                                fill="var(--primary)"
                                                ></path>
                                            </svg>
                                            </Link>
                                        </span>
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={t("Search here...")}
                                            onChange={(e) => {
                                            setSearchValue(e.target.value);
                                            }}
                                        />
                                    </div>
                                    {
                                        // checkPermissions(
                                        // "create subscriptions",
                                        <>
                                        <Link 
                                        to={`#`} 
                                        className="btn btn-primary btn-sm"
                                        onClick={() => setShowNewBuclkForm(true)}
                                        >
                                            {t("Create")}
                                            <i className="fa-solid fa-plus ml-1"></i>
                                        </Link>
                                        </>
                                        // )
                                    }
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive">
                                        <div
                                            id="student_wrapper"
                                            className="dataTables_wrapper no-footer"
                                        >
                                            <table
                                            className="table display mb-4 dataTablesCard order-table card-table text-black application "
                                            id="application-tbl1_next"
                                            >
                                            <thead>
                                                <tr>
                                                <th className="">#{t("ID")}</th>
                                                <th className="">{t("Name")}</th>
                                                <th className="">{t("Count")}</th>
                                                <th className="">{t("Course Ex")}</th>
                                                <th className="">{t("Creation")}</th>
                                                <th className="">{t("Last Update")}</th>
                                                <th className=" text-end">
                                                    {t("Decision")}
                                                </th>
                                                </tr>
                                            </thead>
                                                <tbody>
                                                    {bulcksList &&
                                                    jobData.current.length > 0 ?
                                                    jobData.current.map((bulck, index) => {
                                                        const {title , date_student_status} = bulck.course_execution
                                                        const creationDate = new Date(bulck.created_at)
                                                        const creationYear = creationDate.getFullYear();
                                                        const creationMonth = creationDate.getMonth()+1;
                                                        const creationDay = creationDate.getDate();
                                                        const latestUpdateDate = new Date(bulck.updated_at)
                                                        const latestUpdateYear = latestUpdateDate.getFullYear();
                                                        const latestUpdateMonth = latestUpdateDate.getMonth()+1;
                                                        const latestUpdateDay = creationDate.getDate();
                                                        return (
                                                        <tr key={index}>
                                                            <td className="font-bold">
                                                            #{bulck.id}
                                                            </td>
                                                            <td className="">
                                                                {bulck.name}
                                                            </td>
                                                            <td className="">
                                                                {bulck.count}
                                                            </td>
                                                            <td className="">
                                                                {
                                                                    <span className={`relative flex items-center`}>
                                                                        <span className="mr-1">{title}</span>
                                                                        {
                                                                            date_student_status
                                                                        }
                                                                        {
                                                                            date_student_status === "expired"
                                                                            ?
                                                                            <i class="fa-solid fa-temperature-empty text-red-500 ml-1"></i>
                                                                            :
                                                                            <i class="fa-solid fa-temperature-full text-green-500 ml-1"></i>
                                                                        }
                                                                    </span>
                                                                }
                                                            </td>
                                                            <td>
                                                            {`${creationYear} / ${creationMonth} / ${creationDay}`}
                                                            </td>
                                                            <td className="">
                                                            {`${latestUpdateYear} / ${latestUpdateMonth} / ${latestUpdateDay}`}
                                                            </td>
                                                            <td className="">
                                                            <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                                                onClick={() => {
                                                                    deleteCode(bulck.id)
                                                                }}
                                                            >
                                                                <div class="group flex justify-center transition-all rounded-full bg-primary p-2"
                                                                >
                                                                    <i className="fa-solid fa-trash text-white"></i>
                                                                    <span class="absolute opacity-0 group-hover:opacity-100 group-hover:-translate-y-7 duration-700 text-sm"
                                                                    >{t("Delete")}</span>
                                                                </div>
                                                            </button>
                                                            <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                                                onClick={() => {
                                                                    setExecution(bulck?.course_execution)
                                                                    setShowActivationCodes(true)
                                                                    setBulckName(bulck.name)
                                                                    setActivationCodes(bulck.activation_codes)
                                                                }}
                                                            >
                                                                <div class="group flex justify-center transition-all rounded-full bg-primary p-2"
                                                                >
                                                                    <i className="fa-solid fa-qrcode text-white"></i>
                                                                    <span class="absolute opacity-0 group-hover:opacity-100 group-hover:-translate-y-7 duration-700 text-sm"
                                                                    >{t("Codes")}</span>
                                                                </div>
                                                            </button>
                                                            </td>
                                                        </tr>
                                                        );
                                                    })
                                                    :
                                                    <td colSpan={4} className="flex flex-col justify-center items-center">
                                                        <p className="m-0 border-none">{t("No data available")}</p> 
                                                    </td>
                                                    }
                                                </tbody>
                                            </table>
                                            <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                                                <div className="dataTables_info">
                                                    {t("Showing")} {activePag.current * sort + 1} to{" "}
                                                    {bulcksList.length > (activePag.current + 1) * sort
                                                    ? (activePag.current + 1) * sort
                                                    : bulcksList.length}{" "}
                                                    {t("of")} {bulcksList.length} {t("entries")}
                                                </div>
                                                <div
                                                    className="dataTables_paginate paging_simple_numbers mb-0"
                                                    id="example5_paginate"
                                                >
                                                    <Link
                                                    className="paginate_button previous disabled"
                                                    //   to="/table-datatable-basic"
                                                    to="#"
                                                    onClick={() =>
                                                        activePag.current > 0 && onClick(activePag.current - 1)
                                                    }
                                                    >
                                                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                                    </Link>
                                                    <span>
                                                    {paggination.map((number, i) => (
                                                        <Link
                                                        key={i}
                                                        //   to="/table-datatable-basic"
                                                        to="#"
                                                        className={`paginate_button  ${
                                                            activePag.current === i ? "current" : ""
                                                        } `}
                                                        onClick={() => onClick(i)}
                                                        >
                                                        {number}
                                                        </Link>
                                                    ))}
                                                    </span>
                                                    <Link
                                                    className="paginate_button next"
                                                    //   to="/table-datatable-basic"
                                                    to="#"
                                                    onClick={() =>
                                                        activePag.current + 1 < paggination.length &&
                                                        onClick(activePag.current + 1)
                                                    }
                                                    >
                                                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                        :
                        <div className='relative w-full h-[60vh] flex flex-col justify-center items-center'>
                            <img src={faildImg} alt="faild img" className='w-[150px] mb-3'/>
                            <p>{t("No data available")}
                            <span className="text-[#4d5fe3] cursor-pointer"
                            onClick={()=> getCodes()}
                            >
                            {t("Try again")}
                            </span>
                            </p>
                        </div>
                    )
                }
            </div>
        <ShowActivationCodes
            show = {showActivationCodes}
            onHide = {() => setShowActivationCodes(false)}
            bulck_name = {bulckName}
            activation_codes = {activationCodes}
            execution = {execution}
        />
        <NewBulckForm
            show = {showNewBuclkForm}
            onHide = {() => setShowNewBuclkForm(false)}
            bulck_name = {bulckName}
            activation_codes = {activationCodes}
            course_id = {courseId}
            refetch_data = {() => getCodes()}
            
        />
        <OverLayLoading loading={deleteCodeLoading} loadingText={"Deleting Bulck.."} />
        </>
    )
}

export default CodeBulcksPage;