import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const copyQuestionsFunc = createAsyncThunk(
    "copy questions func",
    async ({api , data}) => {
        try {
            const response = await axios.post(api , data );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            checkErrors(error.response , error.message);
        }
    }
);

const copyQuestionsSlicer = createSlice({
    name : "copy questions slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(copyQuestionsFunc.pending , (state) => {
            state.loading = true;
            state.data = null;
            state.error = null;
        });
        builder.addCase(copyQuestionsFunc.rejected , (state) => {
            state.loading = false;
            state.data = null;
            state.error = true;
        });
        builder.addCase(copyQuestionsFunc.fulfilled , (state , action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = false;
        });
    }
})
export default copyQuestionsSlicer.reducer;