import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const filterInstructorsFunc = createAsyncThunk(
    "filter users function",
    async (api) => {
        try {
            const response = await axios.get(api );
            if(response.data.status_code === 401 || response.data.status_code === 403) {
                        window.localStorage.clear();
                        window.location.reload();
            }else{
                return response.data.data.courses;
            }
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
        
    }
)

const filterInstructorsSlicer = createSlice({
    name : "filter users slicer",
    initialState : {
        filterLoading : null,
        filtredInstructors : null,
        error : null
    },
    reducer : {},
    extraReducers : (builder) => {
        builder.addCase(filterInstructorsFunc.pending , (state) => {
            state.filterLoading = true
            state.filtredInstructors = null
            state.error = null
        })
        builder.addCase(filterInstructorsFunc.rejected , (state) => {
            state.filterLoading = null
            state.filtredInstructors = null
            state.error = true
        })
        builder.addCase(filterInstructorsFunc.fulfilled , (state , action) => {
            state.filterLoading = false
            state.filtredInstructors = action.payload
            state.error = null
        })
    }
})

export default filterInstructorsSlicer.reducer;