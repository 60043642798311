import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import Swal from "sweetalert2";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const addNewUserFunc = createAsyncThunk(
    "add new user function",
    async ({usersApi , userData}) => {
        try {
            const response = await axios.post(usersApi , userData );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
        
    }
)

const addNewUserSLicer = createSlice({
    name : "add new user slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(addNewUserFunc.pending , (state) => {
            state.loading = true
            state.data = false
            state.error = false
        });
        builder.addCase(addNewUserFunc.rejected , (state) => {
            state.loading = false
            state.data = null
            state.error = true
        });
        builder.addCase(addNewUserFunc.fulfilled , (state , action) => {
            state.loading = null
            state.data = action.payload
            state.error = null
        });
    }
});

export default addNewUserSLicer.reducer;