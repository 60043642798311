import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import ContextApi from '../../../store/ContextApi';
import sessionImg from "../../../media/images/online-learning.png";
import { getSingleRoleFunc } from '../../../store/custom-actions/roles-actions/GetSingleRoleSlicer';
import { Spinner, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
function ShowRoleModal(props) {
    const {t} = useTranslation();
    const {role_id , role} = props;
    const contextApi = useContext(ContextApi);
    const dispatch = useDispatch();
    const roles_API_URL = `${contextApi.dashboard_URL_API}/roles`;
    const {loading:getSingleRoleLoading , data:roleData} = useSelector(
        state => state.getSingleRoleSlicer
    )
    const [stArr , setStArr] = useState([])
    const [arrayWillSend , setarrayWillSend] = useState([]);
    const [singleRole , setSingleRole] = useState();
    const [rolePermissions , setRolePermissions] = useState([]);

    const getRole = async () => {
        try {
            dispatch(getSingleRoleFunc(`${roles_API_URL}/${role_id}`))
        }
        catch (error){
            alert(error)
        }
    }
    useEffect(() => {
        getRole()
    },[])
    useEffect(() => {
        roleData && setSingleRole(roleData);
        roleData && roleData.permissions &&  setRolePermissions(roleData.permissions);
    },[roleData])
    
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='flex items-center'>
          <img src={sessionImg} alt="session-icon" className='relative w-[20px] mr-2' />
          {role && role.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
    {
        getSingleRoleLoading
        ?
        (
            <>
                <div className="row d-flex justify-content-center gap-2">
                    <Spinner animation="grow" className="bg-primary" />
                    <Spinner animation="grow" className="bg-primary" />
                    <Spinner animation="grow" className="bg-primary" />
                    <Spinner animation="grow" className="bg-primary" />
                </div>
            </>
        )
        :
        <>
            <div className='relative w-full'>
                <div className='relative '>{t("Role Permissions")} : {rolePermissions.length}</div>
            </div>
            <Table responsive>
                <thead>
                    <tr>
                    <th className="width80">
                        <strong>#{t("ID")}</strong>
                    </th>
                    <th>
                        <strong>{t("Display Name")}</strong>
                    </th>
                    <th>
                        <strong>{t("Description")}</strong>
                    </th>
                    <th>
                        <strong>{t("Creation")}</strong>
                    </th>
                    <th>
                        <strong>{t("Last Update")}</strong>
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {rolePermissions && rolePermissions.length >= 1 ? rolePermissions.map((permission,index)=>{
                        const creationDate = new Date(permission.created_at)
                        const creationYear = creationDate.getFullYear();
                        const creationMonth = creationDate.getMonth()+1;
                        const creationDay = creationDate.getDate();
                        const latestUpdateDate = new Date(permission.updated_at)
                        const latestUpdateYear = latestUpdateDate.getFullYear();
                        const latestUpdateMonth = latestUpdateDate.getMonth()+1;
                        const latestUpdateDay = creationDate.getDate();
                        return (
                        <tr key={index}>
                            <td>
                                <strong>#{permission.id}</strong>
                            </td>
                            <td>{permission.display_name}</td>
                            <td>{permission.description}</td>
                            <td>
                            {`${creationYear} / ${creationMonth} / ${creationDay}`}
                            </td>
                            <td className="">
                            {`${latestUpdateYear} / ${latestUpdateMonth} / ${latestUpdateDay}`}
                            </td>
                        </tr>
                        )
                    })
                    :
                    <td colSpan={3}>{t("No Permissions For This Role")}</td>
                
                }
                </tbody>
            </Table>
        </>
    }

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
            props.onHide();
        }}>{t("Close")}</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ShowRoleModal