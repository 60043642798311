import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const linkQuizeWithCourseFunc = createAsyncThunk(
    "link quize with course function",
    async ({api , linkData}) => {
        try {
            const response = await axios.post(api , linkData );
            console.log(response)
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            checkErrors(error.response , error.message);
            return error
        }
        
    }
);

const linkQuizeWithCourseSlicer = createSlice({
    name : "link quize with course slicer",
    initialState : {
        loading : null,
        data : null,
        error : false
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(linkQuizeWithCourseFunc.pending , (state)=>{
            state.loading = true
            state.data = null
            state.error = null
        })
        builder.addCase(linkQuizeWithCourseFunc.rejected , (state)=>{
            state.loading = false
            state.data = null
            state.error = true
        })
        builder.addCase(linkQuizeWithCourseFunc.fulfilled , (state , action)=>{
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
});
export default linkQuizeWithCourseSlicer.reducer;