import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ContextApi from "../../../../store/ContextApi";
import { useDispatch, useSelector } from "react-redux";
import { getAllSessionsFunc } from "../../../../store/custom-actions/sessions-actions/GetAllSessionsSlicer";
import { deleteSessionFunc } from "../../../../store/custom-actions/sessions-actions/DeleteSessionSlicer";
import Swal from "sweetalert2";
import AddSessionForm from "./AddSessionForm";
import SessionDescionMenu from "./SessionDescionMenu";
import PageTitle from "../../../layouts/PageTitle";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";
import EditSessionForm from "./EditSessionForm";
import OverLayLoading from "../../custome-overlay-loading/OverLayLoading";
import "./sessions-page.css";
import RecordOfflineExamForm from "../../../global-compnents/record-ofline-exam-form/RecordOfflineExamForm";
function CourseSessionsPage() {
    const contextApi = useContext(ContextApi);
    const params = useParams();
    const {courseId} = params;
    const [sessionsList , setSessionsList] = useState([]);
    const sessionsAPi = `${contextApi.dashboard_URL_API}/courses/${courseId}/sessions`;
    const deleteSessionsAPi = `${contextApi.dashboard_URL_API}/sessions`;
    const dispatch = useDispatch();
    const {loading , data} = useSelector(state => state.getAllSessionsSlicer);
    const {loading:deletingSession} = useSelector(state => state.deleteSessionSlicer);
    const [showNewSessionForm , setShowNewSessionForm] = useState();
    const [showEditsSessionForm , setShowEditsSessionForm] = useState();
    const [searchSessionValue , setSearchSessionValue] = useState("");
    const [sessionData , setSessionData] = useState();
    const [sessionCourse , setSessionCourse] = useState({});
    const [sessionId , setSessionId] = useState();
    const [singleExecutionId , setSingleExecutionId] = useState();
    const [showRecordExamForm , setShowRecordExamForm] = useState();

    useEffect(() => {
      const courseSessionsAPi = `${contextApi.dashboard_URL_API}/courses/${courseId}/sessions${searchSessionValue !== "" ? `?search=${searchSessionValue}` : ""}`;
  
      let searchTimeout;
      let getSessions = async ()=>{
        if(searchSessionValue !== "") {
          clearTimeout(searchTimeout);
          searchTimeout = setTimeout(()=>{
            dispatch(getAllSessionsFunc(courseSessionsAPi))
          },2000)
        }else {
          dispatch(getAllSessionsFunc(courseSessionsAPi))
        }
      }
      getSessions()
      return () => clearTimeout(searchTimeout);
    },[dispatch , searchSessionValue])

    useEffect(() => {
        data && setSessionsList(data)
        data && data.length > 0 && setSessionCourse(data[0].course)
    },[data, sessionCourse])

    const deleteSession = (sessionName , sessionId) => {
        const deleteFunc = () => {
            dispatch(deleteSessionFunc(`${deleteSessionsAPi}/${sessionId}`))
            .then(result => {
                if(result.payload.success === true) {
                  dispatch(getAllSessionsFunc(sessionsAPi))
                }
            })
        }
        swal({
            title: "Confirm Session Deletion",
            text: `Once deleted, be not avilable on course!`,
            icon: "warning",
            buttons: true,
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
                deleteFunc();
            } else {
              swal("Your Session is safe!");
            }
          });
    }

    
    return (
        <>
      <PageTitle activeMenu={sessionCourse && sessionCourse.name ? sessionCourse.name : "Sessions"} motherMenu="Course" />
<div className="row">
        {/* {loading ? (
          <>
            <div className="row d-flex justify-content-center gap-2">
              <Spinner animation="grow" className="bg-primary" />
              <Spinner animation="grow" className="bg-primary" />
              <Spinner animation="grow" className="bg-primary" />
              <Spinner animation="grow" className="bg-primary" />
            </div>
          </>
        ) : ( */}
          <>

            <div className="col-xl-12">
              <div className="card students-list">
                <div className="card-header border-0 flex-wrap pb-0">
                  <h4>Sessions List</h4>
                  <div className="flex">
                    <div className="input-group search-area w-auto">
                      <span className="input-group-text">
                        <Link to={"#"}>
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                              fill="var(--primary)"
                            ></path>
                          </svg>
                        </Link>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search here..."
                        onChange={(e) => {
                          setSearchSessionValue(e.target.value);
                        }}
                      />
                    </div>
                    {
                      data && sessionsList
                      &&
                      <button
                        className="add-student-btn px-3 relative text-white py-2 rounded-md ml-3 bg-[#4d5fe3] flex justify-center items-center transition-all duration-500 border overflow-hidden"
                        onClick={() => {
                          setShowNewSessionForm(true);
                        }}
                      >
                        <i className="fa-solid fa-plus transition-all duration-700 absolute left-0 opacity-0 delay-[.2s]"></i>
                        <span className="relative mx-[2px] transition-all duration-500 delay-[.1s]">
                          New
                        </span>
                        <span className="relative mx-[2px] transition-all duration-500 delay-[]"></span>
                        <span className="relative mx-[2px] transition-all duration-500 delay-[0s]">
                          Session
                        </span>
                      </button>
                    }
                  </div>
                </div>
                <div className="card-body py-0">
                  <div className="table-responsive">
                    <div
                      id="student_wrapper"
                      className="dataTables_wrapper no-footer"
                    >
                      <table
                        className="table display mb-4 dataTablesCard order-table card-table text-black application "
                        id="application-tbl1_next"
                      >
                        <thead>
                          <tr>
                            <th className="text-[#4d5fe3]">ID</th>
                            <th className="text-[#4d5fe3]">details</th>
                            <th className="text-[#4d5fe3]">session Date</th>
                            <th className="text-[#4d5fe3]">Students</th>
                            <th className="text-[#4d5fe3]">Exams</th>
                            <th className="text-[#4d5fe3]">Info</th>
                            <th className="text-[#4d5fe3] text-end">
                              Decision
                            </th>
                          </tr>
                        </thead>
                        {
                          loading 
                          ?
                          <>
                          <div className="row d-flex justify-content-center gap-2 my-4 pl-4">
                            <Spinner animation="grow" className="bg-primary" />
                            <Spinner animation="grow" className="bg-primary" />
                            <Spinner animation="grow" className="bg-primary" />
                            <Spinner animation="grow" className="bg-primary" />
                          </div>
                          </>
                          :
                          <tbody>
                            {
                              data &&
                              sessionsList.length > 0 ?
                              sessionsList.map((session, index) => {
                                return (
                                  <tr key={index}>
                                    <td className="font-bold text-[#4d5fe3]">
                                      #{session.id}
                                    </td>
                                    <td>{session.details}</td>
                                    <td className="">
                                      {session.session_date}
                                    </td>
                                    <td className="">
                                      {session.students && session.students.length}
                                    </td>
                                    <td className="">
                                      {
                                        session.offline_exam
                                        ?
                                        1
                                        :
                                        "No Exams"
                                      }
                                    </td>
                                    <td>
                                      <Link
                                        to={`/show-session/${session.id}`}
                                        className="hover:text-black transition-all duration-300 text-[16px]"
                                      >
                                        <i className="fa-regular fa-eye transition-all duration-300 hover:text-blue-500"></i>
                                      </Link>
                                    </td>
                                    <td>
                                      <SessionDescionMenu
                                        deleteSessionFunction = {()=>deleteSession(session.details , session.id)}
                                        showEditForm = {() => {
                                          setSingleExecutionId(session.course_execution_id)
                                          setShowEditsSessionForm(true);
                                        }}
                                        setSessionData = {()=>setSessionData(session)}
                                        showRecordExamForm = {()=>setShowRecordExamForm(true)}
                                        passSessionId = {()=>setSessionId(session.id)}
                                      />
                                    </td>
                                  </tr>
                                );
                              })
                              :
                              <h4>No Sessions For This Course</h4>
                            }
                          </tbody>
                        }
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        {/* )} */}
      </div>
        <RecordOfflineExamForm
          show={showRecordExamForm}
          onHide={() => setShowRecordExamForm(false)} 
          sessionId = {sessionId}
          refetchSessions = {()=>dispatch(getAllSessionsFunc(sessionsAPi))}
        />
        {
          showEditsSessionForm
          &&
          <EditSessionForm
            show={showEditsSessionForm}
            onHide={() => setShowEditsSessionForm(false)} 
            courseId = {courseId}
            sessionData = {sessionData}
            single_execution_id = {singleExecutionId}
            // execution_students = {studen}
          />
        }
        {
          showNewSessionForm
          &&
          <AddSessionForm
            show={showNewSessionForm}
            onHide={() => setShowNewSessionForm(false)} 
            courseId = {courseId}
            refetchSessions = {()=>dispatch(getAllSessionsFunc(sessionsAPi))}
          />
        }
        <OverLayLoading loadingText={"Deleting Session.."} loading={deletingSession} />
        </>
    )
}


export default CourseSessionsPage;