import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { addNewCategoryFunc } from '../../../store/custom-actions/categories-actions/AddCategorySlicer';
import { fetchAllCategoriesFunc } from '../../../store/custom-actions/categories-actions/GetAllCategoriesSlicer';
import { useEffect, useState} from 'react';
import HandleErrorsListFunc from '../../global-functions/handleErrorsListFunc';
import { TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';
import { Bounce, toast } from 'react-toastify';

// Function to convert 'children' to 'all_children'
const convertAllChildrenKey = (data) => {
  if (!Array.isArray(data)) return [];
  return data.map((item) => {
    const { name, id, all_children, ...rest } = item;
    return {
      ...rest,
      title: name,
      value: id,
      children: convertAllChildrenKey(all_children),
    };
  });
};

const TreeSelectExam = ({treeData , setParentCategryFunc}) => {
  const [value, setValue] = useState();
  const onChange = (newValue) => {
    console.log(newValue);
    setParentCategryFunc(newValue)
    setValue(newValue);
  };
  const modifiedTreeData = convertAllChildrenKey(treeData);

  return (
    <TreeSelect
      style={{
        width: '100%',
        height: '40px',
      }}
      value={value}
      dropdownStyle={{
        maxHeight: 400,
        overflow: 'auto',
      }}
      treeData={modifiedTreeData}
      placeholder="Please select"
      treeDefaultExpandAll
      onChange={onChange}
      treeDataSimpleMode={{
        id: 'value',
        pId: 'parentId',
        rootPId: null,
      }}
    />
  );
};

function AddCategoryForm({showCategoryForm , hideCategoryForm , categoriesApi , categoriesList}) {
    const {t} = useTranslation();
    const {register , reset , formState : {errors} , handleSubmit} = useForm();
    const {addCategoryLoading} = useSelector(state => state.addCategorySlicer);
    const dispatch = useDispatch();
    const {loading , categoriesData} = useSelector(state => state.getAllCategoriesSlicer);
    const [categoryId , setCategoryId] = useState(null);
    const [makeSubCategory , setMakeSubCategory] = useState(false);
    const [image , setimage] = useState(null)
    const [imageViewr , setImageViewr] = useState();
    const changeCategoryParetIdFunc = (id) => {
      setCategoryId(id)
    }
    const handleSubmitData = (data) => {
      if(makeSubCategory && !categoryId) {
        toast.error(
          t("Please Select The Parent Of This Sub Category") , {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
      })
    }else {
      // if(!image) {
      //     toast.error(
      //       t("Please Select The Category Image") , {
      //       position: "top-right",
      //       autoClose: 5000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //       theme: "light",
      //       transition: Bounce,
      //   })
      //   }else {
      //   }
        const formData = new FormData();
        categoryId && formData.append("parent_id", categoryId);
        formData.append("name", data.name);
        formData.append("image", image);
        dispatch(addNewCategoryFunc({categoryApi : categoriesApi , categoryData : formData}))
        .then(result => {
            if(result.payload.success === true) {
              dispatch(fetchAllCategoriesFunc(categoriesApi))
              hideCategoryForm();
              reset();
              setCategoryId(null);
              setimage(null)
            }
        })
      }
    }
    useEffect(() => {
      HandleErrorsListFunc(errors);
    },[errors])
  return (
    <>
      <Modal show={showCategoryForm} onHide={hideCategoryForm}>
        <Modal.Header closeButton>
          <Modal.Title>{t("New Category")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form 
          id="new-category-form"
          onSubmit={handleSubmit((data) => {
              handleSubmitData(data)
          })}
          >
          <div className='row'>
              <div className="form-group mb-3 col-sm-12">
                  <label>{t("Name")}</label>
                  {errors.name?.message && (
                  <p className="text-red-600">{errors.name?.message}</p>
                  )}
                  <input
                  {...register("name" , {required : t("name should not be empty!")})}
                  type="text"
                  className="form-control"
                  onChange={(e) => console.log(e.target.value)}
                  />
              </div>
              {
                makeSubCategory
                &&
                <div className="form-group mb-3 col-sm-12">
                  {
                      loading ? (
                          <div className="form-group flex items-center">
                              <p>{t("loading")}...</p>
                          </div>
                      ) : categoriesData &&
                          categoriesList &&
                          categoriesList.length <= 0 ? (
                          <div className="form-group flex items-center">
                              <p>{t("No data available")}</p>
                          </div>
                      ) : (
                          <div
                              className={`form-group mb-3 col-sm-12`}
                          >
                          <label>{t("Main Category")}</label>
                            <TreeSelectExam treeData={categoriesList} setParentCategryFunc = {changeCategoryParetIdFunc}/>
                          </div>
                      )
                  }
                </div>
              }
              <div className="form-group mb-3 col-sm-12">
                  <input
                  type="checkbox"
                  id="customCheckbox4"
                  onChange={(e) => {
                    e.target.checked
                    ?
                    setMakeSubCategory(true)
                    :
                    setMakeSubCategory(false)
                  }}
                  className="cursor-pointer scale-105 translate-y-[2px] mx-2"
                  />
                  <label htmlFor='customCheckbox4' className='cursor-pointer'>{t("Make It Sub Category")}</label>
              </div>
              <div className={`col-sm-12 form-group mb-3`}>
                <label>{t("Image")}</label>
                <div className="input-group">
                  <input
                    accept="image/*"
                    type="file"
                    className="form-control"
                    name="image"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if(file) {
                          setimage(file)
                          const fileurl = URL.createObjectURL(file);
                          // setPosterViewr(fileurl)
                      }
                    }}
                  />
                </div>
              </div>
          </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideCategoryForm}>
            {t("Close")}
          </Button>
          <Button type="submit" form="new-category-form" className={`${addCategoryLoading ? "opacity-80 pointer-events-none" : ""}`}>
          {!addCategoryLoading ? (
            t("Submit")
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                {t("loading")}...
                <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white"></div>
              </div>
            </>
          )}
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default AddCategoryForm;