import { useTranslation } from "react-i18next";

function LoadingScreen() {
    const {t} = useTranslation();
    return (
        <>
            <div className="fixed top-0 left-0 w-full h-[100vh] flex flex-col justify-center items-center">
                <div className="loading-div relative flex gap-3">
                    <span class="relative flex h-4 w-4">
                        <span class="animate-ping  absolute  inline-flex h-full w-full rounded-full bg-primary opacity-50"></span>
                        <span class="relative inline-flex rounded-full h-4 w-4 bg-primary"></span>
                    </span>
                    <span class="relative flex h-4 w-4">
                        <span class="animate-ping [animation-delay:-.3s] absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
                        <span class="relative inline-flex rounded-full h-4 w-4 bg-primary"></span>
                    </span>
                    <span class="relative flex h-4 w-4">
                        <span class="animate-ping [animation-delay:-.5s] absolute inline-flex h-full w-full rounded-full bg-primary opacity-50"></span>
                        <span class="relative inline-flex rounded-full h-4 w-4 bg-primary"></span>
                    </span>
                    <span class="relative flex h-4 w-4">
                        <span class="animate-ping [animation-delay:-.5s] absolute inline-flex h-full w-full rounded-full bg-primary opacity-50"></span>
                        <span class="relative inline-flex rounded-full h-4 w-4 bg-primary"></span>
                    </span>
                </div>
                {/* <p className="m-0 mt-2 text-[15px] flex relative items-center text-orange-500">
                    L
                    <span class="relative flex h-2 w-2 mx-1">
                        <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-orange-400 opacity-75"></span>
                        <span class="relative inline-flex rounded-full h-2 w-2 bg-orange-500"></span>
                    </span>
                    ading..
                </p> */}
                <p className="m-0 mt-2 text-[15px] flex relative items-center text-primary">
                    {t("loading...")}
                </p>
            </div>
        </>
    )
}
export default LoadingScreen;