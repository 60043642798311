import { Outlet , Navigate } from "react-router-dom";
import { useState , useEffect } from "react";
function Protect(){
    let [loggedSucsess , setLoggedSucsess] = useState();
    const expire_date = window.localStorage.getItem("expire_date")

    useEffect (() => {
        expire_date ? setLoggedSucsess(true) : setLoggedSucsess(false)
    },[expire_date])

    return !loggedSucsess ? <Outlet/> : <Navigate to="/" replace/>
}
export default Protect;