import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import ContextApi from '../../../../store/ContextApi';
import Swal from 'sweetalert2';
import {addNewSessionFunc} from '../../../../store/custom-actions/sessions-actions/AddSessionSlicer';
import defaultStImg from "../../../../images/1.jpg";
import sessionImg from "../../../../media/images/online-learning.png";
import "./sessions-page.css";
import { getCourseExcutionFunc } from '../../../../store/custom-actions/courses-actions/excutions-actions/GetCourseExcutionsSlicer';
import { getSingleExcutionFunc } from '../../../../store/custom-actions/courses-actions/excutions-actions/GetSingleExcution';
function AddSessionForm(props) {
    const {courseId , refetchSessions} = props
    const contextApi = useContext(ContextApi);
    const sessionsApi = `${contextApi.dashboard_URL_API}/courses/${courseId}/sessions`;
    const invoicesApi = `${contextApi.dashboard_URL_API}/invoices?course_id=${courseId}`
    const {register , handleSubmit , formState : {errors}  } = useForm();
    const dispatch = useDispatch();
    const {sessionData , addSessionLoading} = useSelector(state => state.addNewSessionSlicer)
    const [studentsList , setStudentsList] = useState([]);
    const [stArr , setStArr] = useState([])
    const [executionsList , setExecutionsList] = useState([])
    const [singleExecutionId , setSingleExecutionId] = useState("Choose Execution..")
    const { loading:getExecutionsLoading, data:executionsData } = useSelector((state) => state.getCourseExcutionsSlicer);
    const excutions_URL_APi = `${contextApi.dashboard_URL_API}/courses/${courseId}/course-executions`;

    const {loading:getSingleExecutionLoading , data:sigleExecutionData} = useSelector(
      (state) => state.getSingleExcutionSlicer
      );
    // useEffect(() => {
    //   dispatch(getInvoicesFunc(invoicesApi))
    // },[])
    // useEffect(() => {
    //   dispatch(getSingleExcutionFunc(invoicesApi))
    // },[])
    useEffect(() => {
      dispatch(getCourseExcutionFunc(excutions_URL_APi))
    },[])
    useEffect(() => {
      if(executionsData) {
        setExecutionsList(executionsData)
      }
    },[executionsData])
    useEffect(() => {
      const singleExcutionApi = `${contextApi.dashboard_URL_API}/course-executions/${singleExecutionId}`;
        const getSingleExcution = async () => {
            dispatch(getSingleExcutionFunc(singleExcutionApi))
        }
        if(singleExecutionId !== "Choose Execution..") {
          getSingleExcution();
        }
    },[singleExecutionId])
    useEffect(() => {
      if(sigleExecutionData) {
        setStudentsList(sigleExecutionData.students)
      }
    },[sigleExecutionData])
    const handleSubmitNewSession = async (data) => {
        if(data && stArr) {
          if(stArr.length >= 1) {
            dispatch(addNewSessionFunc(
              {
                sessionsApi : sessionsApi , 
                sessionData : {...data ,
                students : stArr,
                course_execution_id : singleExecutionId
              }}
              ))
              .then(result => {
                  if(result.payload.success === true) {
                    refetchSessions();
                    props.onHide();
                  }
              })
          }else {
            Swal.fire({
              title: "No Students",
              text: "You Should Select Students!",
              icon: "warning",
              confirmButtonColor: "#3085d6",
            })
          }
        }
    }

    // useEffect(() => {
    //   const activeSubscriptions = invoicesData && invoicesData.filter(subscribe => subscribe.status === "active")
    //   const studentsList = invoicesData && activeSubscriptions.length >= 1 && activeSubscriptions.flatMap(sub => sub.student)
    //   setStudentsList(studentsList)
    // },[invoicesData])


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='flex items-center'>
          <img src={sessionImg} alt="session-icon" className='relative w-[20px] mr-2' />
          New Session
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='session-form-div'>
            <form
                id="session-form"
                onSubmit={handleSubmit((data) => {
                  const dateNow = new Date();
                  const session_date = new Date(data.session_date);
                  if (
                    dateNow.getFullYear() > session_date.getFullYear() ||
                    (dateNow.getFullYear() === session_date.getFullYear() && dateNow.getMonth() > session_date.getMonth()) ||
                    (dateNow.getFullYear() === session_date.getFullYear() && dateNow.getMonth() === session_date.getMonth() && dateNow.getDate() > session_date.getDate())
                    )
                    {
                      Swal.fire({
                        title: "Date Validation",
                        text: "Can not add session with past date",
                        icon: "warning",
                        confirmButtonColor: "#3085d6",
                      });
                    }
                    else {
                      handleSubmitNewSession(data);
                    }
                })}
            >
                <div className='row'>
                    <div className='form-group col-sm-12 col-md-6'>
                        <label>Session Date</label>
                        {errors.session_date?.message && (
                          <p className="text-red-600">
                            {errors.session_date?.message}
                          </p>
                        )}
                        <input 
                        {...register("session_date" , {required : "session date is required!"})}
                        type="date" 
                        className='form-control'/>
                    </div>
                    <div className='form-group col-sm-12 col-md-6'>
                        <label>Details</label>
                        {errors.details?.message && (
                          <p className="text-red-600">
                            {errors.details?.message}
                          </p>
                        )}
                        <input 
                        {...register("details" , {required : "session details is required!"})}
                        type="text" 
                        className='form-control'
                        />
                    </div>
                </div>
                <div className='row'>
                {
                      getExecutionsLoading
                      ?
                      <div className="form-group flex items-center">
                        <p>loading Executions..</p>
                      </div>
                      :
                      (
                        executionsData && executionsList && executionsList.length <= 0
                        ?
                        <div className="form-group flex items-center">
                          <p>No Executions</p>
                        </div>
                        :
                        <div className={`form-group mb-3 col-sm-12`}>
                          <label>Execution</label>
                          <select
                          onChange={(e) => {
                            setSingleExecutionId(e.target.value)
                          }}
                            className="form-control"
                            value = {singleExecutionId}
                          >
                            
                            <option  value={"Choose Execution.."}>Choose Execution..</option>
                            {
                              executionsList &&
                              executionsList.map((execuion) => {
                                return (
                                  <option key={execuion.id} value={execuion.id}>{execuion.title}</option>
                                )
                              })
                            }
                          </select>
                        </div>
                      )
                    }
                </div>

                <h4 className="my-2">Select Session Students <span className='text-sm font-normal'>(at least 1 student)</span></h4>
                <div className='row mt-2'>
                  {
                    getSingleExecutionLoading
                    ?
                    <p>Getting Students..</p>
                    :
                    (
                    sigleExecutionData && studentsList &&
                    studentsList.length >= 1
                    ?
                    studentsList.map((student , index) => {
                      return (
                        <>
                          <div key={index} className='cursor-pointer justify-start m-1 col-sm-10 col-md-5 col-lg-2 flex items-center'>
                            <input 
                            onChange={(e)=>{
                              if(e.target.checked){
                                stArr.push(student.id)
                              }else {
                                let filtred = stArr.filter(st => {
                                  return st !== student.id
                                });
                                setStArr([...filtred]);
                              }
                            }}
                            type='checkbox' 
                            className='cursor-pointer m-0' 
                            id={index}/>
                            <label className='cursor-pointer flex items-center ml-1 mt-1' htmlFor={index}>
                              <img src={student.image_url !== null ? student.image_url : defaultStImg} className='relative rounded-[50%] w-[25px] mx-2' alt="st img" />
                              <p className='m-0'>{student.name}</p>
                            </label>
                          </div>
                        </>
                      )
                    })
                    :
                    <p>There are no students participating in this Execution!</p>
                    )
                  }
                </div>
                {/* <div className='row mt-2'>
                  {
                    invoicesData &&
                    studentsList.length >= 1
                    ?
                    studentsList.map((student , index) => {
                      return (
                        <>
                          <div key={index} className='cursor-pointer justify-start m-1 col-sm-10 col-md-5 col-lg-2 flex items-center'>
                            <input 
                            onChange={(e)=>{
                              if(e.target.checked){
                                stArr.push(student.id)
                              }else {
                                let filtred = stArr.filter(st => {
                                  return st !== student.id
                                });
                                setStArr([...filtred]);
                              }
                            }}
                            type='checkbox' 
                            className='cursor-pointer m-0' 
                            id={index}/>
                            <label className='cursor-pointer flex items-center ml-1 mt-1' htmlFor={index}>
                              <img src={student.image_url !== null ? student.image_url : defaultStImg} className='relative rounded-[50%] w-[25px] mx-2' alt="st img" />
                              <p className='m-0'>{student.name}</p>
                            </label>
                          </div>
                        </>
                      )
                    })
                    :
                    <p>There are no students participating in this course!</p>
                  }
                </div> */}

            </form>
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
          props.onHide()
          setSingleExecutionId("Choose Execution..");
          setStudentsList([])
        }}>Close</Button>
        <Button type="submit" form="session-form" className={`${addSessionLoading ? "pointer-events-none opacity-80" : ""}`}>
          {!addSessionLoading ? (
            "Add Session"
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                loading..
                <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white"></div>
              </div>
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default AddSessionForm