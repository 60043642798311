import Swal from "sweetalert2";
import UnAuthAlert from "../global-compnents/un-auth-alert/UnAuthAlert";
import { toast , Bounce} from "react-toastify";
export default function checkErrorsFunctions (response , errors) {
    if(errors) {
        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                const errorMessages = errors[key];
                errorMessages.forEach(errorMessage => {
                    // Swal.fire({
                    //     title: key,
                    //     text: toast(errorMessage),
                    //     icon: 'error',
                    //     confirmButtonText: 'OK'
                    // });
                    toast.error(
                        errorMessage , {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "light",
                        transition: Bounce,
                    });
                });
            }
        }
    }
    else {
        if(response.data.success !== true && response.data.message) {
            // if(response.data.status_code === 401 || response.data.status_code === 403){
            if(response.data.status_code === 401){
                UnAuthAlert();
            }else {
                // Swal.fire({
                //     title: "Oops!",
                //     text: response.data.message,
                //     icon: 'error',
                //     confirmButtonText: 'OK'
                // });
                toast.error(
                    response.data.message , {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        }
        else{
            if(response.data.success === true && response.data.message){
                // Swal.fire({
                //     title: "Good job!",
                //     text: response.data.message,
                //     icon: "success"
                // });
                toast.success(
                    response.data.message , {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
            }
        }
    }
}