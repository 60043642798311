import { useState , useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContextApi from "../../../store/ContextApi";
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { addSubscriptionFunc } from "../../../store/custom-actions/subscription-actions/AddSubscriptionSlicer";
import { fetchAllCoursesFunc } from "../../../store/custom-actions/courses-actions/GetAllCoursesSlicer";
import { getCourseExcutionFunc } from "../../../store/custom-actions/courses-actions/excutions-actions/GetCourseExcutionsSlicer";
import { Bounce, toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function NewSubscripeForm(props) {
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi)
    const {student_id} = props;
    const {loading:addSubscriptionLoading} = useSelector(state => state.addSubscriptionSlicer)
    const {loading:getExecutionsLoading , data:executionsData} = useSelector(state => state.getCourseExcutionsSlicer)
    const {register , handleSubmit , setValue ,  formState : {errors} , reset} = useForm();
    const dispatch = useDispatch();
    const courses_API_URL = `${contextApi.dashboard_URL_API}/courses`;
    const subscription_URL_APi = `${contextApi.dashboard_URL_API}/subscriptions`;
    const [coursesList , setCoursesList] = useState([])
    const [courseWhichChoosed , setCourseWhichChoosed] = useState("Choose Course")
    const [chooseCourse , setChooseCourse] = useState(false)
    const [courseId , setCourseId] = useState(null)
    const [allExcutions , setAllExcutions] = useState([])
    const [executionId , setExecutionId] = useState()
    const { courses } = useSelector(
        (state) => state.getAllCoursesSlicer
      );


    useEffect(() => {
        const getAllData = async () => {
          try {
            dispatch(fetchAllCoursesFunc(courses_API_URL))
          } catch (error) {
            console.log(error);
          }
        };
        getAllData();
      }, []);
      useEffect(() => {
        courses && setCoursesList(courses);
      }, [courses]);
      useEffect(() => {
          const excutions_URL_APi = `${contextApi.dashboard_URL_API}/courses/${courseId}/course-executions`;
        const fetchData = async () => {
          try {
            dispatch(getCourseExcutionFunc(excutions_URL_APi));
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        };
        courseId && fetchData();
      }, [courseId]);
      useEffect(() => {
        executionsData && setAllExcutions(executionsData);
        if(Array.isArray(executionsData) && executionsData.length >= 1) {
          setExecutionId(executionsData[0].id);
        }
      }, [executionsData]);

    const handleSubmitSubscrieData = (data) => {
      if(executionId) {
        dispatch(addSubscriptionFunc(
            {api : subscription_URL_APi , data : {course_execution_id: executionId , student_id : student_id} }))
        .then((result)=>{
          if(result.payload?.success) {
            props.onHide()
            reset()
            setCourseWhichChoosed(t("Choose Course"))
            setChooseCourse(false)
            setAllExcutions([])
            setCourseId(null)
          }
        }
        )
      }else {
        toast.error(
          t("Execution Is Required!") , {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
      });
      }
    }


  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("New Subscrption")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <div className="basic-form">
                <form 
                id="new-assistant-form"
                onSubmit={handleSubmit((data)=>{
                      handleSubmitSubscrieData(data);
                })}>
                  <div className="row">
                    <div className="form-group mb-3 col-sm-12">
                    {
                      <div className="form-group mb-3 col-sm-12  flex flex-col">
                        <div className="form-group mb-3 col-sm-12">
                            <label>{t("Course")}</label>
                            {errors.user_id?.message && (
                            <p className="text-red-600">
                            {errors.user_id?.message}
                            </p>
                            )}
                            <span 
                            className="relative col-sm-12 flex items-center px-1 w-full h-[45px] rounded-md border border-[#302f2f] cursor-text"
                            onClick={()=>setChooseCourse(!chooseCourse)}
                            >{courseWhichChoosed}</span>
                        </div>
                        <div className={`instructors-list col-sm-12 overflow-y-auto transition-all duration-500 ${chooseCourse ? "h-[150px]" : "h-0"}`}>
                            {coursesList.length > 0 &&
                            coursesList.map(course =>{
                                return (
                                <div key={course.id} className="input-div relative col-sm-12 flex items-center my-1 p-2 pl-0">
                                    <input 
                                      {...register("user_id", {
                                      required: true,
                                      })}
                                      form="new-subscripe-form"
                                      id={course.name} 
                                      type="radio" 
                                      name="course" 
                                      value={course.id}
                                      className="hidden"
                                      data-name={course.name}
                                      onChange={(e)=>{
                                        setCourseId(e.target.value)
                                      setValue("course_id" , e.target.value)
                                      setCourseWhichChoosed(e.target.dataset.name);
                                      setChooseCourse(false)
                                    }}
                                    />
                                    <label htmlFor={course.name} className="cursor-pointer mr-2 flex items-center">
                                    <img src={course.image_url ? course.image_url : "https://img.freepik.com/free-vector/students-watching-webinar-computer-studying-online_74855-15522.jpg?w=826&t=st=1702899442~exp=1702900042~hmac=f3c89975f0344fd66d9bfba0158a777d5e50d509a0b753d71c809ad690ab30f3"} alt="img" className="w-[35px] h-[35px] mx-2 rounded-[50%]"/>
                                    {course.name}
                                    </label>
                                </div>
                                )
                            })
                            }
                        </div>
                      </div>
                    }
                    {
                        courseId
                        &&
                        <div className="form-group mb-3 col-sm-12">
                        {
                            getExecutionsLoading ? (
                                <div className="form-group flex items-center">
                                    <p>{t("loading")}...</p>
                                </div>
                            ) : executionsData &&
                                allExcutions &&
                                allExcutions.length <= 0 ? (
                                <div className="form-group flex items-center">
                                    <p>{t("No Executions")}</p>
                                </div>
                            ) : (
                                <div
                                    className={`form-group mb-3 col-sm-12`}
                                >
                                <label>{t("Execution")}</label>
                                <select
                                    className="form-control col-sm-12"
                                    onChange={(e) => setExecutionId(e.target.value)}
                                    value={executionId}
                                >
                                    {
                                    allExcutions &&
                                    allExcutions.map((ex, index) => {
                                        return (
                                        <option key={index} value={ex.id}>
                                            {ex.title}
                                        </option>
                                        );
                                    })
                                    }
                                </select>
                                </div>
                            )
                        }
                        </div>
                    }
                    </div>
                  </div>
                  <div> 
                  </div>
                </form>
              </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>{
          props.onHide()
          reset()
          setCourseWhichChoosed("Choose Course")
          setChooseCourse(false);
          setAllExcutions([]);
          setCourseId(null)
        }}>Close</Button>
        <Button type="submit" form="new-assistant-form">
            {!addSubscriptionLoading 
            ? 
            t("Submit") 
            :
            <div className="relative flex justify-center items-center">
              {t("loading")}..
              <div className="loading-spin-data w-[16px] h-[16px] ml-1 rounded-[50%] border-t-[2px] border-r-[2px] border-white "></div>
            </div> 
            }
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
