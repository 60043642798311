import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import Swal from "sweetalert2";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const deleteCategoryFunc = createAsyncThunk(
    "delete category function",
    async (api) => {
        try {
            const response = await axios.delete(api );
            checkErrorsFunctions(response , response.data.errors , response.data)
            return response.data
        }
        catch(error) {
            // Swal.fire({
            //     title: "Oops!",
            //     text: error,
            //     icon: "error"
            // });
            checkErrors(error.response , error.message);
        }
    }
)

const deleteCategorySlicer = createSlice({
    name : "delete category slicer",
    initialState : {
        deleteCategoriesLoading : null,
        data : null,
        error : null
    },
    reducer : {},
    extraReducers : (builder) => {
        builder.addCase(deleteCategoryFunc.pending , (state) => {
            state.deleteCategoriesLoading = true
            state.data = null
            state.error = null
        })
        builder.addCase(deleteCategoryFunc.rejected , (state) => {
            state.deleteCategoriesLoading = null
            state.data = null
            state.error = true
        })
        builder.addCase(deleteCategoryFunc.fulfilled , (state , action) => {
            state.deleteCategoriesLoading = false
            state.data = action.payload
            state.error = null
        })
    }
})

export default deleteCategorySlicer.reducer;