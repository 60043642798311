import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { addNewLanguageFunc } from '../../../../store/custom-actions/language/AddNewLanguageSlicer';
import { getLanguagesFunc } from '../../../../store/custom-actions/language/GetLanguagesSlicer';
import { useTranslation } from 'react-i18next';

function NewLangForm({show , hide , languagesApi}) {
    const {t} = useTranslation();
    const {register , reset , formState : {errors} , handleSubmit} = useForm();
    const {loading:addNewLangLoading} = useSelector(state => state.addNewLanguageSlicer);
    const[langSlug , setlangSlug] = useState("");
    const dispatch = useDispatch();
    const langList = [
        { name: "Afrikaans", slug: "AF" },
        { name: "Albanian", slug: "SQ" },
        { name: "Amharic", slug: "AM" },
        { name: "Arabic", slug: "AR" },
        { name: "Armenian", slug: "HY" },
        { name: "Azerbaijani", slug: "AZ" },
        { name: "Basque", slug: "EU" },
        { name: "Belarusian", slug: "BE" },
        { name: "Bengali", slug: "BN" },
        { name: "Bosnian", slug: "BS" },
        { name: "Bulgarian", slug: "BG" },
        { name: "Catalan", slug: "CA" },
        { name: "Cebuano", slug: "CEB" },
        { name: "Chichewa", slug: "NY" },
        { name: "Chinese (Simplified)", slug: "ZH" },
        { name: "Chinese (Traditional)", slug: "ZH-TW" },
        { name: "Corsican", slug: "CO" },
        { name: "Croatian", slug: "HR" },
        { name: "Czech", slug: "CS" },
        { name: "Danish", slug: "DA" },
        { name: "Dutch", slug: "NL" },
        { name: "English", slug: "EN" },
        { name: "Esperanto", slug: "EO" },
        { name: "Estonian", slug: "ET" },
        { name: "Finnish", slug: "FI" },
        { name: "French", slug: "FR" },
        { name: "Frisian", slug: "FY" },
        { name: "Galician", slug: "GL" },
        { name: "Georgian", slug: "KA" },
        { name: "German", slug: "DE" },
        { name: "Greek", slug: "EL" },
        { name: "Gujarati", slug: "GU" },
        { name: "Haitian Creole", slug: "HT" },
        { name: "Hausa", slug: "HA" },
        { name: "Hawaiian", slug: "HAW" },
        { name: "Hebrew", slug: "HE" },
        { name: "Hindi", slug: "HI" },
        { name: "Hmong", slug: "HMN" },
        { name: "Hungarian", slug: "HU" },
        { name: "Icelandic", slug: "IS" },
        { name: "Igbo", slug: "IG" },
        { name: "Indonesian", slug: "ID" },
        { name: "Irish", slug: "GA" },
        { name: "Italian", slug: "IT" },
        { name: "Japanese", slug: "JA" },
        { name: "Javanese", slug: "JW" },
        { name: "Kannada", slug: "KN" },
        { name: "Kazakh", slug: "KK" },
        { name: "Khmer", slug: "KM" },
        { name: "Kinyarwanda", slug: "RW" },
        { name: "Korean", slug: "KO" },
        { name: "Kurdish (Kurmanji)", slug: "KU" },
        { name: "Kyrgyz", slug: "KY" },
        { name: "Lao", slug: "LO" },
        // { name: "Latin", slug: "LA" },
        // { name: "Latvian", slug: "LV" },
        // { name: "Lithuanian", slug: "LT" },
        // { name: "Luxembourgish", slug: "LB" },
        // { name: "Macedonian", slug: "MK" },
        // { name: "Malagasy", slug: "MG" },
        // { name: "Malay", slug: "MS" },
        // { name: "Malayalam", slug: "ML" },
        // { name: "Maltese", slug: "MT" },
        // { name: "Maori", slug: "MI" },
        // { name: "Marathi", slug: "MR" },
        // { name: "Mongolian", slug: "MN" },
        // { name: "Myanmar (Burmese)", slug: "MY" },
        // { "name": "Nepali", slug: "NE" },
        // { "name": "Norwegian", slug: "NO" },
        // { "name": "Odia", slug: "OR" },
        // { "name": "Pashto", slug: "PS" },
        // { "name": "Persian", slug: "FA" },
        // { "name": "Polish", slug: "PL" },
        // { "name": "Portuguese", slug: "PT" },
        // { "name": "Punjabi", slug: "PA" },
        // { "name": "Romanian", slug: "RO" },
        // { "name": "Russian", slug: "RU" },
        // { "name": "Samoan", slug: "SM" },
        // { "name": "Scots Gaelic", slug: "GD" },
        // { "name": "Serbian", slug: "SR" },
        // { "name": "Sesotho", slug: "ST" },
        // { "name": "Shona", slug: "SN" },
        // { "name": "Sindhi", slug: "SD" },
        // { "name": "Sinhala", slug: "SI" },
        // { "name": "Slovak", slug: "SK" },
        // { "name": "Slovenian", slug: "SL" },
        // { "name": "Somali", slug: "SO" },
        // { "name": "Spanish", slug: "ES" },
        // { "name": "Sundanese", slug: "SU" },
        // { "name": "Swahili", slug: "SW" },
        // { "name": "Swedish", slug: "SV" },
        // { "name": "Tajik", slug: "TG" },
        // { "name": "Tamil", slug: "TA" },
        // { "name": "Tatar", slug: "TT" },
        // { "name": "Telugu", slug: "TE" },
        // { "name": "Thai", slug: "TH" },
        // { "name": "Turkish", slug: "TR" },
        // { "name": "Turkmen", slug: "TK" },
        // { "name": "Ukrainian", slug: "UK" },
        // { "name": "Urdu", slug: "UR" },
        // { "name": "Uyghur", slug: "UG" },
        // { "name": "Uzbek", slug: "UZ" },
        // { "name": "Vietnamese", slug: "VI" },
        // { "name": "Welsh", slug: "CY" },
        // { "name": "Xhosa", slug: "XH" },
        // { "name": "Yiddish", slug: "YI" },
        // { "name": "Yoruba", slug: "YO" },
        // { "name": "Zulu", slug: "ZU" }
      ]
    useEffect(() => {
      langList && setlangSlug(langList[0].slug)
    },[])
    const handleSubmitData = (data) => {
      dispatch(addNewLanguageFunc({api : languagesApi , data : {...data , slug : langSlug}}))
      .then(result => {
          if(result.payload.success === true) {
            dispatch(getLanguagesFunc(languagesApi))
            hide();
            reset();
            setlangSlug(langList[0].slug)
            toast.success(`${result.payload.message} !`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
          })
          }
      })
    }
  return (
    <>
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>{t("New Language")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form 
          id="new-category-form"
          onSubmit={handleSubmit((data) => {
              handleSubmitData(data)
          })}
          >
          <div className='row'>
              <div className="form-group mb-3 col-sm-12">
                  <label>{t("Name")}</label>
                  {errors.name?.message && (
                  <p className="text-red-600">{errors.name?.message}</p>
                  )}
                <select
                  {...register("name" , {required : t("Name Is Required!")})}
                    className='form-control'
                    onChange={(e) => {
                        const selectedOption = e.target.options[e.target.selectedIndex];
                        setlangSlug(selectedOption.dataset.slug)
                        console.log(selectedOption.dataset.slug)
                        console.log(selectedOption.value)
                    }}
                  >
                    {
                        langList && langList.map((lang , index) => (
                            <option data-slug={lang.slug} className='' key={index} value={lang.name} onSelect={(e) => console.log(e.target.value)}>{lang.name}</option>
                        ))
                    }
                  </select>
              </div>
              <div className="form-group mb-3 col-sm-12">
                  <label>{t("Slug")}</label>
                  {errors.slug?.message && (
                  <p className="text-red-600">{errors.slug?.message}</p>
                  )}
                  <input
                  type="text"
                  className="form-control"
                  value={langSlug}
                  readOnly
                  />
              </div>
              <div className="form-group mb-3 col-sm-12">
                  <select
                  {...register("direction" , {required : t("Direction Is Required")})}
                    className='form-control'
                  >
                    <option value={"rtl"}>RTL</option>
                    <option value={"ltr"}>LTR</option>
                  </select>
              </div>
          </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            {t("Close")}
          </Button>
          <Button type="submit" form="new-category-form" className={`${addNewLangLoading ? "opacity-80 pointer-events-none" : ""}`}>
          {!addNewLangLoading ? (
            t("Submit")
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                {t("loading")}...
              </div>
            </>
          )}
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default NewLangForm;