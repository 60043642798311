/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";
// import { ThemeContext } from "../../../../context/ThemeContext";


class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}


const AssistantLeftSidebar = () => {
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }, []);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

    let courses = [
      "instructor courses",
    ],
    users = ["users-dashbord", "students"],
    Contacts = ["students Messages"],
    offline = ["assistant-offline-page"]
  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          <li className={`${courses.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="bi bi-book"></i>
              <span className="nav-text">Courses</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "courses" ? "mm-active" : ""}`}
                  to="/instructor-courses-page"
                >
                  Courses
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${offline.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="fa-brands fa-hive"></i>
              <span className="nav-text forms">Offline</span>
            </Link>
            <ul>   
              <li>
                <Link
                  className={`${path === "assistant-offline-page" ? "mm-active" : ""}`}
                  to="/assistant-offline-page"
                >
                  Offline Courses
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${users.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="fa-regular fa-user"></i>
              <span className="nav-text forms">Users</span>
            </Link>
            <ul>  
              <li>
                  <Link
                  className={`${
                      path === "students" ? "mm-active" : ""
                  }`}
                  to="/assistant-students-page"
                  >
                  Students
                  </Link>
              </li>
            </ul>
          </li>
          <li className={`${Contacts.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
            <i className="fa-solid fa-dollar-sign"></i>
              <span className="nav-text forms">Subcriptions</span>
            </Link>
            <ul>   
              <li>
                <Link
                  className={`${path === "subscriptions" ? "mm-active" : ""}`}
                  to="/subscriptions"
                >
                  Subscriptions
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${Contacts.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="fa-regular fa-bell"></i>
              <span className="nav-text forms">Contacts</span>
            </Link>
            <ul>   
             <li>
                <Link
                  className={`${path === "Messages" ? "mm-active" : ""}`}
                  to="/users-messages"
                >
                  Messages
                </Link>
              </li>
            </ul>
          </li>
          <li className={`${Contacts.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon" to="#">
              <i className="fa-regular fa-circle-check"></i>
              <span className="nav-text forms">My Permissions</span>
            </Link>
            <ul>   
             <li>
                <Link
                  className={`${path === "permissions" ? "mm-active" : ""}`}
                  to="/assistant-permissions-page"
                >
                  Permissions
                </Link>
              </li>
            </ul>
          </li>
        </MM>

        <div className="copyright">
          {/* <p><strong>GetSkills Online Learning Admin</strong> © 2022 All Rights Reserved</p> */}
          <p className="fs-12">{/* <span className="heart"></span>  */}</p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default AssistantLeftSidebar;

