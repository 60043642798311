import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
import checkErrorsFunctions from "../../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../../jsx/global-functions/checkErrors";
export const getLessonsSchuadlesFunc = createAsyncThunk(
    "get lessons schuadles function",
    async (api) => {
        try {
            const response = await axios.get(api );
            checkErrorsFunctions(response , response.data.errors);
            return response.data.data.lessonSchadules;
        }
        catch (error) {
            checkErrors(error.response , error.message);
        }
        
    }
);

const getLessonsSchuadlesSlicer = createSlice({
    name : "get lessons schuadles slicer",
    initialState : {
        loading : null,
        data : [],
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(getLessonsSchuadlesFunc.pending , (state)=>{
            state.loading = true
            state.data = []
            state.error = null
        })
        builder.addCase(getLessonsSchuadlesFunc.rejected , (state)=>{
            state.loading = false
            state.data = []
            state.error = true
        })
        builder.addCase(getLessonsSchuadlesFunc.fulfilled , (state , action)=>{
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
});
export default getLessonsSchuadlesSlicer.reducer;