import React, { useState  , useEffect} from 'react';
import defaultStImg from "../../../../images/qr.png"

const CheckboxBtn = ({ student , arrayWillSend , removing , additionDone}) => {
  const [checked, setChecked] = useState();

  useEffect(() => {
    student && student.pivot.status && student.pivot.status === "present" ? setChecked(true) : setChecked(false)
  },[student])
  const toggelChecked = (e) => {
    setChecked(!checked)
    if(e.checked) {
      additionDone(student)
    }else {
      const arrAfterRemoving = arrayWillSend && arrayWillSend.length >=1 && arrayWillSend.filter(st => st.id !== student.id) 
      removing(arrAfterRemoving)
    }
  }
  return (
    <div className='cursor-pointer justify-start m-1 col-sm-10 col-md-5 col-lg-2 flex items-center'>
      <input 
      checked = {checked}
      onChange={(e)=>{
        toggelChecked(e.target);
      }}
      type='checkbox' 
      className='cursor-pointer m-0' 
      id={student.name}/>
      <label className='cursor-pointer flex items-center ml-1 mt-1' htmlFor={student.name}>
        <img src={student.image_url !== null ? student.image_url : defaultStImg} className='relative rounded-[50%] w-[25px] mx-2' alt="st img" />
        <p className='m-0'>{student.name}</p>
      </label>
    </div>
  );
};

export default CheckboxBtn;
