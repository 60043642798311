import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useContext } from 'react';
import { toast } from 'react-toastify';
import ContextApi from '../../../../store/ContextApi';
import { newTranslateTextFunc } from '../../../../store/custom-actions/language/NewTranslateTextSlicer';
import { useTranslation } from 'react-i18next';

function NewTextForm({show , hide  , langSlug , refetetchFunc}) {
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const translate_API_URL = `${contextApi.dashboard_URL_API}/languages/add-new-words`;
    const {register , reset , formState : {errors} , handleSubmit} = useForm();
    const {loading:translateTextLoading} = useSelector(state => state.newTranslateTextSlicer);
    const dispatch = useDispatch();
    const handleSubmitData = (data) => {
      const form = new FormData();
      form.append("lang_slug" , langSlug)
      form.append("new_string" , data.new_string)
      form.append("translate_string" , data.translate_string)
      dispatch(newTranslateTextFunc({api : translate_API_URL , data : form}))
      .then(result => {
          if(result.payload.success === true) {
            refetetchFunc()
            hide();
            reset();
            toast.success(`${result.payload.message} !`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
          })
          }
      })
    }
  return (
    <>
      <Modal show={show} onHide={hide}>
        <Modal.Header closeButton>
          <Modal.Title>{t("New Words")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form 
          id="new-category-form"
          onSubmit={handleSubmit((data) => {
              handleSubmitData(data)
          })}
          >
          <div className='row'>
            <div className="form-group mb-3 col-sm-12">
                <label>{("Source Text")}</label>
                {errors.string_key?.message && (
                <p className="text-red-600">{errors.new_string?.message}</p>
                )}
                <input
                {...register("new_string" , {required : t("Source Text Is Required!")})}
                type="text"
                className='form-control'
                />
            </div>
            <div className="form-group mb-3 col-sm-12">
                <label>{("Translate Content")}</label>
                {errors.translate_string?.message && (
                <p className="text-red-600">{errors.translate_string?.message}</p>
                )}
                <input
                {...register("translate_string" , {required : t("Translation Content Is Required")})}
                type="text"
                className='form-control'
                />
            </div>
          </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            {t("Close")}
          </Button>
          <Button type="submit" form="new-category-form" className={`${translateTextLoading ? "opacity-80 pointer-events-none" : ""}`}>
          {!translateTextLoading ? (
            t("Submit")
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                {t("loading")}...
              </div>
            </>
          )}
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default NewTextForm;