import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import Swal from "sweetalert2";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const editUserFunc = createAsyncThunk(
    "edit user function",
    async ({user_api , new_data}) => {
        try {
            const response = await axios.put(user_api , new_data);
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
    }
)

const editUserSlicer = createSlice({
    name : "edit user slicer",
    initialState : {
        editUserTextLoading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(editUserFunc.pending , (state) => {
            state.editUserTextLoading = true
            state.data = null
            state.error = false
        });
        builder.addCase(editUserFunc.rejected , (state) => {
            state.editUserTextLoading = false
            state.data = null
            state.error = true
        });
        builder.addCase(editUserFunc.fulfilled , (state , action) => {
            state.editUserTextLoading = null
            state.data = action.payload
            state.error = null
        });
    }
})

export default editUserSlicer.reducer