import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../../jsx/global-functions/checkErrorsFunction";
import Swal from "sweetalert2";
import { checkErrors } from "../../../../jsx/global-functions/checkErrors";

export const passLessonRequireFunc = createAsyncThunk(
    "pass lesson require func",
    async ({lessonsApi , requirementsData}) => {
        try {
            const response = await axios.post(lessonsApi , requirementsData );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
    }
);

const passLessonRequireSlicer = createSlice({
    name : "pass lesson require slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(passLessonRequireFunc.pending , (state) => {
            state.loading = true;
            state.data = null;
            state.error = null;
        });
        builder.addCase(passLessonRequireFunc.rejected , (state) => {
            state.loading = false;
            state.data = null;
            state.error = true;
        });
        builder.addCase(passLessonRequireFunc.fulfilled , (state , action) => {
            state.loading = false;
            state.data = action.payload;
            state.error = false;
        });
    }
})
export default passLessonRequireSlicer.reducer;