import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
import Swal from "sweetalert2";
export const postMessageToStudentsFunc = createAsyncThunk(
    "post message to students function",
    async ({messagesApi , messageData}) => {
        try {
            const response = await axios.post(messagesApi , messageData );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
        
    }
)

const postMessageToStudentSlicer = createSlice({
    name : "post message to students slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(postMessageToStudentsFunc.pending , (state) => {
            state.loading = true
            state.data = {}
            state.error = false
        });
        builder.addCase(postMessageToStudentsFunc.rejected , (state) => {
            state.loading = false
            state.data = {}
            state.error = true
        });
        builder.addCase(postMessageToStudentsFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = false
        });
    }
});

export default postMessageToStudentSlicer.reducer;