// Import necessary libraries
import React, { useContext, useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ContextApi from '../../../../../store/ContextApi';
import { getSalesCoursesFunc } from '../../../../../store/custom-actions/earnings-actions/GetSalesCoursesSlicer';

// Register chart components
ChartJS.register(ArcElement, Tooltip, Legend);

// Doughnut chart data
// const data = {
//   labels: ['Red', 'Blue', 'Yellow'],
//   datasets: [
//     {
//       label: 'My First Dataset',
//       data: [300, 50, 100],
//       backgroundColor: [
//         'rgb(255, 99, 132)',
//         'rgb(54, 162, 235)',
//         'rgb(255, 205, 86)',
//       ],
//       hoverOffset: 4,
//     },
//   ],
// };

// Chart configuration
// const config = {
//   type: 'doughnut',
//   data: data,
// };

// SalesCoursesChart Component
const SalesCoursesChart = () => {
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const salesCoursesApi = `${contextApi.dashboard_URL_API}/report/sales-courses`;
    const dispatch = useDispatch();
    const {loading , data:salesData} = useSelector(
        state => state.getSalesCoursesSlicer
    )
    const [coursseNames , setCoursesNames] = useState([]);
    const [salesAmounts , setSalesAmounts] = useState([])
    const getData = () => {
        dispatch(getSalesCoursesFunc(salesCoursesApi));
    }
    useEffect(() => {
        getData()
    },[])
    useEffect(() => {
        if(salesData?.length >= 1) {
            const namesOfCourses = salesData?.flatMap(sale => sale.name)
            const amountsOfCourses = salesData?.flatMap(sale => sale.total_sales)
            setCoursesNames(namesOfCourses)
            setSalesAmounts(amountsOfCourses)
        }
    },[salesData])
    const data = {
        labels: ['Red', 'Blue', 'Yellow'],
        datasets: [
          {
            label: 'My First Dataset',
            data: [300, 50, 100],
            backgroundColor: [
              'rgb(255, 99, 132)',
              'rgb(54, 162, 235)',
              'rgb(255, 205, 86)',
            ],
            hoverOffset: 4,
          },
        ],
      };
  return (
    <div style={{ width: '200px'}} className='col-sm-12'>
      <h5>{t("Courses Sales")}</h5>
      {/* Render Doughnut chart */}
      {
        loading
        ?
        <h5>{t("loading Sales Data...")}</h5>
        :
        <Doughnut data={{
            labels: coursseNames,
            datasets: [
                {
                label: t('Sales Earnings'),
                data: salesAmounts,
                backgroundColor: [
                    'rgb(255, 99, 132)',
                    'rgb(54, 162, 235)',
                    'rgb(255, 205, 86)',
                ],
                hoverOffset: 4,
                },
            ],
        }} />
      }
    </div>
  );
};

export default SalesCoursesChart;
