import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import Swal from "sweetalert2";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const fetchAllStudentsFromApi = createAsyncThunk(
    "get all students function",
    async (api) => {
        try {
            const response = await axios.get(api );
            checkErrorsFunctions(response , response.data.errors);
            return response.data.data.students;
        }
        catch (error) {
            // Swal.fire({
            //     title: "Oops!",
            //     text:error,
            //     icon: "error"
            // });
            checkErrors(error.response , error.message);
        }
        
    }
);

const getAllStudents = createSlice({
    name : "get all students slicer",
    initialState : {
        loading : null,
        students : [],
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(fetchAllStudentsFromApi.pending , (state)=>{
            state.loading = true
            state.students = []
            state.error = null
        })
        builder.addCase(fetchAllStudentsFromApi.rejected , (state)=>{
            state.loading = false
            state.students = []
            state.error = true
        })
        builder.addCase(fetchAllStudentsFromApi.fulfilled , (state , action)=>{
            state.loading = false
            state.students = action.payload
            state.error = null
        })
    }
});
export default getAllStudents.reducer;