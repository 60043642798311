import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
import Swal from "sweetalert2";

export const updatePaymentFunc = createAsyncThunk(
    "update payment function",
    async ({api , data}) => {
        try {
            const response = await axios.put(api , data)
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
        
    }
)

const updatePaymentSlicer = createSlice({
    name : "update payment slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(updatePaymentFunc.pending , (state) => {
            state.loading = true
            state.data = {}
            state.error = false
        });
        builder.addCase(updatePaymentFunc.rejected , (state) => {
            state.loading = false
            state.data = {}
            state.error = true
        });
        builder.addCase(updatePaymentFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = false
        });
    }
});

export default updatePaymentSlicer.reducer;