import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Dropdown, Offcanvas } from "react-bootstrap";
// import United from "../../../images/United.png";
// import avatar from "../../../../images/avatar/1.jpg";
import { useDispatch, useSelector } from "react-redux";
import { logoutFunc } from "../../../../store/custom-actions/logout-action/LogoutSlicer";
import ContextApi from "../../../../store/ContextApi";
import swal from "sweetalert";
import userImg from "../../../../media/images/software-engineer.png"
import ChangeUserPasswordForm from "../../../custom-pages/custom-drop-down/ChangePasswordForm";
import UpdateUserImageSlicer, { editUserImageFunc } from "../../../../store/custom-actions/users-actions/UpdateUserImageSlicer";
import { Modal } from "react-bootstrap";
import {Button} from "react-bootstrap";
import NotficationsList from "./NotficationsList";
import { getNotficationsFunc } from "../../../../store/custom-actions/notfications-slicer/GetNotficationSlicer";
import { readAllNotficationsFunc } from "../../../../store/custom-actions/notfications-slicer/ReadAllNotficationsSlicer";
import checkPermissions from "../../../global-functions/checkPermissionsFunc";
import ToggelerLang from "./toggeler-lang/ToggelerLang";
import { useTranslation } from "react-i18next";
import checkUserRole from "../../../global-functions/checkUserRole";

// edit image formlogout
function UpdateImageForm(props) {
  const {t} = useTranslation();
  const {editImageLoading} = useSelector(state => state.editUserImageSlicer);
  const user = JSON.parse(window.localStorage.getItem("user"));
  const userId = user && user.id;
  const dispatch = useDispatch();
  const {
    userapi,
    log_out,
  } = props;

  const [updatingUserImage , setUpdatingUserImage] = useState();

  const handleUploadUserImage = () => {
    if(updatingUserImage) {
      const imageForm = new FormData();
      imageForm.append("image" , updatingUserImage)

      dispatch(editUserImageFunc(
        {user_api : `${userapi}/upload-image/${userId}` , image : imageForm}
      ))
      .then((result) => {
        if(result.payload.success === true){
          log_out();
        }
      })
    }
  }

  useEffect(()=>{
    if(!editImageLoading) {
      props.onHide();
    }
  },[editImageLoading])


return (
  <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        {t("Update Image")}
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className=" w-full">
        <div className="card">
          <div className="card-body">
            <div className="basic-form">
              <form id="upload-user-image-form" className="upload-user-image-form"
                onSubmit={(e)=>{
                  e.preventDefault();
                  handleUploadUserImage()
                }}
                >
                <div className="relative flex justify-center">
                  <p classNam>{t("When Update Your Image Will Log out and should log in again!")}</p>
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-sm-12">
                    <label>{t("Your Image")} ( {t("Best Size")} 100 * 100 )</label>
                    <input 
                      type="file" 
                      className="form-control"
                      onChange={(e)=>setUpdatingUserImage(e.target.files[0])}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={()=>{
        props.onHide();
      }}>{t("Close")}</Button>
      <Button type="submit" form="upload-user-image-form">
        {!editImageLoading 
        ? 
        t("Submit") 
        : 
          <div className="relative flex justify-center items-center">
            {t("loading")}...
            <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-1 "></div>
          </div>
        }
      </Button>
    </Modal.Footer>
  </Modal>
);
}

const RightSidebar = ({ onNote , showRightSidebar}) => {
  const {t} = useTranslation();
  const {loading:switchLangLoading , data:languageData} = useSelector(
		state => state.getLanguageSettingsSlicer
	)
  const user = JSON.parse(window.localStorage.getItem("user"))
  const userRoleName = user && user.roles;
  const image = user && user.image;
  const userId = user && user.id;
  const contextApi = useContext(ContextApi);
  const logout_url = `${contextApi.dashboard_URL_API}/logout`
  const users_API = `${contextApi.dashboard_URL_API}/users`
  const dispatch = useDispatch();
  const {loading} = useSelector(state => state.logoutSlicer)
  const [userImage , setUserImg] = useState(userImg)
  const [showUpdatePassword , setShowUpdatePassword] = useState();
  const [showUpdateImageForm , setShowUpdateImageForm] = useState();
  const [showNotficationsList , setShowNotficationsList] = useState();
  const {loading:getNotficationsLoading , data} = useSelector(state => state.getNotficationSlicer);
  const {loading:readAllNotsLoading} = useSelector(state => state.readAllNotficationsSlicer);
  const notficationsApi = `${contextApi.dashboard_URL_API}/notifications`;
  const [notficationsList , setNotficationsList] = useState([]);
  const [unReadNotficationsCount , setUnReadNotficationsCount] = useState(0);
  const [unReadNotfications , setUnReadNotfications] = useState([]);
  const [readNotfications , setReadNotfications] = useState([]);
  const navigate = useNavigate();
  const getNotfications = async () => {
      dispatch(getNotficationsFunc(notficationsApi))
  }
  useEffect(() => {
      getNotfications()
  },[])
  useEffect(() => {
      data && data.read_notifications && setNotficationsList(data.read_notifications)
      data && data.unread_notifications_count && setUnReadNotficationsCount(data.unread_notifications_count)
      data && 
      data.unread_notifications && 
      setUnReadNotfications(data.unread_notifications)
      data && 
      data.read_notifications && 
      setReadNotfications(data.read_notifications)
  },[data])

  const logoutFunction = async () => {
    dispatch(logoutFunc(logout_url))
    .then((result) => {
      if (result.payload.success === true) {
          localStorage.removeItem("expire_date");
          localStorage.removeItem("user");
          window.location.reload();
      } else {
        swal({
          title: "warning!",
          text: `you are already logged in please refresh page!`,
          icon: "warning",
          buttons: {
            refresh: {
              text: "Refresh!",
              value: "refresh",
            }
          }
        }).then(value=>{
          if(value === "refresh") {
            if(window.localStorage.expire_date) {
              window.localStorage.removeItem("expire_date");
              window.location.reload();
            }
          }
        })
      }
    });
  };

  useEffect(()=>{
    image !== null && setUserImg(image)
  },[image])

  const readAllNotfications = async () => {
    dispatch(readAllNotficationsFunc(notficationsApi))
    .then(result => {
      if(result.payload.success === true) {
        getNotfications();
        setUnReadNotficationsCount(0)
      }
    })
  }
  return (
    <>
      <div className={`right-sidebar shadow-lg shadow-slate-500 flex flex-col justify-between 

      ${languageData?.defaultLang?.slug === "ar" 
        ? `rounded-br-[20px] rounded-tr-[20px] left-0 ${!showRightSidebar ? "translate-x-0 opacity-100" : "left-[-50px] opacity-0"} `
        : `rounded-bl-[20px] rounded-tl-[20px] right-0 ${!showRightSidebar ? "translate-x-0 opacity-100" : "right-[-50px] opacity-0"} `}
      items-center md:translate-x-0 h-[85vh] top-[15vh] md:top-0 md:h-[100vh] fixed 
      bg-white z-[9999] transition-all duration-500`}>
        <ul className="z-[99] m-3">
          <Dropdown as="li" className="nav-item dropdown header-profile cursor-pointer">
            <Dropdown.Toggle
              variant=""
              as="a"
              className=""
            >
              <img src={userImage} width={20} alt="" className="w-[40px] rounded-md"/>
            </Dropdown.Toggle>
            <Dropdown.Menu
              align="right"
              className=""
            >
              {
              userRoleName === "Assistant"
              &&
              <button
                className="flex items-center relative w-full my-1 py-2 pl-2 hover:bg-slate-200"
              >
                <Link to="/assistant-profile" className="relative flex items-center">
                  <i className="fa-regular fa-user mr-1 text-primary"></i>
                  <span className="ms-2">{t("Profile")}</span>
                </Link>
              </button>
              }
              <button
                className="flex items-center relative w-full my-1 py-2 pl-2 hover:bg-slate-200"
                onClick={() => {
                  logoutFunction()
                  console.log("clicked")
                }}
              >
                {
                !loading 
                ? 
                <i className="fa-solid fa-arrow-right-from-bracket text-primary"></i> 
                : 
                <div className="loading-spin-data w-[13px] h-[13px] rounded-[50%] border-t-[2px] border-r-[2px] border-blue-600 ml-2"></div> 
                }
                <span className="ms-2">{t("Logout")}</span>
              </button>
              <button
                className="flex items-center relative w-full my-1 py-2 pl-2 hover:bg-slate-200"
                onClick={() =>setShowUpdateImageForm(true)}
              >
                <i className="fa-solid fa-image text-primary"></i> 
                <span className="ms-2">{t("Update Image")}</span>
              </button>
              <button
                className="flex items-center relative w-full my-1 py-2 pl-2 hover:bg-slate-200"
                onClick={() => {
                  setShowUpdatePassword(true)
                }}
              >
                <i className="fa-solid fa-key text-primary"></i>
                <span className="ms-2">{t("Change Password")}</span>
              </button>
            </Dropdown.Menu>
          </Dropdown>
        </ul>
        <div className="sidebar-social-link z-[99] ">
          <ul className="flex flex-col py-2 mb-3">
            {
              checkUserRole
              (
                ["manager" , "teacher"]
                ,
                <li className="mb-3 bg-primary p-2 rounded-lg">
                  <Link to="/send-notfications">
                    {/* <i className="fa-solid fa-paper-plane transition-all duration-300 hover:translate-y-[-5px] text-[20px] hover:text-primary"></i> */}
                    <i className="fa-solid fa-paper-plane text-white text-[18px]"></i>
                  </Link>
                </li>
              )
            }
            {
              checkUserRole
              (
                ["manager" , "teacher"]
                ,
                <li className="mb-3 bg-primary p-2 rounded-lg">
                  <Link to="/send-mail">
                    {/* <i className="fa-solid fa-paper-plane transition-all duration-300 hover:translate-y-[-5px] text-[20px] hover:text-primary"></i> */}
                    <i className="fa-solid fa-at text-white text-[18px]"></i>
                  </Link>
                </li>
              )
            }
            <li className={`mb-3 relative  transition-all duration-300 bg-secondary p-2 rounded-lg`}
              onClick={()=>{
                // setShowNotficationsList(true);
                // readAllNotfications();
                navigate("/notfications")
              }}
            >
              {
                parseInt(unReadNotficationsCount) >= 1
                &&
                <span className="absolute top-[-5px] right-[-5px] w-[10px] h-[10px] text-white bg-red-500 flex justify-center items-center rounded-[50%] p-2">{unReadNotficationsCount}</span>
              }
              <Link to="#">
                {/* <i className="fa-solid fa-bell text-[20px] hover:text-primary"></i> */}
                <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M18.7071 8.56414C18.7071 9.74035 19.039 10.4336 19.7695 11.2325C20.3231 11.8211 20.5 12.5766 20.5 13.3963C20.5 14.215 20.2128 14.9923 19.6373 15.6233C18.884 16.3798 17.8215 16.8627 16.7372 16.9466C15.1659 17.0721 13.5937 17.1777 12.0005 17.1777C10.4063 17.1777 8.83505 17.1145 7.26375 16.9466C6.17846 16.8627 5.11602 16.3798 4.36367 15.6233C3.78822 14.9923 3.5 14.215 3.5 13.3963C3.5 12.5766 3.6779 11.8211 4.23049 11.2325C4.98384 10.4336 5.29392 9.74035 5.29392 8.56414V8.16515C5.29392 6.58996 5.71333 5.55995 6.577 4.55164C7.86106 3.08114 9.91935 2.19922 11.9558 2.19922H12.0452C14.1254 2.19922 16.2502 3.12359 17.5125 4.65728C18.3314 5.64484 18.7071 6.63146 18.7071 8.16515V8.56414ZM9.07367 19.1136C9.07367 18.642 9.53582 18.426 9.96318 18.3336C10.4631 18.2345 13.5093 18.2345 14.0092 18.3336C14.4366 18.426 14.8987 18.642 14.8987 19.1136C14.8738 19.5626 14.5926 19.9606 14.204 20.2134C13.7001 20.5813 13.1088 20.8143 12.4906 20.8982C12.1487 20.9397 11.8128 20.9407 11.4828 20.8982C10.8636 20.8143 10.2723 20.5813 9.76938 20.2125C9.37978 19.9606 9.09852 19.5626 9.07367 19.1136Z" fill="white"/>
								</svg>	
              </Link>
            </li>
            {
              checkPermissions
              (
                "contacts_read"
                ,
                <li className="bg-primary p-2 rounded-lg">
                  <Link to="/users-messages">
                    {/* <i className="fa-solid fa-envelope transition-all duration-300 hover:translate-y-[-5px] text-[20px] hover:text-primary"></i> */}
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M16.9394 3.57129C18.2804 3.57129 19.5704 4.06765 20.5194 4.95828C21.4694 5.84704 22.0004 7.04579 22.0004 8.30073V15.6993C22.0004 18.3122 19.7304 20.4287 16.9394 20.4287H7.0604C4.2694 20.4287 2.0004 18.3122 2.0004 15.6993V8.30073C2.0004 5.68783 4.2594 3.57129 7.0604 3.57129H16.9394ZM18.5304 9.69615L18.6104 9.62123C18.8494 9.34964 18.8494 8.9563 18.5994 8.68471C18.4604 8.54517 18.2694 8.45994 18.0704 8.44121C17.8604 8.43091 17.6604 8.4974 17.5094 8.62852L13.0004 12C12.4204 12.4505 11.5894 12.4505 11.0004 12L6.5004 8.62852C6.1894 8.41312 5.7594 8.44121 5.5004 8.69407C5.2304 8.94693 5.2004 9.34964 5.4294 9.6306L5.5604 9.75234L10.1104 13.077C10.6704 13.4891 11.3494 13.7138 12.0604 13.7138C12.7694 13.7138 13.4604 13.4891 14.0194 13.077L18.5304 9.69615Z" fill="white"/>
										{/* {
										parseInt(unReadNotficationsCount) >= 1
										&&
										<span className="absolute top-[-5px] right-[-5px] w-[10px] h-[10px] text-white bg-red-500 flex justify-center items-center rounded-[50%] p-2">{unReadNotficationsCount}</span>
										} */}
								</svg>	
                  </Link>
                </li>
              )
            }
          </ul>
        </div>
        <div className="relative">
          <ToggelerLang/>
        </div>
      </div>
      <ChangeUserPasswordForm
          show={showUpdatePassword}
          onHide={() => setShowUpdatePassword(false)} 
          refetchFunction= {() => window.location.reload()}
          userId = {userId}
        />
        <UpdateImageForm
          show={showUpdateImageForm}
          onHide={() => setShowUpdateImageForm(false)} 
          userapi = {users_API}
          log_out = {logoutFunction}
          userid = {userId}
        />
        {/* <UpdateUserImageForm
          show={showUpdateImageForm}
          onHide={() => setShowUpdateImageForm(false)} 
          usersapi = {users_API}
          log_out = {logoutFunction}
          userid = {userId}
        /> */}
        <NotficationsList
          show = {showNotficationsList}
          onHide = {() => setShowNotficationsList(false)}
          notficationsLoading = {readAllNotsLoading}
          // notfications = {readNotfications}
          notfications = {unReadNotfications}
          readAllNotsFunc={() => readAllNotfications()}
        />
    </>
  );
};

export default RightSidebar;
