import { useContext, useEffect, useState } from "react";
import ContextApi from "../../../store/ContextApi";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentsFunc } from "../../../store/custom-actions/payments-actions/GetPaymentsSlicer";
import Swal from "sweetalert2";
import { deletePaymentFunc } from "../../../store/custom-actions/payments-actions/DeletePaymentSlicer";
import swal from "sweetalert";
import { Spinner } from "react-bootstrap";
import faildImg from "../../../media/images/planet.png";
import PaymentCard from "./PaymentCard";
import "./payment-page.css";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import PageTitle from "../../layouts/PageTitle";
import { postPaymentSettingsFunc } from "../../../store/custom-actions/payments-actions/PostPaymentsSettingsSlicer";
import { useTranslation } from "react-i18next";

export default function PaymentsPage(){
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const payments_API_URL = `${contextApi.dashboard_URL_API}/payment-settings`;
    const dispatch = useDispatch();
    const {loading:getPaymentsLoading , data:paymentsData} = useSelector(
        state => state.getPaymentsSlicer
    )
    const {loading:postSettingsLoading} = useSelector(
        state => state.postPaymentSettingsSlicer
    )
    const [paymentsGateway , setPaymentsGateway] = useState();

    const getPayments = () => {
        try {
            dispatch(getPaymentsFunc(payments_API_URL))
        }
        catch (error) {
            Swal.fire({
                title: t("Oops!"),
                text: error,
                icon: "warning"
            });
        }
    }
    useEffect(() => {
        getPayments()
    },[])
    const {register , setValue , formState : {errors} , handleSubmit} = useForm();
    useEffect(() => {
        paymentsData && setPaymentsGateway(paymentsData);
        if(paymentsData) {
            setValue("paymob_card_api_key" , paymentsData.paymob_card_api_key);
            setValue("paymob_card_gateway" , paymentsData.paymob_card_gateway);
            setValue("paymob_card_hmac" , paymentsData.paymob_card_hmac);
            setValue("paymob_card_iframe_id" , paymentsData.paymob_card_iframe_id);
            setValue("paymob_card_integration_id" , paymentsData.paymob_card_integration_id);
            setValue("paymob_wallet_api_key" , paymentsData.paymob_wallet_api_key);
            setValue("paymob_wallet_gateway" , paymentsData.paymob_wallet_gateway);
            setValue("paymob_wallet_hmac" , paymentsData.paymob_wallet_hmac);
            setValue("paymob_wallet_integration_id" , paymentsData.paymob_wallet_integration_id);
            setValue("send_receipt" , paymentsData.send_receipt);
        }
    },[paymentsData])

    const handleSubmitSettings = async (data) => {
        console.log(data)
        if(Object.keys(paymentsGateway).length >= 1) {
            const formData = new FormData();
            for(const [key , value] of Object.entries(paymentsGateway)) {
                formData.append(key , value)
            }
            dispatch(postPaymentSettingsFunc({
                api : payments_API_URL , 
                data : formData
            }))
            .then(result => {
                if(result.payload.success === true) {
                    // getSettings();
                    toast.success(`${result.payload.message} !`, {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                    })
                }
            })
        }else {
            toast.error(t(`Can Not Submit Empty Data!`), {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            })
        }
    }

    return (
        <>
        <PageTitle motherMenu={"Dashboard"} activeMenu={"Payments Settings"} />
        <div className="relative flex items-center justify-between my-3">
                <div className="relative flex items-center">
                    <button 
                        form="payments-gateway-from"
                        className={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-orange-400 duration-300 mx-1
                        ${postSettingsLoading ? "pointer-events-none opacity-80" : ""}
                        `}
                        onClick={() => window.history.back()}
                        >
                        {t("Back")}
                    </button>
                    <button 
                        type="submit"
                        form="payments-gateway-from"
                        className={`flex items-center bg-primary text-white gap-1 px-4 py-2 cursor-pointer  font-semibold tracking-widest rounded-md hover:bg-orange-400 duration-300 ml-1 hover:gap-2 hover:translate-x-3
                        ${postSettingsLoading ? "pointer-events-none opacity-80" : ""}
                        `}
                        >
                            {
                                postSettingsLoading
                                ?
                                <>{("Please Wait")}...</>
                                :
                                <>
                                    {t("Submit")}
                                    <i className="fa-solid fa-gear text-white ml-1"></i>
                                </>
                            }
                    </button>
                </div>
        </div>
            {
                getPaymentsLoading
                ?
                (
                    <>
                        <div className="row d-flex justify-content-center gap-2">
                            <Spinner animation="grow" className="bg-primary" />
                            <Spinner animation="grow" className="bg-primary" />
                            <Spinner animation="grow" className="bg-primary" />
                            <Spinner animation="grow" className="bg-primary" />
                        </div>
                    </>
                )
                :
                (
                
                    paymentsData
                    ?
                    (
                        paymentsGateway
                        &&
                        Object.keys(paymentsGateway).length >= 1
                        ?
                        
                            <form
                            id="payments-gateway-from"
                            onSubmit={handleSubmit((data) => {
                                handleSubmitSettings(data)
                            })}
                        >
                            <div className="row">
                                <div className="col-sm-12 col-md-4">
                                    <div className="card p-3 paymob-card">
                                        <h3>Paymob Card</h3>
                                        <div className="row">
                                            <div className="form-group mb-2 col-12">
                                                <label >
                                                    paymob card api key
                                                </label>
                                                <input
                                                    {...register("paymob_card_api_key")}
                                                    type="text"
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        setPaymentsGateway((prevObject) => ({
                                                            ...prevObject,
                                                            ["paymob_card_api_key"]: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group mb-2 col-12">
                                                {/* <input
                                                    {...register("paymob_card_gateway")}
                                                    type="checkbox"
                                                    id="customCheckBox5"
                                                    className={`scale-110 mx-2 cursor-pointer`}
                                                    onChange={(e) => {
                                                        setPaymentsGateway((prevObject) => ({
                                                            ...prevObject,
                                                            ["paymob_card_gateway"]: e.target.checked ? 1 : 0,
                                                        }));
                                                    }}
                                                /> */}
                                                <label className="cursor-pointer" htmlFor="customCheckBox5">
                                                    paymob card gateway
                                                </label>
                                                <select
                                                {...register("paymob_card_gateway")}
                                                className="form-control"
                                                onChange={(e) => {
                                                    setPaymentsGateway((prevObject) => ({
                                                        ...prevObject,
                                                        ["paymob_card_gateway"]: e.target.value,
                                                    }));
                                                }}
                                                >
                                                    <option value={1}>Enable</option>
                                                    <option value={0}>Disable</option>
                                                </select>
                                            </div>
                                            <div className="form-group mb-2 col-12">
                                                <label >
                                                    paymob card hmac
                                                </label>
                                                <input
                                                    {...register("paymob_card_hmac")}
                                                    type="text"
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        setPaymentsGateway((prevObject) => ({
                                                            ...prevObject,
                                                            ["paymob_card_hmac"]: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group mb-2 col-12">
                                                <label >
                                                    paymob card iframe id
                                                </label>
                                                <input
                                                    {...register("paymob_card_iframe_id")}
                                                    type="text"
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        setPaymentsGateway((prevObject) => ({
                                                            ...prevObject,
                                                            ["paymob_card_iframe_id"]: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group mb-2 col-12">
                                                <label >
                                                    paymob card integration id
                                                </label>
                                                <input
                                                    {...register("paymob_card_integration_id")}
                                                    type="text"
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        setPaymentsGateway((prevObject) => ({
                                                            ...prevObject,
                                                            ["paymob_card_integration_id"]: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <div className="card p-3 paymob-wallet">
                                        <h3>Paymob Wallet</h3>
                                        <div className="row">
                                            <div className="form-group mb-2 col-sm-12">
                                                <label >
                                                    paymob wallet api key
                                                </label>
                                                <input
                                                    {...register("paymob_wallet_api_key")}
                                                    type="text"
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        setPaymentsGateway((prevObject) => ({
                                                            ...prevObject,
                                                            ["paymob_wallet_api_key"]: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group mb-2 col-sm-12">
                                                {/* <input
                                                    {...register("paymob_wallet_gateway")}
                                                    type="checkbox"
                                                    id="customCheckBox4"
                                                    className={`scale-110 mx-2 cursor-pointer`}
                                                    onChange={(e) => {
                                                        setPaymentsGateway((prevObject) => ({
                                                            ...prevObject,
                                                            ["paymob_wallet_gateway"]: e.target.checked ? 1 : 0,
                                                        }));
                                                    }}
                                                /> */}
                                                <label className="cursor-pointer" htmlFor="customCheckBox4">
                                                    paymob wallet gateway
                                                </label>
                                                <select
                                                {...register("paymob_wallet_gateway")}
                                                className="form-control"
                                                onChange={(e) => {
                                                    setPaymentsGateway((prevObject) => ({
                                                        ...prevObject,
                                                        ["paymob_wallet_gateway"]: e.target.value,
                                                    }));
                                                }}
                                                >
                                                    <option value={1}>Enable</option>
                                                    <option value={0}>Disable</option>
                                                </select>
                                            </div>
                                            <div className="form-group mb-2 col-sm-12">
                                                <label >
                                                    paymob wallet hmac
                                                </label>
                                                <input
                                                    {...register("paymob_wallet_hmac")}
                                                    type="text"
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        setPaymentsGateway((prevObject) => ({
                                                            ...prevObject,
                                                            ["paymob_wallet_hmac"]: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group mb-2 col-sm-12">
                                                <label >
                                                    paymob wallet integration id
                                                </label>
                                                <input
                                                    {...register("paymob_wallet_integration_id")}
                                                    type="text"
                                                    className={`form-control`}
                                                    onChange={(e) => {
                                                        setPaymentsGateway((prevObject) => ({
                                                            ...prevObject,
                                                            ["paymob_wallet_integration_id"]: e.target.value,
                                                        }));
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 col-md-4">
                                    <div className="card p-3 paymob-card">
                                        <h3>Send Receipt</h3>
                                        <div className="row">
                                            <div className="form-group mb-2 col-sm-12">
                                                <label className="cursor-pointer" htmlFor="customCheckBox3">
                                                    Send Receipt gateway
                                                </label>
                                                <select
                                                {...register("send_receipt")}
                                                className="form-control"
                                                onChange={(e) => {
                                                    setPaymentsGateway((prevObject) => ({
                                                        ...prevObject,
                                                        ["send_receipt"]: e.target.value,
                                                    }));
                                                }}
                                                >
                                                    <option value={1}>Enable</option>
                                                    <option value={0}>Disable</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        :
                        <div className='relative w-full h-[60vh] flex flex-col justify-center items-center'>
                            <img src={faildImg} alt="faild img" className='w-[150px] mb-3'/>
                            <p>{t("No data available")}
                            </p>
                        </div>
                        
                    )
                    :
                    <div className='relative w-full h-[60vh] flex flex-col justify-center items-center'>
                        <img src={faildImg} alt="faild img" className='w-[150px] mb-3'/>
                        <p>{t("No data available")}
                        <span className="text-[#4d5fe3] cursor-pointer"
                        onClick={()=> getPayments()}
                        >
                        {t("Try again")}
                        </span>
                        </p>
                    </div>
                )
            }
        </>
    )
}