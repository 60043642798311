import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { getSingleCategoryFunc } from "../../../../store/custom-actions/categories-actions/GetSingleCategorySlicer";
import CategoryCard from "./CategoryCard";
import ContextApi from "../../../../store/ContextApi";
import PageTitle from "../../../layouts/PageTitle";
import { useTranslation } from "react-i18next";

export default function SingleCategoryPage() {
    const {t} = useTranslation();
    const {categoryId} = useParams();
    const contextApi = useContext(ContextApi);
    const dispatch = useDispatch();
    const {loading:getCategoryLoading , data:categoryData} = useSelector(
        state => state.getSingleCategorySlicer
    )
    const [categoryChildren , setCategoryChildren] = useState([]);
    useEffect(() => {
        const categoryApi = `${contextApi.dashboard_URL_API}/categories/${categoryId}`;
        const getData = () => {
            dispatch(getSingleCategoryFunc(categoryApi))
        }
        getData();
    },[categoryId])
    useEffect(() => {
        if(categoryData) {
            setCategoryChildren(categoryData.all_children)
        }
    },[categoryData])
    return (
        <>
            <PageTitle motherMenu={categoryData ? categoryData?.name : t("loading")} activeMenu={"Sub Categories"} />
            {
                getCategoryLoading
                ?
                <div className="row d-flex justify-content-center gap-2">
                    <Spinner animation="grow" className="bg-primary" />
                    <Spinner animation="grow" className="bg-primary" />
                    <Spinner animation="grow" className="bg-primary" />
                    <Spinner animation="grow" className="bg-primary" />
                </div>
                :
                (
                    categoryData
                    ?
                    <>
                        <div className="h-fit relative row">
                            {
                                categoryChildren && categoryChildren.length >= 1
                                ?
                                (
                                    categoryChildren.map((child , index) => {
                                        const {id , all_children , name , image_url} = child;
                                        // const {name:courseName} = course;
                                        return (
                                            <CategoryCard
                                            id={id}
                                            name={name}
                                            key={index}
                                            image={image_url}
                                            childrenCount={all_children.length}
                                            refetchData = {() => dispatch(getSingleCategoryFunc(`${contextApi.dashboard_URL_API}/categories/${categoryId}`))}
                                            />
                                        )
                                    })
                                )
                                :
                                <h3 className="text-center my-3 w-full">{t("No data available")}</h3>
                            }
                        </div>
                        {/* <ChapterLessonsTable
                            lessonsData={categoryData.lessons}
                            categoryId={categoryId}
                            refetchSingleChapter = {() => dispatch(getSingleChapterFunc(`${contextApi.dashboard_URL_API}/chapters/${categoryId}`))}
                        /> */}
                    </>
                    :
                    <h3 className="text-center my-3 w-full">{t("No data available")}</h3>
                )
            }
        </>
    )
}