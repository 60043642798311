import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const postGeneralSettingsFunc = createAsyncThunk(
    "post settings function",
    async ({api , data}) => {
        try {
            const response = await axios.post(api , data );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            checkErrors(error.response , error.message);
            return error
        }
        
    }
);

const postGeneralSettingsSlicer = createSlice({
    name : "post settings slicer",
    initialState : {
        loading : null,
        data : [],
        error : false
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(postGeneralSettingsFunc.pending , (state)=>{
            state.loading = true
            state.data = []
            state.error = null
        })
        builder.addCase(postGeneralSettingsFunc.rejected , (state)=>{
            state.loading = false
            state.data = []
            state.error = true
        })
        builder.addCase(postGeneralSettingsFunc.fulfilled , (state , action)=>{
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
});
export default postGeneralSettingsSlicer.reducer;
