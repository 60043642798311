import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
import Swal from "sweetalert2";
export const getPaymentsFunc = createAsyncThunk(
    "get payments function",
    async (api) => {
        try {
            const response = await axios.get(api );
            checkErrorsFunctions(response , response.data.errors);
            console.log(response.data.data)
            return response.data.data.payments_gateway;
        }
        catch (error) {
            checkErrors(error.response , error.message);
        }
        
    }
)

const getPaymentsSlicer = createSlice({
    name : "get payments slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(getPaymentsFunc.pending , (state) => {
            state.loading = true
            state.data = false
            state.error = false
        });
        builder.addCase(getPaymentsFunc.rejected , (state) => {
            state.loading = false
            state.data = null
            state.error = true
        });
        builder.addCase(getPaymentsFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = null
        });
    }
});

export default getPaymentsSlicer.reducer;