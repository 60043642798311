import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {useEffect, useState} from 'react';
import { updateCategoryFunc } from '../../../store/custom-actions/categories-actions/UpdateCategorySlicer';
import { fetchAllCategoriesFunc } from '../../../store/custom-actions/categories-actions/GetAllCategoriesSlicer';
import HandleErrorsListFunc from '../../global-functions/handleErrorsListFunc';
import { toast } from 'react-toastify';
import { TreeSelect } from 'antd';
import { useTranslation } from 'react-i18next';
const convertAllChildrenKey = (data) => {
  if (!Array.isArray(data)) return [];
  return data.map((item) => {
    const { name, id, all_children, ...rest } = item;
    return {
      ...rest,
      title: name,
      value: id,
      children: convertAllChildrenKey(all_children),
    };
  });
};

const TreeSelectExam = ({treeData , setParentCategryFunc , defaultValue}) => {
  const [value, setValue] = useState(defaultValue);
  const onChange = (newValue) => {
    setParentCategryFunc(newValue)
    setValue(newValue);
  };
  const modifiedTreeData = convertAllChildrenKey(treeData);

  return (
    <TreeSelect
      style={{
        width: '100%',
        height: '40px',
      }}
      value={value}
      dropdownStyle={{
        maxHeight: 400,
        overflow: 'auto',
      }}
      treeData={modifiedTreeData}
      placeholder="Please select"
      treeDefaultExpandAll
      onChange={onChange}
      treeDataSimpleMode={{
        id: 'value',
        pId: 'parentId',
        rootPId: null,
      }}
    />
  );
};

function UpdateCategoryForm({showUpdateCategoryForm , hideUpdateCategoryForm , categoriesApi , categoryName , categoryId , refetchData , categoriesList , oldParentId , categoryImage}) {
    const {t} = useTranslation();
    const {register , formState : {errors} , handleSubmit , setValue} = useForm();
    const {updateCategoryloading} = useSelector(state => state.updateCategorySlicer);
    const dispatch = useDispatch();
    const {loading , categoriesData} = useSelector(state => state.getAllCategoriesSlicer);
    const [parentId , setParentId] = useState(null);
    const [image , setImage] = useState()
    const [imageViewr , setImageViewr] = useState()
    const changeCategoryParetIdFunc = (id) => {
      setParentId(id)
    }
    useEffect(() => categoryImage && setImageViewr(categoryImage) , [categoryImage])
    const handleSubmitData = (data) => {
      if (!data.name === "") {
        toast.error(t("Category name must be more than 3 letters!"));
        return;
      }
      const formData = new FormData();
      categoryId && formData.append("parent_id", categoryId);
      formData.append("name", data.name);
      image && formData.append("image", image);
      formData.append("_method", "PUT");
        dispatch(updateCategoryFunc({categoryApi : `${categoriesApi}/${categoryId}` , newCategoryData : formData}))
        .then(result => {
            if(result.payload.success === true) {
              dispatch(fetchAllCategoriesFunc(categoriesApi));
              hideUpdateCategoryForm();
              refetchData && refetchData()
            }
        })
    }
    useEffect(() => {
      setValue("name" , categoryName)
    },[setValue , categoryName])

  useEffect(() => {
    HandleErrorsListFunc(errors);
  },[errors])
  return (
    <>
      <Modal show={showUpdateCategoryForm} onHide={hideUpdateCategoryForm}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Update")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form 
            id="update-category-form"
            onSubmit={handleSubmit((data) => {
                handleSubmitData(data);
            })}
            >
              <div className='row'>
                  {
                    oldParentId
                    &&
                    <div className="form-group mb-3 col-sm-12">
                      {
                          loading ? (
                              <div className="form-group flex items-center">
                                  <p>{t("loading")}...</p>
                              </div>
                          ) : categoriesData &&
                              categoriesList &&
                              categoriesList.length <= 0 ? (
                              <div className="form-group flex items-center">
                                  <p>{t("No data available")}</p>
                              </div>
                          ) : (
                              <div
                                  className={`form-group mb-3 col-sm-12`}
                              >
                              <label>{t("Main Category")}</label>
                              <TreeSelectExam treeData={categoriesList} setParentCategryFunc = {changeCategoryParetIdFunc} defaultValue={oldParentId}/>
                              </div>
                          )
                      }
                    </div>
                  }
                  <div className="form-group mb-3 col-sm-12">
                      <label>{t("Name")}</label>
                      {errors.name?.message && (
                          <p className="text-red-600">{errors.name?.message}</p>
                      )}
                      <input
                      {...register("name", {required : t("name should not be empty!")})}
                      type="text"
                      className="form-control"
                      />
                  </div>
                  <div className={`col-sm-12 form-group mb-3 flex justify-center items-center`}>
                    <img src={imageViewr} alt="cat img" className='relative max-w-[60px]' />
                  </div>
                  <div className={`col-sm-12 form-group mb-3`}>
                    <label>{t("Image")}</label>
                    <div className="input-group">
                      <input
                        accept="image/*"
                        type="file"
                        className="form-control"
                        name="image"
                        onChange={(e) => {
                          const file = e.target.files[0];
                          if(file) {
                              setImage(file)
                              const fileurl = URL.createObjectURL(file);
                              setImageViewr(fileurl)
                          }
                        }}
                      />
                    </div>
                  </div>
              </div>
            </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={hideUpdateCategoryForm}>
            {t("Close")}
          </Button>
          <Button type="submit" form="update-category-form" className={`${updateCategoryloading ? "pointer-events-none opacity-80" : ""}`}>
          {!updateCategoryloading 
          ? (
            t("Submit")
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                {t("loading")}...
                <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white"></div>
              </div>
            </>
          )}
        </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default UpdateCategoryForm;