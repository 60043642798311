import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const deleteSubscriptionFunc = createAsyncThunk(
    "delete subscription function",
    async (api) => {
        try {
            const response = await axios.delete(api);
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            checkErrors(error.response , error.message);
            return error
        }
        
    }
)

const deleteSubscriptionSlicer = createSlice({
    name : "delete subscription slicer",
    initialState : {
        loading : null,
        data : null,
        error : "error" 
    },
    reducer :{},
    extraReducers : (builder) => {
        builder.addCase(deleteSubscriptionFunc.pending , (state) => {
            state.loading = true
            state.data = null
            state.error = null
        })
        builder.addCase(deleteSubscriptionFunc.rejected , (state) => {
            state.loading = null
            state.data = null
            state.error = true
        })
        builder.addCase(deleteSubscriptionFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
})
export default deleteSubscriptionSlicer.reducer;