import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
import Swal from "sweetalert2";

export const deleteQuizeFunc = createAsyncThunk(
    "delete quize function",
    async (api) => {
        try {
            const response = await axios.delete(api );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
    }
)

const deleteQuizeSlicer = createSlice({
    name : "delete quize slicer",
    initialState : {
        deleteQuizeLoading : null,
        data : null,
        error : "error"
    },
    reducer :{},
    extraReducers : (builder) => {
        builder.addCase(deleteQuizeFunc.pending , (state) => {
            state.deleteQuizeLoading = true;
            state.data = null;
            state.error = null;
        })
        builder.addCase(deleteQuizeFunc.rejected , (state) => {
            state.deleteQuizeLoading = null;
            state.data = null;
            state.error = true;
        })
        builder.addCase(deleteQuizeFunc.fulfilled , (state , action) => {
            state.deleteQuizeLoading = false;
            state.data = action.payload;
            state.error = null;
        })
    }
})
export default deleteQuizeSlicer.reducer;