import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import Swal from "sweetalert2";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const getQuizeSingleAttempFunc = createAsyncThunk(
    "get quize single attempt function",
    async (api) => {
        try {
            const response = await axios.get(api );
            checkErrorsFunctions(response , response.data.errors);
            console.log(response)
            return response.data.data.quiz;
        }
        catch (error) {
            // Swal.fire({
            //     title: "Oops!",
            //     text: error,
            //     icon: "error"
            // });
            checkErrors(error.response , error.message);
        }
        
    }
)

const getQuizeSingleAttemptsSlicer = createSlice({
    name : "get quize single attempts slicer",
    initialState : {
        quizeSingleAttemptsloading : null,
        attempData : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(getQuizeSingleAttempFunc.pending , (state) => {
            state.quizeSingleAttemptsloading = true
            state.attempData = false
            state.error = false
        });
        builder.addCase(getQuizeSingleAttempFunc.rejected , (state) => {
            state.quizeSingleAttemptsloading = false
            state.attempData = null
            state.error = true
        });
        builder.addCase(getQuizeSingleAttempFunc.fulfilled , (state , action) => {
            state.quizeSingleAttemptsloading = false
            state.attempData = action.payload
            state.error = null
        });
    }
});

export default getQuizeSingleAttemptsSlicer.reducer;