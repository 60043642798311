import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import ContextApi from '../../../store/ContextApi';
import sessionImg from "../../../media/images/online-learning.png";
import { fetchAllPermissionsFunc } from '../../../store/custom-actions/permissions-actions/GetAllPermissions';
import { updateRoleFunc } from '../../../store/custom-actions/roles-actions/UpdateRoleSlicer';
import { getSingleRoleFunc } from '../../../store/custom-actions/roles-actions/GetSingleRoleSlicer';
import { Spinner } from 'react-bootstrap';
import { getRolesFunc } from '../../../store/custom-actions/roles-actions/GetRolesSlicer';
import HandleErrorsListFunc from '../../global-functions/handleErrorsListFunc';
import { useTranslation } from 'react-i18next';
function UpdateRoleForm(props) {
    const {t} = useTranslation();
    const {role_id , role} = props;
    const contextApi = useContext(ContextApi);
    const [permissionsList , setPermissionsList] = useState([]);
    const {register , handleSubmit , formState : {errors} , reset , setValue} = useForm();
    const dispatch = useDispatch();
    const allPerissionsApi = `${contextApi.dashboard_URL_API}/permissions`;
    const roles_API_URL = `${contextApi.dashboard_URL_API}/roles`;
    const {loading:getPermissionsLoading , data:permissionsData} = useSelector(
        state => state.getAllPermissionsSlicer
    )
    const {loading:updateRoleLoading} = useSelector(
        state => state.updateRoleSlicer
    )
    const {loading:getSingleRoleLoading , data:roleData} = useSelector(
        state => state.getSingleRoleSlicer
        )
    const [arrayWillSend , setarrayWillSend] = useState([]);
    const getRole = async () => {
        try {
            dispatch(getSingleRoleFunc(`${roles_API_URL}/${role_id}`))
        }
        catch (error){
            alert(error)
        }
    }
    useEffect(() => {
        getRole()
    },[])
    useEffect(() => {
        roleData && roleData.permissions &&  setarrayWillSend(roleData.permissions);
    },[roleData])

    useEffect(() => {
        if(role) {
            setValue("name" , role.name)
            setValue("description" , role.description)
        }
    },[role , setValue])
    // new code
    const getPermissions = async () => {
            try {
                dispatch(fetchAllPermissionsFunc(allPerissionsApi))
            }
            catch (error) {
                alert(error)
            }
        }
    useEffect(() => {
        getPermissions()
    },[])
    useEffect(() => {
        permissionsData && setPermissionsList(permissionsData)
    },[permissionsData])


const removingPermisssion = (arrayAfterRemoving) => {
    setarrayWillSend(arrayAfterRemoving)
  }
  const additionPermission = (permission) => {
    setarrayWillSend([...arrayWillSend , permission])
  }
    
  const handleSubmitRole = async (data) => {
    if(data && arrayWillSend ) {
        const uniqueData = arrayWillSend.flatMap(permission => permission.name)
        dispatch(updateRoleFunc(
        {
            api : `${roles_API_URL}/${role_id}` , 
            data : {...data ,
                permissions : uniqueData
            }}
        ))
        .then(result => {
            if(result.payload.success === true) {
                props.onHide();
                dispatch(getRolesFunc(roles_API_URL));
            }
        })
}
}

useEffect(() => {
    HandleErrorsListFunc(errors)
  },[errors])
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='flex items-center'>
          <img src={sessionImg} alt="session-icon" className='relative w-[20px] mr-2' />
          {t("Update Role")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='session-form-div'>
            {
                getSingleRoleLoading
                ?
                (
                    <>
                        <div className="row d-flex justify-content-center gap-2">
                            <Spinner animation="grow" className="bg-primary" />
                            <Spinner animation="grow" className="bg-primary" />
                            <Spinner animation="grow" className="bg-primary" />
                            <Spinner animation="grow" className="bg-primary" />
                        </div>
                    </>
                )
                :
                (
                <form
                    id="session-form"
                    onSubmit={handleSubmit((data) => {
                        handleSubmitRole(data);
                    })}
                >
                    <div className='row'>
                        <div className='form-group col-sm-12 col-md-6'>
                            <label>{t("Name")}</label>
                            {errors.name?.message && (
                            <p className="text-red-600">
                                {errors.name?.message}
                            </p>
                            )}
                            <input 
                            {...register("name" , {required : "role name is required!"})}
                            type="text" 
                            className='form-control'
                            />
                        </div>
                        <div className='form-group col-sm-12 col-md-6'>
                            <label>{t("Description")}</label>
                            {errors.description?.message && (
                            <p className="text-red-600">
                                {errors.description?.message}
                            </p>
                            )}
                            <input 
                            {...register("description" , {required : "role description is required!"})}
                            type="text" 
                            className='form-control'
                            />
                        </div>
                    </div>

                    {/* <h4 className="my-2">Select Role Permissions <span className='text-sm font-normal'>(at least 1 permission)</span></h4>
                    <div className='row mt-2'>
                    {
                        getPermissionsLoading
                        ?
                        <p className='my-1 text-center w-full relative'>Loading Permissions...</p>
                        :
                        (
                            permissionsData
                            ?
                            (
                                permissionsList.length >= 1
                                ?
                                permissionsList.map((permission , index) => {
                                return (
                                    <>
                                    <div key={index} className='cursor-pointer justify-start m-1 col-sm-10 col-md-5 col-lg-3 flex items-center'>
                                        
                                        <PermissionCheckBox 
                                            key={index}
                                            permission = {permission}
                                            arrayWillSend = {arrayWillSend}
                                            removing = {removingPermisssion}
                                            additionDone = {additionPermission}
                                        />
                                    </div>
                                    </>
                                )
                                })
                                :
                                <p>There are no Permissions!</p>
                            )
                            :
                            <div className="relative w-full h-[60vh] flex flex-col justify-center items-center">
                                <img
                                src={faildImg}
                                alt="faild img"
                                className="w-[150px] mb-3"
                                />
                                <p>
                                Faild load Permissions!
                                <span
                                    className="text-[#4d5fe3] cursor-pointer"
                                    onClick={() =>
                                        getPermissions()
                                    }
                                >
                                    Try again
                                </span>
                                </p>
                            </div>
                        )
                    }
                    </div> */}

                </form>
                )
            }
        </div>

      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
            props.onHide();
            reset();
        }}>{t("Close")}</Button>
        <Button type="submit" form="session-form" className={`${updateRoleLoading ? "pointer-events-none opacity-80" : ""}`}>
          {!updateRoleLoading ? (
            t("Submit")
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                {t("loading")}...
                <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white"></div>
              </div>
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default UpdateRoleForm