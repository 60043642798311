import { useState , useEffect, useContext, useRef } from "react";
import PageTitle from "../../layouts/PageTitle";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import faildImg from "../../../media/images/planet.png"
import ContextApi from "../../../store/ContextApi";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import OverLayLoading from "../custome-overlay-loading/OverLayLoading";
import { getCouponseFunc } from "../../../store/custom-actions/couponse-actions/GetCouponseSlicer";
import { deleteCouponeFunc } from "../../../store/custom-actions/couponse-actions/DeleteCouponseSlicer";
import Swal from "sweetalert2";
import ShowCouponUsers from "./ShowCouponUsers";
import { fetchAllCoursesFunc } from "../../../store/custom-actions/courses-actions/GetAllCoursesSlicer";
import checkPermissions from "../../global-functions/checkPermissionsFunc";
import NewCouponeForm from "./couponse-forms/NewCouponForm";
import { useTranslation } from "react-i18next";

function CouponsePage() {
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const coupons_API_URL = `${contextApi.dashboard_URL_API}/coupons`;
    const [couponsList , setCouponsList] = useState([]);
    const dispatch = useDispatch();
    const [searchValue , setSearchValue] = useState("");
    const [showCouponUsers , setShowCouponUsers] = useState();
    const [couponUsers , setCouponUsers] = useState([]);
    const coursesApi = `${contextApi.dashboard_URL_API}/courses`;
    const [coursesList , setCoursesList] = useState([]);
    const [courseId , setCourseId] = useState("");
    const [showNewCouponForm , setShowNewCouponForm] = useState();
    const {loading:getCoursesLoading , courses:coursesData} = useSelector(
        state => state.getAllCoursesSlicer
    )
    const {loading:getCouponsLoading , data} = useSelector(
        state => state.getCouponseSlicer
    )
    const {loading:deleteCouponLoading} = useSelector(
        state => state.deleteCouponeSlicer
    )
    const getCouponse = async () => {
        try {
            dispatch(getCouponseFunc(coupons_API_URL))
        }
        catch (error){
            alert(error)
        }
    }
    useEffect(() => {
        data && setCouponsList(data)
        data && (jobData.current = data.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
          ));
    },[data])
    const sort = 20;
    let paggination = Array(Math.ceil(couponsList.length / sort))
      .fill()
      .map((_, i) => i + 1);
  
    const activePag = useRef(0);
    const jobData = useRef(
      couponsList.slice(
        activePag.current * sort,
        (activePag.current + 1) * sort
      )
    );
    const onClick = (i) => {
      activePag.current = i;
      jobData.current = couponsList.slice(
        activePag.current * sort,
        (activePag.current + 1) * sort
      );
    };
    useEffect(() => {
        dispatch(fetchAllCoursesFunc(coursesApi))
      },[])
      useEffect(() => {
        const onlineCourses = coursesData && coursesData.length>=1 && coursesData.filter(course => course.type === "online");
        // coursesData && setCoursesList(coursesData)
        coursesData && setCoursesList(onlineCourses)
      },[coursesData])
    const deleteCoupon = async (couponId) => {
        const deleteFunc = () => {
            try {
                dispatch(deleteCouponeFunc(`${coupons_API_URL}/${couponId}`))
                .then(result => {
                    if(result.payload.success === true) {
                        getCouponse();
                    }
                })
            }
            catch (error){
                alert(error)
            }
        }
        swal({
            title: t("Confirm Coupon Deletion"),
            text: t(`Once deleted, this will be not avilable in this Platform!`),
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                deleteFunc()
            }
        });
    }


    useEffect(() => {
        let api = `${coupons_API_URL}?${searchValue ? `search=${searchValue}` : ""}&${courseId !== "" ? `course_id=${courseId}` : ""}`;
        let searchTimeout;
        let fetchCouponse = async ()=>{
          if(searchValue !== "") {
            clearTimeout(searchTimeout);
            searchTimeout = setTimeout(()=>{
              dispatch(getCouponseFunc(api))
            },1000)
          }else {
            dispatch(getCouponseFunc(api))
          }
        }
        fetchCouponse()
        return () => clearTimeout(searchTimeout)
      },[searchValue , courseId])



    return (
        <>
            <PageTitle activeMenu="Coupons" motherMenu="Dashboard" />
            <div className="card">
                <div className="card-header flex justify-between items-center">
                    <h4 className="card-title">{t("Coupons List")}</h4>
                    <div className="relative add-and-search flex items-center">
                    <div className="input-group search-area w-auto mr-2">
                        <span className="input-group-text"
                        //   onClick={() => search()}
                        >
                            <Link to={"#"}>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                d="M27.414 24.586L22.337 19.509C23.386 17.928 24 16.035 24 14C24 8.486 19.514 4 14 4C8.486 4 4 8.486 4 14C4 19.514 8.486 24 14 24C16.035 24 17.928 23.386 19.509 22.337L24.586 27.414C25.366 28.195 26.634 28.195 27.414 27.414C28.195 26.633 28.195 25.367 27.414 24.586ZM7 14C7 10.14 10.14 7 14 7C17.86 7 21 10.14 21 14C21 17.86 17.86 21 14 21C10.14 21 7 17.86 7 14Z"
                                fill="var(--primary)"
                                ></path>
                            </svg>
                            </Link>
                        </span>
                        <input
                            type="text"
                            className="form-control"
                            placeholder={t("Search here...")}
                            onChange={(e) => {
                                setSearchValue(e.target.value);
                            }}
                        />
                    </div>
                    {
                    checkPermissions
                    (
                        "coupons_create"
                        ,
                        <Link 
                        to={"#"} 
                        className="btn btn-primary btn-sm"
                        onClick={() => {
                            setShowNewCouponForm(true)
                        }}
                        >
                            {t("Create")}
                            <i className="fa-solid fa-plus ml-1"></i>
                        </Link>
                    )
                    }
                    </div>
                </div>
                <div className="card-body">
                    <div className="content relative w-full">
                        {
                            getCouponsLoading
                            ?
                            (
                                <>
                                    <div className="row d-flex justify-content-center gap-2">
                                        <Spinner animation="grow" className="bg-primary" />
                                        <Spinner animation="grow" className="bg-primary" />
                                        <Spinner animation="grow" className="bg-primary" />
                                        <Spinner animation="grow" className="bg-primary" />
                                    </div>
                                </>
                            )
                            :
                            (
                                data
                                ?
                                (
                                    <div className="table-responsive">
                                    <div
                                        id="student_wrapper"
                                        className="dataTables_wrapper no-footer"
                                    >
                                        <table
                                        className="table display mb-4 dataTablesCard order-table card-table text-black application "
                                        id="application-tbl1_next"
                                        >
                                        <thead>
                                            <tr>
                                            <th className="">#{t("ID")}</th>
                                            <th className="">{t("Coupon")}</th>
                                            <th className="">{t("Start")}</th>
                                            <th className="">{t("End")}</th>
                                            <th className="">{t("Discount")}</th>
                                            <th className="">{t("Type")}</th>
                                            <th className="">{t("Usage Limit")}</th>
                                            <th className="">{t("Used")}</th>
                                            <th className="">{t("Status")}</th>
                                            <th className=" text-end">
                                                {t("Decision")}
                                            </th>
                                            </tr>
                                        </thead>
                                            <tbody>
                                                {couponsList &&
                                                couponsList.length > 0 ?
                                                jobData.current.map((coupon, index) => {
                                                    const creationDate = new Date(coupon.created_at)
                                                    const creationYear = creationDate.getFullYear();
                                                    const creationMonth = creationDate.getMonth()+1;
                                                    const creationDay = creationDate.getDate();
                                                    const latestUpdateDate = new Date(coupon.updated_at)
                                                    const latestUpdateYear = latestUpdateDate.getFullYear();
                                                    const latestUpdateMonth = latestUpdateDate.getMonth()+1;
                                                    const latestUpdateDay = creationDate.getDate();
                                                    return (
                                                    <tr key={index}>
                                                        <td className="font-bold">
                                                        #{coupon.id}
                                                        </td>
                                                        <td className="">
                                                            {coupon.code}
                                                        </td>
                                                        <td className="">
                                                            {coupon.start_date}
                                                        </td>
                                                        <td className="">
                                                            {coupon.end_date}
                                                        </td>
                                                        <td>
                                                        {coupon.discount}
                                                        </td>
                                                        <td>
                                                        {coupon.type}
                                                        </td>
                                                        {/* <td className="">
                                                        {`${latestUpdateYear} / ${latestUpdateMonth} / ${latestUpdateDay}`}
                                                        </td> */}
                                                        <td className="">
                                                            {coupon.count_used}
                                                        </td>
                                                        <td className="">
                                                            {
                                                            coupon.coupon_usages && 
                                                            (
                                                                coupon.coupon_usages.length >= 1
                                                                ?
                                                                coupon.coupon_usages.length
                                                                :
                                                                "Not Usage Yet"
                                                            )
                                                            }
                                                        </td>
                                                        <td className="">
                                                            {
                                                            coupon.coupon_usages && 
                                                            (
                                                                coupon.coupon_usages.length < coupon.count_used
                                                                ?
                                                                <span className="relative flex justify-center p-1 items-center bg-green-500 text-white rounded-md">Active</span>
                                                                :
                                                                <span className="relative flex justify-center p-1 items-center bg-red-500 text-white rounded-md">Expired</span>
                                                            )
                                                            }
                                                        </td>
                                                        <td className="">
                                                        {
                                                            checkPermissions
                                                            (
                                                                "coupons_delete"
                                                                ,
                                                                <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                                                    onClick={() => {
                                                                        deleteCoupon(coupon.id)
                                                                    }}
                                                                >
                                                                    <div class="group flex justify-center transition-all rounded-full bg-primary p-2"
                                                                    >
                                                                        <i className="fa-solid fa-trash text-white"></i>
                                                                        <span class="absolute opacity-0 group-hover:opacity-100 group-hover:-translate-y-7 duration-700 text-sm"
                                                                        >{t("Delete")}</span>
                                                                    </div>
                                                                </button>
                                                            )
                                                        }
                                                        <button class="relative inline-flex mr-2 justify-center items-center cursor-pointer"
                                                            onClick={() => {
                                                                if(coupon.coupon_usages && coupon.coupon_usages.length >= 1) {
                                                                    setCouponUsers(coupon.coupon_usages)
                                                                    setShowCouponUsers(true)
                                                                }else {
                                                                    Swal.fire({
                                                                        title: t("No Usage!"),
                                                                        text: t("This Coupon Not Used Yet!"),
                                                                        icon: "warning"
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            <div class="group flex justify-center transition-all rounded-full bg-primary p-2"
                                                            >
                                                                <i className="fa-solid fa-user text-white"></i>
                                                                <span class="absolute opacity-0 group-hover:opacity-100 group-hover:-translate-y-7 duration-700 text-sm"
                                                                >{t("users")}</span>
                                                            </div>
                                                        </button>
                                                        </td>
                                                    </tr>
                                                    );
                                                })
                                                :
                                                <td colSpan={4} className="">
                                                    <p className="m-0 border-none">{t("No data available")}</p> 
                                                </td>
                                                }
                                            </tbody>
                                        </table>
                                        <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-2">
                                    <div className="dataTables_info">
                                      {t("Showing")} {activePag.current * sort + 1} to{" "}
                                      {couponsList.length > (activePag.current + 1) * sort
                                        ? (activePag.current + 1) * sort
                                        : couponsList.length}{" "}
                                      {t("of")} {couponsList.length} {t("entries")}
                                    </div>
                                    <div
                                      className="dataTables_paginate paging_simple_numbers mb-0"
                                      id="example5_paginate"
                                    >
                                      <Link
                                        className="paginate_button previous disabled"
                                      
                                        to="#"
                                        onClick={() =>
                                          activePag.current > 0 && onClick(activePag.current - 1)
                                        }
                                      >
                                        <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                      </Link>
                                      <span>
                                        {paggination.map((number, i) => (
                                          <Link
                                            key={i}
                                          
                                            to="#"
                                            className={`paginate_button  ${
                                              activePag.current === i ? "current" : ""
                                            } `}
                                            onClick={() => onClick(i)}
                                          >
                                            {number}
                                          </Link>
                                        ))}
                                      </span>
                                      <Link
                                        className="paginate_button next"
                                      
                                        to="#"
                                        onClick={() =>
                                          activePag.current + 1 < paggination.length &&
                                          onClick(activePag.current + 1)
                                        }
                                      >
                                        <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                      </Link>
                                    </div>
                                  </div>
                                    </div>
                                    </div>
                                )
                                :
                                <div className='relative w-full h-[60vh] flex flex-col justify-center items-center'>
                                    <img src={faildImg} alt="faild img" className='w-[150px] mb-3'/>
                                    <p>{t("No data available")} 
                                    <span className="text-[#4d5fe3] cursor-pointer"
                                    onClick={()=> getCouponse()}
                                    >
                                    {t("Try again")}
                                    </span>
                                    </p>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        {
            showCouponUsers
            &&
            <ShowCouponUsers
                show = {showCouponUsers}
                onHide = {() => setShowCouponUsers(false)}
                usages = {couponUsers}
            />
        }
        {
            showNewCouponForm
            &&
            <NewCouponeForm
                show = {showNewCouponForm}
                onHide = {() => setShowNewCouponForm(false)}
            />
        }
        <OverLayLoading loading={deleteCouponLoading} loadingText={"Deleting Coupon.."} />
        </>
    )
}

export default CouponsePage;