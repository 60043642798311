import { useEffect } from "react";
import swal from "sweetalert";
import timeImg from "../../media/images/time-left.png"
function LogOut() {
    const expire_date = window.localStorage.getItem("expire_date")
    
    useEffect(() => {
        const checkerInterval = setInterval(() => {
          const dateNow = Date.now();
          const expirationTime = new Date(expire_date).getTime();
          const timeDifference = expirationTime - dateNow;
        if(timeDifference > 0 && timeDifference <= 60 * 1000) {
            swal({
              title : "session will end after 1 munite!",
              buttons  : "ok"
            })
        }
          if (dateNow > expirationTime) {
            window.localStorage.removeItem("expire_date")
            window.localStorage.removeItem("user")
            window.location.reload();
          }
        }, 60 * 1000); // Check every 1 minute
        return () => clearInterval(checkerInterval);
      }, [expire_date]);
    return (
        <>
        </>
    )
}
export default LogOut;