import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import sessionImg from "../../../media/images/online-learning.png";
import {Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import qrcode from "../../../images/frame.png";
import QRCode from "react-qr-code";
function ShowActivationCodes(props) {
    const {t} = useTranslation();
    const {bulck_name , activation_codes , execution} = props;
    const [targetCode , setTargetCode] = useState();
    const printRef = useRef();
    const printSingleRef = useRef();
    const printAllSingleRef = useRef();
    const handlePrint = useReactToPrint({
      content : () => printRef.current
    })
    const handlePrintSingleCode = useReactToPrint({
      content : () => printSingleRef.current,
      pageStyle : `
        @page {
            size: A5 landscape;
        }
        @media print {
        /* Ensure background colors are printed */
        body {
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
        }
    }
      `
    })
    const handlePrintSAllingleCode = useReactToPrint({
      content : () => printAllSingleRef.current,
      pageStyle : `
        @page {
            size: A5 landscape;
        }
        @media print {
        /* Ensure background colors are printed */
        body {
            -webkit-print-color-adjust: exact;
            print-color-adjust: exact;
        }
    }
      `
    })
    const {loading:getBasicSettingsLoading , data:settingsData} = useSelector(
        state => state.getBasicSettingsSlicer
    )
    useEffect(() => {
        if (targetCode) {
          handlePrintSingleCode();
        }
    }, [targetCode]);
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='flex items-center'>
          <img src={sessionImg} alt="session-icon" className='relative w-[20px] mr-2' />
          {bulck_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
    {
        <>
            <div className='relative w-full flex justify-between items-center'>
                <div className='relative '>{t("Activation Codes")} : {activation_codes && activation_codes.length}</div>
                <div className='relative flex items-center'>
                    <Link
                        to={`#`} 
                        className="btn btn-primary btn-sm ml-2"
                        onClick={() => {
                            handlePrint()
                        }}
                        >
                        {t("Print")}
                        <i className="fa-solid fa-print ml-1"></i>
                    </Link>
                    <Link
                        to={`#`} 
                        className="btn btn-primary btn-sm ml-2"
                        onClick={() => {
                            handlePrintSAllingleCode()
                        }}
                        >
                        {t("Print all as single")}
                        <i className="fa-solid fa-print ml-1"></i>
                    </Link>
                </div>
            </div>
            <Table responsive>
                <thead>
                    <tr>
                    {/* <th className="width80">
                        <strong>#{t("ID")}</strong>
                    </th> */}
                    <th>
                        <strong>{t("Code")}</strong>
                    </th>
                    <th>
                        <strong>{t("Used")}</strong>
                    </th>
                    {/* <th>
                        <strong>{t("Creation")}</strong>
                    </th>
                    <th>
                        <strong>{t("Last Update")}</strong>
                    </th> */}
                    <th>
                        <strong>{t("Print")}</strong>
                    </th>
                    </tr>
                </thead>
                <tbody>
                    {
                    activation_codes && activation_codes.length >= 1 ? activation_codes.map((code,index)=>{
                        const creationDate = new Date(code.created_at)
                        const creationYear = creationDate.getFullYear();
                        const creationMonth = creationDate.getMonth()+1;
                        const creationDay = creationDate.getDate();
                        const latestUpdateDate = new Date(code.updated_at)
                        const latestUpdateYear = latestUpdateDate.getFullYear();
                        const latestUpdateMonth = latestUpdateDate.getMonth()+1;
                        const latestUpdateDay = creationDate.getDate();
                        return (
                        <tr key={index}>
                            {/* <td>
                                <strong>#{code.id}</strong>
                            </td> */}
                            <td>{code.code}</td>
                            <td>
                                {
                                    code.student_id
                                    ?
                                    t("Yes")
                                    :
                                    t("No")
                                }
                            </td>
                            {/* <td>
                            {`${creationYear} / ${creationMonth} / ${creationDay}`}
                            </td>
                            <td className="">
                            {`${latestUpdateYear} / ${latestUpdateMonth} / ${latestUpdateDay}`}
                            </td> */}
                            <td className="">
                                <Link
                                    to={`#`} 
                                    className="btn btn-primary btn-sm ml-2"
                                    // onClick={() => setShowNewBuclkForm(true)}
                                    onClick={() => {
                                        setTargetCode(code.code)
                                        // if(targetCode) {
                                        //     handlePrintSingleCode()
                                        // }
                                    }}
                                    >
                                    {t("Print")}
                                    <i className="fa-solid fa-print ml-1"></i>
                                </Link>
                            </td>
                        </tr>
                        )
                    })
                    :
                    <td colSpan={3}>{t("No data available")}</td>
                
                }
                </tbody>
            </Table>
        </>
    }
        <div style={{display: "none"}}>
            <div className='border border-black m-2' ref={printRef}>
                <div className='relative w-full flex justify-between items-center px-4'>
                    <img src={settingsData?.site_logo} className='w-[100px]' />
                    <div className='relative flex flex-col justify-center items-center'>
                        {/* <h3 className='text-center'>{execution?.title}</h3> */}
                        <h3>{execution?.course?.name}</h3>
                        <h5>{execution?.course?.user?.name}</h5>
                    </div>
                    {/* <img src={qrcode} className='w-[100px]' /> */}
                    <div className='relative'>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "80px", width: "80px" }}
                            value={`${settingsData?.site_front_url}/courses/${execution?.id}`}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                </div>
                <Table className='print-modale' responsive>
                    <thead>
                        <tr>
                        <th className='text-center'>
                            <strong>{t("Code")}</strong>
                        </th>
                        <th className='text-center'>
                            <strong>{t("Used")}</strong>
                        </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                        activation_codes && activation_codes.length >= 1 ? activation_codes.map((code,index)=>{
                            const creationDate = new Date(code.created_at)
                            const creationYear = creationDate.getFullYear();
                            const creationMonth = creationDate.getMonth()+1;
                            const creationDay = creationDate.getDate();
                            const latestUpdateDate = new Date(code.updated_at)
                            const latestUpdateYear = latestUpdateDate.getFullYear();
                            const latestUpdateMonth = latestUpdateDate.getMonth()+1;
                            const latestUpdateDay = creationDate.getDate();
                            return (
                            <tr key={index}>
                                <td className='text-center'>{code.code}</td>
                                <td className='text-center'>
                                    {
                                        code.student_id
                                        ?
                                        t("Yes")
                                        :
                                        t("No")
                                    }
                                </td>
                            </tr>
                            )
                        })
                        :
                        <td colSpan={3}>{t("No data available")}</td>
                    
                    }
                    </tbody>
                </Table>
            </div>
        </div>
        <div style={{display: "none"}}>
            <div
                className='relative w-full flex flex-col landscape-print printable-content'
                ref={printSingleRef}
                style={{ padding: "20px" , height: "100vh"}} // Add padding for better print formatting
                >
                <div className='relative w-full flex justify-between items-center px-4'>
                    {/* <img src={qrcode} className='w-[100px]' alt='QR Code' /> */}
                    <div className='relative'>
                        <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "80px", width: "80px" }}
                            value={`${settingsData?.site_front_url}/courses/${execution?.id}`}
                            viewBox={`0 0 256 256`}
                        />
                    </div>
                    <div className='relative flex flex-col justify-center items-center'>
                        {/* <h3 className='text-center'>{execution?.title}</h3> */}
                        <h2 className='text-center'>{execution?.course?.name}</h2>
                        <h5 className='text-center'>{execution?.course?.user?.name}</h5>
                    </div>
                    <img src={settingsData?.site_logo} className='w-[100px]' alt='Site Logo' />
                </div>
                <div className='relative my-auto flex flex-col justify-center items-center'>
                    <div className='relative flex flex-col justify-center items-center '>
                        {/* <h4>Activation Code</h4> */}
                        <h2 style={{backgroundColor: "#14c9f5"}} className=' text-white rounded-lg py-3 px-[70px]'>{targetCode}</h2>
                    </div>
                    <div className='url-and-sochial relative flex flex-col justify-center items-center mt-3'>
                        <h4>{settingsData?.site_front_url}</h4>
                        <div className='icons relative flex'>
                            <i className="fa-brands fa-facebook mx-2 text-black text-[25px]"></i>
                            <i className="fa-brands fa-instagram mx-2 text-black text-[25px]"></i>
                            <i className="fa-brands fa-youtube mx-2 text-black text-[25px]"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style={{display: "none"}}>
            <div ref={printAllSingleRef}>
                {
                    activation_codes && activation_codes.length >= 1 && activation_codes.map((code,index)=> (
                        <div
                        key={index}
                        className='relative w-full flex flex-col landscape-print printable-content'
                        style={{ padding: "20px" , height: "100vh"}} // Add padding for better print formatting
                        >
                        <div className='relative w-full flex justify-between items-center px-4'>
                            <div className='relative'>
                                <QRCode
                                    size={256}
                                    style={{ height: "auto", maxWidth: "80px", width: "80px" }}
                                    value={`${settingsData?.site_front_url}/courses/${execution?.id}`}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                            <div className='relative flex flex-col justify-center items-center'>
                                <h2 className='text-center'>{execution?.course?.name}</h2>
                                <h5 className='text-center'>{execution?.course?.user?.name}</h5>
                            </div>
                            <img src={settingsData?.site_logo} className='w-[100px]' alt='Site Logo' />
                        </div>
                        <div className='relative my-auto flex flex-col justify-center items-center'>
                            <div className='relative flex flex-col justify-center items-center '>
                                <h2 style={{backgroundColor: "#14c9f5"}} className=' text-white rounded-lg py-3 px-[70px]'>{code.code}</h2>
                            </div>
                            <div className='url-and-sochial relative flex flex-col justify-center items-center mt-3'>
                                <h4>{settingsData?.site_front_url}</h4>
                                <div className='icons relative flex'>
                                    <i className="fa-brands fa-facebook mx-2 text-black text-[25px]"></i>
                                    <i className="fa-brands fa-instagram mx-2 text-black text-[25px]"></i>
                                    <i className="fa-brands fa-youtube mx-2 text-black text-[25px]"></i>
                                </div>
                            </div>
                        </div>
                        </div>
                    ))
                }
            </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
            props.onHide();
        }}>{t("Close")}</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ShowActivationCodes