import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import { Button, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { getChaptersFunc } from "../../../store/custom-actions/chapters/GetChaptersSlicer";
import { fetchSingleCourseFunc } from "../../../store/custom-actions/courses-actions/GetSingleCourseSlicer";
import ContextApi from "../../../store/ContextApi";
import { addChapterFunc } from "../../../store/custom-actions/chapters/AddChapterSlicer";
import { getSingleChapterFunc } from "../../../store/custom-actions/chapters/GetSingleChapterSlicer";
import { updateChapterFunc } from "../../../store/custom-actions/chapters/UpdateChapterSlicer";
import { useTranslation } from "react-i18next";

export default function UpdateChapterForm() {
    const {t} = useTranslation();
    const {chapterId} = useParams();
    useEffect(() => {
        console.log(chapterId)
    },[chapterId])
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        reset,
      } = useForm();
      const user = JSON.parse(window.localStorage.getItem("user"));
      const userRole = user && user.roleName;
      const contextApi = useContext(ContextApi);
      const { loading:updateChapterLoading } = useSelector((state) => state.updateChapterSlicer);
      const { loading: getChapterLoading, data:chapterData } = useSelector(
        (state) => state.getSingleChapterSlicer
      );
      const dispatch = useDispatch();
      const courses_API_URL = `${contextApi.dashboard_URL_API}/courses`;
      const [parentChapterId, setParentChapterId] = useState("");
      const [description, setDescription] = useState("");
      const chaptersApi = `${contextApi.dashboard_URL_API}/chapters`;
      useEffect(() => {
        const getChapter = async () => {
          dispatch(getSingleChapterFunc(`${chaptersApi}/${chapterId}`));
        };
        getChapter();
      }, []);
    
      const handleSubmitData = (data) => {
        console.log(data)
        // if (description !== "") {
          try {
            dispatch(
                updateChapterFunc({
                api: `${chaptersApi}/${chapterId}`,
                data: {
                  ...data,
                  description: description,
                  "_method" : "PUT"
                },
              })
            )
            // .then((result) => {
            //   if (result.payload.success === true) {
            //     // dispatch(getChaptersFunc(`${chaptersApi}`));
            //     window.history.back();
            //     // reset();
            //     // setDescription("");
            //   }
            // });
          } catch (error) {
            toast.error(error);
          }
        // } else {
        //   toast.error(t("Course Description Is Required"));
        // }
      };
    
      const colors = [
        '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
        '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
    ];
    const modules = {
        toolbar: {
        container: [
        [{"color" : colors}],
        [{ 'header': [1, 2, 3, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['link'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'align': [] }],
        ['clean']
        ],
    }
    };
    useEffect(() => {
        if(chapterData) {
            setValue("name" , chapterData.name)
            setValue("order" , chapterData.order)
            setDescription(chapterData.description)
        }
    },[setValue , chapterData])
    return (
        <>
        {
            getChapterLoading
            ?
            <>
                    <div className="row d-flex justify-content-center gap-2">
                      <Spinner animation="grow" className="bg-primary" />
                      <Spinner animation="grow" className="bg-primary" />
                      <Spinner animation="grow" className="bg-primary" />
                      <Spinner animation="grow" className="bg-primary" />
                    </div>
            </>
            :
            (
                chapterData
                ?
                <div className="row justify-content-center h-100 align-items-center" data-select2-id="15">
                <div className="col-xl-12 col-lg-12" data-select2-id="14">
                    <div className="card" data-select2-id="13">
                        <div className="card-header">
                        <h4 className="card-title">{t("Update Chapter")}</h4>
                        </div>
                        <div className="card-body" data-select2-id="12">
                        <div className="basic-form" data-select2-id="11">
                            <form
                            id="new-chapter-form"
                            onSubmit={handleSubmit((data) => {
                                handleSubmitData(data)
                            })}
                            >
                            <div className="row">
                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                <label>{t("Name")}</label>
                                {errors.name?.message && (
                                    <p className="text-red-600">{errors.name?.message}</p>
                                )}
                                <input
                                    {...register("name", {
                                    required: t("Name Is Required"),
                                    })}
                                    type="text"
                                    className="form-control"
                                />
                                </div>
                                <div className="form-group mb-3 col-sm-12 col-md-6">
                                <label>{t("Order")}</label>
                                {errors.order?.message && (
                                    <p className="text-red-600">{errors.order?.message}</p>
                                )}
                                <input
                                    {...register("order", {
                                    required: t("Order Is Required"),
                                    })}
                                    type="number"
                                    className="form-control"
                                />
                                </div>
                            </div>
                            <div className="row m-0">
                                <ReactQuill
                                modules={modules}
                                className="p-0 border-none outline-none form-control bg-white my-3"
                                value={description}
                                theme="snow"
                                onChange={setDescription}
                                />
                            </div>
                            </form>
                            <div className="relative flex items-center">
                                <Button
                                onClick={() => {
                                    window.history.back();
                                }}
                                >
                                {t("Back")}
                                </Button>
                                <Button type="submit" form="new-chapter-form" className={`mx-2 ${updateChapterLoading ? "pointer-events-none opacity-75" : ""}`}>
                                {!updateChapterLoading ? (
                                    t("Submit")
                                ) : (
                                    <>
                                    <div className="relative flex justify-center items-center">
                                        {t("loading")}..
                                        <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white"></div>
                                    </div>
                                    </>
                                )}
                                </Button>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                </div>
                :
                <h3>{t("No Data for This Chapter")}!</h3>
            )
        }
        </>
    )
}