import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const getQuizzesFunc = createAsyncThunk(
    "get quizzes function",
    async (api) => {
        try {
            const response = await axios.get(api );
            checkErrorsFunctions(response , response.data.errors);
            return response.data.data.quizzes;
        }
        catch (error) {
            checkErrors(error.response , error.message);
            return error
        }
        
    }
);

const getQuizzesSlicer = createSlice({
    name : "get quizzes slicer",
    initialState : {
        getQuizzesLoading : null,
        quizzesData : [],
        error : false
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(getQuizzesFunc.pending , (state)=>{
            state.getQuizzesLoading = true
            state.quizzesData = []
            state.error = null
        })
        builder.addCase(getQuizzesFunc.rejected , (state)=>{
            state.getQuizzesLoading = false
            state.quizzesData = []
            state.error = true
        })
        builder.addCase(getQuizzesFunc.fulfilled , (state , action)=>{
            state.getQuizzesLoading = false
            state.quizzesData = action.payload
            state.error = null
        })
    }
});
export default getQuizzesSlicer.reducer;