import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const getLanguageSettingsFunc = createAsyncThunk(
    "languageSettings/get",
    async (api) => {
        try {
            const response = await axios.get(api);
            return response.data.data;
        }
        catch(error) {
            checkErrors(error.response , error.message);
            return error
        }
    }
)
const getLanguageSettingsSlicer = createSlice({
    name : "languageSettings/get",
    initialState : {
        loading : false,
        data : null,
        error : false
    },
    extraReducers : builder => {
        builder.addCase(getLanguageSettingsFunc.pending , (state) => {
            state.loading = true
            state.data = null
            state.error = false
        })
        builder.addCase(getLanguageSettingsFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = false
        })
        builder.addCase(getLanguageSettingsFunc.rejected , (state) => {
            state.loading = false
            state.data = null
            state.error = true
        })
    }
})
export default getLanguageSettingsSlicer.reducer;