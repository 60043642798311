import { Modal , Button } from "react-bootstrap";
import "./payment-page.css"
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
function ShowPaymentModal(props) {
    const {t} = useTranslation();
    const {payment_types , payment_status , creation_date , last_update} = props;
    const [createionDate , setCreationDate] = useState("");
    const [lastUpdate , setLastUpdate] = useState("");

    useEffect(() => {
        if(creation_date || last_update) {
            const creation = new Date(creation_date)
            const creationYear = creation.getFullYear();
            const creationMonth = creation.getMonth()+1;
            const creationDay = creation.getDate();
            const updated = new Date(last_update)
            const lastUpdatedYear = updated.getFullYear();
            const lastUpdatedMonth = updated.getMonth()+1;
            const lastUpdateDay = updated.getDate();
            setCreationDate(`${creationYear} / ${creationMonth} / ${creationDay}`);
            setLastUpdate(`${lastUpdatedYear} / ${lastUpdatedMonth} / ${lastUpdateDay}`);
        }
    },[creation_date , last_update]);
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="flex items-center">
            {t("Show Payment")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="payment-info relative w-full flex flex-col">
                <div className="status flex items-center mb-1">
                    <h5 className="mb-0 mr-1">{t("Payment Status")}</h5>
                    :
                    <h4 className="ml-1 mb-0 font-normal text-[14px]">
                        {
                            payment_status === 1
                            ?
                            <span className="relative flex items-center">{t("Active")} <i className="fa-solid fa-circle-check ml-1 text-green-500"></i></span>
                            :
                            <span className="relative flex items-center">{t("Not Active")} <i className="fa-solid fa-circle-xmark ml-1 text-red-500"></i></span>
                        }
                    </h4>
                </div>
                <div className="relative flex flex-col">
                    {
                        payment_types
                        ?
                        Object.keys(payment_types).map((key , index) => {
                            return (
                                <div key={index} className="relative flex items-center mb-1 w-full">
                                    <h4 className="mb-0 text-slate-700 text-[14px] mr-1">{key}</h4> :
                                    <p className="mb-0 api ml-1 overflow-auto api-key-content">{payment_types[key]}</p>
                                </div>
                            )
                        })
                        :
                        <p>{t("No Types for this payment")}</p>
                    }
                </div>
                <div className="creation-date relative flex items-center mb-1">
                    <i className="fa-solid fa-clock mr-1"></i>
                    {t("Creation")} : <span className="text-primary ml-1">{createionDate}</span>
                </div>
                <div className="creation-date relative flex items-center mb-1">
                    <i className="fa-solid fa-clock mr-1"></i>
                    {t("Last Update")} : <span className="text-primary ml-1">{lastUpdate}</span>
                </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>{
            props.onHide();
          }}>{t("Close")}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

export default ShowPaymentModal;