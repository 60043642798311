/// Menu
import Metismenu from "metismenujs";
import React, { Component, useContext, useEffect, useState } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { ThemeContext } from "../../../context/ThemeContext";

// import medal from "../../../images/medal.png";
// import AddInstructorForm from "./addition-users-forms/add-instructors-form/AddInstructorForm";
// import AddAssistantForm from "./addition-users-forms/add-assistant-form/AddAssistantForm";
// import NewQuestionForm from "../../global-compnents/NewQuestionForm";
import NewQuizeForm from "../../global-compnents/new-quize-form/NewQuizeForm";


class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new Metismenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const TeacherLeftSidebar = () => {
  const [showInstructorForm , setShowInstructorForm] = useState();
  const [showAssistantForm , setShowAssistantForm] = useState();
  const [showStudentForm , setShowStudentForm] = useState();
  const [showQuizeForm , setShowQuizeForm] = useState();
  const [showQuestionForm , setShowQuestionForm] = useState();
  const { iconHover, sidebarposition, headerposition, sidebarLayout } =
    useContext(ThemeContext);
  useEffect(() => {
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }, []);
  //For scroll
  const [hideOnScroll, setHideOnScroll] = useState(true);
  useScrollPosition(
    ({ prevPos, currPos }) => {
      const isShow = currPos.y > prevPos.y;
      if (isShow !== hideOnScroll) setHideOnScroll(isShow);
    },
    [hideOnScroll]
  );
  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  /// Active menu
  let teacher = [
      "",
      "my-assistants",
      "my-courses",
      "my-permissions",
    ],
    offline = [
      "teacher-offline-page"
    ],
    addition = ["Add Quize" , "Add Question"]
    // Contacts = ["Users Messages"]

  return (
    <div
      className={`dlabnav ${iconHover} ${
        sidebarposition.value === "fixed" &&
        sidebarLayout.value === "horizontal" &&
        headerposition.value === "static"
          ? hideOnScroll > 120
            ? "fixed"
            : ""
          : ""
      }`}
    >
      <PerfectScrollbar className="dlabnav-scroll">
        <MM className="metismenu" id="menu">
          <li className={``}>
          <Link className="has-arrow" to="#">
              <i className="bi bi-grid"></i>
              <span className="nav-text">Teacher</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "my-courses" ? "mm-active" : ""}`}
                  to="/my-courses"
                >

                  My Courses
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "my-assistants" ? "mm-active" : ""}`}
                  to="/my-assistants"
                >

                  My Assistants
                </Link>
              </li>

              <li>
                <Link
                  className={`${path === "my-permissions" ? "mm-active" : ""}`}
                  to="/my-permissions"
                >
                  My Permissions
                </Link>
              </li>

            </ul>
          </li>

      <li className={`${addition.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow" to="#">
              <i className="fa-regular fa-square-plus"></i>
              <span className="nav-text">Addition</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === "add-quize" ? "mm-active" : ""}`}
                  to="#"
                  onClick={()=>setShowQuizeForm(true)}
                >

                  Add Quize
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === "add-question" ? "mm-active" : ""}`}
                  to="#"
                  onClick={()=>setShowQuestionForm(true)}
                >

                  Add Question
                </Link>
              </li>

              <li>
                <Link
                  className={`${path === "my-permissions" ? "mm-active" : ""}`}
                  to="/my-permissions"
                >


                  My Permissions
                </Link>
              </li>

            </ul>
      </li>
      <li className={`${offline.includes(path) ? "mm-active" : ""}`}>
        <Link className="has-arrow" to="#">
          <i className="fa-brands fa-hive"></i>
          <span className="nav-text forms">Offline</span>
        </Link>
        <ul>   
          <li>
            <Link
              className={`${path === "teacher-offline-page" ? "mm-active" : ""}`}
              to="/teacher-offline-page"
            >
              Offline Courses
            </Link>
          </li>
        </ul>
      </li>


        </MM>

        <div className="copyright">
          {/* <p><strong>GetSkills Online Learning Admin</strong> © 2022 All Rights Reserved</p> */}
          <p className="fs-12">{/* <span className="heart"></span>  */}</p>
        </div>
      </PerfectScrollbar>
      {
        showQuizeForm
        &&
        <NewQuizeForm
          show={showQuizeForm}
          onHide={()=>setShowQuizeForm(false)}
        />
      }
      {/* {
        showQuestionForm
        &&
        <NewQuestionForm
          show={showQuestionForm}
          onHide={()=>setShowQuestionForm(false)}
        />
      } */}
    </div>
  );
};

export default TeacherLeftSidebar;