import { createSlice , createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import swal from "sweetalert";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";

export const getAllLevelsFunc = createAsyncThunk(
    "get all levels",
    async (levelsApi) => {
        try{
            const response = await axios.get(levelsApi );
            checkErrorsFunctions(response , response.data.errors);
            return response.data.data.levels;
        }
        catch (error) {
            checkErrors(error.response , error.message);
        }
    }
)

const getAllLevelsSlicer = createSlice({
    name : "get levels slicer",
    initialState : {
        levelLoading : null,
        levels : [],
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(getAllLevelsFunc.fulfilled , (state , action) => {
            state.levels = action.payload
        })
    }
})
export default getAllLevelsSlicer.reducer