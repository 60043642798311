import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useContext, useEffect, useState } from 'react';
import ContextApi from '../../../store/ContextApi';
import sessionImg from "../../../media/images/online-learning.png";
import { addRoleFunc } from '../../../store/custom-actions/roles-actions/AddRoleSlicer';
import { getRolesFunc } from '../../../store/custom-actions/roles-actions/GetRolesSlicer';
import HandleErrorsListFunc from '../../global-functions/handleErrorsListFunc';
import { useTranslation } from 'react-i18next';
function NewRoleForm(props) {
    const {t} = useTranslation();
    const contextApi = useContext(ContextApi);
    const {register , handleSubmit , formState : {errors} , reset } = useForm();
    const dispatch = useDispatch();
    const roles_API_URL = `${contextApi.dashboard_URL_API}/roles`;
    const {loading:addRoleLoading} = useSelector(
        state => state.addRoleSlicer
    )
    const [stArr , setStArr] = useState([])

    const handleSubmitRole = async (data) => {
    dispatch(addRoleFunc(
      {
        api : roles_API_URL , 
        data : {...data ,
            permissions : stArr
        }}
    ))
    .then(result => {
        if(result.payload.success === true) {
            props.onHide();
            reset();
            dispatch(getRolesFunc(roles_API_URL));
        }
    })
  }
  useEffect(() => {
    HandleErrorsListFunc(errors)
  },[errors])

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className='flex items-center'>
          <img src={sessionImg} alt="session-icon" className='relative w-[20px] mr-2' />
          {t("New Role")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='session-form-div'>
            <form
                id="session-form"
                onSubmit={handleSubmit((data) => {
                    handleSubmitRole(data);
                })}
            >
                <div className='row'>
                    <div className='form-group col-sm-12 col-md-6'>
                        <label>{t("Name")}</label>
                        {errors.name?.message && (
                          <p className="text-red-600">
                            {errors.name?.message}
                          </p>
                        )}
                        <input 
                        {...register("name" , {required : "role name is required!"})}
                        type="text" 
                        className='form-control'
                        />
                    </div>
                    <div className='form-group col-sm-12 col-md-6'>
                        <label>{t("Description")}</label>
                        {errors.description?.message && (
                          <p className="text-red-600">
                            {errors.description?.message}
                          </p>
                        )}
                        <input 
                        {...register("description" , {required : "role description is required!"})}
                        type="text" 
                        className='form-control'
                        />
                    </div>
                </div>
            </form>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={() => {
          props.onHide();
          reset();
        }}>{t("Close")}</Button>
        <Button type="submit" form="session-form" className={`${addRoleLoading ? "pointer-events-none opacity-80" : ""}`}>
          {!addRoleLoading ? (
            t("Submit")
          ) : (
            <>
              <div className="relative flex justify-center items-center">
                {t("loading")}...
                <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white"></div>
              </div>
            </>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default NewRoleForm