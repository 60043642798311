import { useState , useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContextApi from "../../../store/ContextApi";
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { addInvoicesFunc } from "../../../store/custom-actions/invoices-actions/AddInvoicesSlicer";
import { fetchAllCoursesFunc } from "../../../store/custom-actions/courses-actions/GetAllCoursesSlicer";
import { fetchAllCategoriesFunc } from "../../../store/custom-actions/categories-actions/GetAllCategoriesSlicer";
import { getCourseExcutionFunc } from "../../../store/custom-actions/courses-actions/excutions-actions/GetCourseExcutionsSlicer";
import executionImg from "../../../media/images/course-img.png";

function AddInvoiceForm(props) {
    const contextApi = useContext(ContextApi)
    const {studentId , refetchFunction , student_id} = props;
    const {loading:addInvoicesLoading} = useSelector(state => state.addInvoicesSlicer)
    const {register , handleSubmit , setValue ,  formState : {errors} , watch , reset} = useForm();
    const dispatch = useDispatch();
    const courses_API_URL = `${contextApi.dashboard_URL_API}/courses`;
    const categories_API_URL = `${contextApi.dashboard_URL_API}/categories`;
    const invoicesApi = `${contextApi.dashboard_URL_API}/invoices`;
    const stId  = watch("student_id");
    const [courseId , setCourseId] = useState();
    const [coursesList , setCoursesList] = useState([])
    const [categoriesList , setCategoriesList] = useState([])
    const [executionsList , setExecutionsList] = useState([])
    const [courseWhichChoosed , setCourseWhichChoosed] = useState("Choose Course");
    const [chooseCourse , setChooseCourse] = useState()
    const [category , setCategory] = useState()
    const { loading:getCoursesLoading , courses } = useSelector(
        (state) => state.getAllCoursesSlicer
      );
    const { loading:getCategoriesLoading, categoriesData } = useSelector(
        (state) => state.getAllCategoriesSlicer
      );
    const { loading:getCourseExecutionsLoading , data:courseExecutionsData } = useSelector(
        (state) => state.getCourseExcutionsSlicer
      );

      const [chooseExecution , setChooseExecution] = useState(false)
      const [executionWhichChoosed , setExecutionWhichChoosed] = useState(false)
      const [courseExecutionId , setCourseExecutionId] = useState()
      const [price , setPrice] = useState()
    useEffect(() => {
        const getAllData = async () => {
          try {
            dispatch(fetchAllCoursesFunc(courses_API_URL));
            dispatch(fetchAllCategoriesFunc(categories_API_URL));
          } catch (error) {
            alert(error);
          }
        };
        getAllData();
      }, []);
    
      
      useEffect(() => {
        const getCourses = async () => {
          dispatch(fetchAllCoursesFunc(`${courses_API_URL}?${category ? `category_id=${category}` : ""}`));
        }
        getCourses()
      },[category]);
      useEffect(() => {
        const getCourseExecutions = async () => {
          dispatch(getCourseExcutionFunc(`${courses_API_URL}/${courseId}/course-executions`));
        }
        courseId && getCourseExecutions()
      },[courseId])

      useEffect(() => {
        courses && setCoursesList(courses);
        categoriesData && setCategoriesList(categoriesData);
        courseExecutionsData && setExecutionsList(courseExecutionsData);
      }, [courses , categoriesData ,courseExecutionsData]);

    const handleSubmitSubscrieData = (data) => {
      if(data) {
        dispatch(addInvoicesFunc(
            {api : invoicesApi , 
              data : {...data , student_id : !student_id ? stId : student_id , course_execution_id : courseExecutionId} }
            ))
            .then((result)=>{
              if(result.payload.success === true){
                props.onHide();
                reset();
                refetchFunction();
                setChooseCourse(false);
                setCourseWhichChoosed("");
              }
            }
        )
        }
    }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <i className="fa-solid fa-file-invoice-dollar mr-1"></i>
          New Invoice
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" w-full">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <form 
                  id="new-subscription-form"
                  className="new-subscription-form w-full"
                  onSubmit={handleSubmit((data)=>{
                    handleSubmitSubscrieData(data);
                  })}
                >
                  {
                    studentId
                    &&
                    <div className="row">
                      <div className="form-group mb-3 w-full">
                          <label>Student Id</label>
                          {errors.student_id?.message && (
                            <p className="text-red-600">
                              {errors.student_id?.message}
                            </p>
                          )}
                          <input
                            {...register("student_id", {
                              required: "Student Id Is Required",
                            })}
                            type="number"
                            className="form-control w-full"
                          />
                        </div>
                    </div>
                  }
                  <div className="row">
                    {
                      getCategoriesLoading
                      ?
                      <p>Loading Categories...</p>
                      :
                      <div className="form-group mb-3 w-full">
                          <label>Category</label>
                          <select
                            className="form-control w-full"
                            onChange={(e) => setCategory(e.target.value)}
                            defaultValue={0}
                          >
                            <option className="" value={0} disabled>Choose Category..</option>
                            {
                              categoriesData
                              &&
                              categoriesList.length >= 1
                              ?
                              categoriesList.map((cat , index) => {
                                return <option key={index} className="" value={cat.id}>{cat.name}</option>
                              })
                              :
                              <p>No Categories</p>
                            }
                          </select>
                      </div>
                    }
                  </div>
                  {
                    category && category !== 0
                    &&
                    <div className="row">
                      <div className="form-group mb-3 col-sm-12">
                      {
                        getCoursesLoading
                        ?
                        (
                          <p>Loading Courses...</p>
                        )
                        :
                        (
                          courses
                          ?
                          (
                            coursesList.length >= 1
                            ?
                          <div className="form-group mb-3 col-sm-12  flex flex-col">
                            <div className="form-group mb-3 col-sm-12">
                                <label>Course Execution</label>
                                {errors.course_execution_id?.message && (
                                <p className="text-red-600">
                                {errors.course_execution_id?.message}
                                </p>
                                )}
                                <span 
                                className="relative col-sm-12 flex items-center px-1 w-full h-[45px] rounded-md border border-[#302f2f] cursor-text"
                                onClick={()=>setChooseCourse(true)}
                                >{courseWhichChoosed}</span>
                            </div>
                            <div className={`instructors-list col-sm-12 overflow-y-auto transition-all duration-500 ${chooseCourse ? "h-[150px]" : "h-0"}`}>
                                {coursesList.length > 0 &&
                                coursesList.map(course =>{
                                    return (
                                    <div key={course.id} className="input-div relative col-sm-12 flex items-center my-1 p-2 pl-0">
                                        <input
                                          form="new-subscripe-form"
                                          id={course.name} 
                                          type="radio" 
                                          name="course" 
                                          value={course.id}
                                          className="hidden"
                                          data-name={course.name}
                                          onChange={(e)=>{
                                          setCourseWhichChoosed(e.target.dataset.name);
                                          setChooseCourse(false);
                                          setCourseId(e.target.value)
                                        }}
                                        />
                                        <label htmlFor={course.name} className="cursor-pointer mr-2 flex items-center">
                                        <img src={course.image_url ? course.image_url : "https://img.freepik.com/free-vector/students-watching-webinar-computer-studying-online_74855-15522.jpg?w=826&t=st=1702899442~exp=1702900042~hmac=f3c89975f0344fd66d9bfba0158a777d5e50d509a0b753d71c809ad690ab30f3"} alt="img" className="w-[35px] h-[35px] mx-2 rounded-[50%]"/>
                                        {course.name}
                                        </label>
                                    </div>
                                    )
                                })
                                }
                            </div>
                          </div>
                          :
                          <p>No Courses With This Category</p>

                          )
                          :
                          <p>Feild Getting Courses</p>
                        )
                      }
                      </div>
                    </div>
                  }
                  {
                    courseId
                    &&
                    <div className="row">
                      <div className="form-group mb-3 col-sm-12">
                      {
                        getCoursesLoading
                        ?
                        (
                          <p>Loading Executions...</p>
                        )
                        :
                        (
                          courseExecutionsData
                          ?
                          (
                            executionsList.length >= 1
                            ?
                          <div className="form-group mb-3 col-sm-12  flex flex-col">
                            <div className="form-group mb-3 col-sm-12">
                                <label>Course Executions</label>
                                {errors.course_execution_id?.message && (
                                <p className="text-red-600">
                                {errors.course_execution_id?.message}
                                </p>
                                )}
                                <span 
                                className="relative col-sm-12 flex items-center px-1 w-full h-[45px] rounded-md border border-[#302f2f] cursor-text"
                                onClick={()=>setChooseExecution(!chooseExecution)}
                                >{executionWhichChoosed}</span>
                            </div>
                            <div className={`instructors-list col-sm-12 overflow-y-auto transition-all duration-500 ${chooseExecution ? "h-[150px]" : "h-0"}`}>
                                {executionsList.length > 0 &&
                                executionsList.map(execution =>{
                                    return (
                                    <div key={execution.id} className="input-div relative col-sm-12 flex items-center my-1 p-2 pl-0">
                                        <input 
                                          {...register("course_execution_id")}
                                          form="new-subscripe-form"
                                          id={execution.title} 
                                          type="radio" 
                                          name="execution" 
                                          value={execution.id}
                                          className="hidden"
                                          data-name={execution.title}
                                          onChange={(e)=>{
                                          setExecutionWhichChoosed(e.target.dataset.name);
                                          setChooseExecution(!chooseExecution);
                                          setPrice(execution.price)
                                          setCourseExecutionId(execution.id)
                                        }}
                                        />
                                        <label htmlFor={execution.title} className="cursor-pointer mr-2 flex items-center">
                                        <img src={executionImg} alt="img" className="w-[35px] h-[35px] mx-2 rounded-[50%]"/>
                                        {execution.title}
                                        </label>
                                    </div>
                                    )
                                })
                                }
                            </div>
                          </div>
                          :
                          <p>No Executions With This Course</p>

                          )
                          :
                          <p>Feild Getting executions</p>
                        )
                      }
                      </div>
                    </div>
                  }
                  <div> 
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={()=>{
          props.onHide()
          reset()
          setCourseWhichChoosed("")
          setChooseCourse(false)
        }}>Close</Button>
        <Button type="submit" form="new-subscription-form" className={`${addInvoicesLoading ? "opacity-80 pointer-events-none" : ""}`}>
            {!addInvoicesLoading 
            ? 
            "Subscripe" 
            :
            <div className="relative flex justify-center items-center">
              loading..
              <div className="loading-spin-data w-[16px] h-[16px] ml-1 rounded-[50%] border-t-[2px] border-r-[2px] border-white "></div>
            </div> 
            }
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default AddInvoiceForm;