import { Modal , Button } from "react-bootstrap";
import { useState , useEffect, useContext } from "react";
import { useSelector , useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import swal from "sweetalert";
import { getQuestionsFunc } from "../../../store/custom-actions/Question-actions/GetQuestionsSlicer";
import ContextApi from "../../../store/ContextApi";
import { addNewQuizeFunc } from "../../../store/custom-actions/quizzes-actions/AddNewQuizeSlicer";
import { getQuizzesFunc } from "../../../store/custom-actions/quizzes-actions/GetQuizzesSlicer";
import Swal from "sweetalert2";
import { fetchAllCategoriesFunc } from "../../../store/custom-actions/categories-actions/GetAllCategoriesSlicer";
import QuestionBox from "./QuestionBox";
import quizeIcon from "../../../media/images/page.png";
import "./new-quize-form.css";
import { toast } from "react-toastify";
import HandleErrorsListFunc from "../../global-functions/handleErrorsListFunc";

function NewQuizeForm(props) {
    const {
      register,
      handleSubmit,
      formState: { errors },
      setValue,
      watch,
      reset,
    } = useForm();
    const contextApi = useContext(ContextApi);
    const courseQuestionsApi = `${contextApi.dashboard_URL_API}/questions`;
    const CategoriesApi = `${contextApi.dashboard_URL_API}/categories`;
    const quizzesApi = `${contextApi.dashboard_URL_API}/quizzes`;
    const { addQuizeLoading } = useSelector((state) => state.addQuizeSlicer);
    const { data:questionsData , getQuestionsLoading} = useSelector((state) => state.getQuestionsSlicer);
    const { loading:getCategoriesLoading , categoriesData} = useSelector((state) => state.getAllCategoriesSlicer);
    const dispatch = useDispatch();
    const [questionsList , seQuestionsList] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [showChooseQuestions , setShowChooseQuestions] = useState();
    const [categoriesList , setCategoriesList] = useState([]);
    const [questionSearch , setQuestionSearch] = useState("");
    const [quizeCategory , setQuizeCategory] = useState("");
    const [questionsType , setQuestionsType] = useState();
    const [arrayWillSend , setArrayWillSend] = useState([]);
    const [categoryId , setCategoryId] = useState(0);
    const getQuestions = async () => {
      dispatch(getQuestionsFunc(`${courseQuestionsApi}?${quizeCategory ? `category_id=${quizeCategory}` : ""}&${questionSearch !== "" ? `search=${questionSearch}` : ""}&${questionsType ? `type=${questionsType}` : ""}`))
    }
    const getCategories = async () => {
        dispatch(fetchAllCategoriesFunc(CategoriesApi))
        .then(result => console.log(result))
    }
    useEffect(() => {
        getCategories();
    },[])
    useEffect(() => {
      getQuestions();
    },[quizeCategory , questionSearch , questionsType])
    useEffect(() => {
      questionsData && seQuestionsList(questionsData);
      categoriesData && setCategoriesList(categoriesData);
    },[questionsData , categoriesData])

    const handleAddQuestion = (question) => {
        setArrayWillSend([...arrayWillSend , question])
    }
    const handleUndoAddQuestion = (question) => {
        const filtred = arrayWillSend.filter(Q => Q.id !== question.id)
        setArrayWillSend(filtred)
    }
    const handleSubmitQuize = (data) => {
      if(data && selectedOption) {
        const questionsIdWillSend = arrayWillSend.flatMap(Q => Q.id);
        if(questionsIdWillSend.length < 1) {
          toast.error("Questions List Is Empty!");
        }else {
          dispatch(addNewQuizeFunc({api : quizzesApi , 
              quizeData: {
                title : data.title,
                questions_count : data.questions_count,
                category_id : data.category_id,
                questions : questionsIdWillSend
            }
          }))
          .then(result => {
            if(result.payload.success === true){
              reset();
              props.onHide();
              setArrayWillSend([]);
              dispatch(getQuizzesFunc(quizzesApi));
              setCategoryId(0)
            }
          })
        }
      }
    }
    useEffect(() => {
      quizeCategory !== "" ? setShowChooseQuestions(true) : setShowChooseQuestions(false)
    },[quizeCategory])
    useEffect(() => {
      HandleErrorsListFunc(errors)
    },[errors])
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter" className="flex items-center">
            <img src={quizeIcon} className="relative w-[20px] mr-2" alt="form img" />
            New Quize
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="basic-form">
            <form
              id="add-quize-form"
              onSubmit={handleSubmit((data) => {
                  handleSubmitQuize(data);
              })}
            >
                {
                    <div className="row">
                        <div className="form-group mb-3 col-sm-12 col-md-6">
                        <label>Quize Title</label>
                        {errors.title?.message && (
                            <p className="text-red-600">{errors.title?.message}</p>
                        )}
                        <input
                            {...register("title", {
                            required: "Quize Title is required!",
                            })}
                            type="text"
                            className="form-control placeholder:text-[10px]"
                        />
                        </div>
                        {
                            getCategoriesLoading
                            ?
                            <p>Loading Categories..</p>
                            :
                            (
                            categoriesData && categoriesList
                            &&
                            <div className={`form-group mb-3 col-sm-12 col-md-6`}>
                                <label>quize Category</label>
                                {errors.category_id?.message && (
                                <p className="text-red-600">{errors.category_id?.message}</p>
                                )}
                                <select 
                                    {...register("category_id" , {required : "Quize Category must be not empty!"})}
                                    defaultValue={0}
                                    className="mb-3 form-control col-sm-12"
                                    onChange={(e)=>{
                                      setQuizeCategory(e.target.value)
                                      setCategoryId(e.target.value)
                                    }}
                                >
                                <option value={0} disabled>Choose Category</option>
                                    {
                                        categoriesList &&
                                        categoriesList.map((cat , index)=>{
                                        return <option key={index} value={cat.id}>{cat.name} {cat.level && cat.level.name && cat.level.name}</option>
                                        })
                                    }
                                </select>
                            </div>
                            )
                        }
                    </div>
                }
                {
                  parseInt(categoryId) !== 0
                  &&
                  <>
                      <div className="relative my-3 flex justify-center items-center after:absolute after:left-[50%] after:translate-x-[-50%] after:top-[50%] after:px-2 after:bg-white after:content-['Questions'] after:translate-y-[-50%] bg-slate-300 w-full h-[1px]"></div>
                      <div className="quiestions-content mt-2 relative flex flex-col">
                          <div className="filter-questions row relative flex items-center">
                              <div className="search-div form-group mb-3 col-sm-12 col-md-6">
                                  <label className="type-label" htmlFor="questions-type">Search</label>
                                  <input 
                                      className="form-control"
                                      type="search" 
                                      onChange={(e)=>setQuestionSearch(e.target.value)}
                                  />
                              </div>
                              <div className={`form-group mb-3 col-sm-12 col-md-6`}>
                                  <label className="type-label" htmlFor="questions-type">Questions Type</label>
                                  <select
                                      id="questions-type"
                                      className="form-control"
                                      onChange={(e)=>setQuestionsType(e.target.value)}
                                  >
                                      <option value={""}>All</option>
                                      <option value={1}>True & False</option>
                                      <option value={2}>Choice Questions</option>
                                      <option value={3}>Article Questions</option>
                                  </select>
                              </div>
                          </div>
                          <div className="questions p-2 relative flex flex-col max-h-[300px] overflow-y-auto">
                              {
                                  getQuestionsLoading
                                  ?
                                  (
                                      <div className="relative flex  w-full justify-center items-center">
                                          <span className="relative flex h-3 w-3 mx-2">
                                              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                                              <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                                          </span>
                                          <span className="relative flex h-3 w-3 mx-2">
                                              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                                              <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                                          </span>
                                          <span className="relative flex h-3 w-3 mx-2">
                                              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                                              <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                                          </span>
                                          <span className="relative flex h-3 w-3 mx-2">
                                              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-sky-400 opacity-75"></span>
                                              <span className="relative inline-flex rounded-full h-3 w-3 bg-sky-500"></span>
                                          </span>
                                      </div>
                                  )
                                  :
                                  (
                                      questionsData && questionsList.length >= 1
                                      ?
                                      (
                                          questionsList.map((question , index)=>{
                                              return (
                                                  <QuestionBox
                                                      key={index}
                                                      title={question.title}
                                                      answers={question.options}
                                                      correct_answer={question.correct_option}
                                                      questionType = {question.type}
                                                      questionImg = {question.image_url}
                                                      question={question}
                                                      additionFunc={()=>handleAddQuestion(question)}
                                                      undoAddition = {() => handleUndoAddQuestion(question)}
                                                      arrayWillSend={arrayWillSend}
                                                  />
                                              )
                                          })
                                      )
                                      :
                                      <p className="m-0 text-center">No Questions!</p>
                                  )
                              }
                          </div>
                      </div>
                    </>
                }
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>{
            props.onHide();
            reset();
            setQuizeCategory("");
            setShowChooseQuestions(false);
            setArrayWillSend([]);
          }}>Close</Button>
          <Button type="submit" form="add-quize-form" className={`${addQuizeLoading ? "pointer-events-none opacity-80" : ""}`}>
            {!addQuizeLoading ? (
              "Add Quize"
            ) : (
              <>
                <div className="relative flex justify-center items-center">
                  loading..
                  <div className="loading-spin-data w-[16px] h-[16px] rounded-[50%] border-t-[2px] border-r-[2px] border-white "></div>
                </div>
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

export default NewQuizeForm;