import { useContext, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ContextApi from "../../../store/ContextApi";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { editCourseFunc } from "../../../store/custom-actions/courses-actions/EditCourseSlicer";
import Swal from "sweetalert2";
import HandleErrorsListFunc from "../../global-functions/handleErrorsListFunc";
import ReactQuill from "react-quill";
import { useTranslation } from "react-i18next";

function UpdateCourseForm(props) {
    const {t} = useTranslation();
    const {
      course_id,
      oldcoursename,
      oldcoursedescription,
      refetch_data_func,
    } = props;
    const {
      register,
      handleSubmit,
      setValue,
      formState: { errors },
      reset,
    } = useForm();
    const contextApi = useContext(ContextApi);
    const updateCourseApi = `${contextApi.dashboard_URL_API}/courses/${course_id}`;
    const { loading } = useSelector((state) => state.editCourseSlicer);
    const dispatch = useDispatch();
    const [courseDescription , setCourseDescription] = useState();

    useEffect(() => {
      setValue("name", oldcoursename);
      oldcoursedescription && setCourseDescription(oldcoursedescription);
    }, [
      setValue,
      oldcoursename,
      oldcoursedescription,
    ]);
    const handleSubmitData = (data) => {
      const courseFormData = new FormData();
      courseFormData.append("name" , data.name);
      courseFormData.append("description" , courseDescription);
      courseFormData.append("category_id" , data.category_id);
      courseFormData.append("_method" , 'PUT');
      if (data.name === "") {
        toast.error(t("Course name must not be empty!"));
        return;
      }
      if (data.description === "") {
        toast.error(t("Course Description must not be empty!"));
        return;
      }
      try {
        dispatch(
          editCourseFunc({
            courseApi: updateCourseApi,
            courseData: courseFormData,
          })
        ).then(((result) => {
          if(result.payload.success === true){
            refetch_data_func()
            reset();
            props.onHide();
          }
        }))
      }
      catch(error) {
        Swal.fire({
            title: t("Oops!"),
            text: error,
            icon: "error"
        });
      }
    }
    const colors = [
      '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
      '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
      '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
      '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
      '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466',
      '#000000', '#990000', '#b26b00', '#b2b200', '#005c00', '#003399', '#592966'
  ];
  const modules = {
    toolbar: {
      container: [
        [{ 'font': [] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': colors }, { 'background': colors }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'align': [] }],
        ['blockquote', 'code-block'],
        ['link', 'image', 'video'],
        ['clean']
      ],
    }
  };
  useEffect(() => {
    HandleErrorsListFunc(errors);
  },[errors])
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("Update")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" w-full">
            <div className="card">
              <div className="card-body">
                <div className="basic-form">
                  <form
                    id="update-course-form"
                    onSubmit={handleSubmit((data) => {
                        handleSubmitData(data);
                    })}
                  >
                    <div className="row">
                      <div className="form-group mb-3 col-sm-12">
                        <label>{t("Course Name")}</label>
                        {errors.name?.message && (
                          <p className="text-red-600">{errors.name?.message}</p>
                        )}
                        <input
                          {...register("name", {
                            required: t("Course Name is Required"),
                          })}
                          type="text"
                          className="form-control"
                        />
                      </div>
                    </div>
                      <div className="row">
                          <div className="lesson-description-line mb-3 relative my-3 flex justify-center items-center after:absolute after:left-[50%] after:translate-x-[-50%] after:top-[50%] after:px-2 after:bg-[#eaebef] after:content-['Lesson Description'] after:translate-y-[-50%] bg-slate-300 w-full h-[1px]"></div>
                          <ReactQuill
                            modules = {modules}
                            className="p-0 border-none outline-none form-control bg-white my-3" 
                            value={courseDescription} theme="snow" 
                            onChange={(content) => setCourseDescription(content)} />
                      </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>{
            props.onHide();
            reset()
          }}>{t("Close")}</Button>
          <Button type="submit" form="update-course-form" className="btn btn-primary flex items-center justify-center">
            {!loading ? (
              t("Submit")
            ) : (
              <div className="relative flex justify-center items-center">
                {t("loading")}..
                <div className="loading-spin-data w-[15px] h-[15px] rounded-[50%] border-t-[2px] border-r-[2px] border-white ml-1"></div>
              </div>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
  export default UpdateCourseForm;