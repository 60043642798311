import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../../jsx/global-functions/checkErrorsFunction";
import Swal from "sweetalert2";
import { checkErrors } from "../../../../jsx/global-functions/checkErrors";
export const deleteLessonRequirmentFunc = createAsyncThunk(
    "delete lesson requirment function",
    async (api) => {
        try {
            const response = await axios.delete(api );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
        
    }
);

const deleteLessonRequirmentSlicer = createSlice({
    name : "delete lesson requirment",
    initialState : {
        loading : null,
        data : [],
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(deleteLessonRequirmentFunc.pending , (state)=>{
            state.loading = true
            state.data = []
            state.error = null
        })
        builder.addCase(deleteLessonRequirmentFunc.rejected , (state)=>{
            state.loading = false
            state.data = []
            state.error = true
        })
        builder.addCase(deleteLessonRequirmentFunc.fulfilled , (state , action)=>{
            state.loading = false
            state.data = action.payload
            state.error = null
        })
    }
});
export default deleteLessonRequirmentSlicer.reducer;