import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
import Swal from "sweetalert2";

export const addLessonNameAndDescFunc = createAsyncThunk(
    "add lesson func",
    async ({lessonsApi , lessonData}) => {
        try {
            const response = await axios.post(lessonsApi , lessonData );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
    }
);

const addLessonNameAndDescSlicer = createSlice({
    name : "add lesson slicer",
    initialState : {
        loadingNameAndDesc : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(addLessonNameAndDescFunc.pending , (state) => {
            state.loadingNameAndDesc = true;
            state.data = null;
            state.error = null;
        });
        builder.addCase(addLessonNameAndDescFunc.rejected , (state) => {
            state.loadingNameAndDesc = false;
            state.data = null;
            state.error = true;
        });
        builder.addCase(addLessonNameAndDescFunc.fulfilled , (state , action) => {
            state.loadingNameAndDesc = false;
            state.data = action.payload;
            state.error = false;
        });
    }
})
export default addLessonNameAndDescSlicer.reducer;