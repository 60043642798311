import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
import Swal from "sweetalert2";

export const deleteQuestionFunc = createAsyncThunk(
    "delete question function",
    async (api) => {
        try {
            const response = await axios.delete(api );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
    }
)

const deleteQuestionSlicer = createSlice({
    name : "delete question slicer",
    initialState : {
        deleteQuestionLoading : null,
        data : null,
        error : "error" 
    },
    reducer :{},
    extraReducers : (builder) => {
        builder.addCase(deleteQuestionFunc.pending , (state) => {
            state.deleteQuestionLoading = true
            state.data = null
            state.error = null
        })
        builder.addCase(deleteQuestionFunc.rejected , (state) => {
            state.deleteQuestionLoading = null
            state.data = null
            state.error = true
        })
        builder.addCase(deleteQuestionFunc.fulfilled , (state , action) => {
            state.deleteQuestionLoading = false
            state.data = action.payload
            state.error = null
        })
    }
})
export default deleteQuestionSlicer.reducer;