import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import swal from "sweetalert";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const unApproveCourseFunc = createAsyncThunk(
    "un approve course function",
    async ({coursesApi}) => {
        try {
            const response = await axios.put(coursesApi , {publish : "unpublish"} );
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            // swal({
            //     title: "Oops!",
            //     text: error,
            //     icon: "warning",
            //     button: "Retry",
            // });
            checkErrors(error.response , error.message);
        }
        
    }
)

const unApproveCourseSlicer = createSlice({
    name : "un approve course slicer",
    initialState : {
        unapprove_course_loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(unApproveCourseFunc.pending , (state) => {
            state.unapprove_course_loading = true
            state.data = {}
            state.error = false
        });
        builder.addCase(unApproveCourseFunc.rejected , (state) => {
            state.unapprove_course_loading = false
            state.data = {}
            state.error = true
        });
        builder.addCase(unApproveCourseFunc.fulfilled , (state , action) => {
            state.unapprove_course_loading = false
            state.data = action.payload
            state.error = false
        });
    }
});

export default unApproveCourseSlicer.reducer;