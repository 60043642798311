import { useState , useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsersFunc } from "../../../store/custom-actions/users-actions/GetUsersSlicer";
import ContextApi from "../../../store/ContextApi";
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';
import assistantIcon from "../../../media/images/information.png";
import { getRolesFunc } from "../../../store/custom-actions/roles-actions/GetRolesSlicer";
import Swal from "sweetalert2";
import { addNewUserFunc } from "../../../store/custom-actions/users-actions/AddUserSlicer";
import { toast } from "react-toastify";
import HandleErrorsListFunc from "../../global-functions/handleErrorsListFunc";
import { useTranslation } from "react-i18next";

function NewUserForm(props) {
    const {t} = useTranslation();
    const {register , handleSubmit , setValue ,  formState : {errors} , watch , reset} = useForm();
    const {loading:addUserLoading} = useSelector(state => state.addNewUserSLicer)
    const {loading:getRolesLoading , data:rolesData} = useSelector(state => state.getRolesSlicer)
    const dispatch = useDispatch();
    const contextApi = useContext(ContextApi)
    const users_API = `${contextApi.dashboard_URL_API}/users`;
    const roles_API = `${contextApi.dashboard_URL_API}/roles`;
    const userPassword = watch("password");
    const userConfirmPassword = watch("password_confirmation");
    const validName = /[a-zA-Z]{3,}/;
    const validEmail = /^[a-zA-Z0-9]+@[a-zA-Z]+\.[a-zA-Z]{2,4}$/;
    const validPass = /[a-zA-Z0-9]{8,}/;
    const [validationPassword , setValidationPassword] = useState();
    const [rolesList, setRolesList] = useState([]);
    const [userImage, setUserImage] = useState();
    const [role, setRole] = useState("");
    const validPhone = /[0-9]{11,}/;

    const getRoles = () => {
      try {
        dispatch(getRolesFunc(roles_API))
      }
      catch (error) {
        Swal.fire({
          title: t("Oops!"),
          text: error,
          icon: "warning"
        });
      }
    }
    useEffect(() => {
      getRoles()
    },[])

    useEffect(() => {
      const uniqeRoles = rolesData && rolesData.filter(role => role.name.toLowerCase() !== "manager")
      rolesData && uniqeRoles && setRolesList(uniqeRoles)
    },[rolesData])
    
    useEffect(() => {
      userConfirmPassword !== userPassword
      ?
      setValidationPassword(false)
      :
      setValidationPassword(true);
    },[userPassword , userConfirmPassword])


    const handleUploadUserData = (data) => {
      if(data) {
        if(role !== ""){
            const userFormData = new FormData();
            userFormData.append("name" , data.name);
            userFormData.append("email" , data.email);
            userFormData.append("phone" , data.phone);
            userFormData.append("password" , data.password);
            userFormData.append("password_confirmation" , data.password_confirmation);
            userFormData.append("role" , data.role);
            userImage && userFormData.append("image" , userImage);
            if (!validationPassword) {
              toast.error(t("Password Not Confirmed!"));
              return;
            }
            if (!data.name.match(validName)) {
              toast.error(t("User name must be more than 3 letters!"));
              return;
            }
            if (!data.phone.match(validPhone)) {
              toast.error(t("Phone must be 11 numbers!"));
              return;
            }
            if (!data.email.match(validEmail)) {
              toast.error(t("Please enter a valid email!"));
              return;
            }
            if (!data.password.match(validPass)) {
              toast.error(t("Password must be more than 8 characters!"));
              return;
            }
            dispatch(addNewUserFunc({usersApi : users_API , userData : userFormData }))
            .then((result)=>{
              if(result.payload.success === true){
                window.location.reload();
                dispatch(fetchUsersFunc(users_API));
                props.onHide();
                reset();
              }
            })
          }else {
            Swal.fire({
              title: t("Oops!"),
              text: t("User Role Should Not be empty"),
              icon: "warning"
            });
          }
        }
    }
    useEffect(() => {
      HandleErrorsListFunc(errors);
    },[errors])

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" className="flex items-center">
          <img src={assistantIcon} className="relative w-[20px] mr-2" alt="form img" />
          {t("New user")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className=" w-full">
          <div className="card">
            <div className="card-body">
              <div className="basic-form">
                <form 
                  id="new-assistant-form"
                  onSubmit={handleSubmit((data)=>{
                  if(validationPassword){
                    handleUploadUserData(data);
                  }else {
                    swal("Password not confirmed!");
                  }
                })}>
                  <div className="row">
                    <div className="form-group mb-3 col-sm-12 col-md-6">
                      <label>{t("Name")}</label>
                      {errors.name?.message &&
                        <p className="text-red-600">{errors.name?.message}</p>
                        }
                      <input
                        {...register("name" , {required : t("Name Is Required!")})}
                        type="text"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3 col-sm-12 col-md-6">
                      <label>{t("Email")}</label>
                      {errors.email?.message &&
                        <p className="text-red-600">{errors.email?.message}</p>
                        }
                      <input
                        {...register("email" , {required : t("Email Is Required!")})}
                        type="text"
                        className="form-control"
                        placeholder="Example12@gmail.com"
                      />
                    </div>
                    <div className="form-group mb-3 col-sm-12 col-md-6">
                      <label>{t("Password")}</label>
                      {errors.password?.message &&
                        <p className="text-red-600">{errors.password?.message}</p>
                        }
                      <input
                        {...register("password" , {required : t("Password Is Required!")})}
                        type="password"
                        className="form-control"
                      />
                    </div>
                    <div className="form-group mb-3 col-sm-12 col-md-6">
                      <label>{t("Confirm Password")}</label>
                      {errors.password_confirmation?.message &&
                        <p className="text-red-600">{errors.password_confirmation?.message}</p>
                        }
                      <input 
                        type="password" 
                        className="form-control"
                        {...register("password_confirmation" , {required : t("Please Confirm Password!")})} 
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12 col-md-6">
                      {
                      getRolesLoading
                      ?
                      <p className="text-center w-full">{t("loading")}...</p>
                      :
                      <>
                        <label>{t("Role")}</label>
                        {errors.role?.message &&
                          <p className="text-red-600">{errors.role?.message}</p>
                          }
                        <select className="form-control"
                        {...register("role" , {required : t("Role Should Not Be Empty!")})}
                        onChange={(e) => setRole(e.target.value)}
                        >
                          <option value={""}>{t("All Roles")}</option>
                          {
                            rolesList
                            &&
                            rolesList.length >= 1
                            &&
                            rolesList.map((role , index) => {
                              return <option key={index} value={role.name}>{role.name}</option>
                            })
                          }
                        </select>
                      </>
                      }
                    </div>
                    <div className="form-group  col-sm-12 col-md-6">
                      <label>{t("Phone Number")}</label>
                      {errors.phone?.message &&
                        <p className="text-red-600">{errors.phone?.message}</p>
                        }
                      <input 
                        type="text" 
                        className="form-control"
                        {...register("phone" , {required : t("User Phone Is Required!")})} 
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-sm-12">
                      <label>{t("User Image")} ( {t("Best Size")} 200 * 200 )</label>
                      {errors.image?.message &&
                        <p className="text-red-600">{errors.image?.message}</p>
                        }
                      <input 
                        accept="image/jpeg"
                        type="file" 
                        className="form-control"
                        onChange={(e) => setUserImage(e.target.files[0])}
                      />
                    </div>
                  </div>
                  <div> 
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>{t("Close")}</Button>
        <Button type="submit" form="new-assistant-form" className={`${addUserLoading ? "pointer-events-none opacity-80" : ""}`}>
            {!addUserLoading 
            ? 
            t("Submit") 
            :
            <div className="relative flex justify-center items-center">
              {t("loading")}..
              <div className="loading-spin-data w-[16px] h-[16px] ml-1 rounded-[50%] border-t-[2px] border-r-[2px] border-white "></div>
            </div> 
            }
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewUserForm;