// import "./courses.css";
import React from "react";
import { useEffect } from "react";
import {useState} from "react";
import { Link } from "react-router-dom";
import onlineIcon from "../../../media/images/audiobook.png";
import offlineIcon from "../../../media/images/offline.png";
import { Button } from "react-bootstrap";

function OfflineCourseCard({
  id,
  course,
  course_img,
  course_status,
  course_title,
  course_price,
  coures_instructor,
  course_rating,
  lessonsCount,
  loading,
  courseType,
  sessionsCount
}) {

    const [animate , setAnimate] = useState()
    const user = JSON.parse(window.localStorage.getItem("user"));

    useEffect(() => {
      !loading ? setTimeout(()=>setAnimate(true) , 300) : setAnimate(false)
    },[loading])
    
  return (
    <>
      <div style={{"animationDelay": `.${id}s`}} className={`course-card hover:shadow-lg hover:shadow-slate-400 hover:translate-y-[-5px] transition-all duration-500 bg-white relative w-full shadow-md shadow-slate-300 sm:w-[270px] flex flex-col justify-center p-3 rounded-md overflow-hidden ${animate ? "animate" : ""}`}>
        {
          // course_status === "publish"
          // ?
          // <div className="relative flex items-center my-2">
          //   <i className="fa-solid fa-bookmark mr-1 text-xl text-green-500" 
          //   title="this course is glopaly on platform"></i>
          //   {course_status === "publish" ? "active" : "not active yet"}
          // </div>
          // :
          // <div className="relative flex items-center my-2">
          //   <i className={`fa-solid fa-bookmark text-red-700 mr-1 text-xl`}
          //     title="this course is not glopaly on platform"></i>
          //   {course_status === "publish" ? "active" : "not active yet"}
          // </div>
          
        }
        <div className="course-image relative w-full h-[200px] overflow-hidden rounded-md">
          <img
            src={`${course_img != null ? course_img : "https://img.freepik.com/free-vector/students-watching-webinar-computer-studying-online_74855-15522.jpg?w=826&t=st=1702899442~exp=1702900042~hmac=f3c89975f0344fd66d9bfba0158a777d5e50d509a0b753d71c809ad690ab30f3"}`}
            alt="course img"
            className="absolute top-0 left-0 w-full h-full transition-all duration-500"
          />
        </div>
        <div className="course-info flex flex-col my-2">
          <div className="row flex my-1 justify-between">
            <h4
              className="text-lg font-bold text-black"
            >
              {course_title}
            </h4>
            <h2 className="text-lg text-[#ff6a59]">${course_price}</h2>
          </div>
          <div className="row flex items-center my-1">
            <h3 className="text-sm my-auto col-sm-6 col-md-3">{coures_instructor.name}</h3>
            <div className="flex items-center course-rating col-sm-6 col-md-3">
              <i className="fa-solid fa-star text-orange-500 mr-1"></i>
              <span className="text-orange-500">({course_rating})</span>
            </div>
            <div className="col-sm-6 col-md-6 flex p-0 items-center col-sm-6 col-md-6">
              <i className="fa-solid fa-globe mr-1 text-[15px] opacity-75"></i>
              <p className="m-0 text-sm">Sessions ({sessionsCount})</p>
            </div>
          </div>
        </div>
        <div className="btns-group relative flex justify-between items-center mb-2">
          <div className="relative flex flex-col rounded-md">
                <div className="box">
                  <i className="fa-solid fa-book-open-reader mr-2 text-[20px] opacity-75"></i>
                  Lessons ({lessonsCount})
                </div>
                {
                  courseType === "online"
                  ?
                  <div className="relative flex items-center">
                    <img src={onlineIcon} alt="online img" className="w-[20px] mr-2" />
                    Online
                  </div>
                  :
                  <div className="relative flex items-center mt-1">
                    <img src={offlineIcon} alt="online img" className="w-[20px] mr-2" />
                    Offline
                  </div>
                }
          </div>
          {
            courseType === "offline"
            &&
            <Link className="btn btn-primary" to={`sessions-page/${id}`}>Sessions</Link>
          }
        </div>
      </div>
    </>
  );
}

export default OfflineCourseCard;