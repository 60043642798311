import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
export const approveAssistantFunc = createAsyncThunk(
    "approve assistant function",
    async (assistantApi) => {
        const config = {
            headers: {
              "Content-Type": "application/json",
              "Accept": "application/json",
            },
        }
        try {
            const response = await axios.put(assistantApi , {publish : "publish"}  , config);
            checkErrorsFunctions(response , response.data.errors);
            return response.data;
        }
        catch (error) {
            console.log(error)
            checkErrors(error.response , error.message);
        }
        
    }
)

const approveAssistantSlicer = createSlice({
    name : "approve assistant slicer",
    initialState : {
        approve_assistant_loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(approveAssistantFunc.pending , (state) => {
            state.approve_assistant_loading = true
            state.data = {}
            state.error = false
        });
        builder.addCase(approveAssistantFunc.rejected , (state) => {
            state.approve_assistant_loading = false
            state.data = {}
            state.error = true
        });
        builder.addCase(approveAssistantFunc.fulfilled , (state , action) => {
            state.approve_assistant_loading = false
            state.data = action.payload
            state.error = false
        });
    }
});

export default approveAssistantSlicer.reducer;