import { createSlice , createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import checkErrorsFunctions from "../../../jsx/global-functions/checkErrorsFunction";
import { checkErrors } from "../../../jsx/global-functions/checkErrors";
import Swal from "sweetalert2";
export const getSingleRoleFunc = createAsyncThunk(
    "get single role function",
    async (api) => {
        try {
            const response = await axios.get(api );
            checkErrorsFunctions(response , response.data.errors);
            return response.data.data.role;
        }
        catch (error) {
checkErrors(error.response , error.message);
        }
        
    }
)

const getSingleRoleSlicer = createSlice({
    name : "get single role slicer",
    initialState : {
        loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(getSingleRoleFunc.pending , (state) => {
            state.loading = true
            state.data = false
            state.error = false
        });
        builder.addCase(getSingleRoleFunc.rejected , (state) => {
            state.loading = false
            state.data = null
            state.error = true
        });
        builder.addCase(getSingleRoleFunc.fulfilled , (state , action) => {
            state.loading = false
            state.data = action.payload
            state.error = null
        });
    }
});

export default getSingleRoleSlicer.reducer;